export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Base64 encoded binary string. */
  Binary: any;
  /** Supported timezone. */
  Timezone: any;
  /** Valid currencies */
  Currency: any;
  /** Type for language codes */
  Language: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** Float rounded to 4 decimal places. */
  RoundedFloat: any;
  URL: any;
  /** IP address */
  IpAddress: any;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A time string at UTC, such as 10:15:30Z, compliant with the `full-time` format outlined in section 5.6 of the RFC 3339profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Time: any;
  /** Integer between 0 and 6 inclusive corresponding to Sunday, Monday, Tuesday, and so on. */
  DayOfWeek: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** Float rounded to 8 decimal places. */
  RoundedCryptoFloat: any;
  /** Time duration as supported by https://www.npmjs.com/package/ms. */
  Duration: any;
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values.BigInt can represent values between -(2^53) + 1 and 2^53 - 1. */
  BigInt: any;
  /** Integer between 1 and 31 inclusive. */
  DayOfMonth: any;
  Month: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Account = {
  id: Scalars['ID'];
  role: AccountRole;
  username: Scalars['String'];
  currency: Scalars['Currency'];
  language: Scalars['Language'];
  timezone: Scalars['Timezone'];
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  realName?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  address?: Maybe<Address>;
  countryCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  /**
   * > Mobile number should include the country code e.g.
   *
   * +631234567890
   */
  mobileNumber?: Maybe<Scalars['String']>;
  wechatId?: Maybe<Scalars['String']>;
  qqId?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  mobileNumberVerified?: Maybe<Scalars['Boolean']>;
  wechatIdVerified?: Maybe<Scalars['Boolean']>;
  qqIdVerified?: Maybe<Scalars['Boolean']>;
  lastLoginDateTime: Scalars['DateTime'];
  registrationDateTime: Scalars['DateTime'];
  dateTimeCreated: Scalars['DateTime'];
  dateTimeUpdated: Scalars['DateTime'];
  otpValidationEnabled: Scalars['Boolean'];
  forceChangePassword?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Scalars['String']>;
};

export enum AccountRole {
  SuperAdmin = 'SUPER_ADMIN',
  Admin = 'ADMIN',
  AdminOperator = 'ADMIN_OPERATOR',
  Operator = 'OPERATOR',
  Member = 'MEMBER',
}

export type AccountUsageReport = {
  __typename?: 'AccountUsageReport';
  id: Scalars['ID'];
  timezone?: Maybe<Scalars['Timezone']>;
  creator: Account;
  dateTimeCreated: Scalars['DateTime'];
  filter: AccountUsageReportFilter;
  report?: Maybe<File>;
  scheduledDateTime?: Maybe<Scalars['DateTime']>;
  status: AccountUsageReportStatus;
};

export type AccountUsageReportFilter = {
  __typename?: 'AccountUsageReportFilter';
  dateTimeProcessed: DateTimeQueryOperator;
  admin: IdQueryOperator;
  vendor?: Maybe<IdQueryOperator>;
};

export type AccountUsageReportFilterInput = {
  status?: Maybe<StringQueryOperatorInput>;
};

export type AccountUsageReportsConnection = {
  __typename?: 'AccountUsageReportsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<AccountUsageReportsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type AccountUsageReportsConnectionEdge = {
  __typename?: 'AccountUsageReportsConnectionEdge';
  cursor: Scalars['Binary'];
  node: AccountUsageReport;
};

export enum AccountUsageReportStatus {
  Generating = 'GENERATING',
  Generated = 'GENERATED',
  Scheduled = 'SCHEDULED',
}

export type Address = {
  __typename?: 'Address';
  building?: Maybe<Scalars['String']>;
  premise?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  stateDistrict?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  building?: Maybe<Scalars['String']>;
  premise?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  stateDistrict?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
};

export type AdjustAccountBalanceInput = {
  amount: Scalars['Float'];
  turnoverRequirement: Scalars['Float'];
  scope?: Maybe<UnitScopeInput>;
  remarks?: Maybe<Scalars['String']>;
};

export type AdjustBalanceInput = {
  amount: Scalars['Float'];
  remarks: Scalars['String'];
};

export type Admin = Account & {
  __typename?: 'Admin';
  id: Scalars['ID'];
  role: AccountRole;
  adminCode: Scalars['String'];
  username: Scalars['String'];
  currency: Scalars['Currency'];
  language: Scalars['Language'];
  timezone: Scalars['Timezone'];
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  realName?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  address?: Maybe<Address>;
  countryCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  wechatId?: Maybe<Scalars['String']>;
  qqId?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  mobileNumberVerified?: Maybe<Scalars['Boolean']>;
  wechatIdVerified?: Maybe<Scalars['Boolean']>;
  qqIdVerified?: Maybe<Scalars['Boolean']>;
  lastLoginDateTime: Scalars['DateTime'];
  registrationDateTime: Scalars['DateTime'];
  dateTimeCreated: Scalars['DateTime'];
  dateTimeUpdated: Scalars['DateTime'];
  /** @deprecated No longer used. */
  turnover: Scalars['Float'];
  /** @deprecated No longer used. */
  winloss: Scalars['Float'];
  /** @deprecated No longer used. */
  revenueShare: Scalars['Float'];
  /** @deprecated No longer used. */
  vendor: Scalars['Boolean'];
  company?: Maybe<Scalars['String']>;
  skypeId?: Maybe<Scalars['String']>;
  trial: Scalars['Boolean'];
  contractStartDateTime?: Maybe<Scalars['DateTime']>;
  contractEndDateTime?: Maybe<Scalars['DateTime']>;
  enabled: Scalars['Boolean'];
  status: AdminStatus;
  twoFactorAuthenticationEnabled: Scalars['Boolean'];
  twoFactorAuthenticationKey?: Maybe<Scalars['String']>;
  twoFactorAuthenticationQRCode?: Maybe<Scalars['URL']>;
  ipWhitelist?: Maybe<Array<Scalars['IpAddress']>>;
  vendorGroup?: Maybe<VendorGroup>;
  attachments?: Maybe<Array<File>>;
  adminOperators: AdminOperatorsConnection;
  subdomain: Scalars['String'];
  personInCharge?: Maybe<Scalars['String']>;
  otpValidationEnabled: Scalars['Boolean'];
  forceChangePassword?: Maybe<Scalars['Boolean']>;
  multiCurrencyEnabled?: Maybe<Scalars['Boolean']>;
  linkedAdmins?: Maybe<Array<Maybe<LinkedAdmin>>>;
};

export type AdminRevenueShareArgs = {
  vendor?: Maybe<VendorCode>;
};

export type AdminVendorArgs = {
  vendor?: Maybe<VendorCode>;
};

export type AdminAdminOperatorsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
};

export enum AdminCategory {
  Trial = 'TRIAL',
  Production = 'PRODUCTION',
}

export type AdminGroup = {
  __typename?: 'AdminGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
  admins: Array<Maybe<Scalars['ID']>>;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeUpdated: Scalars['DateTime'];
};

export type AdminGroupsConnection = {
  __typename?: 'AdminGroupsConnection';
  totalCount: Scalars['Int'];
  edges: Array<AdminGroupsConnectionEdge>;
  pageInfo: PageInfo;
};

export type AdminGroupsConnectionEdge = {
  __typename?: 'AdminGroupsConnectionEdge';
  cursor: Scalars['Binary'];
  node: AdminGroup;
};

export type AdminMetrics = {
  __typename?: 'AdminMetrics';
  totalMemberBalance: Scalars['RoundedFloat'];
  totalMemberEffectiveBet: Scalars['RoundedFloat'];
};

export type AdminOperator = {
  __typename?: 'AdminOperator';
  id: Scalars['ID'];
  role: AccountRole;
  username: Scalars['String'];
  currency: Scalars['Currency'];
  language: Scalars['Language'];
  timezone: Scalars['Timezone'];
  realName?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  email?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  wechatId?: Maybe<Scalars['String']>;
  qqId?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  mobileNumberVerified?: Maybe<Scalars['Boolean']>;
  wechatIdVerified?: Maybe<Scalars['Boolean']>;
  qqIdVerified?: Maybe<Scalars['Boolean']>;
  lastLoginDateTime: Scalars['DateTime'];
  registrationDateTime: Scalars['DateTime'];
  dateTimeCreated: Scalars['DateTime'];
  dateTimeUpdated: Scalars['DateTime'];
};

export type AdminOperatorsConnection = {
  __typename?: 'AdminOperatorsConnection';
  totalCount: Scalars['Int'];
  edges: Array<AdminOperatorsConnectionEdge>;
  pageInfo: PageInfo;
};

export type AdminOperatorsConnectionEdge = {
  __typename?: 'AdminOperatorsConnectionEdge';
  cursor: Scalars['Binary'];
  node: AdminOperator;
};

export type AdminOperatorsFilterInput = {
  id?: Maybe<IdQueryOperatorInput>;
  username?: Maybe<StringQueryOperatorInput>;
  adminCode?: Maybe<StringQueryOperatorInput>;
};

export type AdminsConnection = {
  __typename?: 'AdminsConnection';
  totalCount: Scalars['Int'];
  edges: Array<AdminsConnectionEdge>;
  pageInfo: PageInfo;
};

export type AdminsConnectionEdge = {
  __typename?: 'AdminsConnectionEdge';
  cursor: Scalars['Binary'];
  node: Admin;
};

export type AdminsFilterInput = {
  id?: Maybe<IdQueryOperatorInput>;
  username?: Maybe<StringQueryOperatorInput>;
  company?: Maybe<StringQueryOperatorInput>;
  adminCode?: Maybe<StringQueryOperatorInput>;
  vendorGroup?: Maybe<IdQueryOperatorInput>;
  contractStartDateTime?: Maybe<DateTimeQueryOperatorInput>;
  contractEndDateTime?: Maybe<DateTimeQueryOperatorInput>;
  enabled?: Maybe<BooleanQueryOperatorInput>;
  status?: Maybe<AdminStatusQueryOperatorInput>;
  trial?: Maybe<BooleanQueryOperatorInput>;
};

export enum AdminStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Expired = 'EXPIRED',
  Draft = 'DRAFT',
}

export type AdminStatusQueryOperatorInput = {
  eq?: Maybe<AdminStatus>;
  ne?: Maybe<AdminStatus>;
  in?: Maybe<Array<AdminStatus>>;
  nin?: Maybe<Array<AdminStatus>>;
};

export type AdminVendor = {
  __typename?: 'AdminVendor';
  revenueShare: Scalars['RoundedFloat'];
  vendor: Scalars['Boolean'];
};

export type AdminVendorRevenueShareArgs = {
  vendor: VendorCode;
};

export type AdminVendorVendorArgs = {
  vendor: VendorCode;
};

export type Affiliate = {
  __typename?: 'Affiliate';
  id: Scalars['ID'];
  code: Scalars['String'];
};

export type AffiliatePayout = {
  __typename?: 'AffiliatePayout';
  id: Scalars['ID'];
  serialCode: Scalars['String'];
  member: Scalars['ID'];
  affiliateProgramme: Scalars['ID'];
  amount?: Maybe<Scalars['RoundedFloat']>;
  positiveCarry?: Maybe<Scalars['RoundedFloat']>;
  status?: Maybe<AffiliatePayoutStatus>;
  dateTimeCreated?: Maybe<Scalars['Date']>;
  dateTimeClaimed?: Maybe<Scalars['Date']>;
};

export enum AffiliatePayoutStatus {
  Pending = 'PENDING',
  Ready = 'READY',
  Expired = 'EXPIRED',
  Claimed = 'CLAIMED',
}

export type AffiliateProgramme = {
  __typename?: 'AffiliateProgramme';
  id: Scalars['ID'];
  serialCode: Scalars['String'];
  creator: Account;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  automaticPayoutClaiming?: Maybe<Scalars['Boolean']>;
  negativeCarryOverEnabled?: Maybe<Scalars['Boolean']>;
  vendorPlatformFees?: Maybe<Array<VendorPlatformFee>>;
  minimumEffectiveBetRequirement?: Maybe<Scalars['RoundedFloat']>;
  minimumDepositRequirement?: Maybe<Scalars['RoundedFloat']>;
  minimumPayoutAccumulationEnabled?: Maybe<Scalars['Boolean']>;
  minimumPayoutAccumulationAmount?: Maybe<Scalars['RoundedFloat']>;
  turnoverRequirementMultiplier?: Maybe<Scalars['RoundedFloat']>;
  settlementPeriod?: Maybe<AffiliateProgrammeSettlementPeriod>;
  settlementTime?: Maybe<Scalars['Time']>;
  settlementDayOfWeek?: Maybe<Scalars['DayOfWeek']>;
  settlementDayOfMonth?: Maybe<Scalars['DayOfMonth']>;
  payoutClaimOffsetDuration?: Maybe<Scalars['Duration']>;
  payoutClaimExpiryDuration?: Maybe<Scalars['Duration']>;
  payoutExpiryDuration?: Maybe<Scalars['Duration']>;
  levels?: Maybe<Array<AffiliateProgrammeLevel>>;
  levelsCount?: Maybe<Scalars['Int']>;
  levelsRequirement: AffiliateProgrammeLevelsRequirement;
  status: AffiliateProgrammeStatus;
  membersCount: Scalars['Int'];
  depositTransactionCost?: Maybe<Scalars['RoundedFloat']>;
  thirdPartyDepositTransactionCost?: Maybe<Scalars['RoundedFloat']>;
  withdrawalTransactionCost?: Maybe<Scalars['RoundedFloat']>;
  thirdPartyWithdrawalTransactionCost?: Maybe<Scalars['RoundedFloat']>;
  promoCost?: Maybe<Scalars['RoundedFloat']>;
  rebateCost?: Maybe<Scalars['RoundedFloat']>;
  interestAccountCost?: Maybe<Scalars['RoundedFloat']>;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeUpdated: Scalars['DateTime'];
};

export type AffiliateProgrammeLevel = {
  __typename?: 'AffiliateProgrammeLevel';
  name: Scalars['String'];
  /** Make this required after data is cleared */
  minimumActiveMembersCount?: Maybe<Scalars['Int']>;
  /** @deprecated Use `minimumActiveMembersCount` instead. */
  minimumActiveMembers?: Maybe<Scalars['Int']>;
  minimumNetProfit: Scalars['RoundedFloat'];
  percentage: Scalars['RoundedFloat'];
};

export type AffiliateProgrammeLevelInput = {
  name: Scalars['String'];
  minimumActiveMembersCount: Scalars['Int'];
  minimumActiveMembers?: Maybe<Scalars['Int']>;
  minimumNetProfit: Scalars['Float'];
  percentage: Scalars['Float'];
};

export enum AffiliateProgrammeLevelsRequirement {
  Both = 'BOTH',
  Either = 'EITHER',
  NetProfit = 'NET_PROFIT',
  ActiveMembersCount = 'ACTIVE_MEMBERS_COUNT',
}

export type AffiliateProgrammesConnection = {
  __typename?: 'AffiliateProgrammesConnection';
  totalCount: Scalars['Int'];
  edges: Array<AffiliateProgrammesConnectionEdge>;
  pageInfo: PageInfo;
};

export type AffiliateProgrammesConnectionEdge = {
  __typename?: 'AffiliateProgrammesConnectionEdge';
  cursor: Scalars['Binary'];
  node: AffiliateProgramme;
};

export enum AffiliateProgrammeSettlementPeriod {
  /**
   * ## Note
   * This is for testing
   */
  Hourly = 'HOURLY',
  /** # DEPRECATED */
  Daily = 'DAILY',
  Biweekly = 'BIWEEKLY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY',
}

export type AffiliateProgrammesFilterInput = {
  name?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<AffiliateProgrammeStatusQueryOperatorInput>;
  default?: Maybe<BooleanQueryOperatorInput>;
  negativeCarryOverEnabled?: Maybe<BooleanQueryOperatorInput>;
  serialCode?: Maybe<StringQueryOperatorInput>;
};

export enum AffiliateProgrammeStatus {
  Draft = 'DRAFT',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type AffiliateProgrammeStatusQueryOperatorInput = {
  eq?: Maybe<AffiliateProgrammeStatus>;
  ne?: Maybe<AffiliateProgrammeStatus>;
  in?: Maybe<Array<AffiliateProgrammeStatus>>;
  nin?: Maybe<Array<AffiliateProgrammeStatus>>;
};

export type AffiliateRequest = {
  __typename?: 'AffiliateRequest';
  id: Scalars['ID'];
  serialCode: Scalars['String'];
  member: Member;
  formFields: Array<FormField>;
  formFieldValues: Array<Maybe<Scalars['String']>>;
  programme?: Maybe<AffiliateProgramme>;
  processor?: Maybe<Account>;
  status: AffiliateRequestStatus;
  remarks?: Maybe<Scalars['String']>;
  dateTimeProcessingStarted?: Maybe<Scalars['DateTime']>;
  dateTimeProcessingEnded?: Maybe<Scalars['DateTime']>;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeUpdated: Scalars['DateTime'];
};

export type AffiliateRequestsConnection = {
  __typename?: 'AffiliateRequestsConnection';
  totalCount: Scalars['Int'];
  edges: Array<AffiliateRequestsConnectionEdge>;
  pageInfo: PageInfo;
};

export type AffiliateRequestsConnectionEdge = {
  __typename?: 'AffiliateRequestsConnectionEdge';
  cursor: Scalars['Binary'];
  node: AffiliateRequest;
};

export type AffiliateRequestsFilterInput = {
  status?: Maybe<AffiliateRequestStatusQueryOperatorInput>;
  serialCode?: Maybe<StringQueryOperatorInput>;
};

export enum AffiliateRequestStatus {
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
}

export type AffiliateRequestStatusQueryOperatorInput = {
  eq?: Maybe<AffiliateRequestStatus>;
  ne?: Maybe<AffiliateRequestStatus>;
  in?: Maybe<Array<AffiliateRequestStatus>>;
  nin?: Maybe<Array<AffiliateRequestStatus>>;
};

export type AffiliatesFilterInput = {
  upline?: Maybe<IdQueryOperatorInput>;
  username?: Maybe<StringQueryOperatorInput>;
  programme?: Maybe<IdQueryOperatorInput>;
  programmeLevel?: Maybe<StringQueryOperatorInput>;
  serialCode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<StringQueryOperatorInput>;
};

export type AgentExpense = {
  __typename?: 'AgentExpense';
  sharePercentage: Scalars['Float'];
  maximum?: Maybe<Scalars['Float']>;
};

export type AgentExpenseInput = {
  sharePercentage: Scalars['Float'];
  maximum?: Maybe<Scalars['Float']>;
};

export type AgentLevel = {
  __typename?: 'AgentLevel';
  id: Scalars['ID'];
  name: Scalars['String'];
  commissionEnabled: Scalars['Boolean'];
  minimumMemberTurnover: Scalars['Float'];
  notes: Scalars['String'];
  withdrawalFeeExpense?: Maybe<AgentExpense>;
  rebateExpense?: Maybe<AgentExpense>;
  commissionLevels: Array<CommissionLevel>;
  isDefault?: Maybe<Scalars['Boolean']>;
};

export type AlipayDeposit = Deposit & {
  __typename?: 'AlipayDeposit';
  id: Scalars['ID'];
  amount: Scalars['RoundedFloat'];
  account: Member;
  processingTime?: Maybe<Scalars['Int']>;
  paymentMethod?: Maybe<PaymentMethod>;
  status: DepositStatus;
  processor?: Maybe<Account>;
  serialCode: Scalars['String'];
  firstDeposit?: Maybe<Scalars['Boolean']>;
  remarks?: Maybe<Scalars['String']>;
  manualAdjustment: Scalars['Boolean'];
  transactionReference?: Maybe<Scalars['String']>;
  depositorName?: Maybe<Scalars['String']>;
  transactionDetails: DepositEventDetails;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeProcessed?: Maybe<Scalars['DateTime']>;
  baseCurrency?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  amountInBaseCurrency?: Maybe<Scalars['RoundedFloat']>;
  isCrypto?: Maybe<Scalars['Boolean']>;
};

export type AlipayPaymentMethod = PaymentMethod & {
  __typename?: 'AlipayPaymentMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  currentAccumulation: Scalars['RoundedFloat'];
  memberLoyaltyLevel?: Maybe<Array<MemberLoyaltyLevel>>;
  excludedMemberLevel?: Maybe<Array<MemberLevel>>;
  minimumDepositAmount: Scalars['RoundedFloat'];
  maximumDepositAmount: Scalars['RoundedFloat'];
  instructionText?: Maybe<Scalars['String']>;
  maximumDepositAmountPerDay: Scalars['RoundedFloat'];
  depositExpiry: Scalars['RoundedFloat'];
  turnoverRequirementMultiplier: Scalars['RoundedFloat'];
  suggestedAmounts: Array<Scalars['RoundedFloat']>;
  transactionFee: Scalars['RoundedFloat'];
  transactionFeeType?: Maybe<TransactionFeeType>;
  allowCustomAmount: Scalars['Boolean'];
  updateable: Scalars['Boolean'];
  nickname: Scalars['String'];
  image?: Maybe<File>;
  provider?: Maybe<PaymentMethodProvider>;
};

export type AlipayWithdrawalMethod = WithdrawalMethod & {
  __typename?: 'AlipayWithdrawalMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  updateable: Scalars['Boolean'];
  transactionFee: Scalars['RoundedFloat'];
  transactionFeeType: TransactionFeeType;
  provider?: Maybe<WithdrawalMethodProvider>;
  nickname: Scalars['String'];
  accountNumber?: Maybe<Scalars['String']>;
};

export type AlphapoDeposit = Deposit & {
  __typename?: 'AlphapoDeposit';
  id: Scalars['ID'];
  amount: Scalars['RoundedFloat'];
  account: Member;
  processingTime?: Maybe<Scalars['Int']>;
  paymentMethod?: Maybe<PaymentMethod>;
  status: DepositStatus;
  processor?: Maybe<Account>;
  serialCode: Scalars['String'];
  firstDeposit?: Maybe<Scalars['Boolean']>;
  remarks?: Maybe<Scalars['String']>;
  manualAdjustment: Scalars['Boolean'];
  transactionDetails: DepositEventDetails;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeProcessed?: Maybe<Scalars['DateTime']>;
  cryptoAmount?: Maybe<Scalars['RoundedFloat']>;
  cryptoExchangeRate?: Maybe<Scalars['Float']>;
  cryptoCurrency?: Maybe<Scalars['String']>;
  depositorBlockchainAddress: Scalars['String'];
  depositorBlockchainAddressURL: Scalars['URL'];
  transactionReference?: Maybe<Scalars['String']>;
  transactionReferenceURL?: Maybe<Scalars['URL']>;
  image?: Maybe<File>;
  exchangeRate: Scalars['Float'];
  baseCurrency?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  amountInBaseCurrency?: Maybe<Scalars['RoundedFloat']>;
  isCrypto?: Maybe<Scalars['Boolean']>;
};

export type AlphapoPaymentMethod = PaymentMethod & {
  __typename?: 'AlphapoPaymentMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  currentAccumulation: Scalars['RoundedFloat'];
  memberLoyaltyLevel?: Maybe<Array<MemberLoyaltyLevel>>;
  excludedMemberLevel?: Maybe<Array<MemberLevel>>;
  minimumDepositAmount: Scalars['RoundedFloat'];
  maximumDepositAmount: Scalars['RoundedFloat'];
  instructionText?: Maybe<Scalars['String']>;
  maximumDepositAmountPerDay: Scalars['RoundedFloat'];
  depositExpiry: Scalars['RoundedFloat'];
  turnoverRequirementMultiplier: Scalars['RoundedFloat'];
  suggestedAmounts: Array<Scalars['RoundedFloat']>;
  transactionFee: Scalars['RoundedFloat'];
  transactionFeeType?: Maybe<TransactionFeeType>;
  allowCustomAmount: Scalars['Boolean'];
  updateable: Scalars['Boolean'];
  image?: Maybe<File>;
  cryptocurrency?: Maybe<Cryptocurrency>;
  blockchain?: Maybe<Blockchain>;
  blockchainAddress?: Maybe<Scalars['String']>;
  blockchainAddressURL?: Maybe<Scalars['URL']>;
  minimumConfirmationsCount?: Maybe<Scalars['Int']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  exchangeRateSource?: Maybe<ExchangeRateSource>;
  exchangeRateUpdateFrequency?: Maybe<Scalars['String']>;
  exchangeRateSpread?: Maybe<Scalars['RoundedFloat']>;
  provider?: Maybe<PaymentMethodProvider>;
};

export type ApproveWithdrawalInput = {
  withdrawalMethod: Scalars['ID'];
};

export type ApproveWithdrawalNextInput = {
  withdrawalMethod?: Maybe<Scalars['ID']>;
  remarks?: Maybe<Scalars['String']>;
};

export type AstroPayWalletDeposit = Deposit & {
  __typename?: 'AstroPayWalletDeposit';
  id: Scalars['ID'];
  amount: Scalars['RoundedFloat'];
  account: Member;
  processingTime?: Maybe<Scalars['Int']>;
  paymentMethod?: Maybe<PaymentMethod>;
  status: DepositStatus;
  processor?: Maybe<Account>;
  serialCode: Scalars['String'];
  firstDeposit?: Maybe<Scalars['Boolean']>;
  remarks?: Maybe<Scalars['String']>;
  transactionReference?: Maybe<Scalars['String']>;
  manualAdjustment: Scalars['Boolean'];
  transactionDetails: DepositEventDetails;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeProcessed?: Maybe<Scalars['DateTime']>;
  baseCurrency?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  amountInBaseCurrency?: Maybe<Scalars['RoundedFloat']>;
  isCrypto?: Maybe<Scalars['Boolean']>;
};

export type AstroPayWalletPaymentMethod = PaymentMethod & {
  __typename?: 'AstroPayWalletPaymentMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
  minimumDepositAmount: Scalars['RoundedFloat'];
  maximumDepositAmount: Scalars['RoundedFloat'];
  instructionText?: Maybe<Scalars['String']>;
  turnoverRequirementMultiplier: Scalars['RoundedFloat'];
  suggestedAmounts: Array<Maybe<Scalars['RoundedFloat']>>;
  provider?: Maybe<PaymentMethodProvider>;
  transactionFee: Scalars['RoundedFloat'];
  transactionFeeType?: Maybe<TransactionFeeType>;
  allowCustomAmount: Scalars['Boolean'];
  updateable: Scalars['Boolean'];
  depositExpiry: Scalars['RoundedFloat'];
  excludedMemberLevel?: Maybe<Array<MemberLevel>>;
  memberLoyaltyLevel?: Maybe<Array<MemberLoyaltyLevel>>;
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  currentAccumulation: Scalars['RoundedFloat'];
  maximumDepositAmountPerDay: Scalars['RoundedFloat'];
};

export type AstroPayWalletWithdrawalMethod = WithdrawalMethod & {
  __typename?: 'AstroPayWalletWithdrawalMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  updateable: Scalars['Boolean'];
  transactionFee: Scalars['RoundedFloat'];
  transactionFeeType: TransactionFeeType;
  provider?: Maybe<WithdrawalMethodProvider>;
};

export type AutomatedMessage = {
  __typename?: 'AutomatedMessage';
  id: Scalars['ID'];
  event: AutomatedMessageEvent;
  title_en: Scalars['String'];
  title_zh: Scalars['String'];
  content_en: Scalars['String'];
  content_zh: Scalars['String'];
  enabled: Scalars['Boolean'];
  dateTimeCreated: Scalars['DateTime'];
  dateTimeUpdated: Scalars['DateTime'];
};

export enum AutomatedMessageEvent {
  DepositReceived = 'DEPOSIT_RECEIVED',
  DepositProcessing = 'DEPOSIT_PROCESSING',
  DepositApproved = 'DEPOSIT_APPROVED',
  DepositRejected = 'DEPOSIT_REJECTED',
  DepositExpired = 'DEPOSIT_EXPIRED',
  WithdrawalReceived = 'WITHDRAWAL_RECEIVED',
  WithdrawalProcessing = 'WITHDRAWAL_PROCESSING',
  WithdrawalApproved = 'WITHDRAWAL_APPROVED',
  WithdrawalRejected = 'WITHDRAWAL_REJECTED',
  PromoReceived = 'PROMO_RECEIVED',
  PromoProcessing = 'PROMO_PROCESSING',
  PromoApproved = 'PROMO_APPROVED',
  PromoRejected = 'PROMO_REJECTED',
  AutoPromoEnrollmentRequestIpLimitReached = 'AUTO_PROMO_ENROLLMENT_REQUEST_IP_LIMIT_REACHED',
  AutoPromoEnrollmentRequestNameLimitReached = 'AUTO_PROMO_ENROLLMENT_REQUEST_NAME_LIMIT_REACHED',
  AutoPromoEnrollmentRequestBothLimitReached = 'AUTO_PROMO_ENROLLMENT_REQUEST_BOTH_LIMIT_REACHED',
  PromoAutopayout = 'PROMO_AUTOPAYOUT',
  PromoManualClaim = 'PROMO_MANUAL_CLAIM',
  VipUpgrade = 'VIP_UPGRADE',
  VipDowngrade = 'VIP_DOWNGRADE',
  VipUpgradeAutopayout = 'VIP_UPGRADE_AUTOPAYOUT',
  VipBirthdayAutopayout = 'VIP_BIRTHDAY_AUTOPAYOUT',
  VipDailyAutopayout = 'VIP_DAILY_AUTOPAYOUT',
  VipWeeklyAutopayout = 'VIP_WEEKLY_AUTOPAYOUT',
  VipMonthlyAutopayout = 'VIP_MONTHLY_AUTOPAYOUT',
  VipAnnualAutopayout = 'VIP_ANNUAL_AUTOPAYOUT',
  AffiliateReceived = 'AFFILIATE_RECEIVED',
  AffiliateProcessing = 'AFFILIATE_PROCESSING',
  AffiliateApproved = 'AFFILIATE_APPROVED',
  AffiliateRejected = 'AFFILIATE_REJECTED',
  RebateLiveAutopayout = 'REBATE_LIVE_AUTOPAYOUT',
  RebatePeriodicAutopayout = 'REBATE_PERIODIC_AUTOPAYOUT',
  SystemDepositLimitRevised = 'SYSTEM_DEPOSIT_LIMIT_REVISED',
  DepositLimitIncreaseInitiated = 'DEPOSIT_LIMIT_INCREASE_INITIATED',
  DepositLimitIncreased = 'DEPOSIT_LIMIT_INCREASED',
  DepositLimitDecreased = 'DEPOSIT_LIMIT_DECREASED',
  NoDepositLimit = 'NO_DEPOSIT_LIMIT',
  DepositLimitFrequencyUpdated = 'DEPOSIT_LIMIT_FREQUENCY_UPDATED',
}

export type AutomatedMessageEventQueryOperatorInput = {
  eq?: Maybe<AutomatedMessageEvent>;
  ne?: Maybe<AutomatedMessageEvent>;
  in?: Maybe<Array<AutomatedMessageEvent>>;
  nin?: Maybe<Array<AutomatedMessageEvent>>;
};

export type AutomatedMessageFilterInput = {
  enabled?: Maybe<BooleanQueryOperatorInput>;
  event?: Maybe<AutomatedMessageEventQueryOperatorInput>;
};

export type AutomatedMessagesConnection = {
  __typename?: 'AutomatedMessagesConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<AutomatedMessagesConnectionEdge>>;
  pageInfo: PageInfo;
};

export type AutomatedMessagesConnectionEdge = {
  __typename?: 'AutomatedMessagesConnectionEdge';
  cursor: Scalars['Binary'];
  node: AutomatedMessage;
};

export type AvailableCurrency = {
  __typename?: 'AvailableCurrency';
  code: Scalars['String'];
  name: Scalars['String'];
  symbol?: Maybe<Scalars['String']>;
  type?: Maybe<CurrencyType>;
};

export type AvailablePaymentMethodsFilterInput = {
  account: Scalars['ID'];
  paymentMethodType?: Maybe<PaymentMethodTypeQueryOperatorInput>;
  withApprovedDeposits?: Maybe<Scalars['Boolean']>;
  provider?: Maybe<PaymentMethodProviderQueryOperatorInput>;
};

export type AverageEffectiveBetChartInput = {
  vendor?: Maybe<Scalars['ID']>;
  gameType?: Maybe<GameType>;
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
};

export type AverageEffectiveBetInput = {
  vendor?: Maybe<Scalars['ID']>;
  gameType?: Maybe<GameType>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
};

export enum BadgeSet {
  Primary = 'PRIMARY',
  DarkBlue = 'DARK_BLUE',
  LightBlue = 'LIGHT_BLUE',
}

export type BalanceTransactionRecord = {
  __typename?: 'BalanceTransactionRecord';
  id: Scalars['ID'];
  serialCode: Scalars['ID'];
  member: Member;
  affiliate?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  balance: Scalars['RoundedFloat'];
  mainCashBalance?: Maybe<Scalars['RoundedFloat']>;
  casinoCashBalance?: Maybe<Scalars['RoundedFloat']>;
  sportsCashBalance?: Maybe<Scalars['RoundedFloat']>;
  casinoBonusBalance?: Maybe<Scalars['RoundedFloat']>;
  sportsBonusBalance?: Maybe<Scalars['RoundedFloat']>;
  mainCashBase?: Maybe<Scalars['RoundedFloat']>;
  casinoCashBase?: Maybe<Scalars['RoundedFloat']>;
  sportsCashBase?: Maybe<Scalars['RoundedFloat']>;
  casinoBonusBase?: Maybe<Scalars['RoundedFloat']>;
  sportsBonusBase?: Maybe<Scalars['RoundedFloat']>;
  amountInBaseCurrency?: Maybe<Scalars['RoundedFloat']>;
  balanceInBaseCurrency?: Maybe<Scalars['RoundedFloat']>;
  currency?: Maybe<Scalars['Currency']>;
  baseCurrency?: Maybe<Scalars['Currency']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  type: BalanceTransactionType;
  transaction?: Maybe<Scalars['String']>;
  /** @deprecated To be removed. */
  promo?: Maybe<Promo>;
  processor: Account;
  /** @deprecated Rename to remarks */
  notes?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  manualAdjustment?: Maybe<ManualAdjustment>;
  manualAdjustmentRemarks?: Maybe<Scalars['String']>;
  actual?: Maybe<Scalars['Boolean']>;
  dateTimeCreated: Scalars['DateTime'];
  platformId?: Maybe<Scalars['String']>;
  brandId?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  isCrypto?: Maybe<Scalars['Boolean']>;
  cryptoAmount?: Maybe<Scalars['Float']>;
  cryptoCurrency?: Maybe<Scalars['Currency']>;
  cryptoExchangeRate?: Maybe<Scalars['Float']>;
  cryptoBalance?: Maybe<Scalars['Float']>;
};

export type BalanceTransactionRecordRemarksArgs = {
  language?: Maybe<Scalars['Language']>;
};

export type BalanceTransactionRecordsConnection = {
  __typename?: 'BalanceTransactionRecordsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<BalanceTransactionRecordsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type BalanceTransactionRecordsConnectionEdge = {
  __typename?: 'BalanceTransactionRecordsConnectionEdge';
  cursor: Scalars['Binary'];
  node: BalanceTransactionRecord;
};

export type BalanceTransactionRecordsFilterInput = {
  unit?: Maybe<StringQueryOperatorInput>;
  serialCode?: Maybe<StringQueryOperatorInput>;
  member?: Maybe<IdQueryOperatorInput>;
  affiliate?: Maybe<IdQueryOperatorInput>;
  transaction?: Maybe<StringQueryOperatorInput>;
  amount?: Maybe<FloatQueryOperatorInput>;
  balanceAdjustment?: Maybe<BooleanQueryOperatorInput>;
  balanceAdjustmentRemarks?: Maybe<StringQueryOperatorInput>;
  dateTimeCreated?: Maybe<DateTimeQueryOperatorInput>;
  type?: Maybe<BalanceTransactionTypeQueryOperatorInput>;
  processor?: Maybe<IdQueryOperatorInput>;
  notes?: Maybe<StringQueryOperatorInput>;
  remarks?: Maybe<StringQueryOperatorInput>;
  manualAdjustment?: Maybe<IdQueryOperatorInput>;
  manualAdjustmentRemarks?: Maybe<StringQueryOperatorInput>;
  platformId?: Maybe<StringQueryOperatorInput>;
  brandId?: Maybe<StringQueryOperatorInput>;
  brand?: Maybe<StringQueryOperatorInput>;
  currency?: Maybe<StringQueryOperatorInput>;
};

export enum BalanceTransactionType {
  Bet = 'BET',
  RefundBet = 'REFUND_BET',
  Payout = 'PAYOUT',
  BalanceAdjustment = 'BALANCE_ADJUSTMENT',
  Deposit = 'DEPOSIT',
  Withdrawal = 'WITHDRAWAL',
  WithdrawalFee = 'WITHDRAWAL_FEE',
  WithdrawalClawbackFee = 'WITHDRAWAL_CLAWBACK_FEE',
  WithdrawalRequestRejected = 'WITHDRAWAL_REQUEST_REJECTED',
  PromoPayout = 'PROMO_PAYOUT',
  RebatePayout = 'REBATE_PAYOUT',
  /**
   * # DEPRECATED
   * The name payout doesn't corresponds to the actual
   * scenario
   */
  AffiliatePayout = 'AFFILIATE_PAYOUT',
  AffiliateCommission = 'AFFILIATE_COMMISSION',
  MemberLoyaltyPayout = 'MEMBER_LOYALTY_PAYOUT',
  /**
   * # DEPRECATED
   * It should be consistent. If the relation is 1-1 then
   * it is payout. If the relation is 1-many then it is a
   * commission
   */
  MemberLoyaltyAward = 'MEMBER_LOYALTY_AWARD',
  VendorTransfer = 'VENDOR_TRANSFER',
  DebitVendorTransfer = 'DEBIT_VENDOR_TRANSFER',
  CreditVendorTransfer = 'CREDIT_VENDOR_TRANSFER',
  RefundCreditVendorTransfer = 'REFUND_CREDIT_VENDOR_TRANSFER',
  System = 'SYSTEM',
  PayoutManualAdjustment = 'PAYOUT_MANUAL_ADJUSTMENT',
  PromoPayoutManualAdjustment = 'PROMO_PAYOUT_MANUAL_ADJUSTMENT',
  SystemManualAdjustment = 'SYSTEM_MANUAL_ADJUSTMENT',
  SystemCreditManualAdjustment = 'SYSTEM_CREDIT_MANUAL_ADJUSTMENT',
  SystemDebitManualAdjustment = 'SYSTEM_DEBIT_MANUAL_ADJUSTMENT',
  ExternalProgrammeManualAdjustment = 'EXTERNAL_PROGRAMME_MANUAL_ADJUSTMENT',
  DebitVendorTransferManualAdjustment = 'DEBIT_VENDOR_TRANSFER_MANUAL_ADJUSTMENT',
  CreditVendorTransferManualAdjustment = 'CREDIT_VENDOR_TRANSFER_MANUAL_ADJUSTMENT',
  DepositManualAdjustment = 'DEPOSIT_MANUAL_ADJUSTMENT',
  WithdrawalManualAdjustment = 'WITHDRAWAL_MANUAL_ADJUSTMENT',
  PromoDepositBonus = 'PROMO_DEPOSIT_BONUS',
  PromoFundsTransfer = 'PROMO_FUNDS_TRANSFER',
  PromoBonusWon = 'PROMO_BONUS_WON',
  PromoBonusLost = 'PROMO_BONUS_LOST',
  PromoBonusCanceled = 'PROMO_BONUS_CANCELED',
  PromoBonusExpired = 'PROMO_BONUS_EXPIRED',
  PromoWon = 'PROMO_WON',
  PromoLost = 'PROMO_LOST',
  PromoCanceled = 'PROMO_CANCELED',
  PromoExpired = 'PROMO_EXPIRED',
  PromoRefund = 'PROMO_REFUND',
}

export type BalanceTransactionTypeQueryOperatorInput = {
  eq?: Maybe<BalanceTransactionType>;
  ne?: Maybe<BalanceTransactionType>;
  in?: Maybe<Array<Maybe<BalanceTransactionType>>>;
  nin?: Maybe<Array<Maybe<BalanceTransactionType>>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type BalanceUnit = {
  __typename?: 'BalanceUnit';
  id: Scalars['ID'];
  amount: Scalars['RoundedFloat'];
  originalAmount: Scalars['RoundedFloat'];
  turnoverRequirement: Scalars['RoundedFloat'];
  turnoverRequirementMultiplier: Scalars['RoundedFloat'];
  turnoverRequirementBalance: Scalars['RoundedFloat'];
  source: Scalars['String'];
  scope?: Maybe<BalanceUnitScope>;
  timestamp?: Maybe<Scalars['DateTime']>;
  expireAt?: Maybe<Scalars['DateTime']>;
  manualAdjustment: Scalars['Boolean'];
};

export type BalanceUnitScope = {
  __typename?: 'BalanceUnitScope';
  game?: Maybe<Scalars['String']>;
  vendor?: Maybe<Scalars['String']>;
  gameType?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
};

export enum Bank {
  Icbc = 'ICBC',
  Abc = 'ABC',
  Boc = 'BOC',
  Ccb = 'CCB',
  Cmb = 'CMB',
  Bocom = 'BOCOM',
  Pab = 'PAB',
  Citic = 'CITIC',
  Cib = 'CIB',
  Ceb = 'CEB',
  Cmbc = 'CMBC',
  Psbc = 'PSBC',
  Other = 'OTHER',
}

export type BankAccount = {
  __typename?: 'BankAccount';
  id: Scalars['ID'];
  bank: Bank;
  accountName: Scalars['String'];
  accountNumber: Scalars['String'];
  default: Scalars['Boolean'];
  province?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
};

export type BankAccountsConnection = {
  __typename?: 'BankAccountsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<BankAccountsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type BankAccountsConnectionEdge = {
  __typename?: 'BankAccountsConnectionEdge';
  node: BankAccount;
  cursor: Scalars['String'];
};

export type BankAccountsFilterInput = {
  id?: Maybe<IdQueryOperatorInput>;
  account?: Maybe<IdQueryOperatorInput>;
  bank?: Maybe<BankQueryOperatorInput>;
  accountName?: Maybe<StringQueryOperatorInput>;
  accountNumber?: Maybe<StringQueryOperatorInput>;
};

export type BankQueryOperatorInput = {
  eq?: Maybe<Bank>;
  ne?: Maybe<Bank>;
  in?: Maybe<Array<Bank>>;
  nin?: Maybe<Array<Bank>>;
};

export type Banner = {
  __typename?: 'Banner';
  id: Scalars['ID'];
  creator: Account;
  name: Scalars['String'];
  link?: Maybe<Scalars['URL']>;
  promo?: Maybe<Promo>;
  imageDesktop?: Maybe<File>;
  imageMobile?: Maybe<File>;
  imageHorizontal?: Maybe<File>;
  enabledDesktop: Scalars['Boolean'];
  enabledMobile: Scalars['Boolean'];
  enabledHorizontal: Scalars['Boolean'];
  rank: Scalars['Int'];
  dateTimeCreated: Scalars['DateTime'];
  dateTimeUpdated: Scalars['DateTime'];
};

export type BannersConnection = {
  __typename?: 'BannersConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<BannersConnectionEdge>>;
  pageInfo: PageInfo;
};

export type BannersConnectionEdge = {
  __typename?: 'BannersConnectionEdge';
  cursor: Scalars['Binary'];
  node: Banner;
};

export enum BannerScrollingSpeed {
  Slow = 'SLOW',
  Medium = 'MEDIUM',
  Fast = 'FAST',
}

export type BannersFilterInput = {
  id?: Maybe<IdQueryOperatorInput>;
  creator?: Maybe<IdQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  promo?: Maybe<IdQueryOperatorInput>;
  enabledDesktop?: Maybe<BooleanQueryOperatorInput>;
  enabledMobile?: Maybe<BooleanQueryOperatorInput>;
  enabledHorizontal?: Maybe<BooleanQueryOperatorInput>;
};

export type BetHistoryReport = {
  __typename?: 'BetHistoryReport';
  id: Scalars['ID'];
  timezone?: Maybe<Scalars['Timezone']>;
  creator: Account;
  dateTimeCreated: Scalars['DateTime'];
  filter: BetHistoryReportFilter;
  report?: Maybe<File>;
  scheduledDateTime?: Maybe<Scalars['DateTime']>;
  status: BetHistoryReportStatus;
};

export type BetHistoryReportFilter = {
  __typename?: 'BetHistoryReportFilter';
  dateTimeProcessed: DateTimeQueryOperator;
  admin: IdQueryOperator;
  id?: Maybe<IdQueryOperator>;
  member?: Maybe<IdQueryOperator>;
  vendor?: Maybe<IdQueryOperator>;
  game?: Maybe<IdQueryOperator>;
  dateTimeSettled?: Maybe<DateTimeQueryOperator>;
  dateTimePlaced?: Maybe<DateTimeQueryOperator>;
  dateTimeCanceled?: Maybe<DateTimeQueryOperator>;
  status?: Maybe<StringQueryOperator>;
  tips?: Maybe<FloatQueryOperator>;
  jackpotContributionBet?: Maybe<FloatQueryOperator>;
  jackpotWin?: Maybe<FloatQueryOperator>;
  cashoutWinloss?: Maybe<FloatQueryOperator>;
  freeSpin?: Maybe<BooleanQueryOperator>;
  device?: Maybe<StringQueryOperator>;
};

export type BetHistoryReportFilterInput = {
  status?: Maybe<StringQueryOperatorInput>;
};

export type BetHistoryReportsConnection = {
  __typename?: 'BetHistoryReportsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<BetHistoryReportsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type BetHistoryReportsConnectionEdge = {
  __typename?: 'BetHistoryReportsConnectionEdge';
  cursor: Scalars['Binary'];
  node: BetHistoryReport;
};

export enum BetHistoryReportStatus {
  Generating = 'GENERATING',
  Generated = 'GENERATED',
  Scheduled = 'SCHEDULED',
}

export enum Blockchain {
  Erc20 = 'ERC20',
  Trc20 = 'TRC20',
  Omni = 'OMNI',
}

export type BooleanQueryOperator = {
  __typename?: 'BooleanQueryOperator';
  eq?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
};

export type BooleanQueryOperatorInput = {
  eq?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
};

export type BtagsNext = {
  __typename?: 'BtagsNext';
  btag?: Maybe<Scalars['String']>;
  btag2?: Maybe<Scalars['String']>;
  btag3?: Maybe<Scalars['String']>;
  btag4?: Maybe<Scalars['String']>;
  btag5?: Maybe<Scalars['String']>;
};

export type BtagsNextInput = {
  btag?: Maybe<Scalars['String']>;
  btag2?: Maybe<Scalars['String']>;
  btag3?: Maybe<Scalars['String']>;
  btag4?: Maybe<Scalars['String']>;
  btag5?: Maybe<Scalars['String']>;
};

export type BuiltInFormField = FormField & {
  __typename?: 'BuiltInFormField';
  type: FormFieldType;
  required: Scalars['Boolean'];
  field: MemberField;
  updatable?: Maybe<Scalars['Boolean']>;
  otpVerificationRequired?: Maybe<Scalars['Boolean']>;
};

export type BulkUpdateGameRoundRecord = {
  __typename?: 'BulkUpdateGameRoundRecord';
  initiator: Account;
  result?: Maybe<Scalars['String']>;
  status: BulkUpdateGameRoundRecordStatus;
  totalRows?: Maybe<Scalars['Int']>;
  processedRows?: Maybe<Scalars['Int']>;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeDone?: Maybe<Scalars['DateTime']>;
};

export enum BulkUpdateGameRoundRecordStatus {
  Done = 'DONE',
  InProgress = 'IN_PROGRESS',
  Failed = 'FAILED',
}

export enum BulkUpdateMemberTagsAction {
  BulkAdd = 'BULK_ADD',
  BulkRemove = 'BULK_REMOVE',
}

export type CancelDepositLimitChangeInput = {
  frequency: MemberDepositLimitFrequency;
  member: Scalars['ID'];
};

export enum Channel {
  MobileApp = 'MOBILE_APP',
  MobileWeb = 'MOBILE_WEB',
  DesktopWeb = 'DESKTOP_WEB',
  Desktop = 'DESKTOP',
  Mobile = 'MOBILE',
  MobileAppIos = 'MOBILE_APP_IOS',
  MobileAppAndroid = 'MOBILE_APP_ANDROID',
  MobileAppIosHorizontal = 'MOBILE_APP_IOS_HORIZONTAL',
  MobileAppAndroidHorizontal = 'MOBILE_APP_ANDROID_HORIZONTAL',
  MobileWebHorizontal = 'MOBILE_WEB_HORIZONTAL',
  DesktopWebHorizontal = 'DESKTOP_WEB_HORIZONTAL',
}

export type ChartData = {
  __typename?: 'ChartData';
  interval: ChartInterval;
  dataPoints: Array<DataPoint>;
};

export enum ChartInterval {
  Hourly = 'HOURLY',
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
}

export type CloseMemberInput = {
  reason: Scalars['String'];
  brandLinkagesUpdated?: Maybe<Scalars['Boolean']>;
};

export type CoinifyDeposit = Deposit & {
  __typename?: 'CoinifyDeposit';
  id: Scalars['ID'];
  amount: Scalars['RoundedFloat'];
  account: Member;
  processingTime?: Maybe<Scalars['Int']>;
  paymentMethod?: Maybe<PaymentMethod>;
  status: DepositStatus;
  processor?: Maybe<Account>;
  serialCode: Scalars['String'];
  firstDeposit?: Maybe<Scalars['Boolean']>;
  remarks?: Maybe<Scalars['String']>;
  transactionReference?: Maybe<Scalars['String']>;
  manualAdjustment: Scalars['Boolean'];
  transactionDetails: DepositEventDetails;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeProcessed?: Maybe<Scalars['DateTime']>;
  baseCurrency?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  amountInBaseCurrency?: Maybe<Scalars['RoundedFloat']>;
  isCrypto?: Maybe<Scalars['Boolean']>;
};

export type CoinifyPaymentMethod = PaymentMethod & {
  __typename?: 'CoinifyPaymentMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  currentAccumulation: Scalars['RoundedFloat'];
  memberLoyaltyLevel?: Maybe<Array<MemberLoyaltyLevel>>;
  excludedMemberLevel?: Maybe<Array<MemberLevel>>;
  minimumDepositAmount: Scalars['RoundedFloat'];
  maximumDepositAmount: Scalars['RoundedFloat'];
  instructionText?: Maybe<Scalars['String']>;
  maximumDepositAmountPerDay: Scalars['RoundedFloat'];
  depositExpiry: Scalars['RoundedFloat'];
  turnoverRequirementMultiplier: Scalars['RoundedFloat'];
  suggestedAmounts: Array<Maybe<Scalars['RoundedFloat']>>;
  transactionFee: Scalars['RoundedFloat'];
  transactionFeeType?: Maybe<TransactionFeeType>;
  allowCustomAmount: Scalars['Boolean'];
  updateable: Scalars['Boolean'];
  provider?: Maybe<PaymentMethodProvider>;
};

export type CoinifyWithdrawalMethod = WithdrawalMethod & {
  __typename?: 'CoinifyWithdrawalMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  updateable: Scalars['Boolean'];
  transactionFee: Scalars['RoundedFloat'];
  transactionFeeType: TransactionFeeType;
  provider?: Maybe<WithdrawalMethodProvider>;
};

export type CommissionLevel = {
  __typename?: 'CommissionLevel';
  minimumProfit: Scalars['Float'];
  minimumActiveMembersCount: Scalars['Int'];
  minimumTurnover: Scalars['Float'];
  commissionPercentages: Array<CommissionPercentage>;
  rebatePercentages: Array<RebatePercentage>;
};

export type CommissionLevelInput = {
  minimumProfit: Scalars['Float'];
  minimumActiveMembersCount: Scalars['Int'];
  minimumTurnover: Scalars['Float'];
  commissionPercentages: Array<CommissionPercentageInput>;
  rebatePercentages: Array<RebatePercentageInput>;
};

export type CommissionPercentage = {
  __typename?: 'CommissionPercentage';
  vendor: VendorCode;
  gameType: GameType;
  percentage: Scalars['Float'];
};

export type CommissionPercentageInput = {
  vendor: VendorCode;
  gameType: GameType;
  percentage: Scalars['Float'];
};

export enum CommunicationType {
  Sms = 'SMS',
  Email = 'EMAIL',
  PhoneCall = 'PHONE_CALL',
  Mail = 'MAIL',
}

export enum ComplainceCheckStatus {
  Passed = 'PASSED',
  Failed = 'FAILED',
}

export type ComplainceCheckStatusQueryOperatorInput = {
  eq?: Maybe<ComplainceCheckStatus>;
  ne?: Maybe<ComplainceCheckStatus>;
  in?: Maybe<Array<ComplainceCheckStatus>>;
  nin?: Maybe<Array<Maybe<ComplainceCheckStatus>>>;
};

export type ComplianceCheckSetting = {
  __typename?: 'ComplianceCheckSetting';
  type: ComplianceCheckType;
  enabled: Scalars['Boolean'];
  auto: Scalars['Boolean'];
  activationThreshold: Scalars['RoundedFloat'];
};

export type ComplianceCheckSettingInput = {
  type: ComplianceCheckType;
  enabled: Scalars['Boolean'];
  auto: Scalars['Boolean'];
  activationThreshold?: Maybe<Scalars['Float']>;
};

export enum ComplianceCheckType {
  BonusHunter = 'BONUS_HUNTER',
  ContraBetOffender = 'CONTRA_BET_OFFENDER',
  MartingaleBetOffender = 'MARTINGALE_BET_OFFENDER',
  TurnoverRequirement = 'TURNOVER_REQUIREMENT',
  IpCollision = 'IP_COLLISION',
}

export type Config = {
  __typename?: 'Config';
  marqueeMessageSpeed: MarqueeMessageSpeed;
  bannerAutomaticScrollingEnabled: Scalars['Boolean'];
  bannerScrollingSpeed: BannerScrollingSpeed;
  emailVerificationEnabled: Scalars['Boolean'];
  isEmailReusable: Scalars['Boolean'];
  emailReusabilityCount?: Maybe<Scalars['Int']>;
  mobileNumberVerificationEnabled: Scalars['Boolean'];
  isMobileNumberReusable: Scalars['Boolean'];
  mobileNumberReusabilityCount?: Maybe<Scalars['Int']>;
  domainName?: Maybe<Scalars['String']>;
  adminCode: Scalars['String'];
  cname: Scalars['String'];
  domainDescription?: Maybe<Scalars['String']>;
  sslEnabled?: Maybe<Scalars['Boolean']>;
  crtFile?: Maybe<File>;
  privateKey?: Maybe<File>;
  intermediaryCert?: Maybe<File>;
  captchaEnabledFrontend: Scalars['Boolean'];
  captchaEnabledBackend: Scalars['Boolean'];
  googleAuthEnabledBackend: Scalars['Boolean'];
  transferVendorsEnabled: Scalars['Boolean'];
  seamlessVendorsEnabled: Scalars['Boolean'];
  paymentMethodSuccessRateCalculationLastTransactionsCount: Scalars['Int'];
  turnoverRequirementWaiverEnabled: Scalars['Boolean'];
  turnoverRequirementWaiverThreshold: Scalars['Float'];
  withdrawalHandlingFeeType: WithdrawalHandlingFeeType;
  withdrawalHandlingFee: Scalars['Float'];
  withdrawalTaxType: WithdrawalTaxType;
  withdrawalTaxValue: Scalars['Float'];
  depositSourceFieldStateInputType: FieldStateInputType;
  paymentMethodConfiguration: Array<PaymentMethodConfiguration>;
  paymentMethodRotation: PaymentMethodRotation;
  timezone?: Maybe<Scalars['Timezone']>;
  affiliateRequestFormFields: Array<FormField>;
  memberFormFields: Array<FormField>;
  memberForceVerificationSettings: MemberForceVerificationSettings;
  defaultCurrency?: Maybe<CurrencyMetadata>;
  defaultCountry?: Maybe<Country>;
  defaultCountryCode?: Maybe<Country>;
  depositLimitSettings: Array<DepositLimitSetting>;
  geolocationSettings: Array<GeolocationSetting>;
  realityCheckTime?: Maybe<Scalars['Int']>;
  selfExclusionCheckInterval?: Maybe<Scalars['Int']>;
  checkFraud?: Maybe<Scalars['Boolean']>;
  multipleSession?: Maybe<Scalars['Boolean']>;
  hexoPayShopId?: Maybe<Scalars['String']>;
  hexoPaySecretKey?: Maybe<Scalars['String']>;
  hexoPayPublicKey?: Maybe<Scalars['String']>;
  hexoPayTestMode?: Maybe<Scalars['Boolean']>;
  enableLoqateIntegration?: Maybe<Scalars['Boolean']>;
  enableGBG?: Maybe<Scalars['Boolean']>;
  enableGBGAffordabilityCheck?: Maybe<Scalars['Boolean']>;
  gbgNetDepositThreshold?: Maybe<Scalars['Float']>;
  gbgTrigger?: Maybe<GbgTrigger>;
  gbgThresholdAmount?: Maybe<Scalars['Int']>;
  gbgDepositAmount?: Maybe<Scalars['Int']>;
  gbgWithdrawalAmount?: Maybe<Scalars['Int']>;
  websiteLink?: Maybe<Scalars['String']>;
  logoLink?: Maybe<Scalars['String']>;
  themeColor?: Maybe<Scalars['String']>;
  themeSubColor?: Maybe<Scalars['String']>;
  smtpHost?: Maybe<Scalars['String']>;
  smtpService?: Maybe<Scalars['String']>;
  smtpUser?: Maybe<Scalars['String']>;
  smtpPassword?: Maybe<Scalars['String']>;
  checkDepositLimit?: Maybe<Scalars['Boolean']>;
  interimSafeGamblingPage?: Maybe<Scalars['Boolean']>;
  autoDetectUserCountryCode?: Maybe<Scalars['Boolean']>;
  optInChecked?: Maybe<Scalars['Boolean']>;
  tncChecked?: Maybe<Scalars['Boolean']>;
  checkGamstop?: Maybe<Scalars['Boolean']>;
  netellerShopId?: Maybe<Scalars['String']>;
  netellerSecretKey?: Maybe<Scalars['String']>;
  netellerTestMode?: Maybe<Scalars['Boolean']>;
  skrillShopId?: Maybe<Scalars['String']>;
  skrillSecretKey?: Maybe<Scalars['String']>;
  skrillTestMode?: Maybe<Scalars['Boolean']>;
  skrillAccount?: Maybe<Scalars['String']>;
  coinifyShopId?: Maybe<Scalars['String']>;
  coinifySecretKey?: Maybe<Scalars['String']>;
  coinifyTestMode?: Maybe<Scalars['Boolean']>;
  coinifyAccount?: Maybe<Scalars['String']>;
  coinifyBTCAddress?: Maybe<Scalars['String']>;
  wonkaPayKey?: Maybe<Scalars['String']>;
  wonkaPaySecret?: Maybe<Scalars['String']>;
  xtpAppToken?: Maybe<Scalars['String']>;
  enableWithdrawalCancelation?: Maybe<Scalars['Boolean']>;
  multiCurrencyEnabled?: Maybe<Scalars['Boolean']>;
  baseCurrency?: Maybe<Scalars['String']>;
  baseCurrencySymbol?: Maybe<Scalars['String']>;
  astroPayKey?: Maybe<Scalars['String']>;
  astroPayMerchantCode?: Maybe<Scalars['String']>;
  astroPaySecretKey?: Maybe<Scalars['String']>;
  quickDepositEnabled?: Maybe<Scalars['Boolean']>;
  preDepositEnabled?: Maybe<Scalars['Boolean']>;
  preDepositValues?: Maybe<Array<Scalars['Float']>>;
};

export type ConfirmDepositLimitChangeInput = {
  frequency: MemberDepositLimitFrequency;
  member: Scalars['ID'];
};

export type Country = {
  __typename?: 'Country';
  id: Scalars['ID'];
  code: Scalars['String'];
  name: Scalars['String'];
};

export type CountryCode = {
  __typename?: 'CountryCode';
  country: Scalars['String'];
  countryCode: Scalars['String'];
};

export type CountryConnection = {
  __typename?: 'CountryConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<CountryConnectionEdge>>;
  pageInfo: PageInfo;
};

export type CountryConnectionEdge = {
  __typename?: 'CountryConnectionEdge';
  cursor: Scalars['Binary'];
  node: Country;
};

export type CountryFilterInput = {
  id?: Maybe<IdQueryOperatorInput>;
};

export type CreateAccountUsageReportFilterInput = {
  dateTimeProcessed: DateTimeQueryOperatorInput;
  admin: IdQueryOperatorInput;
  vendor?: Maybe<IdQueryOperatorInput>;
};

export type CreateAccountUsageReportInput = {
  filter: CreateAccountUsageReportFilterInput;
  timezone?: Maybe<Scalars['Timezone']>;
  scheduledDateTime?: Maybe<Scalars['DateTime']>;
};

export type CreateAdminGroupInput = {
  name: Scalars['String'];
  admins: Array<Maybe<Scalars['ID']>>;
};

export type CreateAdminInput = {
  username: Scalars['String'];
  password: Scalars['String'];
  currency?: Maybe<Scalars['Currency']>;
  language?: Maybe<Scalars['Language']>;
  timezone?: Maybe<Scalars['Timezone']>;
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  realName?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  email?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  wechatId?: Maybe<Scalars['String']>;
  qqId?: Maybe<Scalars['String']>;
  company: Scalars['String'];
  skypeId?: Maybe<Scalars['String']>;
  contractStartDateTime?: Maybe<Scalars['DateTime']>;
  contractEndDateTime?: Maybe<Scalars['DateTime']>;
  twoFactorAuthenticationEnabled?: Maybe<Scalars['Boolean']>;
  ipWhitelist?: Maybe<Array<Scalars['IpAddress']>>;
  vendorGroup?: Maybe<Scalars['ID']>;
  attachments?: Maybe<Array<Scalars['ID']>>;
  subdomain?: Maybe<Scalars['String']>;
  personInCharge?: Maybe<Scalars['String']>;
  trial?: Maybe<Scalars['Boolean']>;
  status?: Maybe<AdminStatus>;
  otpValidationEnabled?: Maybe<Scalars['Boolean']>;
  linkedAdmins?: Maybe<Array<Scalars['ID']>>;
  multiCurrencyEnabled?: Maybe<Scalars['Boolean']>;
  adminCode?: Maybe<Scalars['String']>;
};

export type CreateAdminOperatorInput = {
  adminCode: Scalars['String'];
  username: Scalars['String'];
  password: Scalars['String'];
  currency?: Maybe<Scalars['Currency']>;
  language?: Maybe<Scalars['Language']>;
  timezone?: Maybe<Scalars['Timezone']>;
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  realName?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  email?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  wechatId?: Maybe<Scalars['String']>;
  qqId?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  skypeId?: Maybe<Scalars['String']>;
};

export type CreateAffiliateProgrammeInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  automaticPayoutClaiming?: Maybe<Scalars['Boolean']>;
  negativeCarryOverEnabled?: Maybe<Scalars['Boolean']>;
  vendorPlatformFees?: Maybe<Array<VendorPlatformFeeInput>>;
  depositTransactionCost?: Maybe<Scalars['Float']>;
  thirdPartyDepositTransactionCost?: Maybe<Scalars['Float']>;
  withdrawalTransactionCost?: Maybe<Scalars['Float']>;
  thirdPartyWithdrawalTransactionCost?: Maybe<Scalars['Float']>;
  promoCost?: Maybe<Scalars['Float']>;
  rebateCost?: Maybe<Scalars['Float']>;
  interestAccountCost?: Maybe<Scalars['Float']>;
  minimumEffectiveBetRequirement?: Maybe<Scalars['Float']>;
  minimumDepositRequirement?: Maybe<Scalars['Float']>;
  minimumPayoutAccumulationEnabled?: Maybe<Scalars['Boolean']>;
  minimumPayoutAccumulationAmount?: Maybe<Scalars['Float']>;
  turnoverRequirementMultiplier?: Maybe<Scalars['Float']>;
  settlementPeriod?: Maybe<AffiliateProgrammeSettlementPeriod>;
  settlementTime?: Maybe<Scalars['Time']>;
  settlementDayOfWeek?: Maybe<Scalars['DayOfWeek']>;
  settlementDayOfMonth?: Maybe<Scalars['DayOfMonth']>;
  payoutClaimOffsetDuration?: Maybe<Scalars['Duration']>;
  payoutClaimExpiryDuration?: Maybe<Scalars['Duration']>;
  payoutExpiryDuration?: Maybe<Scalars['Duration']>;
  levels?: Maybe<Array<AffiliateProgrammeLevelInput>>;
  levelsRequirement: AffiliateProgrammeLevelsRequirement;
};

export type CreateAgentLevelInput = {
  name: Scalars['String'];
  commissionEnabled: Scalars['Boolean'];
  minimumMemberTurnover: Scalars['Float'];
  notes?: Maybe<Scalars['String']>;
  withdrawalFeeExpense?: Maybe<AgentExpenseInput>;
  rebateExpense?: Maybe<AgentExpenseInput>;
  commissionLevels?: Maybe<Array<CommissionLevelInput>>;
  isDefault: Scalars['Boolean'];
};

export type CreateAlipayWithdrawalMethodInput = {
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  transactionFee: Scalars['Float'];
  transactionFeeType: TransactionFeeType;
  provider?: Maybe<WithdrawalMethodProvider>;
  nickname: Scalars['String'];
  accountNumber: Scalars['String'];
};

export type CreateAstroPayWalletWithdrawalMethodInput = {
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  transactionFee: Scalars['Float'];
  transactionFeeType: TransactionFeeType;
  provider?: Maybe<WithdrawalMethodProvider>;
};

export type CreateBalanceTransactionRecordReportInput = {
  filter?: Maybe<BalanceTransactionRecordsFilterInput>;
  timezone?: Maybe<Scalars['Timezone']>;
};

export type CreateBankAccountInput = {
  bank: Bank;
  accountName: Scalars['String'];
  accountNumber: Scalars['String'];
  province?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
};

export type CreateBannerInput = {
  name: Scalars['String'];
  link?: Maybe<Scalars['URL']>;
  promo?: Maybe<Scalars['ID']>;
  imageDesktop?: Maybe<Scalars['ID']>;
  imageMobile?: Maybe<Scalars['ID']>;
  imageHorizontal?: Maybe<Scalars['ID']>;
  enabledDesktop?: Maybe<Scalars['Boolean']>;
  enabledMobile?: Maybe<Scalars['Boolean']>;
  enabledHorizontal?: Maybe<Scalars['Boolean']>;
};

export type CreateBetHistoryReportFilterInput = {
  dateTimeProcessed: DateTimeQueryOperatorInput;
  admin: IdQueryOperatorInput;
  id?: Maybe<IdQueryOperatorInput>;
  member?: Maybe<IdQueryOperatorInput>;
  vendor?: Maybe<IdQueryOperatorInput>;
  game?: Maybe<IdQueryOperatorInput>;
  dateTimeSettled?: Maybe<DateTimeQueryOperatorInput>;
  dateTimePlaced?: Maybe<DateTimeQueryOperatorInput>;
  dateTimeCanceled?: Maybe<DateTimeQueryOperatorInput>;
  status?: Maybe<StringQueryOperatorInput>;
  tips?: Maybe<FloatQueryOperatorInput>;
  jackpotContributionBet?: Maybe<FloatQueryOperatorInput>;
  jackpotWin?: Maybe<FloatQueryOperatorInput>;
  cashoutWinloss?: Maybe<FloatQueryOperatorInput>;
  freeSpin?: Maybe<BooleanQueryOperatorInput>;
  device?: Maybe<StringQueryOperatorInput>;
};

export type CreateBetHistoryReportInput = {
  filter: CreateBetHistoryReportFilterInput;
  timezone?: Maybe<Scalars['Timezone']>;
  scheduledDateTime?: Maybe<Scalars['DateTime']>;
};

export type CreateCreateManualAdjustmentsJobInput = {
  file: Scalars['ID'];
  type: ManualAdjustmentType;
  remarks: Scalars['String'];
  turnoverRequirementMultiplier?: Maybe<Scalars['Float']>;
  actual?: Maybe<Scalars['Boolean']>;
  promo?: Maybe<Scalars['ID']>;
  playerRemarks?: Maybe<Scalars['String']>;
  automaticPayoutClaiming?: Maybe<Scalars['Boolean']>;
  claimOffsetDuration?: Maybe<Scalars['Duration']>;
  claimExpiryDuration?: Maybe<Scalars['Duration']>;
};

export type CreateCryptoCurrencyWithdrawalMethodInput = {
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  transactionFee: Scalars['Float'];
  transactionFeeType: TransactionFeeType;
  withdrawalProvider?: Maybe<Scalars['ID']>;
  provider?: Maybe<WithdrawalMethodProvider>;
};

export type CreateCustomFilterInput = {
  name: Scalars['String'];
  context: Scalars['ID'];
  filter: Scalars['JSON'];
};

export type CreateDailyCasinoPerformanceFilterInput = {
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
};

export type CreateDailyCasinoPerformanceInput = {
  filter?: Maybe<CreateDailyCasinoPerformanceFilterInput>;
};

export type CreateDailyDepositReportFilterInput = {
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  admins?: Maybe<Array<Scalars['ID']>>;
};

export type CreateDailyDepositReportInput = {
  filter?: Maybe<CreateDailyDepositReportFilterInput>;
  timezone?: Maybe<Scalars['Timezone']>;
};

export type CreateDailyPlayerActivityFilterInput = {
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  admins?: Maybe<Array<Scalars['ID']>>;
};

export type CreateDailyPlayerActivityInput = {
  filter?: Maybe<CreateDailyPlayerActivityFilterInput>;
  timezone?: Maybe<Scalars['Timezone']>;
};

export type CreateDailySelfExcludedReportFilterInput = {
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  admins?: Maybe<Array<Scalars['ID']>>;
};

export type CreateDailySelfExcludedReportInput = {
  filter?: Maybe<CreateDailySelfExcludedReportFilterInput>;
  timezone?: Maybe<Scalars['Timezone']>;
};

export type CreateDailySportsPerformanceFilterInput = {
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
};

export type CreateDailySportsPerformanceInput = {
  filter?: Maybe<CreateDailySportsPerformanceFilterInput>;
};

export type CreateDailyTimeOutReportFilterInput = {
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  admins?: Maybe<Array<Scalars['ID']>>;
};

export type CreateDailyTimeOutReportInput = {
  filter?: Maybe<CreateDailyTimeOutReportFilterInput>;
  timezone?: Maybe<Scalars['Timezone']>;
};

export type CreateDailyTransactionReportFilterInput = {
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  admins?: Maybe<Array<Scalars['ID']>>;
};

export type CreateDailyTransactionReportInput = {
  filter?: Maybe<CreateDailyTransactionReportFilterInput>;
  timezone?: Maybe<Scalars['Timezone']>;
};

export type CreateDepositLimitReportFilterInput = {
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  admins?: Maybe<Array<Scalars['ID']>>;
};

export type CreateDepositLimitReportInput = {
  filter?: Maybe<CreateDepositLimitReportFilterInput>;
  timezone?: Maybe<Scalars['Timezone']>;
};

export type CreateDepositProviderInput = {
  name: Scalars['String'];
  apiKey?: Maybe<Scalars['String']>;
  encryptionKey?: Maybe<Scalars['String']>;
  merchantId?: Maybe<Scalars['String']>;
  merchantCode?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  partner?: Maybe<Scalars['String']>;
  midPayDirectPaymentGateway?: Maybe<Scalars['ID']>;
  credentialValues?: Maybe<Array<Scalars['String']>>;
};

export type CreateDepositSourceInput = {
  logo: Scalars['ID'];
  order: Scalars['Int'];
  shortName: StringLanguageInput;
  displayName: StringLanguageInput;
  visible: Scalars['Boolean'];
  loadBankSource: Scalars['Boolean'];
};

export type CreateDomainInput = {
  name: Scalars['String'];
  status: DomainStatus;
  description: Scalars['String'];
  sslEnabled: Scalars['Boolean'];
  certificate?: Maybe<Scalars['String']>;
  privateKey?: Maybe<Scalars['String']>;
  intermediateCertificate?: Maybe<Scalars['String']>;
};

export type CreateDynamicPageInput = {
  pages: Array<DynamicPagePagesInput>;
  type: DynamicPageType;
  version?: Maybe<Scalars['String']>;
  customDateTimeCreated?: Maybe<Scalars['DateTime']>;
};

export type CreateFullCustomerDumpFilterInput = {
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  admins?: Maybe<Array<Scalars['ID']>>;
};

export type CreateFullCustomerDumpInput = {
  filter?: Maybe<CreateFullCustomerDumpFilterInput>;
  timezone?: Maybe<Scalars['Timezone']>;
};

export type CreateGameCategoryInput = {
  name: Scalars['String'];
  gameTypes: Array<GameType>;
  icon: Scalars['ID'];
  desktopIcon?: Maybe<Scalars['ID']>;
  vendors: Array<Scalars['ID']>;
  heroBanner?: Maybe<Scalars['ID']>;
  banner?: Maybe<Scalars['ID']>;
  mascotHorizontal?: Maybe<Scalars['ID']>;
  backgroundHorizontal?: Maybe<Scalars['ID']>;
  platform: Platform;
};

export type CreateGameInput = {
  vendor: Scalars['String'];
  code: Scalars['String'];
  type: GameType;
  name: GameNameInput;
  image: GameImageInput;
  hot: Scalars['Boolean'];
  new: Scalars['Boolean'];
  category: Scalars['String'];
  subCategory: Scalars['String'];
  supportedChannels?: Maybe<Array<Channel>>;
  jackpotStakes: Scalars['Boolean'];
  rtpStakes: Scalars['Int'];
  houseEdgeStakes: Scalars['Int'];
  volatilityStakes: Scalars['String'];
};

export type CreateGameSessionDurationFilterInput = {
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
};

export type CreateGameSessionDurationInput = {
  filter?: Maybe<CreateGameSessionDurationFilterInput>;
  timezone?: Maybe<Scalars['Timezone']>;
};

export type CreateHexoPayWithdrawalMethodInput = {
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  transactionFee: Scalars['Float'];
  transactionFeeType: TransactionFeeType;
  provider?: Maybe<WithdrawalMethodProvider>;
};

export type CreateJobInput = {
  type: JobType;
  /** Import Members inputs */
  file?: Maybe<Scalars['ID']>;
  forcePasswordChange?: Maybe<Scalars['Boolean']>;
  forceWithdrawalPinChange?: Maybe<Scalars['Boolean']>;
  enabled?: Maybe<Scalars['Boolean']>;
  memberLevel?: Maybe<Scalars['ID']>;
  memberLoyaltyLevel?: Maybe<Scalars['ID']>;
  memberLoyaltyLevels?: Maybe<Array<Maybe<Scalars['ID']>>>;
  memberTags?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type CreateMaintenanceScheduleInput = {
  vendor: VendorCode;
  gameType: GameType;
  mode: MaintenanceScheduleMode;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  period?: Maybe<Period>;
  startTime?: Maybe<Scalars['Time']>;
  endTime?: Maybe<Scalars['Time']>;
  startDay?: Maybe<Day>;
  endDay?: Maybe<Day>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};

export type CreateManualAdjustmentInput = {
  account: Scalars['ID'];
  amount: Scalars['Float'];
  type: ManualAdjustmentType;
  remarks: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  /** Required when type is DEPOSIT or EXTERNAL_PROGRAMME */
  turnoverRequirementMultiplier?: Maybe<Scalars['Float']>;
  /** Required when type is DEPOSIT or WITHDRAWAL */
  actual?: Maybe<Scalars['Boolean']>;
  promo?: Maybe<Scalars['ID']>;
  playerRemarks?: Maybe<Scalars['String']>;
  automaticPayoutClaiming?: Maybe<Scalars['Boolean']>;
  claimOffsetDuration?: Maybe<Scalars['Duration']>;
  claimExpiryDuration?: Maybe<Scalars['Duration']>;
  withdrawalMethod?: Maybe<Scalars['ID']>;
  hexoPayCreditCard?: Maybe<Scalars['ID']>;
  disableClosedLoop?: Maybe<Scalars['Boolean']>;
};

export type CreateManualAdjustmentsJob = Job & {
  __typename?: 'CreateManualAdjustmentsJob';
  id: Scalars['ID'];
  type: JobType;
  progress: Scalars['Float'];
  status: JobStatus;
  dateTimeCreated: Scalars['DateTime'];
  body: CreateManualAdjustmentsJobBody;
  result: CreateManualAdjustmentsJobResult;
};

export type CreateManualAdjustmentsJobBody = {
  __typename?: 'CreateManualAdjustmentsJobBody';
  file: File;
  type: ManualAdjustmentType;
  remarks: Scalars['String'];
  turnoverRequirementMultiplier?: Maybe<Scalars['RoundedFloat']>;
  actual?: Maybe<Scalars['Boolean']>;
  promo?: Maybe<Scalars['ID']>;
  playerRemarks?: Maybe<Scalars['String']>;
  automaticPayoutClaiming?: Maybe<Scalars['Boolean']>;
  claimOffsetDuration?: Maybe<Scalars['Duration']>;
  claimExpiryDuration?: Maybe<Scalars['Duration']>;
};

export type CreateManualAdjustmentsJobResult = {
  __typename?: 'CreateManualAdjustmentsJobResult';
  errorReportFile?: Maybe<File>;
  successCount: Scalars['Int'];
  failCount: Scalars['Int'];
};

export type CreateManualDepositInput = {
  account: Scalars['ID'];
  amount: Scalars['Float'];
  turnoverRequirementMultiplier?: Maybe<Scalars['Float']>;
  actual: Scalars['Boolean'];
  type: ManualDepositType;
  remarks?: Maybe<Scalars['String']>;
  meta?: Maybe<ManualDepositMetaInput>;
};

export type CreateManualWithdrawalInput = {
  account: Scalars['ID'];
  amount: Scalars['Float'];
  actual: Scalars['Boolean'];
  type: ManualWithdrawalType;
  remarks?: Maybe<Scalars['String']>;
};

export type CreateMarqueeMessageInput = {
  title: Scalars['String'];
  message: Scalars['String'];
  snippet: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
};

export type CreateMemberAffiliateModelInput = {
  name: Scalars['String'];
  levels: Array<MemberAffiliateLevelInput>;
  maximumCommission: Scalars['Float'];
  salesMetric: MemberAffiliateSalesMetricInput;
  settlementPeriod: MemberAffiliateSettlementPeriodSettingsInput;
  automaticSettlement: Scalars['Boolean'];
  default: Scalars['Boolean'];
  enabled: Scalars['Boolean'];
};

export type CreateMemberBetRecordReportFilterInput = {
  id?: Maybe<IdQueryOperatorInput>;
  admin?: Maybe<IdQueryOperatorInput>;
  serialCode?: Maybe<StringQueryOperatorInput>;
  affiliate?: Maybe<IdQueryOperatorInput>;
  member?: Maybe<IdQueryOperatorInput>;
  vendor?: Maybe<IdQueryOperatorInput>;
  game?: Maybe<IdQueryOperatorInput>;
  gameType?: Maybe<GameTypeQueryOperatorInput>;
  effectiveBet?: Maybe<FloatQueryOperatorInput>;
  winloss?: Maybe<FloatQueryOperatorInput>;
  dateTimePlaced?: Maybe<DateTimeQueryOperatorInput>;
  dateTimeSettled?: Maybe<DateTimeQueryOperatorInput>;
  dateTimeCanceled?: Maybe<DateTimeQueryOperatorInput>;
  status?: Maybe<MemberBetRecordStatusQueryOperatorInput>;
  balanceUnitSerialCodes?: Maybe<StringArrayQueryOperatorInput>;
  round?: Maybe<StringQueryOperatorInput>;
  session?: Maybe<StringQueryOperatorInput>;
  tips?: Maybe<FloatQueryOperatorInput>;
  jackpotContributionBet?: Maybe<FloatQueryOperatorInput>;
  jackpotWin?: Maybe<FloatQueryOperatorInput>;
  cashoutWinloss?: Maybe<FloatQueryOperatorInput>;
  freeSpin?: Maybe<BooleanQueryOperatorInput>;
  device?: Maybe<StringQueryOperatorInput>;
  turnover?: Maybe<FloatQueryOperatorInput>;
  platformId?: Maybe<StringQueryOperatorInput>;
  brandId?: Maybe<StringQueryOperatorInput>;
  payout?: Maybe<FloatQueryOperatorInput>;
  gameTitle?: Maybe<StringLanguageQueryOperatorInput>;
  gameCategory?: Maybe<StringQueryOperatorInput>;
  gameSubCategory?: Maybe<StringQueryOperatorInput>;
  brand?: Maybe<StringQueryOperatorInput>;
};

export type CreateMemberBetRecordReportInput = {
  filter?: Maybe<CreateMemberBetRecordReportFilterInput>;
  timezone?: Maybe<Scalars['Timezone']>;
};

export type CreateMemberInput = {
  username: Scalars['String'];
  password: Scalars['String'];
  withdrawalPassword?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  realName?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  address?: Maybe<AddressInput>;
  countryCode?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['Currency']>;
  email?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  wechatId?: Maybe<Scalars['String']>;
  qqId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  status?: Maybe<MemberStatus>;
  tags?: Maybe<Array<Maybe<Scalars['ID']>>>;
  remarks?: Maybe<Scalars['String']>;
  memberLevel?: Maybe<Scalars['ID']>;
  memberLoyaltyProgramme?: Maybe<Scalars['ID']>;
  memberLoyaltyLevel?: Maybe<Scalars['ID']>;
  memberLoyaltyLevels?: Maybe<Array<Maybe<Scalars['ID']>>>;
  otpValidationEnabled?: Maybe<Scalars['Boolean']>;
  forceChangeWithdrawalPassword?: Maybe<Scalars['Boolean']>;
  forceChangePassword?: Maybe<Scalars['Boolean']>;
  forceVerification?: Maybe<Scalars['Boolean']>;
  communicationTypePreferences?: Maybe<Array<Maybe<CommunicationType>>>;
  phoneNumber?: Maybe<Scalars['String']>;
  title?: Maybe<MemberTitle>;
  depositLimitFrequency?: Maybe<MemberDepositLimitFrequency>;
  depositLimit?: Maybe<Scalars['Float']>;
  btags?: Maybe<Array<Maybe<Scalars['String']>>>;
  btagsNext?: Maybe<BtagsNextInput>;
};

export type CreateMemberLevelInput = {
  name: Scalars['String'];
  color: Scalars['String'];
  default: Scalars['Boolean'];
  status: MemberLevelStatus;
  brandLinkage?: Maybe<Scalars['Boolean']>;
};

export type CreateMemberLoyaltyProgrammeInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  validityStartDate?: Maybe<Scalars['Date']>;
  validityEndDate?: Maybe<Scalars['Date']>;
  evaluationPeriod?: Maybe<MemberLoyaltyProgrammeEvaluationPeriod>;
  automaticMemberLoyaltyLevelUpgrade?: Maybe<Scalars['Boolean']>;
  automaticMemberLoyaltyLevelDowngrade?: Maybe<Scalars['Boolean']>;
  badgeSet?: Maybe<BadgeSet>;
  levels?: Maybe<Array<MemberLoyaltyLevelInput>>;
};

export type CreateMemberNoteInput = {
  member: Scalars['ID'];
  header: Scalars['String'];
  note: Scalars['String'];
  pinned: Scalars['Boolean'];
};

export type CreateMembersJob = Job & {
  __typename?: 'CreateMembersJob';
  id: Scalars['ID'];
  type: JobType;
  progress: Scalars['Float'];
  status: JobStatus;
  dateTimeCreated: Scalars['DateTime'];
  file: File;
  errorReportFile?: Maybe<File>;
  successCount: Scalars['Int'];
  failCount: Scalars['Int'];
};

export type CreateMemberSummaryReportFilterInput = {
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
  members?: Maybe<Array<Scalars['ID']>>;
  gameTypes?: Maybe<Array<GameType>>;
  vendors?: Maybe<Array<Scalars['ID']>>;
  isAffiliate?: Maybe<Array<Scalars['Boolean']>>;
  isAffiliateUpline?: Maybe<Array<Scalars['Boolean']>>;
  affiliates?: Maybe<Array<Scalars['ID']>>;
  memberLoyaltyLevels?: Maybe<Array<Scalars['ID']>>;
  memberLevels?: Maybe<Array<Scalars['ID']>>;
  memberTags?: Maybe<Array<Scalars['ID']>>;
  memberStatus?: Maybe<Array<MemberStatus>>;
  registrationDateTime?: Maybe<DateTimeRangeInput>;
  lastLoginDateTime?: Maybe<DateTimeRangeInput>;
  dateOfBirthDateTime?: Maybe<DateTimeRangeInput>;
  totalBalance?: Maybe<TotalBalanceRangeInput>;
};

export type CreateMemberSummaryReportInput = {
  filter: CreateMemberSummaryReportFilterInput;
  timezone?: Maybe<Scalars['Timezone']>;
};

export type CreateMemberTagInput = {
  name: Scalars['String'];
  color: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  brandLinkage?: Maybe<Scalars['Boolean']>;
};

export type CreateMessageInput = {
  title: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  targetMembers?: Maybe<Array<Scalars['ID']>>;
  targetMemberLevels?: Maybe<Array<Scalars['ID']>>;
  targetMemberLoyaltyLevels?: Maybe<Array<Scalars['ID']>>;
  excludedMembers?: Maybe<Array<Scalars['ID']>>;
  excludedMemberLevels?: Maybe<Array<Scalars['ID']>>;
  excludedMemberLoyaltyLevels?: Maybe<Array<Scalars['ID']>>;
  schedule?: Maybe<Scalars['DateTime']>;
};

export type CreateMissionInput = {
  type: MissionType;
  name?: Maybe<Scalars['String']>;
  automaticEnrollment?: Maybe<Scalars['Boolean']>;
  enrollmentStartDate?: Maybe<Scalars['Date']>;
  enrollmentEndDate?: Maybe<Scalars['Date']>;
  requireBankAccount?: Maybe<Scalars['Boolean']>;
  maximumEnrolleesCount?: Maybe<Scalars['Int']>;
  maximumEnrolleesCountPerIpAddress?: Maybe<Scalars['Int']>;
  maximumEnrolleesCountPerName?: Maybe<Scalars['Int']>;
  automaticPayout?: Maybe<Scalars['Boolean']>;
  generateRebate?: Maybe<Scalars['Boolean']>;
  generateMemberLoyaltyProgress?: Maybe<Scalars['Boolean']>;
  cyclesCount?: Maybe<Scalars['Int']>;
  dayRewardEnabled?: Maybe<Scalars['Boolean']>;
  dayRewardAmount?: Maybe<Scalars['RoundedFloat']>;
  dayRewardMultiplier?: Maybe<Scalars['Boolean']>;
  dayRewardPointsRequirement?: Maybe<Scalars['Int']>;
  dayRewardTurnoverRequirementMultiplier?: Maybe<Scalars['RoundedFloat']>;
  dayRewardType?: Maybe<MissionRewardType>;
  days?: Maybe<Array<MissionDayInput>>;
  memberLoyaltyGroups?: Maybe<Array<MissionMemberLoyaltyGroupInput>>;
  milestones?: Maybe<Array<MissionMilestoneInput>>;
  milestonesEnabled?: Maybe<Scalars['Boolean']>;
};

export type CreateNewlyRegisteredMembersReportFilterInput = {
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  admins?: Maybe<Array<Scalars['ID']>>;
};

export type CreateNewlyRegisteredMembersReportInput = {
  filter?: Maybe<CreateNewlyRegisteredMembersReportFilterInput>;
  timezone?: Maybe<Scalars['Timezone']>;
};

export type CreateOfflineBankTransferWithdrawalMethodInput = {
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  transactionFee: Scalars['RoundedFloat'];
  transactionFeeType: TransactionFeeType;
  provider?: Maybe<WithdrawalMethodProvider>;
  bank: Bank;
  accountNumber: Scalars['String'];
  accountName: Scalars['String'];
  branch?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
};

export type CreateOnlineWithdrawalMethodInput = {
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  transactionFee: Scalars['Float'];
  transactionFeeType: TransactionFeeType;
  provider?: Maybe<WithdrawalMethodProvider>;
  withdrawalProvider: Scalars['ID'];
  paymentChannel?: Maybe<Scalars['String']>;
};

export type CreateOperatorInput = {
  username: Scalars['String'];
  password: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  realName?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  email?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  wechatId?: Maybe<Scalars['String']>;
  qqId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  permissionGroups: Array<Scalars['ID']>;
  status?: Maybe<OperatorStatus>;
  otpValidationEnabled?: Maybe<Scalars['Boolean']>;
};

export type CreatePasswordResetInput = {
  email: Scalars['String'];
  username: Scalars['String'];
  adminCode: Scalars['String'];
};

export type CreatePaymentMethodInput = {
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  excludedMemberLevel?: Maybe<Array<Scalars['String']>>;
  memberLoyaltyLevel?: Maybe<Array<Scalars['String']>>;
  minimumDepositAmount: Scalars['Float'];
  maximumDepositAmount: Scalars['Float'];
  instructionText?: Maybe<Scalars['String']>;
  maximumDepositAmountPerDay: Scalars['Float'];
  depositExpiry: Scalars['Float'];
  turnoverRequirementMultiplier: Scalars['Float'];
  suggestedAmounts: Array<Scalars['Float']>;
  transactionFee: Scalars['Float'];
  transactionFeeType: TransactionFeeType;
  allowCustomAmount: Scalars['Boolean'];
  bank?: Maybe<Bank>;
  accountNumber?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['ID']>;
  depositProvider?: Maybe<Scalars['String']>;
  paymentChannel?: Maybe<Scalars['String']>;
  cryptocurrency?: Maybe<Cryptocurrency>;
  blockchain?: Maybe<Blockchain>;
  blockchainAddress?: Maybe<Scalars['String']>;
  minimumConfirmationsCount?: Maybe<Scalars['Int']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  exchangeRateSource?: Maybe<ExchangeRateSource>;
  exchangeRateUpdateFrequency?: Maybe<Scalars['String']>;
  exchangeRateSpread?: Maybe<Scalars['Float']>;
  provider?: Maybe<PaymentMethodProvider>;
};

export type CreatePermissionGroupInput = {
  name: Scalars['String'];
  permissions: Array<Scalars['String']>;
};

export type CreatePlayerStatusReportFilterInput = {
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  admins?: Maybe<Array<Scalars['ID']>>;
};

export type CreatePlayerStatusReportInput = {
  filter?: Maybe<CreatePlayerStatusReportFilterInput>;
};

export type CreatePromoInput = {
  name: Scalars['String'];
  type: PromoType;
  status: PromoStatus;
  event: PromoEvent;
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
  platform: Platform;
  includedMemberTags: Array<Maybe<Scalars['ID']>>;
  excludedMemberTags: Array<Maybe<Scalars['ID']>>;
  claimType: PromoClaimType;
  awardPreRequisite: PromoAwardPreRequisiteType;
  turnoverRequirementMultiplier?: Maybe<Scalars['Float']>;
  wagerContributions?: Maybe<Array<Maybe<CreatePromoWagerContributionInput>>>;
  autoCancel: Scalars['Boolean'];
  maintainingBalance?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['Currency']>;
  amount?: Maybe<Scalars['Float']>;
  amountPercentage?: Maybe<Scalars['Float']>;
  maxMemberCount?: Maybe<Scalars['Int']>;
  awardType?: Maybe<PromoAwardType>;
  awardCap?: Maybe<Scalars['Float']>;
  minAmount?: Maybe<Scalars['Float']>;
  maxAmount?: Maybe<Scalars['Float']>;
  claimCountLimit?: Maybe<Scalars['Int']>;
  paymentMethods?: Maybe<Array<Maybe<Scalars['ID']>>>;
  maxPayoutAmount?: Maybe<Scalars['Float']>;
};

export type CreatePromoPayoutReportInput = {
  filter?: Maybe<PromoPayoutsFilterInput>;
  timezone?: Maybe<Scalars['Timezone']>;
};

export type CreatePromoWagerContributionInput = {
  vendor: Scalars['ID'];
  gameType: GameType;
  contribution: Scalars['Float'];
  rank: Scalars['Int'];
};

export type CreateRebateGroupInput = {
  name?: Maybe<Scalars['String']>;
  qualifyingMemberLoyaltyLevels?: Maybe<Array<Scalars['ID']>>;
  excludedMemberLevels?: Maybe<Array<Scalars['ID']>>;
  validityDateTimeRange?: Maybe<DateTimeRangeInput>;
  automaticPayout?: Maybe<Scalars['Boolean']>;
  turnoverRequirementMultiplier?: Maybe<Scalars['Float']>;
  claimOffsetDuration?: Maybe<Scalars['Duration']>;
  claimExpiryDuration?: Maybe<Scalars['Duration']>;
  payoutExpiryDuration?: Maybe<Scalars['Duration']>;
  /**
   * # DEPRECATED
   *   - please use payoutExpiryDuration instead
   */
  awardExpiryDuration?: Maybe<Scalars['Duration']>;
  minimumPayout?: Maybe<Scalars['Float']>;
  maximumPayout?: Maybe<Scalars['Float']>;
  type?: Maybe<RebateGroupType>;
  percentages?: Maybe<Array<RebatePercentageInput>>;
  payoutSettlementPeriod?: Maybe<RebatePayoutSettlementPeriod>;
  payoutSettlementDayOfWeek?: Maybe<Scalars['DayOfWeek']>;
  payoutSettlementDayOfMonth?: Maybe<Scalars['DayOfMonth']>;
  payoutSettlementTime?: Maybe<Scalars['Time']>;
  payoutSettlementMonth?: Maybe<Scalars['Int']>;
  levels?: Maybe<Array<RebateLevelInput>>;
};

export type CreateRebateGroupInput_Next = {
  name?: Maybe<Scalars['String']>;
  qualifyingMemberLoyaltyLevels?: Maybe<Array<Scalars['ID']>>;
  excludedMemberLevels?: Maybe<Array<Scalars['ID']>>;
  validityDateTimeRange?: Maybe<DateTimeRangeInput>;
  automaticPayout?: Maybe<Scalars['Boolean']>;
  turnoverRequirementMultiplier?: Maybe<Scalars['Float']>;
  claimOffsetDuration?: Maybe<Scalars['Duration']>;
  claimExpiryDuration?: Maybe<Scalars['Duration']>;
  payoutExpiryDuration?: Maybe<Scalars['Duration']>;
  minimumPayout?: Maybe<Scalars['Float']>;
  maximumPayout?: Maybe<Scalars['Float']>;
  type?: Maybe<RebateGroupType>;
  percentages?: Maybe<Array<RebatePercentageInput>>;
  payoutSettlementPeriod?: Maybe<RebatePayoutSettlementPeriod>;
  payoutSettlementDayOfWeek?: Maybe<Scalars['DayOfWeek']>;
  payoutSettlementDayOfMonth?: Maybe<Scalars['DayOfMonth']>;
  payoutSettlementTime?: Maybe<Scalars['Time']>;
  payoutSettlementMonth?: Maybe<Scalars['Int']>;
  levels?: Maybe<Array<RebateLevelInput>>;
};

export type CreateVendorGroupInput = {
  name?: Maybe<Scalars['String']>;
  GGRCharges?: Maybe<Array<VendorGroupGgrChargeInput>>;
  enabled?: Maybe<Scalars['Boolean']>;
};

export type CreateVendorUsageReportFilterInput = {
  dateTimeProcessed: DateTimeQueryOperatorInput;
  vendor?: Maybe<IdQueryOperatorInput>;
};

export type CreateVendorUsageReportInput = {
  filter: CreateVendorUsageReportFilterInput;
  timezone?: Maybe<Scalars['Timezone']>;
  scheduledDateTime?: Maybe<Scalars['DateTime']>;
};

export type CreateWechatWithdrawalMethodInput = {
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  transactionFee: Scalars['Float'];
  transactionFeeType: TransactionFeeType;
  provider?: Maybe<WithdrawalMethodProvider>;
  nickname: Scalars['String'];
  accountNumber: Scalars['String'];
};

export type CreateWithdrawalProviderInput = {
  name: Scalars['String'];
  apiKey?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  merchantCode?: Maybe<Scalars['String']>;
  type: WithdrawalProviderType;
  midPayDirectPaymentGateway?: Maybe<Scalars['ID']>;
  credentialValues?: Maybe<Array<Scalars['String']>>;
};

export type CreditVendorTransferManualAdjustment = ManualAdjustment & {
  __typename?: 'CreditVendorTransferManualAdjustment';
  id: Scalars['ID'];
  type: ManualAdjustmentType;
  account: Account;
  amount: Scalars['RoundedFloat'];
  remarks: Scalars['String'];
  dateTimeCreated: Scalars['DateTime'];
};

export enum Cryptocurrency {
  Usdt = 'USDT',
}

export type CryptocurrencyDeposit = Deposit & {
  __typename?: 'CryptocurrencyDeposit';
  id: Scalars['ID'];
  amount: Scalars['RoundedFloat'];
  account: Member;
  processingTime?: Maybe<Scalars['Int']>;
  paymentMethod?: Maybe<PaymentMethod>;
  status: DepositStatus;
  processor?: Maybe<Account>;
  serialCode: Scalars['String'];
  firstDeposit?: Maybe<Scalars['Boolean']>;
  remarks?: Maybe<Scalars['String']>;
  manualAdjustment: Scalars['Boolean'];
  transactionDetails: DepositEventDetails;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeProcessed?: Maybe<Scalars['DateTime']>;
  cryptoAmount?: Maybe<Scalars['RoundedFloat']>;
  cryptoExchangeRate?: Maybe<Scalars['Float']>;
  cryptoCurrency?: Maybe<Scalars['String']>;
  depositorBlockchainAddress: Scalars['String'];
  depositorBlockchainAddressURL: Scalars['URL'];
  transactionReference?: Maybe<Scalars['String']>;
  transactionReferenceURL?: Maybe<Scalars['URL']>;
  image?: Maybe<File>;
  exchangeRate: Scalars['Float'];
  baseCurrency?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  amountInBaseCurrency?: Maybe<Scalars['RoundedFloat']>;
  isCrypto?: Maybe<Scalars['Boolean']>;
};

export type CryptocurrencyPaymentMethod = PaymentMethod & {
  __typename?: 'CryptocurrencyPaymentMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  currentAccumulation: Scalars['RoundedFloat'];
  memberLoyaltyLevel?: Maybe<Array<MemberLoyaltyLevel>>;
  excludedMemberLevel?: Maybe<Array<MemberLevel>>;
  minimumDepositAmount: Scalars['RoundedFloat'];
  maximumDepositAmount: Scalars['RoundedFloat'];
  instructionText?: Maybe<Scalars['String']>;
  maximumDepositAmountPerDay: Scalars['RoundedFloat'];
  depositExpiry: Scalars['RoundedFloat'];
  turnoverRequirementMultiplier: Scalars['RoundedFloat'];
  suggestedAmounts: Array<Scalars['RoundedFloat']>;
  transactionFee: Scalars['RoundedFloat'];
  transactionFeeType?: Maybe<TransactionFeeType>;
  allowCustomAmount: Scalars['Boolean'];
  updateable: Scalars['Boolean'];
  image?: Maybe<File>;
  cryptocurrency?: Maybe<Cryptocurrency>;
  blockchain?: Maybe<Blockchain>;
  blockchainAddress?: Maybe<Scalars['String']>;
  blockchainAddressURL?: Maybe<Scalars['URL']>;
  minimumConfirmationsCount?: Maybe<Scalars['Int']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  exchangeRateSource?: Maybe<ExchangeRateSource>;
  exchangeRateUpdateFrequency?: Maybe<Scalars['String']>;
  exchangeRateSpread?: Maybe<Scalars['RoundedFloat']>;
  provider?: Maybe<PaymentMethodProvider>;
};

export type CryptocurrencyWithdrawalMethod = WithdrawalMethod & {
  __typename?: 'CryptocurrencyWithdrawalMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  updateable: Scalars['Boolean'];
  transactionFee: Scalars['RoundedFloat'];
  transactionFeeType: TransactionFeeType;
  withdrawalProvider?: Maybe<WithdrawalProvider>;
  provider?: Maybe<WithdrawalMethodProvider>;
};

export type CryptoCurrencyWithdrawalProvider = WithdrawalProvider & {
  __typename?: 'CryptoCurrencyWithdrawalProvider';
  id: Scalars['ID'];
  name: Scalars['String'];
  apiKey: Scalars['String'];
  key: Scalars['String'];
  type: WithdrawalProviderType;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeUpdated: Scalars['DateTime'];
};

export type CurrencyMetadata = {
  __typename?: 'CurrencyMetadata';
  id: Scalars['ID'];
  code: Scalars['String'];
  name: Scalars['String'];
  digitalCode?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
};

export type CurrencyMetadataConnection = {
  __typename?: 'CurrencyMetadataConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<CurrencyMetadataConnectionEdge>>;
  pageInfo: PageInfo;
};

export type CurrencyMetadataConnectionEdge = {
  __typename?: 'CurrencyMetadataConnectionEdge';
  cursor: Scalars['Binary'];
  node: CurrencyMetadata;
};

export type CurrencyMetadataFilterInput = {
  id?: Maybe<IdQueryOperatorInput>;
};

export type CurrencyQueryOperatorInput = {
  eq?: Maybe<Scalars['Currency']>;
  ne?: Maybe<Scalars['Currency']>;
  in?: Maybe<Array<Maybe<Scalars['Currency']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Currency']>>>;
};

export enum CurrencyType {
  Crypto = 'CRYPTO',
  Fiat = 'FIAT',
}

export type CustomFilter = {
  __typename?: 'CustomFilter';
  id: Scalars['ID'];
  name: Scalars['String'];
  context: Scalars['ID'];
  filter: Scalars['JSON'];
  dateTimeCreated: Scalars['DateTime'];
  dateTimeUpdated: Scalars['DateTime'];
};

export type CustomFiltersConnection = {
  __typename?: 'CustomFiltersConnection';
  totalCount: Scalars['Int'];
  edges: Array<CustomFiltersConnectionEdge>;
  pageInfo: PageInfo;
};

export type CustomFiltersConnectionEdge = {
  __typename?: 'CustomFiltersConnectionEdge';
  cursor: Scalars['Binary'];
  node: CustomFilter;
};

export type CustomFiltersFilterInput = {
  name?: Maybe<StringQueryOperatorInput>;
  context?: Maybe<IdQueryOperatorInput>;
};

export type CustomFormField = FormField & {
  __typename?: 'CustomFormField';
  type: FormFieldType;
  required: Scalars['Boolean'];
  label: Scalars['String'];
  pattern: Scalars['String'];
  updatable?: Maybe<Scalars['Boolean']>;
  otpVerificationRequired?: Maybe<Scalars['Boolean']>;
};

export type DailyCasinoPerformance = {
  __typename?: 'DailyCasinoPerformance';
  id: Scalars['ID'];
  creator: Account;
  report?: Maybe<File>;
  status: DailyCasinoPerformanceStatus;
  dateTimeCreated: Scalars['DateTime'];
};

export type DailyCasinoPerformanceFilterInput = {
  admin?: Maybe<IdQueryOperatorInput>;
};

export type DailyCasinoPerformancesConnection = {
  __typename?: 'DailyCasinoPerformancesConnection';
  totalCount: Scalars['Int'];
  edges: Array<DailyCasinoPerformancesConnectionEdge>;
  pageInfo: PageInfo;
};

export type DailyCasinoPerformancesConnectionEdge = {
  __typename?: 'DailyCasinoPerformancesConnectionEdge';
  cursor: Scalars['Binary'];
  node: DailyCasinoPerformance;
};

export enum DailyCasinoPerformanceStatus {
  Generating = 'GENERATING',
  Generated = 'GENERATED',
}

export type DailyDepositReport = {
  __typename?: 'DailyDepositReport';
  id: Scalars['ID'];
  creator: Account;
  report?: Maybe<File>;
  status: DailyDepositReportStatus;
  dateTimeCreated: Scalars['DateTime'];
};

export type DailyDepositReportFilterInput = {
  admin?: Maybe<IdQueryOperatorInput>;
};

export type DailyDepositReportsConnection = {
  __typename?: 'DailyDepositReportsConnection';
  totalCount: Scalars['Int'];
  edges: Array<DailyDepositReportsConnectionEdge>;
  pageInfo: PageInfo;
};

export type DailyDepositReportsConnectionEdge = {
  __typename?: 'DailyDepositReportsConnectionEdge';
  cursor: Scalars['Binary'];
  node: DailyDepositReport;
};

export enum DailyDepositReportStatus {
  Generating = 'GENERATING',
  Generated = 'GENERATED',
  Failed = 'FAILED',
}

export type DailyOperationReportFilterInput = {
  date?: Maybe<DateTimeQueryOperatorInput>;
  product?: Maybe<GameTypeQueryOperatorInput>;
};

export type DailyOperationReportItem = {
  __typename?: 'DailyOperationReportItem';
  id: Scalars['ID'];
  date: Scalars['Date'];
  memberRegistrationsCount: Scalars['Int'];
  firstDepositsCount: Scalars['Int'];
  /** @deprecated No longer used. Please use firstDepositsCount */
  firstDepositorsCount: Scalars['Int'];
  firstDepositsAmount: Scalars['RoundedFloat'];
  depositsCount: Scalars['Int'];
  /** @deprecated No longer used. Please use depositsAmount */
  depositAmount: Scalars['Float'];
  depositsAmount: Scalars['RoundedFloat'];
  depositsBaseAmount?: Maybe<Scalars['RoundedFloat']>;
  withdrawalsCount: Scalars['Int'];
  /** @deprecated No longer used. Please use withdrawalsAmount */
  withdrawalAmount: Scalars['Float'];
  withdrawalsAmount: Scalars['RoundedFloat'];
  withdrawalsBaseAmount?: Maybe<Scalars['RoundedFloat']>;
  profit: Scalars['RoundedFloat'];
  betsCount: Scalars['Int'];
  turnover: Scalars['RoundedFloat'];
  effectiveBet: Scalars['RoundedFloat'];
  winloss: Scalars['RoundedFloat'];
  casinoNgr?: Maybe<Scalars['RoundedFloat']>;
  sportsNgr?: Maybe<Scalars['RoundedFloat']>;
  virtualNgr?: Maybe<Scalars['RoundedFloat']>;
  profitRatio: Scalars['RoundedFloat'];
  bonusRebates: Scalars['RoundedFloat'];
  ngr: Scalars['RoundedFloat'];
  winnings: Scalars['RoundedFloat'];
  payout: Scalars['RoundedFloat'];
  ggr: Scalars['RoundedFloat'];
  game?: Maybe<Scalars['ID']>;
  totalBonusTurnover?: Maybe<Scalars['RoundedFloat']>;
  promoPayouts?: Maybe<Scalars['RoundedFloat']>;
  promoUsed?: Maybe<Scalars['RoundedFloat']>;
  totalPromo?: Maybe<Scalars['RoundedFloat']>;
  baseTurnover?: Maybe<Scalars['RoundedFloat']>;
  baseWinloss?: Maybe<Scalars['RoundedFloat']>;
  baseEffectiveBet?: Maybe<Scalars['RoundedFloat']>;
  baseWinnings?: Maybe<Scalars['RoundedFloat']>;
  basePayout?: Maybe<Scalars['RoundedFloat']>;
  baseGgr?: Maybe<Scalars['RoundedFloat']>;
  baseSportsNgr?: Maybe<Scalars['RoundedFloat']>;
  baseCasinoNgr?: Maybe<Scalars['RoundedFloat']>;
  baseVirtualNgr?: Maybe<Scalars['RoundedFloat']>;
  baseNgr?: Maybe<Scalars['RoundedFloat']>;
  totalBaseBonusTurnover?: Maybe<Scalars['RoundedFloat']>;
  basePromoPayouts?: Maybe<Scalars['RoundedFloat']>;
  baseTotalPromo?: Maybe<Scalars['RoundedFloat']>;
};

export type DailyOperationReportItemsConnection = {
  __typename?: 'DailyOperationReportItemsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<DailyOperationReportItemsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type DailyOperationReportItemsConnectionEdge = {
  __typename?: 'DailyOperationReportItemsConnectionEdge';
  cursor: Scalars['Binary'];
  node: DailyOperationReportItem;
};

export type DailyPlayerActivitiesConnection = {
  __typename?: 'DailyPlayerActivitiesConnection';
  totalCount: Scalars['Int'];
  edges: Array<DailyPlayerActivitiesConnectionEdge>;
  pageInfo: PageInfo;
};

export type DailyPlayerActivitiesConnectionEdge = {
  __typename?: 'DailyPlayerActivitiesConnectionEdge';
  cursor: Scalars['Binary'];
  node: DailyPlayerActivity;
};

export type DailyPlayerActivity = {
  __typename?: 'DailyPlayerActivity';
  id: Scalars['ID'];
  creator: Account;
  report?: Maybe<File>;
  status: DailyPlayerActivityStatus;
  dateTimeCreated: Scalars['DateTime'];
};

export type DailyPlayerActivityFilterInput = {
  admin?: Maybe<IdQueryOperatorInput>;
};

export enum DailyPlayerActivityStatus {
  Generating = 'GENERATING',
  Generated = 'GENERATED',
  Failed = 'FAILED',
}

export type DailySelfExcludedReport = {
  __typename?: 'DailySelfExcludedReport';
  id: Scalars['ID'];
  creator: Account;
  report?: Maybe<File>;
  status: DailySelfExcludedReportStatus;
  dateTimeCreated: Scalars['DateTime'];
};

export type DailySelfExcludedReportFilterInput = {
  admin?: Maybe<IdQueryOperatorInput>;
};

export type DailySelfExcludedReportsConnection = {
  __typename?: 'DailySelfExcludedReportsConnection';
  totalCount: Scalars['Int'];
  edges: Array<DailySelfExcludedReportsConnectionEdge>;
  pageInfo: PageInfo;
};

export type DailySelfExcludedReportsConnectionEdge = {
  __typename?: 'DailySelfExcludedReportsConnectionEdge';
  cursor: Scalars['Binary'];
  node: DailySelfExcludedReport;
};

export enum DailySelfExcludedReportStatus {
  Generating = 'GENERATING',
  Generated = 'GENERATED',
}

export type DailySportsPerformance = {
  __typename?: 'DailySportsPerformance';
  id: Scalars['ID'];
  creator: Account;
  report?: Maybe<File>;
  status: DailySportsPerformanceStatus;
  dateTimeCreated: Scalars['DateTime'];
};

export type DailySportsPerformanceFilterInput = {
  admin?: Maybe<IdQueryOperatorInput>;
};

export type DailySportsPerformancesConnection = {
  __typename?: 'DailySportsPerformancesConnection';
  totalCount: Scalars['Int'];
  edges: Array<DailySportsPerformancesConnectionEdge>;
  pageInfo: PageInfo;
};

export type DailySportsPerformancesConnectionEdge = {
  __typename?: 'DailySportsPerformancesConnectionEdge';
  cursor: Scalars['Binary'];
  node: DailySportsPerformance;
};

export enum DailySportsPerformanceStatus {
  Generating = 'GENERATING',
  Generated = 'GENERATED',
}

export type DailyTimeOutReport = {
  __typename?: 'DailyTimeOutReport';
  id: Scalars['ID'];
  creator: Account;
  report?: Maybe<File>;
  status: DailySelfExcludedReportStatus;
  dateTimeCreated: Scalars['DateTime'];
};

export type DailyTimeOutReportFilterInput = {
  admin?: Maybe<IdQueryOperatorInput>;
};

export type DailyTimeOutReportsConnection = {
  __typename?: 'DailyTimeOutReportsConnection';
  totalCount: Scalars['Int'];
  edges: Array<DailyTimeOutReportsConnectionEdge>;
  pageInfo: PageInfo;
};

export type DailyTimeOutReportsConnectionEdge = {
  __typename?: 'DailyTimeOutReportsConnectionEdge';
  cursor: Scalars['Binary'];
  node: DailyTimeOutReport;
};

export enum DailyTimeOutReportStatus {
  Generating = 'GENERATING',
  Generated = 'GENERATED',
}

export type DailyTransactionReport = {
  __typename?: 'DailyTransactionReport';
  id: Scalars['ID'];
  creator: Account;
  report?: Maybe<File>;
  status: DailyTransactionReportStatus;
  dateTimeCreated: Scalars['DateTime'];
};

export type DailyTransactionReportFilterInput = {
  admin?: Maybe<IdQueryOperatorInput>;
};

export type DailyTransactionReportsConnection = {
  __typename?: 'DailyTransactionReportsConnection';
  totalCount: Scalars['Int'];
  edges: Array<DailyTransactionReportsConnectionEdge>;
  pageInfo: PageInfo;
};

export type DailyTransactionReportsConnectionEdge = {
  __typename?: 'DailyTransactionReportsConnectionEdge';
  cursor: Scalars['Binary'];
  node: DailyTransactionReport;
};

export enum DailyTransactionReportStatus {
  Generating = 'GENERATING',
  Generated = 'GENERATED',
  Failed = 'FAILED',
}

export type DataPoint = {
  __typename?: 'DataPoint';
  label: Scalars['String'];
  value: Scalars['RoundedFloat'];
};

export type DateQueryOperatorInput = {
  eq?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Scalars['Date']>>;
  nin?: Maybe<Array<Scalars['Date']>>;
};

export type DateRange = {
  __typename?: 'DateRange';
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
};

export type DateTimeQueryOperator = {
  __typename?: 'DateTimeQueryOperator';
  eq?: Maybe<Scalars['DateTime']>;
  ne?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  nin?: Maybe<Array<Scalars['DateTime']>>;
};

export type DateTimeQueryOperatorInput = {
  eq?: Maybe<Scalars['DateTime']>;
  ne?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  nin?: Maybe<Array<Scalars['DateTime']>>;
};

export type DateTimeRange = {
  __typename?: 'DateTimeRange';
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
};

export type DateTimeRangeInput = {
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
};

export enum Day {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
}

export type DebitVendorTransferManualAdjustment = ManualAdjustment & {
  __typename?: 'DebitVendorTransferManualAdjustment';
  id: Scalars['ID'];
  type: ManualAdjustmentType;
  account: Account;
  amount: Scalars['RoundedFloat'];
  remarks: Scalars['String'];
  dateTimeCreated: Scalars['DateTime'];
};

export type DeleteHexoPayCreditCardInput = {
  id: Scalars['ID'];
  account: Scalars['ID'];
};

export type Deposit = {
  id: Scalars['ID'];
  amount: Scalars['RoundedFloat'];
  account: Member;
  processingTime?: Maybe<Scalars['Int']>;
  paymentMethod?: Maybe<PaymentMethod>;
  status: DepositStatus;
  processor?: Maybe<Account>;
  serialCode: Scalars['String'];
  firstDeposit?: Maybe<Scalars['Boolean']>;
  remarks?: Maybe<Scalars['String']>;
  transactionReference?: Maybe<Scalars['String']>;
  manualAdjustment: Scalars['Boolean'];
  transactionDetails: DepositEventDetails;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeProcessed?: Maybe<Scalars['DateTime']>;
  baseCurrency?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  amountInBaseCurrency?: Maybe<Scalars['RoundedFloat']>;
  isCrypto?: Maybe<Scalars['Boolean']>;
};

export type DepositDetails = {
  __typename?: 'DepositDetails';
  type: DepositType;
  info: Scalars['String'];
  bankCode?: Maybe<Scalars['String']>;
};

export type DepositEventDetails = {
  __typename?: 'DepositEventDetails';
  data?: Maybe<Scalars['JSON']>;
};

export enum DepositLimitFrequency {
  Hourly = 'HOURLY',
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY',
  Quarterly = 'QUARTERLY',
}

export type DepositLimitReport = {
  __typename?: 'DepositLimitReport';
  id: Scalars['ID'];
  creator: Account;
  report?: Maybe<File>;
  status: DepositLimitReportStatus;
  dateTimeCreated: Scalars['DateTime'];
};

export type DepositLimitReportFilterInput = {
  admin?: Maybe<IdQueryOperatorInput>;
};

export type DepositLimitReportsConnection = {
  __typename?: 'DepositLimitReportsConnection';
  totalCount: Scalars['Int'];
  edges: Array<DepositLimitReportsConnectionEdge>;
  pageInfo: PageInfo;
};

export type DepositLimitReportsConnectionEdge = {
  __typename?: 'DepositLimitReportsConnectionEdge';
  cursor: Scalars['Binary'];
  node: DepositLimitReport;
};

export enum DepositLimitReportStatus {
  Generating = 'GENERATING',
  Generated = 'GENERATED',
}

export type DepositLimits = {
  __typename?: 'DepositLimits';
  frequency?: Maybe<MemberDepositLimitFrequency>;
  limit?: Maybe<Scalars['RoundedFloat']>;
  used?: Maybe<Scalars['RoundedFloat']>;
  nextLimit?: Maybe<Scalars['RoundedFloat']>;
};

export type DepositLimitSetting = {
  __typename?: 'DepositLimitSetting';
  frequency: DepositLimitFrequency;
  limit?: Maybe<Scalars['Int']>;
  /**
   * ### Phase 2
   * - `used `
   * - `remaining`
   */
  used?: Maybe<Scalars['Int']>;
  remaining?: Maybe<Scalars['Int']>;
  nextLimit?: Maybe<Scalars['Int']>;
};

export type DepositLimitSettingInput = {
  frequency: DepositLimitFrequency;
  limit?: Maybe<Scalars['Int']>;
  /**
   * ### Phase 2
   * - `used `
   * - `remaining`
   */
  used?: Maybe<Scalars['Int']>;
  remaining?: Maybe<Scalars['Int']>;
};

export type DepositManualAdjustment = ManualAdjustment & {
  __typename?: 'DepositManualAdjustment';
  id: Scalars['ID'];
  type: ManualAdjustmentType;
  account: Account;
  amount: Scalars['RoundedFloat'];
  remarks: Scalars['String'];
  dateTimeCreated: Scalars['DateTime'];
  actual: Scalars['Boolean'];
  turnoverRequirementMultiplier: Scalars['RoundedFloat'];
};

export type DepositPromo = Promo & {
  __typename?: 'DepositPromo';
  id: Scalars['ID'];
  creator: Account;
  status: PromoStatus;
  name: Scalars['String'];
  type: PromoType;
  event: PromoEvent;
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
  dateTimeCreated: Scalars['DateTime'];
  platform: Platform;
  includedMemberTags: Array<Maybe<MemberTag>>;
  excludedMemberTags: Array<Maybe<MemberTag>>;
  claimType: PromoClaimType;
  awardPreRequisite: PromoAwardPreRequisiteType;
  turnoverRequirementMultiplier: Scalars['RoundedFloat'];
  wagerContributions: Array<Maybe<PromoWagerContribution>>;
  autoCancel: Scalars['Boolean'];
  maintainingBalance?: Maybe<Scalars['RoundedFloat']>;
  awardType: PromoAwardType;
  amount?: Maybe<Scalars['RoundedFloat']>;
  amountPercentage?: Maybe<Scalars['RoundedFloat']>;
  awardCap?: Maybe<Scalars['RoundedFloat']>;
  minAmount: Scalars['RoundedFloat'];
  maxAmount: Scalars['RoundedFloat'];
  claimCountLimit: Scalars['Int'];
  paymentMethods: Array<Maybe<PaymentMethod>>;
  maxPayoutAmount: Scalars['RoundedFloat'];
  totalAmountAwarded?: Maybe<Scalars['RoundedFloat']>;
};

export type DepositProvider = {
  id: Scalars['ID'];
  name: Scalars['String'];
  dateTimeCreated: Scalars['DateTime'];
  dateTimeUpdated: Scalars['DateTime'];
};

export type DepositProvidersConnection = {
  __typename?: 'DepositProvidersConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<DepositProvidersConnectionEdge>>;
  pageInfo: PageInfo;
};

export type DepositProvidersConnectionEdge = {
  __typename?: 'DepositProvidersConnectionEdge';
  cursor: Scalars['Binary'];
  node: DepositProvider;
};

export type DepositProvidersFilterInput = {
  name?: Maybe<StringQueryOperatorInput>;
  depositProviderType?: Maybe<DepositProviderTypeQueryOperatorInput>;
};

export enum DepositProviderType {
  Diorpay = 'DIORPAY',
  Diorpay2 = 'DIORPAY2',
  Midpay = 'MIDPAY',
  MidpayDirect = 'MIDPAY_DIRECT',
}

export type DepositProviderTypeQueryOperatorInput = {
  eq?: Maybe<DepositProviderType>;
  ne?: Maybe<DepositProviderType>;
  in?: Maybe<Array<DepositProviderType>>;
  nin?: Maybe<Array<DepositProviderType>>;
};

export type DepositsConnection = {
  __typename?: 'DepositsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<DepositsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type DepositsConnectionEdge = {
  __typename?: 'DepositsConnectionEdge';
  cursor: Scalars['Binary'];
  node: Deposit;
};

export type DepositsFilterInput = {
  dateTimeCreated?: Maybe<DateTimeQueryOperatorInput>;
  dateTimeUpdated?: Maybe<DateTimeQueryOperatorInput>;
  memberLevel?: Maybe<IdQueryOperatorInput>;
  memberLoyaltyLevels?: Maybe<IdQueryOperatorInput>;
  account?: Maybe<IdQueryOperatorInput>;
  amount?: Maybe<FloatQueryOperatorInput>;
  processingTime?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<StringQueryOperatorInput>;
  processor?: Maybe<IdQueryOperatorInput>;
  bank?: Maybe<StringQueryOperatorInput>;
  serialCode?: Maybe<StringQueryOperatorInput>;
  firstDeposit?: Maybe<BooleanQueryOperatorInput>;
  paymentMethodType?: Maybe<PaymentMethodTypeQueryOperatorInput>;
  paymentMethod?: Maybe<IdQueryOperatorInput>;
  depositProvider?: Maybe<IdQueryOperatorInput>;
  brandId?: Maybe<StringQueryOperatorInput>;
  platformId?: Maybe<StringQueryOperatorInput>;
  currency?: Maybe<CurrencyQueryOperatorInput>;
};

export type DepositSource = {
  __typename?: 'DepositSource';
  id: Scalars['ID'];
  logo: File;
  order: Scalars['Int'];
  shortName: Scalars['String'];
  displayName: Scalars['String'];
  visible: Scalars['Boolean'];
  loadBankSource: Scalars['Boolean'];
};

export type DepositSourceShortNameArgs = {
  language?: Maybe<Scalars['Language']>;
};

export type DepositSourceDisplayNameArgs = {
  language?: Maybe<Scalars['Language']>;
};

export type DepositSourceConnection = {
  __typename?: 'DepositSourceConnection';
  totalCount: Scalars['Int'];
  edges: Array<DepositSourceConnectionEdge>;
  pageInfo: PageInfo;
};

export type DepositSourceConnectionEdge = {
  __typename?: 'DepositSourceConnectionEdge';
  cursor: Scalars['Binary'];
  node: DepositSource;
};

export enum DepositStatus {
  Draft = 'DRAFT',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
}

export enum DepositType {
  Url = 'URL',
  Img = 'IMG',
  String = 'STRING',
}

export type DiorPay2DepositProvider = DepositProvider & {
  __typename?: 'DiorPay2DepositProvider';
  id: Scalars['ID'];
  name: Scalars['String'];
  encryptionKey: Scalars['String'];
  merchantId: Scalars['String'];
  partner: Scalars['String'];
  dateTimeCreated: Scalars['DateTime'];
  dateTimeUpdated: Scalars['DateTime'];
};

export type DiorPay2PaymentGateway = PaymentGateway & {
  __typename?: 'DiorPay2PaymentGateway';
  id: Scalars['ID'];
  gateway: Scalars['String'];
  name: Scalars['String'];
  paymentChannels: Array<Scalars['String']>;
};

export type DiorPayDepositProvider = DepositProvider & {
  __typename?: 'DiorPayDepositProvider';
  id: Scalars['ID'];
  name: Scalars['String'];
  merchantCode: Scalars['String'];
  key: Scalars['String'];
  keyPreview: Scalars['String'];
  paymentChannels: ThirdPartyPaymentChannels;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeUpdated: Scalars['DateTime'];
};

export type DiorpayWithdrawalProvider = WithdrawalProvider & {
  __typename?: 'DiorpayWithdrawalProvider';
  id: Scalars['ID'];
  name: Scalars['String'];
  key: Scalars['String'];
  keyPreview: Scalars['String'];
  merchantCode?: Maybe<Scalars['String']>;
  type: WithdrawalProviderType;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeUpdated: Scalars['DateTime'];
};

export type Domain = {
  __typename?: 'Domain';
  id: Scalars['ID'];
  name: Scalars['String'];
  status: DomainStatus;
  description: Scalars['String'];
  sslEnabled: Scalars['Boolean'];
  dateTimeCreated: Scalars['DateTime'];
  dateTimeUpdated: Scalars['DateTime'];
};

export type DomainFilterInput = {
  url?: Maybe<StringQueryOperatorInput>;
  ssl?: Maybe<BooleanQueryOperatorInput>;
  status?: Maybe<DomainStatusQueryOperatorInput>;
};

export type DomainsConnection = {
  __typename?: 'DomainsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<DomainsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type DomainsConnectionEdge = {
  __typename?: 'DomainsConnectionEdge';
  cursor: Scalars['Binary'];
  node: Domain;
};

export enum DomainStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type DomainStatusQueryOperatorInput = {
  eq?: Maybe<DomainStatus>;
  ne?: Maybe<DomainStatus>;
  in?: Maybe<Array<DomainStatus>>;
  nin?: Maybe<Array<DomainStatus>>;
};

export type DynamicPage = {
  __typename?: 'DynamicPage';
  id: Scalars['ID'];
  type: DynamicPageType;
  pages: Array<DynamicPagePages>;
  version: Scalars['String'];
  dateTimeCreated: Scalars['DateTime'];
  customDateTimeCreated?: Maybe<Scalars['DateTime']>;
};

export type DynamicPageConnection = {
  __typename?: 'DynamicPageConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<DynamicPageConnectionEdge>>;
  pageInfo: PageInfo;
};

export type DynamicPageConnectionEdge = {
  __typename?: 'DynamicPageConnectionEdge';
  cursor: Scalars['Binary'];
  node: DynamicPage;
};

export type DynamicPageFilterInput = {
  type?: Maybe<DynamicPageTypeQueryOperatorInput>;
};

export type DynamicPagePages = {
  __typename?: 'DynamicPagePages';
  pageNumber: Scalars['Int'];
  title: Scalars['String'];
  content: Scalars['String'];
};

export type DynamicPagePagesInput = {
  pageNumber: Scalars['Int'];
  title: Scalars['String'];
  content: Scalars['String'];
};

export enum DynamicPageType {
  TermsAndConditions = 'TERMS_AND_CONDITIONS',
  PrivacyPolicy = 'PRIVACY_POLICY',
  FrequentlyAskedQuestions = 'FREQUENTLY_ASKED_QUESTIONS',
}

export type DynamicPageTypeQueryOperatorInput = {
  eq?: Maybe<DynamicPageType>;
  ne?: Maybe<DynamicPageType>;
  in?: Maybe<Array<DynamicPageType>>;
  nin?: Maybe<Array<DynamicPageType>>;
};

export type EmailAuditTrail = {
  __typename?: 'EmailAuditTrail';
  dateTimeCreated: Scalars['DateTime'];
  triggerEventName: TriggerEventName;
  triggerName?: Maybe<Scalars['String']>;
  sentTo?: Maybe<Scalars['String']>;
  source: TriggerSource;
  sent: Scalars['Boolean'];
  blocked?: Maybe<Scalars['Boolean']>;
};

export type EmailAuditTrailConnection = {
  __typename?: 'EmailAuditTrailConnection';
  totalCount: Scalars['Int'];
  edges: Array<EmailAuditTrailConnectionEdge>;
  pageInfo: PageInfo;
};

export type EmailAuditTrailConnectionEdge = {
  __typename?: 'EmailAuditTrailConnectionEdge';
  cursor: Scalars['Binary'];
  node: EmailAuditTrail;
};

export type EmailAuditTrailFilterInput = {
  admin?: Maybe<IdQueryOperatorInput>;
  startDateTime?: Maybe<DateTimeQueryOperatorInput>;
  endDateTime?: Maybe<DateTimeQueryOperatorInput>;
  triggerEventName?: Maybe<EmailTriggerEventQueryOperatorInput>;
  triggerName?: Maybe<StringQueryOperatorInput>;
  sentTo?: Maybe<StringQueryOperatorInput>;
  source?: Maybe<EmailTriggerSourceQueryOperatorInput>;
  sent?: Maybe<BooleanQueryOperatorInput>;
  blocked?: Maybe<BooleanQueryOperatorInput>;
};

export type EmailTriggerEventQueryOperatorInput = {
  eq?: Maybe<TriggerEventName>;
  ne?: Maybe<TriggerEventName>;
  in?: Maybe<Array<TriggerEventName>>;
  nin?: Maybe<Array<TriggerEventName>>;
};

export type EmailTriggerSourceQueryOperatorInput = {
  eq?: Maybe<TriggerSource>;
  ne?: Maybe<TriggerSource>;
  in?: Maybe<Array<TriggerSource>>;
  nin?: Maybe<Array<TriggerSource>>;
};

export type Event = {
  id: Scalars['ID'];
  type: Scalars['String'];
  dateTimeCreated: Scalars['DateTime'];
};

export enum ExchangeRateSource {
  Manual = 'MANUAL',
  HuobiOtc = 'HUOBI_OTC',
  CoinMarketCap = 'COIN_MARKET_CAP',
  Blockchain = 'BLOCKCHAIN',
  CoinGecko = 'COIN_GECKO',
  CoinLayer = 'COIN_LAYER',
  Alphapo = 'ALPHAPO',
}

export type ExportMembersInput = {
  filter?: Maybe<MembersFilterInput>;
};

export type ExternalProgrammeManualAdjustment = ManualAdjustment & {
  __typename?: 'ExternalProgrammeManualAdjustment';
  id: Scalars['ID'];
  type: ManualAdjustmentType;
  account: Account;
  amount: Scalars['RoundedFloat'];
  remarks: Scalars['String'];
  dateTimeCreated: Scalars['DateTime'];
  turnoverRequirementMultiplier: Scalars['RoundedFloat'];
};

export enum FieldStateInputType {
  Mandatory = 'MANDATORY',
  Optional = 'OPTIONAL',
  Hidden = 'HIDDEN',
}

export type File = {
  __typename?: 'File';
  id: Scalars['ID'];
  uri: Scalars['String'];
  mimetype: Scalars['String'];
  encoding: Scalars['String'];
  dateTimeCreated: Scalars['DateTime'];
  contentLength: Scalars['Int'];
};

export type FloatQueryOperator = {
  __typename?: 'FloatQueryOperator';
  eq?: Maybe<Scalars['Float']>;
  ne?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  nin?: Maybe<Array<Scalars['Float']>>;
};

export type FloatQueryOperatorInput = {
  eq?: Maybe<Scalars['Float']>;
  ne?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  nin?: Maybe<Array<Scalars['Float']>>;
};

export type FormField = {
  type: FormFieldType;
  required: Scalars['Boolean'];
  updatable?: Maybe<Scalars['Boolean']>;
  otpVerificationRequired?: Maybe<Scalars['Boolean']>;
};

export type FormFieldInput = {
  type: FormFieldType;
  required: Scalars['Boolean'];
  label?: Maybe<Scalars['String']>;
  pattern?: Maybe<Scalars['String']>;
  field?: Maybe<MemberField>;
  updatable?: Maybe<Scalars['Boolean']>;
  otpVerificationRequired?: Maybe<Scalars['Boolean']>;
};

export enum FormFieldType {
  BuiltIn = 'BUILT_IN',
  Custom = 'CUSTOM',
}

export type FullCustomerDump = {
  __typename?: 'FullCustomerDump';
  id: Scalars['ID'];
  creator: Account;
  report?: Maybe<File>;
  status: FullCustomerDumpStatus;
  dateTimeCreated: Scalars['DateTime'];
};

export type FullCustomerDumpFilterInput = {
  admin?: Maybe<IdQueryOperatorInput>;
};

export type FullCustomerDumpsConnection = {
  __typename?: 'FullCustomerDumpsConnection';
  totalCount: Scalars['Int'];
  edges: Array<FullCustomerDumpsConnectionEdge>;
  pageInfo: PageInfo;
};

export type FullCustomerDumpsConnectionEdge = {
  __typename?: 'FullCustomerDumpsConnectionEdge';
  cursor: Scalars['Binary'];
  node: FullCustomerDump;
};

export enum FullCustomerDumpStatus {
  Generating = 'GENERATING',
  Generated = 'GENERATED',
  Failed = 'FAILED',
}

export type Game = {
  __typename?: 'Game';
  id: Scalars['ID'];
  vendor: Vendor;
  type: GameType;
  name?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  hot?: Maybe<Scalars['Boolean']>;
  new?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  subCategory?: Maybe<Scalars['String']>;
  stakes?: Maybe<GameStakes>;
};

export type GameNameArgs = {
  language?: Maybe<Scalars['Language']>;
};

export type GameImageArgs = {
  language?: Maybe<Scalars['Language']>;
};

export type GameAssets = {
  __typename?: 'GameAssets';
  name: Scalars['String'];
  image: Scalars['String'];
};

export type GameAssetsInput = {
  name: Scalars['String'];
  image: Scalars['URL'];
};

export type GameCategoriesFilterInput = {
  platform: PlatformQueryOperatorInput;
};

export type GameCategory = {
  __typename?: 'GameCategory';
  id: Scalars['ID'];
  creator: Account;
  name: Scalars['String'];
  gameTypes: Array<GameType>;
  icon?: Maybe<File>;
  desktopIcon?: Maybe<File>;
  heroBanner?: Maybe<File>;
  banner?: Maybe<File>;
  mascotHorizontal?: Maybe<File>;
  backgroundHorizontal?: Maybe<File>;
  rank: Scalars['Int'];
  vendors: Array<Vendor>;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeUpdated: Scalars['DateTime'];
  platform: Platform;
};

export type GameImageInput = {
  ZH?: Maybe<Scalars['String']>;
  EN?: Maybe<Scalars['String']>;
};

export type GameNameInput = {
  ZH?: Maybe<Scalars['String']>;
  EN?: Maybe<Scalars['String']>;
};

export type GamesConnection = {
  __typename?: 'GamesConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<GamesConnectionEdge>>;
  pageInfo: PageInfo;
};

export type GamesConnectionEdge = {
  __typename?: 'GamesConnectionEdge';
  cursor: Scalars['Binary'];
  node: Game;
};

export type GameSession = {
  __typename?: 'GameSession';
  id: Scalars['ID'];
  admin: Account;
  member: Account;
  platformId?: Maybe<Scalars['String']>;
  brandId?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  realName?: Maybe<Scalars['String']>;
  gameCategory?: Maybe<Scalars['String']>;
  game?: Maybe<Scalars['String']>;
  gameStartTimeStamp?: Maybe<Scalars['DateTime']>;
  gameEndTimeStamp?: Maybe<Scalars['DateTime']>;
  dateTimeCreated: Scalars['DateTime'];
  duration?: Maybe<Scalars['String']>;
};

export type GameSessionDuration = {
  __typename?: 'GameSessionDuration';
  id: Scalars['ID'];
  creator: Account;
  report?: Maybe<File>;
  status: GameSessionDurationStatus;
  dateTimeCreated: Scalars['DateTime'];
};

export type GameSessionDurationFilterInput = {
  admin?: Maybe<IdQueryOperatorInput>;
};

export type GameSessionDurationsConnection = {
  __typename?: 'GameSessionDurationsConnection';
  totalCount: Scalars['Int'];
  edges: Array<GameSessionDurationsConnectionEdge>;
  pageInfo: PageInfo;
};

export type GameSessionDurationsConnectionEdge = {
  __typename?: 'GameSessionDurationsConnectionEdge';
  cursor: Scalars['Binary'];
  node: GameSessionDuration;
};

export enum GameSessionDurationStatus {
  Generating = 'GENERATING',
  Generated = 'GENERATED',
}

export type GameSessionFilterInput = {
  admin?: Maybe<IdQueryOperatorInput>;
  member?: Maybe<IdQueryOperatorInput>;
  brand?: Maybe<StringQueryOperatorInput>;
  brandId?: Maybe<StringQueryOperatorInput>;
  platformId?: Maybe<StringQueryOperatorInput>;
  username?: Maybe<StringQueryOperatorInput>;
};

export type GameSessionsConnection = {
  __typename?: 'GameSessionsConnection';
  totalCount: Scalars['Int'];
  edges: Array<GameSessionsConnectionEdge>;
  pageInfo: PageInfo;
};

export type GameSessionsConnectionEdge = {
  __typename?: 'GameSessionsConnectionEdge';
  cursor: Scalars['Binary'];
  node: GameSession;
};

export type GameSettings = {
  __typename?: 'GameSettings';
  game: Scalars['ID'];
  admin: Scalars['ID'];
  context: GameSettingsContext;
  vendor: VendorCode;
  disabled: Scalars['Boolean'];
  hidden: Scalars['Boolean'];
  assets: GameAssets;
};

export type GameSettingsAssetsArgs = {
  language: Scalars['Language'];
};

export type GameSettingsAssetsInput = {
  EN?: Maybe<GameAssetsInput>;
  ZH?: Maybe<GameAssetsInput>;
  ZH_HANT?: Maybe<GameAssetsInput>;
  ZH_HANS?: Maybe<GameAssetsInput>;
};

export enum GameSettingsContext {
  Admin = 'ADMIN',
  SuperAdmin = 'SUPER_ADMIN',
}

export type GameSettingsContextQueryOperatorInput = {
  eq?: Maybe<GameSettingsContext>;
  ne?: Maybe<GameSettingsContext>;
  in?: Maybe<Array<Maybe<GameSettingsContext>>>;
  nin?: Maybe<Array<Maybe<GameSettingsContext>>>;
};

export type GameSettingsItemsConnection = {
  __typename?: 'GameSettingsItemsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<GameSettingsItemsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type GameSettingsItemsConnectionEdge = {
  __typename?: 'GameSettingsItemsConnectionEdge';
  cursor: Scalars['Binary'];
  node: GameSettings;
};

export type GameSettingsItemsFilterInput = {
  admin?: Maybe<IdQueryOperatorInput>;
  vendor?: Maybe<VendorCodeQueryOperatorInput>;
  context?: Maybe<GameSettingsContextQueryOperatorInput>;
  game?: Maybe<IdQueryOperatorInput>;
};

export type GamesFilterInput = {
  id?: Maybe<IdQueryOperatorInput>;
  vendor?: Maybe<IdQueryOperatorInput>;
  type?: Maybe<GameTypeQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  hot?: Maybe<BooleanQueryOperatorInput>;
  new?: Maybe<BooleanQueryOperatorInput>;
  category?: Maybe<StringQueryOperatorInput>;
  subCategory?: Maybe<StringQueryOperatorInput>;
};

export type GameStakes = {
  __typename?: 'GameStakes';
  jackpot?: Maybe<Scalars['Boolean']>;
  rtp?: Maybe<Scalars['RoundedFloat']>;
  houseEdge?: Maybe<Scalars['RoundedFloat']>;
  volatility?: Maybe<Scalars['String']>;
};

export enum GameStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum GameType {
  Slot = 'SLOT',
  Live = 'LIVE',
  Poker = 'POKER',
  Fish = 'FISH',
  Sportsbook = 'SPORTSBOOK',
  Lottery = 'LOTTERY',
  Table = 'TABLE',
  Scratch = 'SCRATCH',
  Virtual = 'VIRTUAL',
  Casual = 'CASUAL',
  Blackjack = 'BLACKJACK',
  VideoSlots = 'VIDEO_SLOTS',
  VideoPoker = 'VIDEO_POKER',
  Roulette = 'ROULETTE',
  GameShows = 'GAME_SHOWS',
  OtherTableGames = 'OTHER_TABLE_GAMES',
  Baccarat = 'BACCARAT',
  JackpotSlots = 'JACKPOT_SLOTS',
  OtherLiveGames = 'OTHER_LIVE_GAMES',
  ScratchCards = 'SCRATCH_CARDS',
  VirtualSports = 'VIRTUAL_SPORTS',
  Dice = 'DICE',
  Bingo = 'BINGO',
  ArcadeGames = 'ARCADE_GAMES',
  DragonTiger = 'DRAGON_TIGER',
  Hilo = 'HILO',
  CrashGames = 'CRASH_GAMES',
  Crash = 'CRASH',
  LiveDice = 'LIVE_DICE',
  LiveBaccarat = 'LIVE_BACCARAT',
  LiveBlackjack = 'LIVE_BLACKJACK',
  LiveRoulette = 'LIVE_ROULETTE',
  LivePoker = 'LIVE_POKER',
  LiveDragonTiger = 'LIVE_DRAGON_TIGER',
  LiveGameShows = 'LIVE_GAME_SHOWS',
  LiveDealer = 'LIVE_DEALER',
  MiniGames = 'MINI_GAMES',
  Rng = 'RNG',
}

export type GameTypeArrayQueryOperatorInput = {
  overlaps?: Maybe<Array<GameType>>;
  includesAny?: Maybe<Array<GameType>>;
  excludesAll?: Maybe<Array<GameType>>;
};

export type GameTypeQueryOperatorInput = {
  eq?: Maybe<GameType>;
  ne?: Maybe<GameType>;
  in?: Maybe<Array<GameType>>;
  nin?: Maybe<Array<GameType>>;
};

export type GameTypeSettings = {
  __typename?: 'GameTypeSettings';
  disabled: Scalars['Boolean'];
  hidden: Scalars['Boolean'];
};

export type GameTypeSettingsInput = {
  disabled: Scalars['Boolean'];
  hidden: Scalars['Boolean'];
};

export type GamstopAuditTrail = {
  __typename?: 'GamstopAuditTrail';
  account: Account;
  actionType: Scalars['String'];
  dateTimeCreated: Scalars['DateTime'];
};

export type GamstopAuditTrailConnection = {
  __typename?: 'GamstopAuditTrailConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<GamstopAuditTrailConnectionEdge>>;
  pageInfo: PageInfo;
};

export type GamstopAuditTrailConnectionEdge = {
  __typename?: 'GamstopAuditTrailConnectionEdge';
  cursor: Scalars['Binary'];
  node: GamstopAuditTrail;
};

export type GbgAuditTrail = {
  __typename?: 'GBGAuditTrail';
  account: Account;
  actionType: Scalars['String'];
  dateTimeCreated: Scalars['DateTime'];
};

export type GbgAuditTrailConnection = {
  __typename?: 'GBGAuditTrailConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<GbgAuditTrailConnectionEdge>>;
  pageInfo: PageInfo;
};

export type GbgAuditTrailConnectionEdge = {
  __typename?: 'GBGAuditTrailConnectionEdge';
  cursor: Scalars['Binary'];
  node: GbgAuditTrail;
};

export enum GbgTrigger {
  AfterSuccessfulSignUp = 'AFTER_SUCCESSFUL_SIGN_UP',
  Withdrawal = 'WITHDRAWAL',
  Deposit = 'DEPOSIT',
  DepositOrWithdrawal = 'DEPOSIT_OR_WITHDRAWAL',
}

export enum Gender {
  Male = 'MALE',
  Female = 'FEMALE',
}

export type GeoFenceConfig = {
  __typename?: 'GeoFenceConfig';
  whitelistTarget?: Maybe<Array<Maybe<Place>>>;
  whitelistExcluded?: Maybe<Array<Maybe<Place>>>;
  whitelistTargetIPs?: Maybe<Array<Maybe<Scalars['IpAddress']>>>;
  whitelistExcludedIPs?: Maybe<Array<Maybe<Scalars['IpAddress']>>>;
  blacklistTarget?: Maybe<Array<Maybe<Place>>>;
  blacklistExcluded?: Maybe<Array<Maybe<Place>>>;
  blacklistTargetIPs?: Maybe<Array<Maybe<Scalars['IpAddress']>>>;
  blacklistExcludedIPs?: Maybe<Array<Maybe<Scalars['IpAddress']>>>;
  type: GeoFenceConfigType;
  activeRule: GeoFenceRule;
};

export type GeoFenceConfigInput = {
  whitelistTarget?: Maybe<Array<Scalars['ID']>>;
  whitelistExcluded?: Maybe<Array<Scalars['ID']>>;
  whitelistTargetIPs?: Maybe<Array<Scalars['IpAddress']>>;
  whitelistExcludedIPs?: Maybe<Array<Scalars['IpAddress']>>;
  blacklistTarget?: Maybe<Array<Scalars['ID']>>;
  blacklistExcluded?: Maybe<Array<Scalars['ID']>>;
  blacklistTargetIPs?: Maybe<Array<Scalars['IpAddress']>>;
  blacklistExcludedIPs?: Maybe<Array<Scalars['IpAddress']>>;
  type: GeoFenceConfigType;
  activeRule: GeoFenceRule;
};

export enum GeoFenceConfigType {
  Frontend = 'FRONTEND',
  Backend = 'BACKEND',
}

export enum GeoFenceRule {
  Whitelist = 'WHITELIST',
  Blacklist = 'BLACKLIST',
}

export type GeolocationSetting = {
  __typename?: 'GeolocationSetting';
  location: Place;
  redirectUrl: Scalars['String'];
};

export type GeolocationSettingInput = {
  location: Scalars['ID'];
  redirectUrl: Scalars['String'];
};

export type HexoPayCreditCard = {
  __typename?: 'HexoPayCreditCard';
  id: Scalars['ID'];
  brand?: Maybe<Scalars['String']>;
  lastFourDigits?: Maybe<Scalars['String']>;
  firstDigit?: Maybe<Scalars['String']>;
  firstSixDigits?: Maybe<Scalars['String']>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  disableClosedLoop?: Maybe<Scalars['Boolean']>;
  disableCard?: Maybe<Scalars['Boolean']>;
};

export type HexoPayCreditCardDetails = {
  __typename?: 'HexoPayCreditCardDetails';
  brand?: Maybe<Scalars['String']>;
  lastFourDigits?: Maybe<Scalars['String']>;
  firstDigit?: Maybe<Scalars['String']>;
  firstSixDigits?: Maybe<Scalars['String']>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
};

export type HexoPayCreditCardsConnection = {
  __typename?: 'HexoPayCreditCardsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<HexoPayCreditCardsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type HexoPayCreditCardsConnectionEdge = {
  __typename?: 'HexoPayCreditCardsConnectionEdge';
  node: HexoPayCreditCard;
  cursor: Scalars['Binary'];
};

export type HexoPayCreditCardsFilterInput = {
  account?: Maybe<IdQueryOperatorInput>;
};

export type HexoPayDeposit = Deposit & {
  __typename?: 'HexoPayDeposit';
  id: Scalars['ID'];
  amount: Scalars['RoundedFloat'];
  account: Member;
  processingTime?: Maybe<Scalars['Int']>;
  paymentMethod?: Maybe<PaymentMethod>;
  status: DepositStatus;
  processor?: Maybe<Account>;
  serialCode: Scalars['String'];
  firstDeposit?: Maybe<Scalars['Boolean']>;
  remarks?: Maybe<Scalars['String']>;
  transactionReference?: Maybe<Scalars['String']>;
  manualAdjustment: Scalars['Boolean'];
  hexoPayCreditCard?: Maybe<HexoPayCreditCardDetails>;
  hexoPayUid?: Maybe<Scalars['String']>;
  transactionDetails: DepositEventDetails;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeProcessed?: Maybe<Scalars['DateTime']>;
  baseCurrency?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  amountInBaseCurrency?: Maybe<Scalars['RoundedFloat']>;
  isCrypto?: Maybe<Scalars['Boolean']>;
};

export type HexoPayPaymentMethod = PaymentMethod & {
  __typename?: 'HexoPayPaymentMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  currentAccumulation: Scalars['RoundedFloat'];
  memberLoyaltyLevel?: Maybe<Array<MemberLoyaltyLevel>>;
  excludedMemberLevel?: Maybe<Array<MemberLevel>>;
  minimumDepositAmount: Scalars['RoundedFloat'];
  maximumDepositAmount: Scalars['RoundedFloat'];
  instructionText?: Maybe<Scalars['String']>;
  maximumDepositAmountPerDay: Scalars['RoundedFloat'];
  depositExpiry: Scalars['RoundedFloat'];
  turnoverRequirementMultiplier: Scalars['RoundedFloat'];
  suggestedAmounts: Array<Maybe<Scalars['RoundedFloat']>>;
  transactionFee: Scalars['RoundedFloat'];
  transactionFeeType?: Maybe<TransactionFeeType>;
  allowCustomAmount: Scalars['Boolean'];
  updateable: Scalars['Boolean'];
  provider?: Maybe<PaymentMethodProvider>;
};

export type HexoPayWithdrawalMethod = WithdrawalMethod & {
  __typename?: 'HexoPayWithdrawalMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  updateable: Scalars['Boolean'];
  transactionFee: Scalars['RoundedFloat'];
  transactionFeeType: TransactionFeeType;
  provider?: Maybe<WithdrawalMethodProvider>;
};

export type IdArrayQueryOperatorInput = {
  overlaps?: Maybe<Array<Scalars['ID']>>;
  includesAny?: Maybe<Array<Scalars['ID']>>;
  excludesAll?: Maybe<Array<Scalars['ID']>>;
};

export type IdQueryOperator = {
  __typename?: 'IDQueryOperator';
  eq?: Maybe<Scalars['ID']>;
  ne?: Maybe<Scalars['ID']>;
  in?: Maybe<Array<Scalars['ID']>>;
  nin?: Maybe<Array<Scalars['ID']>>;
};

export type IdQueryOperatorInput = {
  eq?: Maybe<Scalars['ID']>;
  ne?: Maybe<Scalars['ID']>;
  in?: Maybe<Array<Scalars['ID']>>;
  nin?: Maybe<Array<Scalars['ID']>>;
};

export type IntQueryOperatorInput = {
  eq?: Maybe<Scalars['Int']>;
  ne?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  nin?: Maybe<Array<Scalars['Int']>>;
};

export type IpAddressLocation = {
  __typename?: 'IpAddressLocation';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type IpAddressLocationCityArgs = {
  language?: Maybe<Scalars['Language']>;
};

export type IpAddressLocationCountryArgs = {
  language?: Maybe<Scalars['Language']>;
};

export type IpAddressLocationStateArgs = {
  language?: Maybe<Scalars['Language']>;
};

export type IpAddressQueryOperatorInput = {
  eq?: Maybe<Scalars['IpAddress']>;
  ne?: Maybe<Scalars['IpAddress']>;
  in?: Maybe<Array<Scalars['IpAddress']>>;
  nin?: Maybe<Array<Scalars['IpAddress']>>;
  startsWith?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
};

export type IpAddressSummaryReportItem = {
  __typename?: 'IpAddressSummaryReportItem';
  id: Scalars['ID'];
  ipAddress: Scalars['IpAddress'];
  ipAddressLocation: IpAddressLocation;
  lastUsedDateTime: Scalars['DateTime'];
  membersCount: Scalars['Int'];
};

export type IpAddressSummaryReportItemsConnection = {
  __typename?: 'IpAddressSummaryReportItemsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<IpAddressSummaryReportItemsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type IpAddressSummaryReportItemsConnectionEdge = {
  __typename?: 'IpAddressSummaryReportItemsConnectionEdge';
  cursor: Scalars['Binary'];
  node: IpAddressSummaryReportItem;
};

export type IpAddressSummaryReportItemsFilterInput = {
  id?: Maybe<IdQueryOperatorInput>;
  ipAddress?: Maybe<IpAddressQueryOperatorInput>;
  membersCount?: Maybe<IntQueryOperatorInput>;
};

export type Job = {
  id: Scalars['ID'];
  type: JobType;
  progress: Scalars['Float'];
  status: JobStatus;
  dateTimeCreated: Scalars['DateTime'];
};

export type JobsConnection = {
  __typename?: 'JobsConnection';
  totalCount: Scalars['Int'];
  edges: Array<JobsConnectionEdge>;
  pageInfo: PageInfo;
};

export type JobsConnectionEdge = {
  __typename?: 'JobsConnectionEdge';
  cursor: Scalars['Binary'];
  node: Job;
};

export type JobsFilterInput = {
  id?: Maybe<IdQueryOperatorInput>;
  type?: Maybe<JobTypeQueryOperatorInput>;
};

export enum JobStatus {
  Pending = 'PENDING',
  InProgress = 'IN_PROGRESS',
  Succeeded = 'SUCCEEDED',
  Failed = 'FAILED',
}

export enum JobType {
  CreateMembers = 'CREATE_MEMBERS',
  CreateManualAdjustments = 'CREATE_MANUAL_ADJUSTMENTS',
}

export type JobTypeQueryOperatorInput = {
  eq?: Maybe<JobType>;
  ne?: Maybe<JobType>;
  in?: Maybe<Array<JobType>>;
  nin?: Maybe<Array<JobType>>;
};

export type LinkedAdmin = {
  __typename?: 'LinkedAdmin';
  id: Scalars['ID'];
  company: Scalars['String'];
};

export type LinkedPlatform = {
  __typename?: 'LinkedPlatform';
  /** @deprecated will not be used anymore */
  platformId?: Maybe<Scalars['String']>;
  brandId?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
};

export type LiveRebateGroup = RebateGroup & {
  __typename?: 'LiveRebateGroup';
  id: Scalars['ID'];
  serialCode: Scalars['String'];
  creator: Account;
  status: RebateGroupStatus;
  enabled: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  members?: Maybe<Array<Member>>;
  assignedMembers?: Maybe<Array<Member>>;
  qualifyingMemberLoyaltyLevels?: Maybe<Array<Maybe<MemberLoyaltyLevel>>>;
  excludedMemberLevels?: Maybe<Array<Maybe<MemberLevel>>>;
  validityDateTimeRange?: Maybe<DateTimeRange>;
  automaticPayout?: Maybe<Scalars['Boolean']>;
  turnoverRequirementMultiplier?: Maybe<Scalars['RoundedFloat']>;
  claimOffsetDuration?: Maybe<Scalars['Duration']>;
  claimExpiryDuration?: Maybe<Scalars['Duration']>;
  payoutExpiryDuration?: Maybe<Scalars['Duration']>;
  minimumPayout?: Maybe<Scalars['RoundedFloat']>;
  maximumPayout?: Maybe<Scalars['RoundedFloat']>;
  type?: Maybe<RebateGroupType>;
  percentages?: Maybe<Array<RebatePercentage>>;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeSubmitted?: Maybe<Scalars['DateTime']>;
  dateTimeUpdated: Scalars['DateTime'];
  qualifiedMembersCount?: Maybe<Scalars['Int']>;
  participatingMembers: MembersConnection;
  qualifyingMembersCount: Scalars['Int'];
  totalPayout: Scalars['RoundedFloat'];
  totalPendingPayout: Scalars['RoundedFloat'];
  payoutClaimantsCount: Scalars['Int'];
  totalClaimedPayout: Scalars['RoundedFloat'];
  totalExpiredPayout: Scalars['RoundedFloat'];
  totalClawback: Scalars['RoundedFloat'];
};

export type LiveRebateGroupParticipatingMembersArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<MembersFilterInput>;
};

export type LoqateAddress = {
  __typename?: 'LoqateAddress';
  id: Scalars['ID'];
  addressText: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type LoqateAddressesFilterInput = {
  countries?: Maybe<Array<Maybe<Scalars['String']>>>;
  searchText: Scalars['String'];
  container?: Maybe<Scalars['String']>;
};

export type LoqateAuditTrail = {
  __typename?: 'LoqateAuditTrail';
  account: Account;
  actionType: LoqateAuditTrailActionType;
  dateTimeCreated: Scalars['DateTime'];
};

export enum LoqateAuditTrailActionType {
  Off = 'OFF',
  On = 'ON',
}

export type LoqateAuditTrailConnection = {
  __typename?: 'LoqateAuditTrailConnection';
  totalCount: Scalars['Int'];
  edges: Array<LoqateAuditTrailConnectionEdge>;
  pageInfo: PageInfo;
};

export type LoqateAuditTrailConnectionEdge = {
  __typename?: 'LoqateAuditTrailConnectionEdge';
  cursor: Scalars['Binary'];
  node: LoqateAuditTrail;
};

export type MaintenanceSchedule = {
  id: Scalars['ID'];
  vendor: Vendor;
  creator: Account;
  gameType: GameType;
  mode: MaintenanceScheduleMode;
  dateTimeCreated: Scalars['DateTime'];
};

export enum MaintenanceScheduleMode {
  OneTime = 'ONE_TIME',
  Recurring = 'RECURRING',
}

export type MaintenanceSchedulesConnection = {
  __typename?: 'MaintenanceSchedulesConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<MaintenanceSchedulesEdge>>;
  pageInfo: PageInfo;
};

export type MaintenanceSchedulesEdge = {
  __typename?: 'MaintenanceSchedulesEdge';
  cursor: Scalars['String'];
  node: MaintenanceSchedule;
};

export type ManualAdjustment = {
  id: Scalars['ID'];
  type: ManualAdjustmentType;
  account: Account;
  amount: Scalars['RoundedFloat'];
  remarks: Scalars['String'];
  dateTimeCreated: Scalars['DateTime'];
};

export type ManualAdjustmentDeposit = Deposit & {
  __typename?: 'ManualAdjustmentDeposit';
  id: Scalars['ID'];
  amount: Scalars['RoundedFloat'];
  account: Member;
  processingTime?: Maybe<Scalars['Int']>;
  paymentMethod?: Maybe<PaymentMethod>;
  status: DepositStatus;
  processor?: Maybe<Account>;
  serialCode: Scalars['String'];
  firstDeposit?: Maybe<Scalars['Boolean']>;
  remarks?: Maybe<Scalars['String']>;
  transactionReference?: Maybe<Scalars['String']>;
  manualAdjustment: Scalars['Boolean'];
  transactionDetails: DepositEventDetails;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeProcessed?: Maybe<Scalars['DateTime']>;
  baseCurrency?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  amountInBaseCurrency?: Maybe<Scalars['RoundedFloat']>;
  isCrypto?: Maybe<Scalars['Boolean']>;
};

export type ManualAdjustmentPaymentMethod = PaymentMethod & {
  __typename?: 'ManualAdjustmentPaymentMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  currentAccumulation: Scalars['RoundedFloat'];
  /** This is always empty */
  memberLoyaltyLevel?: Maybe<Array<MemberLoyaltyLevel>>;
  /** This is always empty */
  excludedMemberLevel?: Maybe<Array<MemberLevel>>;
  /** This field will always be set to -1, should not be displayed or used */
  minimumDepositAmount: Scalars['RoundedFloat'];
  /** This field will always be set to -1, should not be displayed or used */
  maximumDepositAmount: Scalars['RoundedFloat'];
  /** This field will always be null, should not be displayed or used */
  instructionText?: Maybe<Scalars['String']>;
  /** This field will always be set to -1, should not be displayed or used */
  maximumDepositAmountPerDay: Scalars['RoundedFloat'];
  /** This field will always be set to -1, should not be displayed or used */
  depositExpiry: Scalars['RoundedFloat'];
  /** This field will always be set to -1, should not be dispalyed or used */
  turnoverRequirementMultiplier: Scalars['RoundedFloat'];
  /** This will be always empty */
  suggestedAmounts: Array<Maybe<Scalars['RoundedFloat']>>;
  /** This field will always be set to -1, should not be displayed or used */
  transactionFee: Scalars['RoundedFloat'];
  /** This will always be absolute, should not be displayed or used */
  transactionFeeType?: Maybe<TransactionFeeType>;
  /** This will always be false, should not be displayed or used */
  allowCustomAmount: Scalars['Boolean'];
  updateable: Scalars['Boolean'];
  provider?: Maybe<PaymentMethodProvider>;
};

export enum ManualAdjustmentType {
  Deposit = 'DEPOSIT',
  Withdrawal = 'WITHDRAWAL',
  PromoPayout = 'PROMO_PAYOUT',
  ManualWithdrawal = 'MANUAL_WITHDRAWAL',
  Payout = 'PAYOUT',
  /** Depreciated */
  System = 'SYSTEM',
  SystemCredit = 'SYSTEM_CREDIT',
  SystemDebit = 'SYSTEM_DEBIT',
  ExternalProgramme = 'EXTERNAL_PROGRAMME',
  CreditVendorTransfer = 'CREDIT_VENDOR_TRANSFER',
  DebitVendorTransfer = 'DEBIT_VENDOR_TRANSFER',
}

export type ManualAdjustmentWithdrawalMethod = WithdrawalMethod & {
  __typename?: 'ManualAdjustmentWithdrawalMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  updateable: Scalars['Boolean'];
  transactionFee: Scalars['RoundedFloat'];
  transactionFeeType: TransactionFeeType;
  provider?: Maybe<WithdrawalMethodProvider>;
};

export type ManualDeposit = Deposit & {
  __typename?: 'ManualDeposit';
  id: Scalars['ID'];
  amount: Scalars['RoundedFloat'];
  account: Member;
  processingTime?: Maybe<Scalars['Int']>;
  paymentMethod?: Maybe<PaymentMethod>;
  status: DepositStatus;
  processor?: Maybe<Account>;
  serialCode: Scalars['String'];
  firstDeposit?: Maybe<Scalars['Boolean']>;
  remarks?: Maybe<Scalars['String']>;
  transactionReference?: Maybe<Scalars['String']>;
  manualAdjustment: Scalars['Boolean'];
  transactionDetails: DepositEventDetails;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeProcessed?: Maybe<Scalars['DateTime']>;
  baseCurrency?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  amountInBaseCurrency?: Maybe<Scalars['RoundedFloat']>;
  type?: Maybe<Scalars['String']>;
  actual?: Maybe<Scalars['Boolean']>;
  isCrypto?: Maybe<Scalars['Boolean']>;
};

export type ManualDepositMetaInput = {
  promo?: Maybe<Scalars['ID']>;
};

export enum ManualDepositType {
  Payout = 'PAYOUT',
  Deposit = 'DEPOSIT',
  PromoPayout = 'PROMO_PAYOUT',
  MemberLoyaltyPayout = 'MEMBER_LOYALTY_PAYOUT',
  RebatePayout = 'REBATE_PAYOUT',
  AffiliateCommission = 'AFFILIATE_COMMISSION',
  DebitVendorTransfer = 'DEBIT_VENDOR_TRANSFER',
  BalanceAdjustment = 'BALANCE_ADJUSTMENT',
  System = 'SYSTEM',
}

export type ManualWithdrawalManualAdjustment = ManualAdjustment & {
  __typename?: 'ManualWithdrawalManualAdjustment';
  id: Scalars['ID'];
  type: ManualAdjustmentType;
  account: Account;
  amount: Scalars['RoundedFloat'];
  remarks: Scalars['String'];
  dateTimeCreated: Scalars['DateTime'];
  actual: Scalars['Boolean'];
};

export enum ManualWithdrawalType {
  Withdrawal = 'WITHDRAWAL',
  CreditVendorTransfer = 'CREDIT_VENDOR_TRANSFER',
  System = 'SYSTEM',
}

export type MarqueeMessage = {
  __typename?: 'MarqueeMessage';
  id: Scalars['ID'];
  creator: Account;
  title: Scalars['String'];
  message: Scalars['String'];
  snippet: Scalars['String'];
  enabled: Scalars['Boolean'];
  rank: Scalars['Int'];
  dateTimeCreated: Scalars['DateTime'];
  dateTimeUpdated: Scalars['DateTime'];
};

export type MarqueeMessagesConnection = {
  __typename?: 'MarqueeMessagesConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<MarqueeMessagesConnectionEdge>>;
  pageInfo: PageInfo;
};

export type MarqueeMessagesConnectionEdge = {
  __typename?: 'MarqueeMessagesConnectionEdge';
  cursor: Scalars['Binary'];
  node: MarqueeMessage;
};

export type MarqueeMessagesFilterInput = {
  id?: Maybe<IdQueryOperatorInput>;
  creator?: Maybe<IdQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  message?: Maybe<StringQueryOperatorInput>;
  snippet?: Maybe<StringQueryOperatorInput>;
  enabled?: Maybe<BooleanQueryOperatorInput>;
};

export enum MarqueeMessageSpeed {
  Slow = 'SLOW',
  Medium = 'MEDIUM',
  Fast = 'FAST',
}

export type Member = Account & {
  __typename?: 'Member';
  id: Scalars['ID'];
  role: AccountRole;
  username: Scalars['String'];
  currency: Scalars['Currency'];
  language: Scalars['Language'];
  timezone: Scalars['Timezone'];
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  realName?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  address?: Maybe<Address>;
  countryCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  initialEmail?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  preferredLanguage?: Maybe<Scalars['Language']>;
  /** countryCode+mobileNumber */
  prefixedMobileNumber?: Maybe<Scalars['String']>;
  wechatId?: Maybe<Scalars['String']>;
  qqId?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  mobileNumberVerified?: Maybe<Scalars['Boolean']>;
  wechatIdVerified?: Maybe<Scalars['Boolean']>;
  qqIdVerified?: Maybe<Scalars['Boolean']>;
  lastLoginDateTime: Scalars['DateTime'];
  lastLoginIpAddress?: Maybe<Scalars['IpAddress']>;
  lastLoginIpAddressLocation?: Maybe<IpAddressLocation>;
  registrationDateTime: Scalars['DateTime'];
  lastOnlineDateTime: Scalars['DateTime'];
  dateTimeCreated: Scalars['DateTime'];
  dateTimeUpdated: Scalars['DateTime'];
  dateOfBirth?: Maybe<Scalars['Date']>;
  status: MemberStatus;
  online: Scalars['Boolean'];
  totalBalance: Scalars['RoundedFloat'];
  availableBalance: Scalars['RoundedFloat'];
  totalEffectiveBet: Scalars['RoundedFloat'];
  totalTurnover: Scalars['RoundedFloat'];
  totalPromoPayout: Scalars['RoundedFloat'];
  betsCount: Scalars['RoundedFloat'];
  turnover: Scalars['RoundedFloat'];
  effectiveBet: Scalars['RoundedFloat'];
  winloss: Scalars['RoundedFloat'];
  ggr: Scalars['RoundedFloat'];
  profitRatio: Scalars['RoundedFloat'];
  bankAccounts: BankAccountsConnection;
  memberLevel: MemberLevel;
  tags: Array<Maybe<MemberTag>>;
  vendorBalance: Scalars['RoundedFloat'];
  starred: Scalars['Boolean'];
  remarks?: Maybe<Scalars['String']>;
  memberLoyaltyProgramme?: Maybe<MemberLoyaltyProgramme>;
  notes: MemberNotesConnection;
  /** @deprecated To be removed. */
  memberLoyaltyLevel?: Maybe<MemberLoyaltyLevel>;
  memberLoyaltyLevels?: Maybe<Array<MemberLoyaltyLevel>>;
  playedGames: MemberPlayedGamesConnection;
  memberDeposits: MemberDepositsConnection;
  memberLoyaltyPayouts: MemberLoyaltyPayoutsConnection;
  rebatePayouts: RebatePayoutsConnection;
  deposits: DepositsConnection;
  withdrawals: WithdrawalsConnection;
  messages: MessagesConnection;
  totalCredit: Scalars['RoundedFloat'];
  totalDepositAmount: Scalars['RoundedFloat'];
  totalDepositCount: Scalars['RoundedFloat'];
  totalWithdrawalAmount: Scalars['RoundedFloat'];
  totalWithdrawalCount: Scalars['RoundedFloat'];
  totalReadMessagesCount: Scalars['RoundedFloat'];
  affiliate?: Maybe<Affiliate>;
  registrationIpAddress?: Maybe<Scalars['IpAddress']>;
  registrationIpAddressLocation?: Maybe<IpAddressLocation>;
  memberLoyaltyLevelQualificationProgress?: Maybe<
    MemberLoyaltyLevelQualificationProgress
  >;
  memberLoyaltyLevelMaintenanceProgress?: Maybe<
    MemberLoyaltyLevelMaintenanceProgress
  >;
  totalAccumulatedRebatePayout: Scalars['RoundedFloat'];
  totalRebatePayout: Scalars['RoundedFloat'];
  totalPendingRebatePayout: Scalars['RoundedFloat'];
  totalClaimedRebatePayout: Scalars['RoundedFloat'];
  totalExpiredRebatePayout: Scalars['RoundedFloat'];
  totalMemberLoyaltyPayout: Scalars['RoundedFloat'];
  balanceUnits?: Maybe<Array<BalanceUnit>>;
  watchlistRegistrationDateTime?: Maybe<Scalars['DateTime']>;
  otpValidationEnabled: Scalars['Boolean'];
  forceChangePassword?: Maybe<Scalars['Boolean']>;
  forceVerification: Scalars['Boolean'];
  /** @deprecated no longer used */
  forceVerificationURL?: Maybe<Scalars['URL']>;
  /** Default to true for now. */
  verified: Scalars['Boolean'];
  /** Set as nullable for now since the existing members does not have a platformId */
  platformId?: Maybe<Scalars['String']>;
  brandId?: Maybe<Scalars['String']>;
  linkedPlatforms?: Maybe<Array<Maybe<LinkedPlatform>>>;
  communicationTypePreferences?: Maybe<Array<Maybe<CommunicationType>>>;
  phoneNumber?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  timeOutDuration?: Maybe<Scalars['BigInt']>;
  timeOutStartDateTime?: Maybe<Scalars['DateTime']>;
  timeOutEndDateTime?: Maybe<Scalars['DateTime']>;
  realityCheckDuration?: Maybe<Scalars['Int']>;
  selfExclusionDuration?: Maybe<Scalars['BigInt']>;
  selfExclusionStartDateTime?: Maybe<Scalars['DateTime']>;
  selfExclusionEndDateTime?: Maybe<Scalars['DateTime']>;
  selfExclusionSetBy?: Maybe<Scalars['String']>;
  depositLimitFrequency?: Maybe<MemberDepositLimitFrequency>;
  depositLimit?: Maybe<Scalars['Float']>;
  depositLimits?: Maybe<Array<Maybe<DepositLimits>>>;
  acceptedFrequentlyAskedQuestions?: Maybe<DynamicPage>;
  acceptedPrivacyPolicy?: Maybe<DynamicPage>;
  acceptedTermsAndConditions?: Maybe<DynamicPage>;
  migrationDetails?: Maybe<Scalars['JSON']>;
  depositBlocked?: Maybe<Scalars['Boolean']>;
  withdrawalBlocked?: Maybe<Scalars['Boolean']>;
  wagerBlocked?: Maybe<Scalars['Boolean']>;
  promotionBlocked?: Maybe<Scalars['Boolean']>;
  session?: Maybe<Session>;
  playerFileLink?: Maybe<Scalars['String']>;
  btags?: Maybe<Array<Maybe<Scalars['String']>>>;
  btagsNext?: Maybe<BtagsNext>;
  totalPromoUsedAmount: Scalars['RoundedFloat'];
  totalPromoUsedCount: Scalars['RoundedFloat'];
  totalPromoAmount: Scalars['RoundedFloat'];
  ngr: Scalars['RoundedFloat'];
  skrillDisabled?: Maybe<Scalars['Boolean']>;
  netellerDisabled?: Maybe<Scalars['Boolean']>;
  coinifyDisabled?: Maybe<Scalars['Boolean']>;
  skrillClosedLoopDisabled?: Maybe<Scalars['Boolean']>;
  netellerClosedLoopDisabled?: Maybe<Scalars['Boolean']>;
  coinifyClosedLoopDisabled?: Maybe<Scalars['Boolean']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  company?: Maybe<Scalars['String']>;
  favoriteCurrencyDetails?: Maybe<Array<Maybe<CurrencyMetadata>>>;
  affiliateId?: Maybe<Scalars['String']>;
  activeCurrency?: Maybe<Scalars['Currency']>;
  netDeposits: MemberNetDeposits;
  depositLimitLock?: Maybe<Scalars['Boolean']>;
  depositLimitLockValue?: Maybe<Scalars['Float']>;
};

export type MemberBetsCountArgs = {
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
};

export type MemberTurnoverArgs = {
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
};

export type MemberEffectiveBetArgs = {
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
};

export type MemberWinlossArgs = {
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
};

export type MemberGgrArgs = {
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
};

export type MemberProfitRatioArgs = {
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
};

export type MemberBankAccountsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
};

export type MemberVendorBalanceArgs = {
  vendor?: Maybe<Scalars['ID']>;
};

export type MemberNotesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<MemberNotesFilterInput>;
};

export type MemberPlayedGamesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
};

export type MemberMemberDepositsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
};

export type MemberMemberLoyaltyPayoutsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
};

export type MemberRebatePayoutsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
};

export type MemberDepositsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
};

export type MemberWithdrawalsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
};

export type MemberMessagesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
};

export type MemberMemberLoyaltyLevelQualificationProgressArgs = {
  memberLoyaltyProgramme?: Maybe<Scalars['ID']>;
};

export type MemberMemberLoyaltyLevelMaintenanceProgressArgs = {
  memberLoyaltyProgramme?: Maybe<Scalars['ID']>;
};

export type MemberAccessHistoryReportItem = {
  __typename?: 'MemberAccessHistoryReportItem';
  id: Scalars['ID'];
  member: Member;
  ipAddress: Scalars['IpAddress'];
  ipAddressLocation: IpAddressLocation;
  memberLoyaltyLevel?: Maybe<MemberLoyaltyLevel>;
  memberLevel?: Maybe<MemberLevel>;
  session?: Maybe<Session>;
  registration: Scalars['Boolean'];
  dateTimeCreated: Scalars['DateTime'];
  platformId?: Maybe<Scalars['String']>;
};

export type MemberAccessHistoryReportItemsConnection = {
  __typename?: 'MemberAccessHistoryReportItemsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<MemberAccessHistoryReportItemsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type MemberAccessHistoryReportItemsConnectionEdge = {
  __typename?: 'MemberAccessHistoryReportItemsConnectionEdge';
  cursor: Scalars['Binary'];
  node: MemberAccessHistoryReportItem;
};

export type MemberAccessHistoryReportItemsFilterInput = {
  id?: Maybe<IdQueryOperatorInput>;
  member?: Maybe<IdQueryOperatorInput>;
  ipAddress?: Maybe<IpAddressQueryOperatorInput>;
  memberLoyaltyLevel?: Maybe<IdQueryOperatorInput>;
  memberLevel?: Maybe<IdQueryOperatorInput>;
  registration?: Maybe<BooleanQueryOperatorInput>;
  dateTimeCreated?: Maybe<DateTimeQueryOperatorInput>;
  brand?: Maybe<StringQueryOperatorInput>;
};

export type MemberAccessSummaryReportItem = {
  __typename?: 'MemberAccessSummaryReportItem';
  id: Scalars['ID'];
  member: Member;
  lastLoginDateTime: Scalars['DateTime'];
  ipAddressesCount: Scalars['Int'];
};

export type MemberAccessSummaryReportItemsConnection = {
  __typename?: 'MemberAccessSummaryReportItemsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<MemberAccessSummaryReportItemsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type MemberAccessSummaryReportItemsConnectionEdge = {
  __typename?: 'MemberAccessSummaryReportItemsConnectionEdge';
  cursor: Scalars['Binary'];
  node: MemberAccessSummaryReportItem;
};

export type MemberAccessSummaryReportItemsFilterInput = {
  member?: Maybe<IdQueryOperatorInput>;
  ipAddressesCount?: Maybe<IntQueryOperatorInput>;
  brand?: Maybe<StringQueryOperatorInput>;
};

export type MemberAffiliate = {
  __typename?: 'MemberAffiliate';
  id: Scalars['ID'];
  account: Member;
  code: Scalars['String'];
  upline?: Maybe<MemberAffiliate>;
  totalUnclaimedCommission: Scalars['Float'];
  totalClaimedCommission: Scalars['Float'];
  membersCount: Scalars['Int'];
};

export type MemberAffiliateCommission = {
  __typename?: 'MemberAffiliateCommission';
  id: Scalars['ID'];
  serialCode: Scalars['String'];
  settlement: MemberAffiliateSettlement;
  memberAffiliate: MemberAffiliate;
  level: MemberAffiliateLevel;
  amount: Scalars['Float'];
  membersCount: Scalars['Int'];
  personalSales: Scalars['Float'];
  cumulativeSales: Scalars['Float'];
  status: MemberAffiliateCommissionStatus;
  dateTimeCreated: Scalars['DateTime'];
};

export type MemberAffiliateCommissionsConnection = {
  __typename?: 'MemberAffiliateCommissionsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<MemberAffiliateCommissionsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type MemberAffiliateCommissionsConnectionEdge = {
  __typename?: 'MemberAffiliateCommissionsConnectionEdge';
  cursor: Scalars['String'];
  node: MemberAffiliateCommission;
};

export type MemberAffiliateCommissionSettings = {
  __typename?: 'MemberAffiliateCommissionSettings';
  type: MemberAffiliateCommissionType;
  value: Scalars['Float'];
};

export type MemberAffiliateCommissionSettingsInput = {
  type: MemberAffiliateCommissionType;
  value: Scalars['Float'];
};

export type MemberAffiliateCommissionsFilterInput = {
  memberAffiliate?: Maybe<IdQueryOperatorInput>;
  dateTimeCreated?: Maybe<DateTimeQueryOperatorInput>;
  settlement?: Maybe<IdQueryOperatorInput>;
  status?: Maybe<MemberAffiliateCommissionStatus>;
};

export type MemberAffiliateCommissionsSettlementFilterInput = {
  model?: Maybe<Scalars['ID']>;
  period?: Maybe<MemberAffiliateCommissionsSettlementPeriodFilterInput>;
};

export type MemberAffiliateCommissionsSettlementPeriodFilterInput = {
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
};

export enum MemberAffiliateCommissionStatus {
  Claimed = 'CLAIMED',
  Unclaimed = 'UNCLAIMED',
}

export enum MemberAffiliateCommissionType {
  Percentage = 'PERCENTAGE',
  Absolute = 'ABSOLUTE',
}

export type MemberAffiliateLevel = {
  __typename?: 'MemberAffiliateLevel';
  name: Scalars['String'];
  minimumSales: Scalars['Float'];
  commission: MemberAffiliateCommissionSettings;
};

export type MemberAffiliateLevelInput = {
  name: Scalars['String'];
  minimumSales: Scalars['Float'];
  commission: MemberAffiliateCommissionSettingsInput;
};

export type MemberAffiliateModel = {
  __typename?: 'MemberAffiliateModel';
  id: Scalars['ID'];
  name: Scalars['String'];
  levels: Array<MemberAffiliateLevel>;
  maximumCommission: Scalars['Float'];
  salesMetric: MemberAffiliateSalesMetric;
  settlementPeriod: MemberAffiliateSettlementPeriodSettings;
  automaticSettlement: Scalars['Boolean'];
  default: Scalars['Boolean'];
  enabled: Scalars['Boolean'];
};

export type MemberAffiliateSalesMetric = {
  __typename?: 'MemberAffiliateSalesMetric';
  type: MemberAffiliateSalesMetricType;
  gameTypes?: Maybe<Array<GameType>>;
};

export type MemberAffiliateSalesMetricInput = {
  type: MemberAffiliateSalesMetricType;
  gameTypes?: Maybe<Array<GameType>>;
};

export enum MemberAffiliateSalesMetricType {
  Turnover = 'TURNOVER',
  EffectiveBet = 'EFFECTIVE_BET',
  TheoreticalWin = 'THEORETICAL_WIN',
  DepositAmount = 'DEPOSIT_AMOUNT',
}

export type MemberAffiliatesConnection = {
  __typename?: 'MemberAffiliatesConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<MemberAffiliatesConnectionEdge>>;
  pageInfo: PageInfo;
};

export type MemberAffiliatesConnectionEdge = {
  __typename?: 'MemberAffiliatesConnectionEdge';
  cursor: Scalars['String'];
  node: MemberAffiliate;
};

export type MemberAffiliateSettlement = {
  __typename?: 'MemberAffiliateSettlement';
  id: Scalars['ID'];
  model: MemberAffiliateModel;
  period: MemberAffiliateSettlementPeriod;
  totalCommission?: Maybe<Scalars['Float']>;
  totalUnclaimedCommission?: Maybe<Scalars['Float']>;
  totalClaimedCommission?: Maybe<Scalars['Float']>;
  status: MemberAffiliateSettlementStatus;
};

export type MemberAffiliateSettlementPeriod = {
  __typename?: 'MemberAffiliateSettlementPeriod';
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
};

export type MemberAffiliateSettlementPeriodSettings = {
  __typename?: 'MemberAffiliateSettlementPeriodSettings';
  type: MemberAffiliateSettlementPeriodType;
  time?: Maybe<Scalars['Time']>;
  dayOfWeek?: Maybe<Scalars['DayOfWeek']>;
  dayOfMonth?: Maybe<Scalars['DayOfMonth']>;
  month?: Maybe<Scalars['Month']>;
};

export type MemberAffiliateSettlementPeriodSettingsInput = {
  type: MemberAffiliateSettlementPeriodType;
  time?: Maybe<Scalars['Time']>;
  dayOfWeek?: Maybe<Scalars['DayOfWeek']>;
  dayOfMonth?: Maybe<Scalars['DayOfMonth']>;
  month?: Maybe<Scalars['Month']>;
};

export enum MemberAffiliateSettlementPeriodType {
  Hourly = 'HOURLY',
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY',
}

export type MemberAffiliateSettlementsConnection = {
  __typename?: 'MemberAffiliateSettlementsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<MemberAffiliateSettlementsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type MemberAffiliateSettlementsConnectionEdge = {
  __typename?: 'MemberAffiliateSettlementsConnectionEdge';
  cursor: Scalars['String'];
  node: MemberAffiliateSettlement;
};

export type MemberAffiliateSettlementsFilterInput = {
  model?: Maybe<Scalars['ID']>;
  status?: Maybe<MemberAffiliateSettlementStatus>;
  period?: Maybe<MemberAffiliateSettlementsPeriodFilterInput>;
};

export type MemberAffiliateSettlementsPeriodFilterInput = {
  startDateTime?: Maybe<DateTimeQueryOperatorInput>;
  endDateTime?: Maybe<DateTimeQueryOperatorInput>;
};

export enum MemberAffiliateSettlementStatus {
  Pending = 'PENDING',
  InSettlement = 'IN_SETTLEMENT',
  Settled = 'SETTLED',
}

export type MemberAffiliatesFilterInput = {
  account?: Maybe<IdQueryOperatorInput>;
  upline?: Maybe<IdQueryOperatorInput>;
};

export type MemberAttributesConfigurationField = {
  __typename?: 'MemberAttributesConfigurationField';
  label: Scalars['String'];
  name: Scalars['String'];
  required: Scalars['Boolean'];
  hidden: Scalars['Boolean'];
};

export type MemberAttributesConfigurationFieldInput = {
  label: Scalars['String'];
  name: Scalars['String'];
  required: Scalars['Boolean'];
  hidden: Scalars['Boolean'];
};

export type MemberBalanceLog = {
  __typename?: 'MemberBalanceLog';
  id: Scalars['ID'];
  account: Member;
  delta: Scalars['Float'];
  source: MemberBalanceLogSource;
  data: Scalars['JSON'];
  dateTimeCreated: Scalars['DateTime'];
};

export type MemberBalanceLogsConnection = {
  __typename?: 'MemberBalanceLogsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<MemberBalanceLogsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type MemberBalanceLogsConnectionEdge = {
  __typename?: 'MemberBalanceLogsConnectionEdge';
  cursor: Scalars['String'];
  node: MemberBalanceLog;
};

export type MemberBalanceLogsFilterInput = {
  account?: Maybe<Scalars['ID']>;
};

export enum MemberBalanceLogSource {
  WithdrawalFee = 'WITHDRAWAL_FEE',
  Withdrawal = 'WITHDRAWAL',
  Deposit = 'DEPOSIT',
  BalanceTransferDebit = 'BALANCE_TRANSFER_DEBIT',
  BalanceTransferCredit = 'BALANCE_TRANSFER_CREDIT',
  PlaceBet = 'PLACE_BET',
  SettleBet = 'SETTLE_BET',
  CancelBet = 'CANCEL_BET',
  BalanceAdjustment = 'BALANCE_ADJUSTMENT',
  Rebate = 'REBATE',
  MemberAffiliateCommission = 'MEMBER_AFFILIATE_COMMISSION',
  Rollback = 'ROLLBACK',
}

export type MemberBalanceUnitRecord = {
  __typename?: 'MemberBalanceUnitRecord';
  id: Scalars['ID'];
  serialCode: Scalars['String'];
  member: Member;
  transactionType: MemberBalanceUnitRecordTransactionType;
  remarks?: Maybe<Scalars['String']>;
  originalAmount: Scalars['RoundedFloat'];
  currentAmount: Scalars['RoundedFloat'];
  turnoverRequirement: Scalars['RoundedFloat'];
  turnoverRequirementBalance: Scalars['RoundedFloat'];
  turnoverRequirementMultiplier: Scalars['RoundedFloat'];
  withdrawals: Array<Scalars['String']>;
  betCount: Scalars['Int'];
  bets?: Maybe<Array<Scalars['ID']>>;
  transactionSerialCode: Scalars['String'];
  dateTimeCreated: Scalars['DateTime'];
  dateTimeTurnoverCompleted?: Maybe<Scalars['DateTime']>;
  manualAdjustment?: Maybe<ManualAdjustment>;
  currency?: Maybe<Scalars['String']>;
  baseCurrency?: Maybe<Scalars['String']>;
  baseAmount?: Maybe<Scalars['Float']>;
  cryptoExchangeRate?: Maybe<Scalars['Float']>;
  exchangeRate?: Maybe<Scalars['Float']>;
};

export type MemberBalanceUnitRecordRemarksArgs = {
  language?: Maybe<Scalars['Language']>;
};

export type MemberBalanceUnitRecordFilterInput = {
  member?: Maybe<Scalars['ID']>;
  transactionSerialCode?: Maybe<StringQueryOperatorInput>;
  serialCode?: Maybe<StringQueryOperatorInput>;
  transactionType?: Maybe<
    MemberBalanceUnitRecordTransactionTypeQueryOperatorInput
  >;
  originalAmount?: Maybe<FloatQueryOperatorInput>;
  currentAmount?: Maybe<FloatQueryOperatorInput>;
  turnoverRequirementMultiplier?: Maybe<FloatQueryOperatorInput>;
  turnoverRequirement?: Maybe<FloatQueryOperatorInput>;
  turnoverRequirementBalance?: Maybe<FloatQueryOperatorInput>;
  dateTimeCreated?: Maybe<DateTimeQueryOperatorInput>;
  dateTimeTurnoverCompleted?: Maybe<DateTimeQueryOperatorInput>;
  betCount?: Maybe<FloatQueryOperatorInput>;
  withdrawals?: Maybe<StringArrayQueryOperatorInput>;
  currency?: Maybe<CurrencyQueryOperatorInput>;
};

export type MemberBalanceUnitRecordsConnection = {
  __typename?: 'MemberBalanceUnitRecordsConnection';
  totalCount: Scalars['Int'];
  edges: Array<MemberBalanceUnitRecordsEdge>;
  pageInfo: PageInfo;
};

export type MemberBalanceUnitRecordsEdge = {
  __typename?: 'MemberBalanceUnitRecordsEdge';
  cursor: Scalars['Binary'];
  node: MemberBalanceUnitRecord;
};

export enum MemberBalanceUnitRecordTransactionType {
  Deposit = 'DEPOSIT',
  AffiliateCommission = 'AFFILIATE_COMMISSION',
  RebatePayout = 'REBATE_PAYOUT',
  PromoPayout = 'PROMO_PAYOUT',
  MemberLoyaltyPayout = 'MEMBER_LOYALTY_PAYOUT',
  RefundBet = 'REFUND_BET',
  DebitVendorTransfer = 'DEBIT_VENDOR_TRANSFER',
  SystemCredit = 'SYSTEM_CREDIT',
  ExternalProgramme = 'EXTERNAL_PROGRAMME',
  Payout = 'PAYOUT',
  PromoDepositBonus = 'PROMO_DEPOSIT_BONUS',
  PromoBonusWon = 'PROMO_BONUS_WON',
  PromoFundsTransfer = 'PROMO_FUNDS_TRANSFER',
  PromoWon = 'PROMO_WON',
  PromoBonusLost = 'PROMO_BONUS_LOST',
  PromoLost = 'PROMO_LOST',
  PromoCanceled = 'PROMO_CANCELED',
  PromoExpired = 'PROMO_EXPIRED',
  PromoBonusCanceled = 'PROMO_BONUS_CANCELED',
  PromoBonusExpired = 'PROMO_BONUS_EXPIRED',
}

export type MemberBalanceUnitRecordTransactionTypeQueryOperatorInput = {
  eq?: Maybe<MemberBalanceUnitRecordTransactionType>;
  ne?: Maybe<MemberBalanceUnitRecordTransactionType>;
  in?: Maybe<Array<MemberBalanceUnitRecordTransactionType>>;
  nin?: Maybe<Array<MemberBalanceUnitRecordTransactionType>>;
};

export type MemberBetRecord = {
  __typename?: 'MemberBetRecord';
  id: Scalars['ID'];
  serialCode: Scalars['ID'];
  member: Member;
  affiliate?: Maybe<Scalars['String']>;
  vendor: Vendor;
  gameType: GameType;
  game: Game;
  transactions?: Maybe<Array<BalanceTransactionRecord>>;
  turnover: Scalars['RoundedFloat'];
  effectiveBet?: Maybe<Scalars['RoundedFloat']>;
  winloss?: Maybe<Scalars['RoundedFloat']>;
  ggr?: Maybe<Scalars['RoundedFloat']>;
  payout?: Maybe<Scalars['RoundedFloat']>;
  round?: Maybe<Scalars['String']>;
  session?: Maybe<Scalars['String']>;
  status: MemberBetRecordStatus;
  details: MemberBetRecordDetails;
  dateTimePlaced: Scalars['DateTime'];
  dateTimeSettled?: Maybe<Scalars['DateTime']>;
  /** @deprecated To be removed. Please use dateTimeCanceled. */
  dateTimeCancelled?: Maybe<Scalars['DateTime']>;
  dateTimeCanceled?: Maybe<Scalars['DateTime']>;
  tips?: Maybe<Scalars['RoundedFloat']>;
  jackpotContributionBet?: Maybe<Scalars['RoundedFloat']>;
  jackpotPool?: Maybe<Scalars['RoundedFloat']>;
  jackpotWin?: Maybe<Scalars['RoundedFloat']>;
  cashoutWinloss?: Maybe<Scalars['RoundedFloat']>;
  freeSpin?: Maybe<Scalars['Boolean']>;
  device?: Maybe<Scalars['String']>;
  balanceUnitSerialCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  cashTurnover?: Maybe<Scalars['RoundedFloat']>;
  bonusTurnover?: Maybe<Scalars['RoundedFloat']>;
  baseTurnover?: Maybe<Scalars['RoundedFloat']>;
  baseEffectiveBet?: Maybe<Scalars['RoundedFloat']>;
  baseWinloss?: Maybe<Scalars['RoundedFloat']>;
  baseCashTurnover?: Maybe<Scalars['RoundedFloat']>;
  baseBonusTurnover?: Maybe<Scalars['RoundedFloat']>;
  baseGgr?: Maybe<Scalars['RoundedFloat']>;
  basePayout?: Maybe<Scalars['RoundedFloat']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  memberCurrency?: Maybe<Scalars['String']>;
  cryptoCurrency?: Maybe<Scalars['String']>;
  cryptoPayout?: Maybe<Scalars['RoundedCryptoFloat']>;
  cryptoWinloss?: Maybe<Scalars['RoundedCryptoFloat']>;
  cryptoEffectiveBet?: Maybe<Scalars['RoundedCryptoFloat']>;
  cryptoTurnover?: Maybe<Scalars['RoundedCryptoFloat']>;
  cryptoCashTurnover?: Maybe<Scalars['RoundedCryptoFloat']>;
  cryptoBonusTurnover?: Maybe<Scalars['RoundedCryptoFloat']>;
  cryptoGgr?: Maybe<Scalars['RoundedCryptoFloat']>;
  cryptoExchangeRate?: Maybe<Scalars['Float']>;
};

export type MemberBetRecordDetails = {
  __typename?: 'MemberBetRecordDetails';
  data?: Maybe<Scalars['JSON']>;
  url?: Maybe<Scalars['URL']>;
};

export type MemberBetRecordReport = {
  __typename?: 'MemberBetRecordReport';
  creator: Account;
  report?: Maybe<File>;
  status: MemberBetRecordReportStatus;
  totalRows?: Maybe<Scalars['Int']>;
  processedRows?: Maybe<Scalars['Int']>;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeGenerated?: Maybe<Scalars['DateTime']>;
};

export enum MemberBetRecordReportStatus {
  Generating = 'GENERATING',
  Generated = 'GENERATED',
  Failed = 'FAILED',
}

export type MemberBetRecordsConnection = {
  __typename?: 'MemberBetRecordsConnection';
  totalCount: Scalars['Int'];
  totalBetAmount: Scalars['RoundedFloat'];
  totalEffectiveBet: Scalars['RoundedFloat'];
  totalWinloss: Scalars['RoundedFloat'];
  totalPayout?: Maybe<Scalars['RoundedFloat']>;
  totalGgr?: Maybe<Scalars['RoundedFloat']>;
  totalBetsCount: Scalars['Int'];
  totalSettledBetsCount: Scalars['Int'];
  edges: Array<Maybe<MemberBetRecordsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type MemberBetRecordsConnectionEdge = {
  __typename?: 'MemberBetRecordsConnectionEdge';
  cursor: Scalars['Binary'];
  node: MemberBetRecord;
};

export type MemberBetRecordsFilterInput = {
  id?: Maybe<IdQueryOperatorInput>;
  admin?: Maybe<IdQueryOperatorInput>;
  serialCode?: Maybe<StringQueryOperatorInput>;
  affiliate?: Maybe<IdQueryOperatorInput>;
  member?: Maybe<IdQueryOperatorInput>;
  vendor?: Maybe<IdQueryOperatorInput>;
  game?: Maybe<IdQueryOperatorInput>;
  gameType?: Maybe<GameTypeQueryOperatorInput>;
  effectiveBet?: Maybe<FloatQueryOperatorInput>;
  winloss?: Maybe<FloatQueryOperatorInput>;
  dateTimePlaced?: Maybe<DateTimeQueryOperatorInput>;
  dateTimeSettled?: Maybe<DateTimeQueryOperatorInput>;
  dateTimeCanceled?: Maybe<DateTimeQueryOperatorInput>;
  status?: Maybe<MemberBetRecordStatusQueryOperatorInput>;
  balanceUnitSerialCodes?: Maybe<StringArrayQueryOperatorInput>;
  round?: Maybe<StringQueryOperatorInput>;
  session?: Maybe<StringQueryOperatorInput>;
  tips?: Maybe<FloatQueryOperatorInput>;
  jackpotContributionBet?: Maybe<FloatQueryOperatorInput>;
  jackpotWin?: Maybe<FloatQueryOperatorInput>;
  cashoutWinloss?: Maybe<FloatQueryOperatorInput>;
  freeSpin?: Maybe<BooleanQueryOperatorInput>;
  device?: Maybe<StringQueryOperatorInput>;
  turnover?: Maybe<FloatQueryOperatorInput>;
  platformId?: Maybe<StringQueryOperatorInput>;
  brandId?: Maybe<StringQueryOperatorInput>;
  payout?: Maybe<FloatQueryOperatorInput>;
  gameTitle?: Maybe<StringLanguageQueryOperatorInput>;
  gameCategory?: Maybe<StringQueryOperatorInput>;
  gameSubCategory?: Maybe<StringQueryOperatorInput>;
  brand?: Maybe<StringQueryOperatorInput>;
  memberCurrency?: Maybe<CurrencyQueryOperatorInput>;
};

export enum MemberBetRecordStatus {
  Unsettled = 'UNSETTLED',
  Settled = 'SETTLED',
  Canceled = 'CANCELED',
  Win = 'WIN',
  Loss = 'LOSS',
  Draw = 'DRAW',
  Cashout = 'CASHOUT',
  Refunded = 'REFUNDED',
}

export type MemberBetRecordStatusQueryOperatorInput = {
  eq?: Maybe<MemberBetRecordStatus>;
  ne?: Maybe<MemberBetRecordStatus>;
  in?: Maybe<Array<Maybe<MemberBetRecordStatus>>>;
  nin?: Maybe<Array<Maybe<MemberBetRecordStatus>>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type MemberBetRecordSummary = {
  __typename?: 'MemberBetRecordSummary';
  totalBetAmount: Scalars['RoundedFloat'];
  totalEffectiveBet: Scalars['RoundedFloat'];
  totalWinloss: Scalars['RoundedFloat'];
  totalPayout?: Maybe<Scalars['RoundedFloat']>;
  totalGgr?: Maybe<Scalars['RoundedFloat']>;
  totalBetsCount: Scalars['Int'];
  totalSettledBetsCount: Scalars['Int'];
  totalWinnings: Scalars['RoundedFloat'];
  totalBaseBetAmount: Scalars['RoundedFloat'];
  totalBaseEffectiveBet: Scalars['RoundedFloat'];
  totalBaseWinloss: Scalars['RoundedFloat'];
  totalBaseWinnings: Scalars['RoundedFloat'];
  totalBasePayout: Scalars['RoundedFloat'];
  totalBaseGgr: Scalars['RoundedFloat'];
  totalBaseCashTurnover: Scalars['RoundedFloat'];
  totalBaseBonusTurnover: Scalars['RoundedFloat'];
};

export type MemberBulkNoteCreateRecord = {
  __typename?: 'MemberBulkNoteCreateRecord';
  initiator: Account;
  result?: Maybe<Scalars['String']>;
  status: MemberBulkNoteCreateRecordStatus;
  totalRows?: Maybe<Scalars['Int']>;
  processedRows?: Maybe<Scalars['Int']>;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeDone?: Maybe<Scalars['DateTime']>;
};

export enum MemberBulkNoteCreateRecordStatus {
  Done = 'DONE',
  InProgress = 'IN_PROGRESS',
  Failed = 'FAILED',
}

export type MemberBulkStatusUpdateRecord = {
  __typename?: 'MemberBulkStatusUpdateRecord';
  initiator: Account;
  result?: Maybe<Scalars['String']>;
  status: MemberBulkStatusUpdateRecordStatus;
  totalRows?: Maybe<Scalars['Int']>;
  processedRows?: Maybe<Scalars['Int']>;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeDone?: Maybe<Scalars['DateTime']>;
};

export enum MemberBulkStatusUpdateRecordStatus {
  Done = 'DONE',
  InProgress = 'IN_PROGRESS',
  Failed = 'FAILED',
}

export type MemberBulkUpdateJobRecord = {
  __typename?: 'MemberBulkUpdateJobRecord';
  initiator: Account;
  status: MemberBulkUpdateJobStatus;
  totalRows?: Maybe<Scalars['Int']>;
  processedRows?: Maybe<Scalars['Int']>;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeDone?: Maybe<Scalars['DateTime']>;
  result?: Maybe<File>;
  error?: Maybe<MemberBulkUpdateJobRecordJobError>;
};

export type MemberBulkUpdateJobRecordJobError = {
  __typename?: 'MemberBulkUpdateJobRecordJobError';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  labels?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum MemberBulkUpdateJobStatus {
  Done = 'DONE',
  InProgress = 'IN_PROGRESS',
  Failed = 'FAILED',
}

export type MemberDeposit = {
  __typename?: 'MemberDeposit';
  deposit: Deposit;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeUpdated: Scalars['DateTime'];
};

export enum MemberDepositLimitFrequency {
  Hourly = 'HOURLY',
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY',
  Quarterly = 'QUARTERLY',
}

export type MemberDepositsConnection = {
  __typename?: 'MemberDepositsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<MemberDepositsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type MemberDepositsConnectionEdge = {
  __typename?: 'MemberDepositsConnectionEdge';
  cursor: Scalars['Binary'];
  node: MemberDeposit;
};

export type MemberDocument = {
  __typename?: 'MemberDocument';
  id: Scalars['ID'];
  member: Member;
  type: MemberDocumentType;
  document: File;
  status: MemberDocumentStatus;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeUpdated: Scalars['DateTime'];
};

export type MemberDocumentInput = {
  type: MemberDocumentType;
  document: Scalars['Upload'];
};

export type MemberDocumentsConnection = {
  __typename?: 'MemberDocumentsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<MemberDocumentsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type MemberDocumentsConnectionEdge = {
  __typename?: 'MemberDocumentsConnectionEdge';
  cursor: Scalars['Binary'];
  node: MemberDocument;
};

export type MemberDocumentsFilterInput = {
  id?: Maybe<IdQueryOperatorInput>;
  type?: Maybe<MemberDocumentTypeQueryOperatorInput>;
  status?: Maybe<MemberDocumentStatusQueryOperatorInput>;
  member?: Maybe<IdQueryOperatorInput>;
};

export enum MemberDocumentStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
}

export type MemberDocumentStatusQueryOperatorInput = {
  eq?: Maybe<MemberDocumentStatus>;
  ne?: Maybe<MemberDocumentStatus>;
  in?: Maybe<Array<MemberDocumentStatus>>;
  nin?: Maybe<Array<MemberDocumentStatus>>;
};

export enum MemberDocumentType {
  Passport = 'PASSPORT',
  IdDocumentFront = 'ID_DOCUMENT_FRONT',
  IdDocumentBack = 'ID_DOCUMENT_BACK',
  DrivingLicenseFront = 'DRIVING_LICENSE_FRONT',
  DrivingLicenseBack = 'DRIVING_LICENSE_BACK',
  ProofOfAddressDocument = 'PROOF_OF_ADDRESS_DOCUMENT',
  BirthCertificate = 'BIRTH_CERTIFICATE',
  BankStatement = 'BANK_STATEMENT',
  SelfCertified = 'SELF_CERTIFIED',
  CreditDebitCardFront = 'CREDIT_DEBIT_CARD_FRONT',
  CreditDebitCardBack = 'CREDIT_DEBIT_CARD_BACK',
  NotarizedId = 'NOTARIZED_ID',
  Other = 'OTHER',
}

export type MemberDocumentTypeQueryOperatorInput = {
  eq?: Maybe<MemberDocumentType>;
  ne?: Maybe<MemberDocumentType>;
  in?: Maybe<Array<MemberDocumentType>>;
  nin?: Maybe<Array<MemberDocumentType>>;
};

export type MemberExportRecord = {
  __typename?: 'MemberExportRecord';
  creator: Account;
  report?: Maybe<File>;
  status: MemberExportRecordStatus;
  totalRows?: Maybe<Scalars['Int']>;
  processedRows?: Maybe<Scalars['Int']>;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeGenerated?: Maybe<Scalars['DateTime']>;
};

export enum MemberExportRecordStatus {
  Generating = 'GENERATING',
  Generated = 'GENERATED',
  Failed = 'FAILED',
}

export enum MemberField {
  Username = 'USERNAME',
  RealName = 'REAL_NAME',
  Gender = 'GENDER',
  DateOfBirth = 'DATE_OF_BIRTH',
  Email = 'EMAIL',
  MobileNumber = 'MOBILE_NUMBER',
  WechatId = 'WECHAT_ID',
  QqId = 'QQ_ID',
  Address = 'ADDRESS',
  PhoneNumber = 'PHONE_NUMBER',
  Title = 'TITLE',
}

export type MemberForceVerificationSettings = {
  __typename?: 'MemberForceVerificationSettings';
  realName: Scalars['Boolean'];
  gender: Scalars['Boolean'];
  dateOfBirth: Scalars['Boolean'];
  bankAccountName: Scalars['Boolean'];
  bankAccountNumber: Scalars['Boolean'];
  bankAccountNumberLastDigitsCount: Scalars['Int'];
  mobileNumber: Scalars['Boolean'];
  email: Scalars['Boolean'];
};

export type MemberForceVerificationSettingsInput = {
  realName?: Maybe<Scalars['Boolean']>;
  gender?: Maybe<Scalars['Boolean']>;
  dateOfBirth?: Maybe<Scalars['Boolean']>;
  bankAccountName?: Maybe<Scalars['Boolean']>;
  bankAccountNumber?: Maybe<Scalars['Boolean']>;
  bankAccountNumberLastDigitsCount?: Maybe<Scalars['Int']>;
  mobileNumber?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['Boolean']>;
};

export type MemberInteractionLog = {
  __typename?: 'MemberInteractionLog';
  id: Scalars['ID'];
  dateTimeCreated: Scalars['DateTime'];
  member: Member;
  type: MemberInteractionLogType;
  manual: Scalars['Boolean'];
  actor: MemberInteractionLogActor;
  module?: Maybe<MemberInteractionLogModule>;
  shortId: Scalars['String'];
  session?: Maybe<Session>;
  deposit?: Maybe<Deposit>;
  withdrawal?: Maybe<Withdrawal>;
  memberLoyaltyLevel?: Maybe<MemberLoyaltyLevel>;
  vendor?: Maybe<Vendor>;
  memberDocument?: Maybe<MemberDocument>;
  remarks?: Maybe<Scalars['String']>;
};

export type MemberInteractionLogActor = {
  __typename?: 'MemberInteractionLogActor';
  type: MemberInteractionLogActorType;
  account?: Maybe<Account>;
  channel?: Maybe<Channel>;
};

export enum MemberInteractionLogActorType {
  Operator = 'OPERATOR',
  Member = 'MEMBER',
  System = 'SYSTEM',
  Channel = 'CHANNEL',
}

export type MemberInteractionLogActorTypeQueryOperatorInput = {
  eq?: Maybe<MemberInteractionLogActorType>;
  ne?: Maybe<MemberInteractionLogActorType>;
  in?: Maybe<Array<MemberInteractionLogActorType>>;
  nin?: Maybe<Array<MemberInteractionLogActorType>>;
};

export type MemberInteractionLogConnection = {
  __typename?: 'MemberInteractionLogConnection';
  totalCount: Scalars['Int'];
  edges: Array<MemberInteractionLogConnectionEdge>;
  pageInfo: PageInfo;
};

export type MemberInteractionLogConnectionEdge = {
  __typename?: 'MemberInteractionLogConnectionEdge';
  cursor: Scalars['Binary'];
  node: MemberInteractionLog;
};

export type MemberInteractionLogFilterInput = {
  member?: Maybe<IdQueryOperatorInput>;
  dateTimeCreated?: Maybe<DateTimeQueryOperatorInput>;
  actor?: Maybe<MemberInteractionLogActorTypeQueryOperatorInput>;
  type?: Maybe<MemberInteractionLogTypeQueryOperatorInput>;
  module?: Maybe<MemberInteractionLogModuleQueryOperatorInput>;
  brand?: Maybe<StringQueryOperatorInput>;
  brandId?: Maybe<StringQueryOperatorInput>;
  platformId?: Maybe<StringQueryOperatorInput>;
};

export enum MemberInteractionLogModule {
  Account = 'ACCOUNT',
  Admin = 'ADMIN',
  DepositRequest = 'DEPOSIT_REQUEST',
  WithdrawalRequest = 'WITHDRAWAL_REQUEST',
  Member = 'MEMBER',
  MemberLoyaltyLevel = 'MEMBER_LOYALTY_LEVEL',
  Vendor = 'VENDOR',
  ManualAdjustment = 'MANUAL_ADJUSTMENT',
}

export type MemberInteractionLogModuleQueryOperatorInput = {
  eq?: Maybe<MemberInteractionLogModule>;
  ne?: Maybe<MemberInteractionLogModule>;
  in?: Maybe<Array<MemberInteractionLogModule>>;
  nin?: Maybe<Array<MemberInteractionLogModule>>;
};

export enum MemberInteractionLogType {
  MemberLoggedIn = 'MEMBER_LOGGED_IN',
  MemberLoggedOut = 'MEMBER_LOGGED_OUT',
  SessionExpired = 'SESSION_EXPIRED',
  DepositRequestCreated = 'DEPOSIT_REQUEST_CREATED',
  DepositRequestApproved = 'DEPOSIT_REQUEST_APPROVED',
  DepositRequestRejected = 'DEPOSIT_REQUEST_REJECTED',
  WithdrawalRequestCreated = 'WITHDRAWAL_REQUEST_CREATED',
  WithdrawalRequestApproved = 'WITHDRAWAL_REQUEST_APPROVED',
  WithdrawalRequestRejected = 'WITHDRAWAL_REQUEST_REJECTED',
  VendorBalanceCredited = 'VENDOR_BALANCE_CREDITED',
  VendorBalanceDebited = 'VENDOR_BALANCE_DEBITED',
  VendorGameLaunched = 'VENDOR_GAME_LAUNCHED',
  MemberLoyaltyLevelUpgraded = 'MEMBER_LOYALTY_LEVEL_UPGRADED',
  MemberLoyaltyLevelDowngraded = 'MEMBER_LOYALTY_LEVEL_DOWNGRADED',
  MemberRealityCheckContinued = 'MEMBER_REALITY_CHECK_CONTINUED',
  MemberRealityCheckAdminUpdated = 'MEMBER_REALITY_CHECK_ADMIN_UPDATED',
  MemberRealityCheckDiscontinued = 'MEMBER_REALITY_CHECK_DISCONTINUED',
  AccountPasswordResetCreated = 'ACCOUNT_PASSWORD_RESET_CREATED',
  AccountPasswordResetVerified = 'ACCOUNT_PASSWORD_RESET_VERIFIED',
  AccountPasswordResetExpired = 'ACCOUNT_PASSWORD_RESET_EXPIRED',
  MemberSelfExcluded = 'MEMBER_SELF_EXCLUDED',
  MemberCoolingOff = 'MEMBER_COOLING_OFF',
  MemberCoolingOffEnded = 'MEMBER_COOLING_OFF_ENDED',
  MemberGamstopSelfExcluded = 'MEMBER_GAMSTOP_SELF_EXCLUDED',
  MemberActivated = 'MEMBER_ACTIVATED',
  MemberTimeOut = 'MEMBER_TIME_OUT',
  MemberClosed = 'MEMBER_CLOSED',
  MemberFraud = 'MEMBER_FRAUD',
  MemberDocumentCreated = 'MEMBER_DOCUMENT_CREATED',
  AdminMemberDocumentCreated = 'ADMIN_MEMBER_DOCUMENT_CREATED',
  MemberDocumentApproved = 'MEMBER_DOCUMENT_APPROVED',
  MemberDocumentRejected = 'MEMBER_DOCUMENT_REJECTED',
  AcceptedTermsAndConditions = 'ACCEPTED_TERMS_AND_CONDITIONS',
  MemberVerified = 'MEMBER_VERIFIED',
  MemberUnverified = 'MEMBER_UNVERIFIED',
  MemberUpdated = 'MEMBER_UPDATED',
  ManualAdjustment = 'MANUAL_ADJUSTMENT',
  MemberLocked = 'MEMBER_LOCKED',
}

export type MemberInteractionLogTypeQueryOperatorInput = {
  eq?: Maybe<MemberInteractionLogType>;
  ne?: Maybe<MemberInteractionLogType>;
  in?: Maybe<Array<MemberInteractionLogType>>;
  nin?: Maybe<Array<MemberInteractionLogType>>;
};

export type MemberIpAddressReportItem = {
  __typename?: 'MemberIpAddressReportItem';
  id: Scalars['ID'];
  member: Member;
  ipAddress: Scalars['IpAddress'];
  ipAddressLocation: IpAddressLocation;
  lastUsedDateTime: Scalars['DateTime'];
  usageCount: Scalars['Int'];
};

export type MemberIpAddressReportItemsConnection = {
  __typename?: 'MemberIpAddressReportItemsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<MemberIpAddressReportItemsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type MemberIpAddressReportItemsConnectionEdge = {
  __typename?: 'MemberIpAddressReportItemsConnectionEdge';
  cursor: Scalars['Binary'];
  node: MemberIpAddressReportItem;
};

export type MemberIpAddressReportItemsFilterInput = {
  id?: Maybe<IdQueryOperatorInput>;
  member?: Maybe<IdQueryOperatorInput>;
  ipAddress?: Maybe<IpAddressQueryOperatorInput>;
  brand?: Maybe<StringQueryOperatorInput>;
  realName?: Maybe<StringQueryOperatorInput>;
  username?: Maybe<StringQueryOperatorInput>;
};

export type MemberLevel = {
  __typename?: 'MemberLevel';
  id: Scalars['ID'];
  name: Scalars['String'];
  default: Scalars['Boolean'];
  color: Scalars['String'];
  status: MemberLevelStatus;
  membersCount: Scalars['Int'];
  brandLinkage?: Maybe<Scalars['Boolean']>;
};

export type MemberLevelFilterInput = {
  id?: Maybe<IdQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  default?: Maybe<BooleanQueryOperatorInput>;
  status?: Maybe<MemberLevelStatusQueryOperatorInput>;
};

export type MemberLevelsConnection = {
  __typename?: 'MemberLevelsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<MemberLevelsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type MemberLevelsConnectionEdge = {
  __typename?: 'MemberLevelsConnectionEdge';
  cursor: Scalars['Binary'];
  node: MemberLevel;
};

export enum MemberLevelStatus {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
}

export type MemberLevelStatusQueryOperatorInput = {
  eq?: Maybe<MemberLevelStatus>;
  ne?: Maybe<MemberLevelStatus>;
  in?: Maybe<Array<MemberLevelStatus>>;
  nin?: Maybe<Array<MemberLevelStatus>>;
};

export type MemberLoyaltyLevel = {
  __typename?: 'MemberLoyaltyLevel';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  inviteOnly: Scalars['Boolean'];
  qualificationCriteria?: Maybe<MemberLoyaltyLevelCriteria>;
  maintenanceCriteria?: Maybe<MemberLoyaltyLevelCriteria>;
  color: Scalars['String'];
  programme?: Maybe<MemberLoyaltyProgramme>;
  members?: Maybe<MembersConnection>;
  autoPayout: Scalars['Boolean'];
  upgradePayout?: Maybe<MemberLoyaltyLevelUpgradePayoutSettings>;
  dailyPayout?: Maybe<MemberLoyaltyLevelDailyPayoutSettings>;
  weeklyPayout?: Maybe<MemberLoyaltyLevelWeeklyPayoutSettings>;
  monthlyPayout?: Maybe<MemberLoyaltyLevelMonthlyPayoutSettings>;
  annualPayout?: Maybe<MemberLoyaltyLevelAnnualPayoutSettings>;
  birthdayPayout?: Maybe<MemberLoyaltyLevelBirthdayPayoutSettings>;
  maximumWithdrawalAmountPerRequest?: Maybe<Scalars['RoundedFloat']>;
  maximumWithdrawalRequest?: Maybe<Scalars['Int']>;
  withdrawalLimitRequestReset?: Maybe<Period>;
  withdrawalLimitFee?: Maybe<Scalars['RoundedFloat']>;
  withdrawalLimitFeeType: MemberLoyaltyWithdrawalLimitFeeType;
  bankAccountLimits?: Maybe<Scalars['RoundedFloat']>;
  eWalletLimits?: Maybe<Scalars['RoundedFloat']>;
  membersCount?: Maybe<Scalars['Int']>;
  vipBadge?: Maybe<File>;
  vipIcon?: Maybe<File>;
  rank?: Maybe<Scalars['Int']>;
  qualificationDepositRequirement: Scalars['RoundedFloat'];
  qualificationEffectiveBetRequirement: Scalars['RoundedFloat'];
  qualificationTotalRequirement: Scalars['RoundedFloat'];
  maintenanceDepositRequirement: Scalars['RoundedFloat'];
  maintenanceEffectiveBetRequirement: Scalars['RoundedFloat'];
  maintenanceTotalRequirement: Scalars['RoundedFloat'];
};

export type MemberLoyaltyLevelMembersArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
};

export type MemberLoyaltyLevelAnnualPayoutSettings = MemberLoyaltyLevelPayoutSettings & {
  __typename?: 'MemberLoyaltyLevelAnnualPayoutSettings';
  limit: Scalars['Int'];
  amount: Scalars['RoundedFloat'];
  claimExpiryAmount?: Maybe<Scalars['Int']>;
  claimExpiryDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  claimOffsetAmount?: Maybe<Scalars['Int']>;
  claimOffsetDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  payoutExpiryAmount?: Maybe<Scalars['Int']>;
  payoutExpiryDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  turnover: Scalars['RoundedFloat'];
  timeOfDay?: Maybe<Scalars['Time']>;
};

export type MemberLoyaltyLevelAnnualPayoutSettingsInput = {
  limit: Scalars['Int'];
  amount: Scalars['Float'];
  claimExpiryAmount?: Maybe<Scalars['Int']>;
  claimExpiryDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  claimOffsetAmount?: Maybe<Scalars['Int']>;
  claimOffsetDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  payoutExpiryAmount?: Maybe<Scalars['Int']>;
  payoutExpiryDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  turnover: Scalars['Float'];
  timeOfDay?: Maybe<Scalars['Time']>;
};

export type MemberLoyaltyLevelBirthdayPayoutSettings = MemberLoyaltyLevelPayoutSettings & {
  __typename?: 'MemberLoyaltyLevelBirthdayPayoutSettings';
  limit: Scalars['Int'];
  amount: Scalars['RoundedFloat'];
  claimExpiryAmount?: Maybe<Scalars['Int']>;
  claimExpiryDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  claimOffsetAmount?: Maybe<Scalars['Int']>;
  claimOffsetDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  payoutExpiryAmount?: Maybe<Scalars['Int']>;
  payoutExpiryDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  turnover: Scalars['RoundedFloat'];
  timeOfDay?: Maybe<Scalars['Time']>;
};

export type MemberLoyaltyLevelBirthdayPayoutSettingsInput = {
  limit: Scalars['Int'];
  amount: Scalars['Float'];
  claimExpiryAmount?: Maybe<Scalars['Int']>;
  claimExpiryDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  claimOffsetAmount?: Maybe<Scalars['Int']>;
  claimOffsetDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  payoutExpiryAmount?: Maybe<Scalars['Int']>;
  payoutExpiryDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  turnover: Scalars['Float'];
  timeOfDay?: Maybe<Scalars['Time']>;
};

export type MemberLoyaltyLevelCriteria = {
  __typename?: 'MemberLoyaltyLevelCriteria';
  type?: Maybe<MemberLoyaltyLevelCriteriaType>;
  and?: Maybe<Array<Maybe<MemberLoyaltyLevelCriteria>>>;
  or?: Maybe<Array<Maybe<MemberLoyaltyLevelCriteria>>>;
  amount?: Maybe<Scalars['RoundedFloat']>;
  gameTypes?: Maybe<Array<Scalars['String']>>;
};

export type MemberLoyaltyLevelCriteriaInput = {
  type?: Maybe<MemberLoyaltyLevelCriteriaType>;
  and?: Maybe<Array<Maybe<MemberLoyaltyLevelCriteriaInput>>>;
  or?: Maybe<Array<Maybe<MemberLoyaltyLevelCriteriaInput>>>;
  amount?: Maybe<Scalars['Float']>;
  gameTypes?: Maybe<Array<Scalars['String']>>;
};

export enum MemberLoyaltyLevelCriteriaType {
  Base = 'BASE',
  Deposit = 'DEPOSIT',
  EffectiveBet = 'EFFECTIVE_BET',
}

export type MemberLoyaltyLevelDailyPayoutSettings = MemberLoyaltyLevelPayoutSettings & {
  __typename?: 'MemberLoyaltyLevelDailyPayoutSettings';
  limit: Scalars['Int'];
  amount: Scalars['RoundedFloat'];
  claimExpiryAmount?: Maybe<Scalars['Int']>;
  claimExpiryDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  claimOffsetAmount?: Maybe<Scalars['Int']>;
  claimOffsetDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  payoutExpiryAmount?: Maybe<Scalars['Int']>;
  payoutExpiryDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  turnover: Scalars['RoundedFloat'];
  timeOfDay?: Maybe<Scalars['Time']>;
};

export type MemberLoyaltyLevelDailyPayoutSettingsInput = {
  limit: Scalars['Int'];
  amount: Scalars['Float'];
  claimExpiryAmount?: Maybe<Scalars['Int']>;
  claimExpiryDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  claimOffsetAmount?: Maybe<Scalars['Int']>;
  claimOffsetDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  payoutExpiryAmount?: Maybe<Scalars['Int']>;
  payoutExpiryDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  turnover: Scalars['Float'];
  timeOfDay?: Maybe<Scalars['Time']>;
};

export type MemberLoyaltyLevelInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  inviteOnly: Scalars['Boolean'];
  qualificationCriteria?: Maybe<MemberLoyaltyLevelCriteriaInput>;
  maintenanceCriteria?: Maybe<MemberLoyaltyLevelCriteriaInput>;
  color: Scalars['String'];
  autoPayout: Scalars['Boolean'];
  birthdayPayout?: Maybe<MemberLoyaltyLevelBirthdayPayoutSettingsInput>;
  upgradePayout?: Maybe<MemberLoyaltyLevelUpgradePayoutSettingsInput>;
  dailyPayout?: Maybe<MemberLoyaltyLevelDailyPayoutSettingsInput>;
  weeklyPayout?: Maybe<MemberLoyaltyLevelWeeklyPayoutSettingsInput>;
  monthlyPayout?: Maybe<MemberLoyaltyLevelMonthlyPayoutSettingsInput>;
  annualPayout?: Maybe<MemberLoyaltyLevelAnnualPayoutSettingsInput>;
  maximumWithdrawalAmountPerRequest?: Maybe<Scalars['Float']>;
  maximumWithdrawalRequest?: Maybe<Scalars['Int']>;
  withdrawalLimitRequestReset?: Maybe<Period>;
  withdrawalLimitFee?: Maybe<Scalars['Float']>;
  withdrawalLimitFeeType?: Maybe<MemberLoyaltyWithdrawalLimitFeeType>;
  bankAccountLimits?: Maybe<Scalars['Float']>;
  eWalletLimits?: Maybe<Scalars['Float']>;
  vipBadge?: Maybe<Scalars['ID']>;
  vipIcon?: Maybe<Scalars['ID']>;
};

export type MemberLoyaltyLevelMaintenanceProgress = MemberLoyaltyLevelProgress & {
  __typename?: 'MemberLoyaltyLevelMaintenanceProgress';
  deposit: Scalars['RoundedFloat'];
  depositRemaining: Scalars['RoundedFloat'];
  effectiveBet: Scalars['RoundedFloat'];
  effectiveBetRemaining: Scalars['RoundedFloat'];
  liveEffectiveBet?: Maybe<Scalars['RoundedFloat']>;
  pokerEffectiveBet?: Maybe<Scalars['RoundedFloat']>;
  fishEffectiveBet?: Maybe<Scalars['RoundedFloat']>;
  sportsbookEffectiveBet?: Maybe<Scalars['RoundedFloat']>;
  slotEffectiveBet?: Maybe<Scalars['RoundedFloat']>;
  lotteryEffectiveBet?: Maybe<Scalars['RoundedFloat']>;
  percentage: Scalars['RoundedFloat'];
  daysRemaining?: Maybe<Scalars['Int']>;
  resetDateTime?: Maybe<Scalars['DateTime']>;
  currentMemberLoyaltyLevel?: Maybe<MemberLoyaltyLevel>;
};

export type MemberLoyaltyLevelMonthlyPayoutSettings = MemberLoyaltyLevelPayoutSettings & {
  __typename?: 'MemberLoyaltyLevelMonthlyPayoutSettings';
  limit: Scalars['Int'];
  amount: Scalars['RoundedFloat'];
  claimExpiryAmount?: Maybe<Scalars['Int']>;
  claimExpiryDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  claimOffsetAmount?: Maybe<Scalars['Int']>;
  claimOffsetDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  payoutExpiryAmount?: Maybe<Scalars['Int']>;
  payoutExpiryDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  turnover: Scalars['RoundedFloat'];
  timeOfDay?: Maybe<Scalars['Time']>;
  dayOfMonth?: Maybe<Scalars['String']>;
};

export type MemberLoyaltyLevelMonthlyPayoutSettingsInput = {
  limit: Scalars['Int'];
  amount: Scalars['Float'];
  claimExpiryAmount?: Maybe<Scalars['Int']>;
  claimExpiryDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  claimOffsetAmount?: Maybe<Scalars['Int']>;
  claimOffsetDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  payoutExpiryAmount?: Maybe<Scalars['Int']>;
  payoutExpiryDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  turnover: Scalars['Float'];
  timeOfDay?: Maybe<Scalars['Time']>;
  dayOfMonth: Scalars['String'];
};

export enum MemberLoyaltyLevelPayoutDuration {
  Days = 'DAYS',
  Weeks = 'WEEKS',
  Months = 'MONTHS',
  Years = 'YEARS',
}

export type MemberLoyaltyLevelPayoutSettings = {
  limit: Scalars['Int'];
  amount: Scalars['RoundedFloat'];
  claimExpiryAmount?: Maybe<Scalars['Int']>;
  claimExpiryDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  claimOffsetAmount?: Maybe<Scalars['Int']>;
  claimOffsetDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  payoutExpiryAmount?: Maybe<Scalars['Int']>;
  payoutExpiryDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  turnover: Scalars['RoundedFloat'];
};

export type MemberLoyaltyLevelProgress = {
  deposit: Scalars['RoundedFloat'];
  depositRemaining: Scalars['RoundedFloat'];
  effectiveBet: Scalars['RoundedFloat'];
  effectiveBetRemaining: Scalars['RoundedFloat'];
  liveEffectiveBet?: Maybe<Scalars['RoundedFloat']>;
  pokerEffectiveBet?: Maybe<Scalars['RoundedFloat']>;
  fishEffectiveBet?: Maybe<Scalars['RoundedFloat']>;
  sportsbookEffectiveBet?: Maybe<Scalars['RoundedFloat']>;
  slotEffectiveBet?: Maybe<Scalars['RoundedFloat']>;
  lotteryEffectiveBet?: Maybe<Scalars['RoundedFloat']>;
  percentage: Scalars['RoundedFloat'];
  daysRemaining?: Maybe<Scalars['Int']>;
  resetDateTime?: Maybe<Scalars['DateTime']>;
  currentMemberLoyaltyLevel?: Maybe<MemberLoyaltyLevel>;
};

export type MemberLoyaltyLevelQualificationProgress = MemberLoyaltyLevelProgress & {
  __typename?: 'MemberLoyaltyLevelQualificationProgress';
  deposit: Scalars['RoundedFloat'];
  depositRemaining: Scalars['RoundedFloat'];
  effectiveBet: Scalars['RoundedFloat'];
  effectiveBetRemaining: Scalars['RoundedFloat'];
  liveEffectiveBet?: Maybe<Scalars['RoundedFloat']>;
  pokerEffectiveBet?: Maybe<Scalars['RoundedFloat']>;
  fishEffectiveBet?: Maybe<Scalars['RoundedFloat']>;
  sportsbookEffectiveBet?: Maybe<Scalars['RoundedFloat']>;
  slotEffectiveBet?: Maybe<Scalars['RoundedFloat']>;
  lotteryEffectiveBet?: Maybe<Scalars['RoundedFloat']>;
  percentage: Scalars['RoundedFloat'];
  daysRemaining?: Maybe<Scalars['Int']>;
  resetDateTime?: Maybe<Scalars['DateTime']>;
  nextMemberLoyaltyLevel?: Maybe<MemberLoyaltyLevel>;
  currentMemberLoyaltyLevel?: Maybe<MemberLoyaltyLevel>;
};

export type MemberLoyaltyLevelsConnection = {
  __typename?: 'MemberLoyaltyLevelsConnection';
  totalCount: Scalars['Int'];
  edges?: Maybe<Array<MemberLoyaltyLevelsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type MemberLoyaltyLevelsConnectionEdge = {
  __typename?: 'MemberLoyaltyLevelsConnectionEdge';
  cursor: Scalars['Binary'];
  node: MemberLoyaltyLevel;
};

export type MemberLoyaltyLevelsFilterInput = {
  id?: Maybe<IdQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  programme?: Maybe<IdQueryOperatorInput>;
  status?: Maybe<MemberLoyaltyProgrammeStatusQueryOperatorInput>;
};

export type MemberLoyaltyLevelUpgradePayoutSettings = MemberLoyaltyLevelPayoutSettings & {
  __typename?: 'MemberLoyaltyLevelUpgradePayoutSettings';
  limit: Scalars['Int'];
  amount: Scalars['RoundedFloat'];
  claimExpiryAmount?: Maybe<Scalars['Int']>;
  claimExpiryDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  claimOffsetAmount?: Maybe<Scalars['Int']>;
  claimOffsetDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  payoutExpiryAmount?: Maybe<Scalars['Int']>;
  payoutExpiryDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  turnover: Scalars['RoundedFloat'];
};

export type MemberLoyaltyLevelUpgradePayoutSettingsInput = {
  limit: Scalars['Int'];
  amount: Scalars['Float'];
  claimExpiryAmount?: Maybe<Scalars['Int']>;
  claimExpiryDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  claimOffsetAmount?: Maybe<Scalars['Int']>;
  claimOffsetDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  payoutExpiryAmount?: Maybe<Scalars['Int']>;
  payoutExpiryDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  turnover: Scalars['Int'];
};

export type MemberLoyaltyLevelWeeklyPayoutSettings = MemberLoyaltyLevelPayoutSettings & {
  __typename?: 'MemberLoyaltyLevelWeeklyPayoutSettings';
  limit: Scalars['Int'];
  amount: Scalars['RoundedFloat'];
  claimExpiryAmount?: Maybe<Scalars['Int']>;
  claimExpiryDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  claimOffsetAmount?: Maybe<Scalars['Int']>;
  claimOffsetDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  payoutExpiryAmount?: Maybe<Scalars['Int']>;
  payoutExpiryDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  turnover: Scalars['RoundedFloat'];
  timeOfDay?: Maybe<Scalars['Time']>;
  dayOfWeek?: Maybe<Scalars['DayOfWeek']>;
};

export type MemberLoyaltyLevelWeeklyPayoutSettingsInput = {
  limit: Scalars['Int'];
  amount: Scalars['Float'];
  claimExpiryAmount?: Maybe<Scalars['Int']>;
  claimExpiryDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  claimOffsetAmount?: Maybe<Scalars['Int']>;
  claimOffsetDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  payoutExpiryAmount?: Maybe<Scalars['Int']>;
  payoutExpiryDuration?: Maybe<MemberLoyaltyLevelPayoutDuration>;
  turnover: Scalars['Float'];
  timeOfDay?: Maybe<Scalars['Time']>;
  dayOfWeek: Scalars['DayOfWeek'];
};

export type MemberLoyaltyPayout = {
  __typename?: 'MemberLoyaltyPayout';
  id?: Maybe<Scalars['ID']>;
  amount: Scalars['RoundedFloat'];
  memberLoyaltyLevel: MemberLoyaltyLevel;
  member?: Maybe<Member>;
  turnover: Scalars['RoundedFloat'];
  type?: Maybe<MemberLoyaltyPayoutType>;
  status?: Maybe<MemberLoyaltyPayoutStatus>;
  dateTimeClaimed?: Maybe<Scalars['DateTime']>;
};

export type MemberLoyaltyPayoutsConnection = {
  __typename?: 'MemberLoyaltyPayoutsConnection';
  totalCount: Scalars['Int'];
  edges?: Maybe<Array<MemberLoyaltyPayoutsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type MemberLoyaltyPayoutsConnectionEdge = {
  __typename?: 'MemberLoyaltyPayoutsConnectionEdge';
  cursor: Scalars['Binary'];
  node: MemberLoyaltyPayout;
};

export type MemberLoyaltyPayoutsFilterInput = {
  member?: Maybe<IdQueryOperatorInput>;
};

export enum MemberLoyaltyPayoutStatus {
  Pending = 'PENDING',
  Claimed = 'CLAIMED',
}

export enum MemberLoyaltyPayoutType {
  Upgrade = 'UPGRADE',
  Daily = 'DAILY',
  Birthday = 'BIRTHDAY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Annually = 'ANNUALLY',
}

export type MemberLoyaltyProgramme = {
  __typename?: 'MemberLoyaltyProgramme';
  id: Scalars['ID'];
  serialCode: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  validityStartDate?: Maybe<Scalars['Date']>;
  validityEndDate?: Maybe<Scalars['Date']>;
  evaluationPeriod?: Maybe<MemberLoyaltyProgrammeEvaluationPeriod>;
  automaticMemberLoyaltyLevelUpgrade?: Maybe<Scalars['Boolean']>;
  automaticMemberLoyaltyLevelDowngrade?: Maybe<Scalars['Boolean']>;
  levels?: Maybe<Array<MemberLoyaltyLevel>>;
  status: MemberLoyaltyProgrammeStatus;
  membersCount?: Maybe<Scalars['Int']>;
  primary: Scalars['Boolean'];
  badgeSet: BadgeSet;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeUpdated: Scalars['DateTime'];
};

export enum MemberLoyaltyProgrammeEvaluationPeriod {
  Cumulative = 'CUMULATIVE',
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Annually = 'ANNUALLY',
}

export type MemberLoyaltyProgrammesConnection = {
  __typename?: 'MemberLoyaltyProgrammesConnection';
  totalCount: Scalars['Int'];
  edges?: Maybe<Array<MemberLoyaltyProgrammesConnectionEdge>>;
  pageInfo: PageInfo;
};

export type MemberLoyaltyProgrammesConnectionEdge = {
  __typename?: 'MemberLoyaltyProgrammesConnectionEdge';
  cursor: Scalars['Binary'];
  node: MemberLoyaltyProgramme;
};

export type MemberLoyaltyProgrammesFilterInput = {
  id?: Maybe<IdQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  validityStartDate?: Maybe<DateQueryOperatorInput>;
  validityEndDate?: Maybe<DateQueryOperatorInput>;
  status?: Maybe<MemberLoyaltyProgrammeStatusQueryOperatorInput>;
};

export enum MemberLoyaltyProgrammeStatus {
  Draft = 'DRAFT',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type MemberLoyaltyProgrammeStatusQueryOperatorInput = {
  eq?: Maybe<MemberLoyaltyProgrammeStatus>;
  ne?: Maybe<MemberLoyaltyProgrammeStatus>;
  in?: Maybe<Array<MemberLoyaltyProgrammeStatus>>;
  nin?: Maybe<Array<MemberLoyaltyProgrammeStatus>>;
};

export enum MemberLoyaltyWithdrawalLimitFeeType {
  Absolute = 'ABSOLUTE',
  Relative = 'RELATIVE',
}

export type MemberNetDeposits = {
  __typename?: 'MemberNetDeposits';
  _30_DAYS: Scalars['RoundedFloat'];
  _365_DAYS: Scalars['RoundedFloat'];
  LIFETIME: Scalars['RoundedFloat'];
};

export type MemberNote = {
  __typename?: 'MemberNote';
  id: Scalars['ID'];
  creator: Account;
  member: Member;
  header: Scalars['String'];
  note: Scalars['String'];
  pinned: Scalars['Boolean'];
  dateTimeCreated: Scalars['DateTime'];
  dateTimeUpdated: Scalars['DateTime'];
};

export type MemberNotesConnection = {
  __typename?: 'MemberNotesConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<MemberNotesConnectionEdge>>;
  pageInfo: PageInfo;
};

export type MemberNotesConnectionEdge = {
  __typename?: 'MemberNotesConnectionEdge';
  cursor: Scalars['Binary'];
  node?: Maybe<MemberNote>;
};

export type MemberNotesFilterInput = {
  header?: Maybe<StringQueryOperatorInput>;
  note?: Maybe<StringQueryOperatorInput>;
  pinned?: Maybe<BooleanQueryOperatorInput>;
};

export type MemberPlayedGame = {
  __typename?: 'MemberPlayedGame';
  id: Scalars['ID'];
  game: Game;
  lastMemberBetRecord: MemberBetRecord;
};

export type MemberPlayedGamesConnection = {
  __typename?: 'MemberPlayedGamesConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<MemberPlayedGamesConnectionEdge>>;
  pageInfo: PageInfo;
};

export type MemberPlayedGamesConnectionEdge = {
  __typename?: 'MemberPlayedGamesConnectionEdge';
  cursor: Scalars['Binary'];
  node: MemberPlayedGame;
};

export type MemberRebatePayoutRecord = {
  __typename?: 'MemberRebatePayoutRecord';
  id: Scalars['ID'];
  member: Member;
  rebateGroup?: Maybe<RebateGroup>;
  totalAmountAccumulated: Scalars['RoundedFloat'];
  totalAmountPayout: Scalars['RoundedFloat'];
  totalAmountToBePayout: Scalars['RoundedFloat'];
  totalAmountClaimed: Scalars['RoundedFloat'];
  totalAmountExpired: Scalars['RoundedFloat'];
  dateTimeCreated: Scalars['DateTime'];
};

export type MemberRebatePayoutRecordsConnection = {
  __typename?: 'MemberRebatePayoutRecordsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<MemberRebatePayoutRecordsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type MemberRebatePayoutRecordsConnectionEdge = {
  __typename?: 'MemberRebatePayoutRecordsConnectionEdge';
  cursor: Scalars['Binary'];
  node: MemberRebatePayoutRecord;
};

export type MemberRebatePayoutRecordsFilterInput = {
  member?: Maybe<IdQueryOperatorInput>;
  rebateGroup?: Maybe<IdQueryOperatorInput>;
  totalAmountAccumulated?: Maybe<FloatQueryOperatorInput>;
  totalAmountPayout?: Maybe<FloatQueryOperatorInput>;
  totalAmountToBePayout?: Maybe<FloatQueryOperatorInput>;
  totalAmountClaimed?: Maybe<FloatQueryOperatorInput>;
  totalAmountExpired?: Maybe<FloatQueryOperatorInput>;
  dateTimeCreated?: Maybe<DateTimeQueryOperatorInput>;
};

export type MembersConnection = {
  __typename?: 'MembersConnection';
  totalCount: Scalars['Int'];
  totalEffectiveBet: Scalars['RoundedFloat'];
  totalAccountBalance: Scalars['RoundedFloat'];
  totalDepositRequested: Scalars['RoundedFloat'];
  totalDepositApproved: Scalars['RoundedFloat'];
  edges: Array<MembersConnectionsEdge>;
  pageInfo: PageInfo;
};

export type MembersConnectionsEdge = {
  __typename?: 'MembersConnectionsEdge';
  cursor: Scalars['Binary'];
  node: Member;
};

export type MembersFilterInput = {
  id?: Maybe<IdQueryOperatorInput>;
  username?: Maybe<StringQueryOperatorInput>;
  realName?: Maybe<StringQueryOperatorInput>;
  registrationDateTime?: Maybe<DateTimeQueryOperatorInput>;
  lastLoginDateTime?: Maybe<DateTimeQueryOperatorInput>;
  lastOnlineDateTime?: Maybe<DateTimeQueryOperatorInput>;
  lastLoginIpAddress?: Maybe<IpAddressQueryOperatorInput>;
  registrationIpAddress?: Maybe<IpAddressQueryOperatorInput>;
  dateOfBirth?: Maybe<DateQueryOperatorInput>;
  status?: Maybe<MemberStatusQueryOperatorInput>;
  online?: Maybe<BooleanQueryOperatorInput>;
  memberLevel?: Maybe<IdQueryOperatorInput>;
  tags?: Maybe<IdArrayQueryOperatorInput>;
  tags_next?: Maybe<TagsQueryOperatorInput>;
  totalBalance?: Maybe<FloatQueryOperatorInput>;
  starred?: Maybe<Scalars['Boolean']>;
  forceChangeWithdrawalPassword?: Maybe<BooleanQueryOperatorInput>;
  forceChangePassword?: Maybe<BooleanQueryOperatorInput>;
  forceVerification?: Maybe<BooleanQueryOperatorInput>;
  memberLoyaltyLevel?: Maybe<IdQueryOperatorInput>;
  memberLoyaltyLevels?: Maybe<IdArrayQueryOperatorInput>;
  mobileNumber?: Maybe<StringQueryOperatorInput>;
  email?: Maybe<StringQueryOperatorInput>;
  qqId?: Maybe<StringQueryOperatorInput>;
  wechatId?: Maybe<StringQueryOperatorInput>;
  bankAccounts?: Maybe<IdArrayQueryOperatorInput>;
  affiliate?: Maybe<IdQueryOperatorInput>;
  upline?: Maybe<IdQueryOperatorInput>;
  totalEffectiveBet?: Maybe<FloatQueryOperatorInput>;
  platformId?: Maybe<StringQueryOperatorInput>;
  brandId?: Maybe<StringQueryOperatorInput>;
  brand?: Maybe<StringQueryOperatorInput>;
  postalCode?: Maybe<StringQueryOperatorInput>;
  verified?: Maybe<BooleanQueryOperatorInput>;
  affiliateId?: Maybe<StringQueryOperatorInput>;
};

export enum MemberStatus {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
  Frozen = 'FROZEN',
  Kicked = 'KICKED',
  SelfExcluded = 'SELF_EXCLUDED',
  GamstopSelfExcluded = 'GAMSTOP_SELF_EXCLUDED',
  CoolingOff = 'COOLING_OFF',
  CoolingOffEnded = 'COOLING_OFF_ENDED',
  TimeOut = 'TIME_OUT',
  Fraud = 'FRAUD',
  /** The same as softly deleted member */
  Closed = 'CLOSED',
  Banned = 'BANNED',
  /** Inactive for 60 days */
  Dormant = 'DORMANT',
  ClosedLegacy = 'CLOSED_LEGACY',
}

export type MemberStatusQueryOperatorInput = {
  eq?: Maybe<MemberStatus>;
  ne?: Maybe<MemberStatus>;
  in?: Maybe<Array<MemberStatus>>;
  nin?: Maybe<Array<MemberStatus>>;
};

export type MemberSummaryReport = {
  __typename?: 'MemberSummaryReport';
  id: Scalars['ID'];
  creator: Account;
  timezone?: Maybe<Scalars['Timezone']>;
  filter: MemberSummaryReportFilter;
  report?: Maybe<File>;
  status: MemberSummaryReportStatus;
  dateTimeCreated: Scalars['DateTime'];
};

export type MemberSummaryReportFilter = {
  __typename?: 'MemberSummaryReportFilter';
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
  members?: Maybe<Array<Account>>;
  gameTypes?: Maybe<Array<GameType>>;
  vendors?: Maybe<Array<Vendor>>;
  isAffiliate?: Maybe<Array<Scalars['Boolean']>>;
  isAffiliateUpline?: Maybe<Array<Scalars['Boolean']>>;
  affiliates?: Maybe<Array<Account>>;
  memberLoyaltyLevels?: Maybe<Array<MemberLoyaltyLevel>>;
  memberLevels?: Maybe<Array<MemberLevel>>;
  memberTags?: Maybe<Array<MemberTag>>;
  memberStatus?: Maybe<Array<MemberStatus>>;
  registrationDateTime?: Maybe<DateTimeRange>;
  lastLoginDateTime?: Maybe<DateTimeRange>;
  dateOfBirthDateTime?: Maybe<DateRange>;
  totalBalance?: Maybe<TotalBalanceRange>;
};

export type MemberSummaryReportsConnection = {
  __typename?: 'MemberSummaryReportsConnection';
  totalCount: Scalars['Int'];
  edges: Array<MemberSummaryReportsConnectionEdge>;
  pageInfo: PageInfo;
};

export type MemberSummaryReportsConnectionEdge = {
  __typename?: 'MemberSummaryReportsConnectionEdge';
  cursor: Scalars['Binary'];
  node: MemberSummaryReport;
};

export enum MemberSummaryReportStatus {
  Generating = 'GENERATING',
  Generated = 'GENERATED',
}

export type MemberTag = {
  __typename?: 'MemberTag';
  id: Scalars['ID'];
  name: Scalars['String'];
  color: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  membersCount: Scalars['Int'];
  brandLinkage?: Maybe<Scalars['Boolean']>;
};

export type MemberTagConnectionsEdge = {
  __typename?: 'MemberTagConnectionsEdge';
  cursor: Scalars['Binary'];
  node: MemberTag;
};

export type MemberTagOperation = {
  add?: Maybe<Array<Maybe<Scalars['ID']>>>;
  remove?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type MemberTagsConnection = {
  __typename?: 'MemberTagsConnection';
  totalCount: Scalars['Int'];
  edges: Array<MemberTagConnectionsEdge>;
  pageInfo: PageInfo;
};

export type MemberTagsFilterInput = {
  id?: Maybe<IdQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
};

export enum MemberTitle {
  Mr = 'MR',
  Mrs = 'MRS',
  Ms = 'MS',
}

export type Message = {
  __typename?: 'Message';
  id: Scalars['ID'];
  creator: Account;
  title: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  targetMembers?: Maybe<Array<Member>>;
  targetMemberLevels?: Maybe<Array<MemberLevel>>;
  targetMemberLoyaltyLevels?: Maybe<Array<MemberLoyaltyLevel>>;
  excludedMembers?: Maybe<Array<Member>>;
  excludedMemberLevels?: Maybe<Array<MemberLevel>>;
  excludedMemberLoyaltyLevels?: Maybe<Array<MemberLoyaltyLevel>>;
  totalRecipients: Scalars['Int'];
  totalReads: Scalars['Int'];
  schedule?: Maybe<Scalars['DateTime']>;
  submitted: Scalars['Boolean'];
  dateTimeCreated: Scalars['DateTime'];
  dateTimeUpdated: Scalars['DateTime'];
  dateTimeSubmitted?: Maybe<Scalars['DateTime']>;
};

export type MessagesConnection = {
  __typename?: 'MessagesConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<MessagesConnectionEdge>>;
  pageInfo: PageInfo;
};

export type MessagesConnectionEdge = {
  __typename?: 'MessagesConnectionEdge';
  cursor: Scalars['Binary'];
  node: Message;
};

export type MessagesFilterInput = {
  title?: Maybe<StringQueryOperatorInput>;
  content?: Maybe<StringQueryOperatorInput>;
  creator?: Maybe<IdQueryOperatorInput>;
  schedule?: Maybe<DateTimeQueryOperatorInput>;
  targetMembers?: Maybe<IdArrayQueryOperatorInput>;
  targetMemberLevels?: Maybe<IdArrayQueryOperatorInput>;
  targetMemberLoyaltyLevels?: Maybe<IdArrayQueryOperatorInput>;
  excludedMembers?: Maybe<IdArrayQueryOperatorInput>;
  excludedMemberLevels?: Maybe<IdArrayQueryOperatorInput>;
  excludedMemberLoyaltyLevels?: Maybe<IdArrayQueryOperatorInput>;
  dateTimeSubmitted?: Maybe<DateTimeQueryOperatorInput>;
};

export type MidPayDepositProvider = DepositProvider & {
  __typename?: 'MidPayDepositProvider';
  id: Scalars['ID'];
  name: Scalars['String'];
  apiKey: Scalars['String'];
  apiKeyPreview: Scalars['String'];
  dateTimeCreated: Scalars['DateTime'];
  dateTimeUpdated: Scalars['DateTime'];
};

export type MidPayDirectDepositProvider = DepositProvider & {
  __typename?: 'MidPayDirectDepositProvider';
  id: Scalars['ID'];
  name: Scalars['String'];
  dateTimeCreated: Scalars['DateTime'];
  dateTimeUpdated: Scalars['DateTime'];
  apiKey: Scalars['String'];
  apiKeyPreview: Scalars['String'];
  midPayDirectPaymentGateway: MidPayDirectPaymentGateway;
  credentialValues: Array<Scalars['String']>;
};

export type MidPayDirectPaymentGateway = PaymentGateway & {
  __typename?: 'MidPayDirectPaymentGateway';
  id: Scalars['ID'];
  name: Scalars['String'];
  credentialFields: Array<PaymentGatewayCredentialField>;
  paymentChannels: Array<Scalars['String']>;
};

export type MidpayDirectWithdrawalProvider = WithdrawalProvider & {
  __typename?: 'MidpayDirectWithdrawalProvider';
  id: Scalars['ID'];
  name: Scalars['String'];
  apiKey: Scalars['String'];
  apiKeyPreview: Scalars['String'];
  type: WithdrawalProviderType;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeUpdated: Scalars['DateTime'];
  midPayDirectPaymentGateway: MidPayDirectPaymentGateway;
  credentialValues: Array<Scalars['String']>;
};

export type MidpayWithdrawalProvider = WithdrawalProvider & {
  __typename?: 'MidpayWithdrawalProvider';
  id: Scalars['ID'];
  name: Scalars['String'];
  apiKey: Scalars['String'];
  apiKeyPreview: Scalars['String'];
  type: WithdrawalProviderType;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeUpdated: Scalars['DateTime'];
};

export type Mission = {
  __typename?: 'Mission';
  id: Scalars['ID'];
  serialCode: Scalars['String'];
  creator: Account;
  type: MissionType;
  name?: Maybe<Scalars['String']>;
  automaticEnrollment: Scalars['Boolean'];
  enrollmentStartDate: Scalars['Date'];
  enrollmentEndDate: Scalars['Date'];
  requireBankAccount: Scalars['Boolean'];
  maximumEnrolleesCount?: Maybe<Scalars['Int']>;
  maximumEnrolleesCountPerIpAddress?: Maybe<Scalars['Int']>;
  maximumEnrolleesCountPerName?: Maybe<Scalars['Int']>;
  automaticPayout: Scalars['Boolean'];
  generateRebate: Scalars['Boolean'];
  generateMemberLoyaltyProgress: Scalars['Boolean'];
  cyclesCount?: Maybe<Scalars['Int']>;
  dayRewardEnabled: Scalars['Boolean'];
  dayRewardAmount?: Maybe<Scalars['RoundedFloat']>;
  dayRewardMultiplier?: Maybe<Scalars['Boolean']>;
  dayRewardPointsRequirement?: Maybe<Scalars['Int']>;
  dayRewardType?: Maybe<MissionRewardType>;
  dayRewardTurnoverRequirementMultiplier?: Maybe<Scalars['RoundedFloat']>;
  days: Array<MissionDay>;
  milestones: Array<MissionMilestone>;
  milestonesEnabled: Scalars['Boolean'];
  memberLoyaltyGroups: Array<MissionMemberLoyaltyGroup>;
  enrolledMembersCount: Scalars['Int'];
  qualifiedMembersCount: Scalars['Int'];
  status: MissionStatus;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeSubmitted?: Maybe<Scalars['DateTime']>;
  dateTimeUpdated: Scalars['DateTime'];
};

export type MissionDay = {
  __typename?: 'MissionDay';
  tasks: Array<MissionTask>;
};

export type MissionDayInput = {
  tasks?: Maybe<Array<MissionTaskInput>>;
};

export type MissionMemberLoyaltyGroup = {
  __typename?: 'MissionMemberLoyaltyGroup';
  name?: Maybe<Scalars['String']>;
  excludedMemberLevels?: Maybe<Array<MemberLevel>>;
  excludedMemberTags?: Maybe<Array<MemberTag>>;
  qualifiedMemberLoyaltyLevels: Array<MemberLoyaltyLevel>;
  milestoneRewardMultiplier?: Maybe<Scalars['RoundedFloat']>;
  taskRewardMultiplier?: Maybe<Scalars['RoundedFloat']>;
  taskMultiplier?: Maybe<Scalars['RoundedFloat']>;
};

export type MissionMemberLoyaltyGroupInput = {
  name?: Maybe<Scalars['String']>;
  excludedMemberLevels?: Maybe<Array<Scalars['ID']>>;
  excludedMemberTags?: Maybe<Array<Scalars['ID']>>;
  qualifiedMemberLoyaltyLevels?: Maybe<Array<Scalars['ID']>>;
  milestoneRewardMultiplier?: Maybe<Scalars['RoundedFloat']>;
  taskRewardMultiplier?: Maybe<Scalars['RoundedFloat']>;
  taskMultiplier?: Maybe<Scalars['RoundedFloat']>;
};

export type MissionMilestone = {
  __typename?: 'MissionMilestone';
  requirement?: Maybe<Scalars['Int']>;
  rewardType?: Maybe<MissionRewardType>;
  rewardAmount?: Maybe<Scalars['RoundedFloat']>;
  rewardMultiplier: Scalars['Boolean'];
  turnoverRequirementMultiplier?: Maybe<Scalars['RoundedFloat']>;
};

export type MissionMilestoneInput = {
  requirement?: Maybe<Scalars['Int']>;
  rewardAmount?: Maybe<Scalars['RoundedFloat']>;
  rewardMultiplier?: Maybe<Scalars['Boolean']>;
  rewardType?: Maybe<MissionRewardType>;
  turnoverRequirementMultiplier?: Maybe<Scalars['RoundedFloat']>;
};

export enum MissionRewardType {
  Money = 'MONEY',
}

export type MissionsConnection = {
  __typename?: 'MissionsConnection';
  totalCount: Scalars['Int'];
  edges: Array<MissionsConnectionEdge>;
  pageInfo: PageInfo;
};

export type MissionsConnectionEdge = {
  __typename?: 'MissionsConnectionEdge';
  cursor: Scalars['Binary'];
  node: Mission;
};

export type MissionsFilterInput = {
  enrollmentStartDate?: Maybe<DateQueryOperatorInput>;
  enrollmentEndDate?: Maybe<DateQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<StringQueryOperatorInput>;
  type?: Maybe<StringQueryOperatorInput>;
};

export enum MissionStatus {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  EnrollmentExpired = 'ENROLLMENT_EXPIRED',
  Expired = 'EXPIRED',
  Inactive = 'INACTIVE',
}

export type MissionTask = {
  __typename?: 'MissionTask';
  type?: Maybe<MissionTaskType>;
  amount?: Maybe<Scalars['RoundedFloat']>;
  vendors?: Maybe<Array<Vendor>>;
  gameTypes?: Maybe<Array<GameType>>;
  multiplier: Scalars['Boolean'];
  rewardAmount?: Maybe<Scalars['RoundedFloat']>;
  rewardPoints?: Maybe<Scalars['Int']>;
  rewardMultiplier: Scalars['Boolean'];
  rewardType?: Maybe<MissionRewardType>;
  turnoverRequirementMultiplier?: Maybe<Scalars['RoundedFloat']>;
};

export type MissionTaskInput = {
  type?: Maybe<MissionTaskType>;
  amount?: Maybe<Scalars['RoundedFloat']>;
  vendors?: Maybe<Array<Scalars['ID']>>;
  gameTypes?: Maybe<Array<GameType>>;
  multiplier?: Maybe<Scalars['Boolean']>;
  rewardAmount?: Maybe<Scalars['RoundedFloat']>;
  rewardMultiplier?: Maybe<Scalars['Boolean']>;
  rewardPoints?: Maybe<Scalars['Int']>;
  rewardType?: Maybe<MissionRewardType>;
  turnoverRequirementMultiplier?: Maybe<Scalars['RoundedFloat']>;
};

export enum MissionTaskType {
  EffectiveBet = 'EFFECTIVE_BET',
  GameTypeEffectiveBet = 'GAME_TYPE_EFFECTIVE_BET',
  Login = 'LOGIN',
  OfflinePaymentMethodDeposit = 'OFFLINE_PAYMENT_METHOD_DEPOSIT',
  OnlinePaymentMethodDeposit = 'ONLINE_PAYMENT_METHOD_DEPOSIT',
  UsdtPaymentMethodDeposit = 'USDT_PAYMENT_METHOD_DEPOSIT',
  VendorEffectiveBet = 'VENDOR_EFFECTIVE_BET',
}

export enum MissionType {
  DailyLogin = 'DAILY_LOGIN',
  DailyMission = 'DAILY_MISSION',
}

export type Mutation = {
  __typename?: 'Mutation';
  createAccountUsageReport: Scalars['ID'];
  updateAccountUsageReport: Scalars['Boolean'];
  deleteAccountUsageReport: Scalars['Boolean'];
  updateMe: Scalars['Boolean'];
  createPasswordReset: Scalars['ID'];
  verifyPasswordReset: Scalars['Boolean'];
  confirmPasswordReset: Scalars['Boolean'];
  updatePassword: Scalars['Boolean'];
  heartbeat: Scalars['Boolean'];
  createAdminGroup: Scalars['ID'];
  updateAdminGroup: Scalars['Boolean'];
  deleteAdminGroup: Scalars['Boolean'];
  createAdmin: Scalars['ID'];
  updateAdmin: Scalars['Boolean'];
  submitAdmin: Scalars['Boolean'];
  deleteAdmin: Scalars['Boolean'];
  createAdminOperator: Scalars['ID'];
  updateAdminOperator: Scalars['Boolean'];
  deleteAdminOperator: Scalars['Boolean'];
  submitAffiliateProgramme: Scalars['Boolean'];
  deleteAffiliateProgramme: Scalars['Boolean'];
  duplicateAffiliateProgramme: Scalars['ID'];
  updateAffiliateRequest: Scalars['Boolean'];
  rejectAffiliateRequest: Scalars['Boolean'];
  startProcessAffiliateRequest: Scalars['Boolean'];
  stopProcessAffiliateRequest: Scalars['Boolean'];
  approveAffiliateRequest: Scalars['Boolean'];
  createAffiliateProgramme: Scalars['ID'];
  updateAffiliateProgramme: Scalars['Boolean'];
  createBalanceTransactionRecordReport: Scalars['Boolean'];
  deleteBankAccount: Scalars['Boolean'];
  createBetHistoryReport: Scalars['ID'];
  updateBetHistoryReport: Scalars['Boolean'];
  deleteBetHistoryReport: Scalars['Boolean'];
  enableAutomaticWithdrawalComplianceCheck: Scalars['Boolean'];
  disableAutomaticWithdrawalComplianceCheck: Scalars['Boolean'];
  updateWithdrawalComplianceCheckSettings: Scalars['Boolean'];
  createCustomFilter: Scalars['Boolean'];
  updateCustomFilter: Scalars['Boolean'];
  deleteCustomFilter: Scalars['Boolean'];
  createDailyCasinoPerformance: Scalars['ID'];
  createDailyDepositReport: Scalars['ID'];
  createDailyPlayerActivity: Scalars['ID'];
  createDailySelfExcludedReport: Scalars['ID'];
  createDailySportsPerformance: Scalars['ID'];
  createDailyTimeOutReport: Scalars['ID'];
  createDailyTransactionReport: Scalars['ID'];
  createDepositLimitReport: Scalars['ID'];
  createDepositProvider: Scalars['ID'];
  updateDepositProvider: Scalars['Boolean'];
  deleteDepositProvider: Scalars['Boolean'];
  createDepositSource: Scalars['ID'];
  updateDepositSource: Scalars['Boolean'];
  deleteDepositSource: Scalars['Boolean'];
  startProcessDeposit?: Maybe<Scalars['Boolean']>;
  stopProcessDeposit?: Maybe<Scalars['Boolean']>;
  approveDeposit?: Maybe<Scalars['Boolean']>;
  rejectDeposit?: Maybe<Scalars['Boolean']>;
  updateDeposit?: Maybe<Scalars['Boolean']>;
  uploadFile: File;
  createFullCustomerDump: Scalars['ID'];
  createGameSessionDuration: Scalars['ID'];
  createGame: Scalars['ID'];
  updateGame: Scalars['Boolean'];
  updateGame_next: Scalars['Boolean'];
  deleteGame: Scalars['Boolean'];
  updateGeoFenceConfig: Scalars['Boolean'];
  deleteHexoPayCreditCard: Scalars['Boolean'];
  togglePaymentMethodClosedLoop: Scalars['Boolean'];
  createJob: Scalars['ID'];
  createCreateManualAdjustmentsJob: Scalars['ID'];
  createManualAdjustment: Scalars['Boolean'];
  createMemberBetRecordReport: Scalars['Boolean'];
  retryCreateMemberBetRecordReport: Scalars['Boolean'];
  bulkUpdateGameRounds: Scalars['Boolean'];
  createMemberLevel: Scalars['ID'];
  updateMemberLevel: Scalars['Boolean'];
  deleteMemberLevel: Scalars['Boolean'];
  duplicateMemberLevel: Scalars['ID'];
  setDefaultMemberLevel: Scalars['Boolean'];
  createMemberLoyaltyProgramme: Scalars['ID'];
  updateMemberLoyaltyProgramme: Scalars['Boolean'];
  submitMemberLoyaltyProgramme: Scalars['Boolean'];
  deleteMemberLoyaltyProgramme: Scalars['Boolean'];
  duplicateMemberLoyaltyProgramme: Scalars['ID'];
  deactivateMemberLoyaltyProgramme: Scalars['Boolean'];
  activateMemberLoyaltyProgramme: Scalars['Boolean'];
  createMemberSummaryReport: Scalars['ID'];
  createMember: Scalars['ID'];
  updateMember: Scalars['Boolean'];
  deleteMember: Scalars['Boolean'];
  createMemberTag: Scalars['ID'];
  updateMemberTag: Scalars['Boolean'];
  deleteMemberTag: Scalars['Boolean'];
  exportMembers: Scalars['Boolean'];
  updateMemberAttributesConfiguration: Scalars['Boolean'];
  createMemberNote: Scalars['ID'];
  updateMemberNote: Scalars['Boolean'];
  deleteMemberNote: Scalars['Boolean'];
  createManualDeposit: Scalars['Boolean'];
  createManualWithdrawal: Scalars['Boolean'];
  resetMemberLoyaltyLevelProgress: Scalars['Boolean'];
  rejectMemberDocument: Scalars['Boolean'];
  approveMemberDocument: Scalars['Boolean'];
  createMemberDocument: Scalars['ID'];
  deleteMemberDocument: Scalars['Boolean'];
  selfExcludeMember: Scalars['Boolean'];
  timeOutMember: Scalars['Boolean'];
  closeMember: Scalars['Boolean'];
  bulkUpdateMemberStatus: Scalars['Boolean'];
  bulkCreateMemberNote: Scalars['Boolean'];
  bulkUpdateMemberTags: Scalars['Boolean'];
  confirmDepositLimitChange: Scalars['Boolean'];
  cancelDepositLimitChange: Scalars['Boolean'];
  createMessage: Scalars['ID'];
  updateMessage: Scalars['Boolean'];
  deleteMessage: Scalars['Boolean'];
  updateAutomatedMessage?: Maybe<Scalars['Boolean']>;
  createMission: Scalars['ID'];
  deleteMission: Scalars['Boolean'];
  duplicateMission: Scalars['ID'];
  submitMission: Scalars['Boolean'];
  updateMission: Scalars['Boolean'];
  adjustBalance: Scalars['Boolean'];
  createMaintenanceSchedule: Scalars['ID'];
  deleteMaintenanceSchedule: Scalars['Boolean'];
  updateMaintenanceSchedule: Scalars['Boolean'];
  updateRevenueShare: Scalars['Boolean'];
  enableVendor: Scalars['Boolean'];
  disableVendor: Scalars['Boolean'];
  createMemberAffiliateModel: Scalars['ID'];
  updateMemberAffiliateModel: Scalars['Boolean'];
  deleteMemberAffiliateModel: Scalars['Boolean'];
  startMemberAffiliateSettlement: Scalars['Boolean'];
  adjustAccountBalance: Scalars['Boolean'];
  updateUniversalVendorSettings: Scalars['Boolean'];
  updateVendorSettings: Scalars['Boolean'];
  updateUniversalGameSettings: Scalars['Boolean'];
  updateGameSettings: Scalars['Boolean'];
  logoutPlayers: Scalars['Boolean'];
  duplicate: Scalars['ID'];
  createNewlyRegisteredMembersReport: Scalars['ID'];
  createOperator: Scalars['ID'];
  updateOperator: Scalars['Boolean'];
  deleteOperator: Scalars['Boolean'];
  sendOtp: Scalars['String'];
  createPaymentMethod: Scalars['String'];
  updatePaymentMethod: Scalars['Boolean'];
  deletePaymentMethod: Scalars['Boolean'];
  createPermissionGroup: Scalars['ID'];
  updatePermissionGroup: Scalars['Boolean'];
  deletePermissionGroup: Scalars['Boolean'];
  duplicatePermissionGroup: Scalars['ID'];
  createPlayerStatusReport: Scalars['ID'];
  upsertPreference: Scalars['Boolean'];
  generatePromoPayoutReport: Scalars['Boolean'];
  createPromo: Scalars['ID'];
  updatePromo: Scalars['Boolean'];
  createRebateGroup: Scalars['ID'];
  updateRebateGroup: Scalars['Boolean'];
  duplicateRebateGroup: Scalars['ID'];
  submitRebateGroup: Scalars['Boolean'];
  deleteRebateGroup: Scalars['Boolean'];
  createRebateGroup_next: Scalars['ID'];
  updateRebateGroup_next: Scalars['Boolean'];
  createBanner: Scalars['ID'];
  updateBanner: Scalars['Boolean'];
  deleteBanner: Scalars['Boolean'];
  duplicateBanner: Scalars['ID'];
  createMarqueeMessage: Scalars['ID'];
  updateMarqueeMessage: Scalars['Boolean'];
  deleteMarqueeMessage: Scalars['Boolean'];
  updateConfig: Scalars['Boolean'];
  updateWithdrawalComplianceCheckConfig: Scalars['Boolean'];
  createGameCategory: Scalars['ID'];
  updateGameCategory: Scalars['Boolean'];
  deleteGameCategory: Scalars['Boolean'];
  createDomain: Scalars['ID'];
  updateDomain: Scalars['Boolean'];
  deleteDomain: Scalars['Boolean'];
  updateProfileSettings: Scalars['Boolean'];
  createSystemNotification: Scalars['ID'];
  createDynamicPage: Scalars['Boolean'];
  createVendorGroup: Scalars['ID'];
  updateVendorGroup: Scalars['Boolean'];
  submitVendorGroup: Scalars['Boolean'];
  deleteVendorGroup: Scalars['Boolean'];
  duplicateVendorGroup: Scalars['ID'];
  createVendorUsageReport: Scalars['ID'];
  updateVendorUsageReport: Scalars['Boolean'];
  deleteVendorUsageReport: Scalars['Boolean'];
  updateVendor: Scalars['Boolean'];
  updateBetHistory: Scalars['Boolean'];
  updateBetHistory_next: Scalars['Boolean'];
  kickPlayers: Scalars['Boolean'];
  createOfflineBankTransferWithdrawalMethod: Scalars['ID'];
  updateOfflineBankTransferWithdrawalMethod: Scalars['Boolean'];
  createAlipayWithdrawalMethod: Scalars['ID'];
  updateAlipayWithdrawalMethod: Scalars['Boolean'];
  createWechatWithdrawalMethod: Scalars['ID'];
  updateWechatWithdrawalMethod: Scalars['Boolean'];
  createOnlineBankTransferWithdrawalMethod: Scalars['ID'];
  updateOnlineBankTransferWithdrawalMethod: Scalars['Boolean'];
  createOnlineAlipayWithdrawalMethod: Scalars['ID'];
  updateOnlineAlipayWithdrawalMethod: Scalars['Boolean'];
  createOnlineWechatWithdrawalMethod: Scalars['ID'];
  updateOnlineWechatWithdrawalMethod: Scalars['Boolean'];
  createHexoPayWithdrawalMethod: Scalars['ID'];
  createCryptoCurrencyWithdrawalMethod: Scalars['ID'];
  updateHexoPayWithdrawalMethod: Scalars['Boolean'];
  deleteWithdrawalMethod: Scalars['Boolean'];
  createAstroPayWalletWithdrawalMethod: Scalars['ID'];
  updateAstroPayWalletWithdrawalMethod: Scalars['Boolean'];
  createWithdrawalProvider: Scalars['ID'];
  updateWithdrawalProvider: Scalars['Boolean'];
  deleteWithdrawalProvider: Scalars['Boolean'];
  startProcessWithdrawal: Scalars['Boolean'];
  stopProcessWithdrawal: Scalars['Boolean'];
  approveWithdrawal: Scalars['Boolean'];
  approveWithdrawalNext: Scalars['Boolean'];
  onHoldWithdrawal: Scalars['Boolean'];
  rejectWithdrawal: Scalars['Boolean'];
  updateWithdrawal: Scalars['Boolean'];
};

export type MutationCreateAccountUsageReportArgs = {
  input: CreateAccountUsageReportInput;
};

export type MutationUpdateAccountUsageReportArgs = {
  id: Scalars['ID'];
  input: UpdateAccountUsageReportInput;
};

export type MutationDeleteAccountUsageReportArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateMeArgs = {
  input: UpdateMeInput;
  password?: Maybe<Scalars['String']>;
};

export type MutationCreatePasswordResetArgs = {
  input: CreatePasswordResetInput;
};

export type MutationVerifyPasswordResetArgs = {
  input: VerifyPasswordResetInput;
};

export type MutationConfirmPasswordResetArgs = {
  input: PasswordResetInput;
};

export type MutationUpdatePasswordArgs = {
  input: UpdatePasswordInput;
};

export type MutationCreateAdminGroupArgs = {
  input: CreateAdminGroupInput;
};

export type MutationUpdateAdminGroupArgs = {
  id: Scalars['ID'];
  input: UpdateAdminGroupInput;
};

export type MutationDeleteAdminGroupArgs = {
  id: Scalars['ID'];
};

export type MutationCreateAdminArgs = {
  input: CreateAdminInput;
};

export type MutationUpdateAdminArgs = {
  id: Scalars['ID'];
  input?: Maybe<UpdateAdminInput>;
  password?: Maybe<Scalars['String']>;
};

export type MutationSubmitAdminArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteAdminArgs = {
  id: Scalars['ID'];
  password: Scalars['String'];
};

export type MutationCreateAdminOperatorArgs = {
  input: CreateAdminOperatorInput;
};

export type MutationUpdateAdminOperatorArgs = {
  id: Scalars['ID'];
  input?: Maybe<UpdateAdminOperatorInput>;
};

export type MutationDeleteAdminOperatorArgs = {
  id: Scalars['ID'];
};

export type MutationSubmitAffiliateProgrammeArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteAffiliateProgrammeArgs = {
  id: Scalars['ID'];
};

export type MutationDuplicateAffiliateProgrammeArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateAffiliateRequestArgs = {
  id: Scalars['ID'];
  input?: Maybe<UpdateAffiliateRequestInput>;
};

export type MutationRejectAffiliateRequestArgs = {
  id: Scalars['ID'];
};

export type MutationStartProcessAffiliateRequestArgs = {
  id: Scalars['ID'];
};

export type MutationStopProcessAffiliateRequestArgs = {
  id: Scalars['ID'];
};

export type MutationApproveAffiliateRequestArgs = {
  id: Scalars['ID'];
};

export type MutationCreateAffiliateProgrammeArgs = {
  input?: Maybe<CreateAffiliateProgrammeInput>;
};

export type MutationUpdateAffiliateProgrammeArgs = {
  id: Scalars['ID'];
  input?: Maybe<UpdateAffiliateProgrammeInput>;
};

export type MutationCreateBalanceTransactionRecordReportArgs = {
  input?: Maybe<CreateBalanceTransactionRecordReportInput>;
};

export type MutationDeleteBankAccountArgs = {
  id: Scalars['ID'];
};

export type MutationCreateBetHistoryReportArgs = {
  input: CreateBetHistoryReportInput;
};

export type MutationUpdateBetHistoryReportArgs = {
  id: Scalars['ID'];
  input: UpdateBetHistoryReportInput;
};

export type MutationDeleteBetHistoryReportArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateWithdrawalComplianceCheckSettingsArgs = {
  input: ComplianceCheckSettingInput;
};

export type MutationCreateCustomFilterArgs = {
  input?: Maybe<CreateCustomFilterInput>;
};

export type MutationUpdateCustomFilterArgs = {
  id: Scalars['ID'];
  input?: Maybe<UpdateCustomFilterInput>;
};

export type MutationDeleteCustomFilterArgs = {
  id: Scalars['ID'];
};

export type MutationCreateDailyCasinoPerformanceArgs = {
  input?: Maybe<CreateDailyCasinoPerformanceInput>;
};

export type MutationCreateDailyDepositReportArgs = {
  input?: Maybe<CreateDailyDepositReportInput>;
};

export type MutationCreateDailyPlayerActivityArgs = {
  input?: Maybe<CreateDailyPlayerActivityInput>;
};

export type MutationCreateDailySelfExcludedReportArgs = {
  input?: Maybe<CreateDailySelfExcludedReportInput>;
};

export type MutationCreateDailySportsPerformanceArgs = {
  input?: Maybe<CreateDailySportsPerformanceInput>;
};

export type MutationCreateDailyTimeOutReportArgs = {
  input?: Maybe<CreateDailyTimeOutReportInput>;
};

export type MutationCreateDailyTransactionReportArgs = {
  input?: Maybe<CreateDailyTransactionReportInput>;
};

export type MutationCreateDepositLimitReportArgs = {
  input?: Maybe<CreateDepositLimitReportInput>;
};

export type MutationCreateDepositProviderArgs = {
  type: DepositProviderType;
  input: CreateDepositProviderInput;
};

export type MutationUpdateDepositProviderArgs = {
  id: Scalars['ID'];
  input: UpdateDepositProviderInput;
};

export type MutationDeleteDepositProviderArgs = {
  id: Scalars['ID'];
};

export type MutationCreateDepositSourceArgs = {
  input: CreateDepositSourceInput;
};

export type MutationUpdateDepositSourceArgs = {
  id: Scalars['ID'];
  input: UpdateDepositSourceInput;
};

export type MutationDeleteDepositSourceArgs = {
  id: Scalars['ID'];
};

export type MutationStartProcessDepositArgs = {
  id: Scalars['ID'];
};

export type MutationStopProcessDepositArgs = {
  id: Scalars['ID'];
};

export type MutationApproveDepositArgs = {
  id: Scalars['ID'];
};

export type MutationRejectDepositArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateDepositArgs = {
  id: Scalars['ID'];
  remarks: Scalars['String'];
};

export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
};

export type MutationCreateFullCustomerDumpArgs = {
  input?: Maybe<CreateFullCustomerDumpInput>;
};

export type MutationCreateGameSessionDurationArgs = {
  input?: Maybe<CreateGameSessionDurationInput>;
};

export type MutationCreateGameArgs = {
  input: CreateGameInput;
};

export type MutationUpdateGameArgs = {
  id: Scalars['ID'];
  input?: Maybe<UpdateGameInput>;
};

export type MutationUpdateGame_NextArgs = {
  id: Scalars['ID'];
  input?: Maybe<UpdateGameInputNext>;
};

export type MutationDeleteGameArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateGeoFenceConfigArgs = {
  input: GeoFenceConfigInput;
};

export type MutationDeleteHexoPayCreditCardArgs = {
  input: DeleteHexoPayCreditCardInput;
};

export type MutationTogglePaymentMethodClosedLoopArgs = {
  input: TogglePaymentMethodClosedLoopInput;
};

export type MutationCreateJobArgs = {
  input: CreateJobInput;
  password?: Maybe<Scalars['String']>;
};

export type MutationCreateCreateManualAdjustmentsJobArgs = {
  input: CreateCreateManualAdjustmentsJobInput;
  password?: Maybe<Scalars['String']>;
};

export type MutationCreateManualAdjustmentArgs = {
  input: CreateManualAdjustmentInput;
  password: Scalars['String'];
};

export type MutationCreateMemberBetRecordReportArgs = {
  input?: Maybe<CreateMemberBetRecordReportInput>;
};

export type MutationBulkUpdateGameRoundsArgs = {
  file: Scalars['ID'];
};

export type MutationCreateMemberLevelArgs = {
  input?: Maybe<CreateMemberLevelInput>;
};

export type MutationUpdateMemberLevelArgs = {
  id: Scalars['ID'];
  input: UpdateMemberLevelInput;
};

export type MutationDeleteMemberLevelArgs = {
  id: Scalars['ID'];
};

export type MutationDuplicateMemberLevelArgs = {
  id: Scalars['ID'];
};

export type MutationSetDefaultMemberLevelArgs = {
  memberLevel: Scalars['ID'];
};

export type MutationCreateMemberLoyaltyProgrammeArgs = {
  input?: Maybe<CreateMemberLoyaltyProgrammeInput>;
};

export type MutationUpdateMemberLoyaltyProgrammeArgs = {
  id: Scalars['ID'];
  input: UpdateMemberLoyaltyProgrammeInput;
};

export type MutationSubmitMemberLoyaltyProgrammeArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteMemberLoyaltyProgrammeArgs = {
  id: Scalars['ID'];
};

export type MutationDuplicateMemberLoyaltyProgrammeArgs = {
  id: Scalars['ID'];
};

export type MutationDeactivateMemberLoyaltyProgrammeArgs = {
  id: Scalars['ID'];
};

export type MutationActivateMemberLoyaltyProgrammeArgs = {
  id: Scalars['ID'];
};

export type MutationCreateMemberSummaryReportArgs = {
  input: CreateMemberSummaryReportInput;
};

export type MutationCreateMemberArgs = {
  input: CreateMemberInput;
};

export type MutationUpdateMemberArgs = {
  id: Scalars['ID'];
  input: UpdateMemberInput;
};

export type MutationDeleteMemberArgs = {
  id: Scalars['ID'];
};

export type MutationCreateMemberTagArgs = {
  input: CreateMemberTagInput;
};

export type MutationUpdateMemberTagArgs = {
  id: Scalars['ID'];
  input: UpdateMemberTagInput;
};

export type MutationDeleteMemberTagArgs = {
  id: Scalars['ID'];
};

export type MutationExportMembersArgs = {
  input?: Maybe<ExportMembersInput>;
};

export type MutationUpdateMemberAttributesConfigurationArgs = {
  input?: Maybe<UpdateMemberAttributesConfigurationInput>;
};

export type MutationCreateMemberNoteArgs = {
  input: CreateMemberNoteInput;
};

export type MutationUpdateMemberNoteArgs = {
  id: Scalars['ID'];
  input: UpdateMemberNoteInput;
};

export type MutationDeleteMemberNoteArgs = {
  id: Scalars['ID'];
};

export type MutationCreateManualDepositArgs = {
  input: CreateManualDepositInput;
  password: Scalars['String'];
};

export type MutationCreateManualWithdrawalArgs = {
  input: CreateManualWithdrawalInput;
  password: Scalars['String'];
};

export type MutationResetMemberLoyaltyLevelProgressArgs = {
  id: Scalars['ID'];
};

export type MutationRejectMemberDocumentArgs = {
  id: Scalars['ID'];
};

export type MutationApproveMemberDocumentArgs = {
  id: Scalars['ID'];
};

export type MutationCreateMemberDocumentArgs = {
  id: Scalars['ID'];
  input: MemberDocumentInput;
};

export type MutationDeleteMemberDocumentArgs = {
  id: Scalars['ID'];
};

export type MutationSelfExcludeMemberArgs = {
  id: Scalars['ID'];
  input: SelfExcludeMemberInput;
};

export type MutationTimeOutMemberArgs = {
  id: Scalars['ID'];
  input: TimeOutMemberInput;
};

export type MutationCloseMemberArgs = {
  id: Scalars['ID'];
  input: CloseMemberInput;
};

export type MutationBulkUpdateMemberStatusArgs = {
  file: Scalars['ID'];
  brandLinkage: Scalars['Boolean'];
};

export type MutationBulkCreateMemberNoteArgs = {
  file: Scalars['ID'];
};

export type MutationBulkUpdateMemberTagsArgs = {
  file: Scalars['ID'];
  action: BulkUpdateMemberTagsAction;
};

export type MutationConfirmDepositLimitChangeArgs = {
  input: ConfirmDepositLimitChangeInput;
};

export type MutationCancelDepositLimitChangeArgs = {
  input: CancelDepositLimitChangeInput;
};

export type MutationCreateMessageArgs = {
  input: CreateMessageInput;
};

export type MutationUpdateMessageArgs = {
  id: Scalars['ID'];
  input: UpdateMessageInput;
};

export type MutationDeleteMessageArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateAutomatedMessageArgs = {
  id: Scalars['ID'];
  input: UpdateAutomatedMessageInput;
};

export type MutationCreateMissionArgs = {
  input: CreateMissionInput;
};

export type MutationDeleteMissionArgs = {
  id: Scalars['ID'];
};

export type MutationDuplicateMissionArgs = {
  id: Scalars['ID'];
};

export type MutationSubmitMissionArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateMissionArgs = {
  id: Scalars['ID'];
  input: UpdateMissionInput;
};

export type MutationAdjustBalanceArgs = {
  account: Scalars['ID'];
  input: AdjustBalanceInput;
};

export type MutationCreateMaintenanceScheduleArgs = {
  input: CreateMaintenanceScheduleInput;
};

export type MutationDeleteMaintenanceScheduleArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateMaintenanceScheduleArgs = {
  id: Scalars['ID'];
  input: UpdateMaintenanceScheduleInput;
};

export type MutationUpdateRevenueShareArgs = {
  account: Scalars['ID'];
  vendor: VendorCode;
  revenueShare: Scalars['Float'];
};

export type MutationEnableVendorArgs = {
  account: Scalars['ID'];
  vendor: VendorCode;
};

export type MutationDisableVendorArgs = {
  account: Scalars['ID'];
  vendor: VendorCode;
};

export type MutationCreateMemberAffiliateModelArgs = {
  input: CreateMemberAffiliateModelInput;
};

export type MutationUpdateMemberAffiliateModelArgs = {
  id: Scalars['ID'];
  input: UpdateMemberAffiliateModelInput;
};

export type MutationDeleteMemberAffiliateModelArgs = {
  id: Scalars['ID'];
};

export type MutationStartMemberAffiliateSettlementArgs = {
  id: Scalars['ID'];
};

export type MutationAdjustAccountBalanceArgs = {
  id: Scalars['ID'];
  input: AdjustAccountBalanceInput;
};

export type MutationUpdateUniversalVendorSettingsArgs = {
  vendor: VendorCode;
  input: UpdateUniversalVendorSettingsInput;
};

export type MutationUpdateVendorSettingsArgs = {
  vendor: VendorCode;
  admin: Scalars['ID'];
  context: VendorSettingsContext;
  input: UpdateVendorSettingsInput;
};

export type MutationUpdateUniversalGameSettingsArgs = {
  game: Scalars['ID'];
};

export type MutationUpdateGameSettingsArgs = {
  game: Scalars['ID'];
  admin: Scalars['ID'];
  context: VendorSettingsContext;
  input: UpdateGameSettingsInput;
};

export type MutationLogoutPlayersArgs = {
  vendor: VendorCode;
};

export type MutationDuplicateArgs = {
  id: Scalars['ID'];
  type: ResourceType;
};

export type MutationCreateNewlyRegisteredMembersReportArgs = {
  input?: Maybe<CreateNewlyRegisteredMembersReportInput>;
};

export type MutationCreateOperatorArgs = {
  input: CreateOperatorInput;
};

export type MutationUpdateOperatorArgs = {
  id: Scalars['ID'];
  input: UpdateOperatorInput;
};

export type MutationDeleteOperatorArgs = {
  id: Scalars['ID'];
};

export type MutationCreatePaymentMethodArgs = {
  type: PaymentMethodType;
  input: CreatePaymentMethodInput;
};

export type MutationUpdatePaymentMethodArgs = {
  id: Scalars['ID'];
  input: UpdatePaymentMethodInput;
};

export type MutationDeletePaymentMethodArgs = {
  id: Scalars['ID'];
};

export type MutationCreatePermissionGroupArgs = {
  input?: Maybe<CreatePermissionGroupInput>;
};

export type MutationUpdatePermissionGroupArgs = {
  id: Scalars['ID'];
  input?: Maybe<UpdatePermissionGroupInput>;
};

export type MutationDeletePermissionGroupArgs = {
  id: Scalars['ID'];
};

export type MutationDuplicatePermissionGroupArgs = {
  id: Scalars['ID'];
};

export type MutationCreatePlayerStatusReportArgs = {
  input?: Maybe<CreatePlayerStatusReportInput>;
};

export type MutationUpsertPreferenceArgs = {
  key: Scalars['String'];
  value: Scalars['JSON'];
};

export type MutationGeneratePromoPayoutReportArgs = {
  input?: Maybe<CreatePromoPayoutReportInput>;
};

export type MutationCreatePromoArgs = {
  input: CreatePromoInput;
};

export type MutationUpdatePromoArgs = {
  id: Scalars['ID'];
  input: UpdatePromoInput;
};

export type MutationCreateRebateGroupArgs = {
  input: CreateRebateGroupInput;
};

export type MutationUpdateRebateGroupArgs = {
  id: Scalars['ID'];
  input: UpdateRebateGroupInput;
};

export type MutationDuplicateRebateGroupArgs = {
  id: Scalars['ID'];
};

export type MutationSubmitRebateGroupArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteRebateGroupArgs = {
  id: Scalars['ID'];
};

export type MutationCreateRebateGroup_NextArgs = {
  input: CreateRebateGroupInput_Next;
};

export type MutationUpdateRebateGroup_NextArgs = {
  id: Scalars['ID'];
  input: UpdateRebateGroupInput_Next;
};

export type MutationCreateBannerArgs = {
  input: CreateBannerInput;
};

export type MutationUpdateBannerArgs = {
  id: Scalars['ID'];
  input: UpdateBannerInput;
};

export type MutationDeleteBannerArgs = {
  id: Scalars['ID'];
};

export type MutationDuplicateBannerArgs = {
  id: Scalars['ID'];
};

export type MutationCreateMarqueeMessageArgs = {
  input: CreateMarqueeMessageInput;
};

export type MutationUpdateMarqueeMessageArgs = {
  id: Scalars['ID'];
  input: UpdateMarqueeMessageInput;
};

export type MutationDeleteMarqueeMessageArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateConfigArgs = {
  input: UpdateConfigInput;
};

export type MutationUpdateWithdrawalComplianceCheckConfigArgs = {
  input: UpdateComplianceCheckConfigInput;
};

export type MutationCreateGameCategoryArgs = {
  input: CreateGameCategoryInput;
};

export type MutationUpdateGameCategoryArgs = {
  id: Scalars['ID'];
  input: UpdateGameCategoryInput;
};

export type MutationDeleteGameCategoryArgs = {
  id: Scalars['ID'];
};

export type MutationCreateDomainArgs = {
  input: CreateDomainInput;
};

export type MutationUpdateDomainArgs = {
  id: Scalars['ID'];
  input: UpdateDomainInput;
};

export type MutationDeleteDomainArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateProfileSettingsArgs = {
  input?: Maybe<Array<ProfileFieldSettingsInput>>;
};

export type MutationCreateSystemNotificationArgs = {
  input?: Maybe<SystemNotificationInput>;
};

export type MutationCreateDynamicPageArgs = {
  input: CreateDynamicPageInput;
};

export type MutationCreateVendorGroupArgs = {
  input: CreateVendorGroupInput;
};

export type MutationUpdateVendorGroupArgs = {
  id: Scalars['ID'];
  input: UpdateVendorGroupInput;
};

export type MutationSubmitVendorGroupArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteVendorGroupArgs = {
  id: Scalars['ID'];
};

export type MutationDuplicateVendorGroupArgs = {
  id: Scalars['ID'];
};

export type MutationCreateVendorUsageReportArgs = {
  input: CreateVendorUsageReportInput;
};

export type MutationUpdateVendorUsageReportArgs = {
  id: Scalars['ID'];
  input: UpdateVendorUsageReportInput;
};

export type MutationDeleteVendorUsageReportArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateVendorArgs = {
  id: Scalars['ID'];
  input: UpdateVendorInput;
};

export type MutationUpdateBetHistoryArgs = {
  vendor: Scalars['ID'];
  startDateTime?: Maybe<Scalars['DateTime']>;
};

export type MutationUpdateBetHistory_NextArgs = {
  vendor: Scalars['ID'];
  cursor?: Maybe<Scalars['String']>;
  context?: Maybe<Scalars['String']>;
};

export type MutationKickPlayersArgs = {
  vendor: Scalars['ID'];
};

export type MutationCreateOfflineBankTransferWithdrawalMethodArgs = {
  input?: Maybe<CreateOfflineBankTransferWithdrawalMethodInput>;
};

export type MutationUpdateOfflineBankTransferWithdrawalMethodArgs = {
  id: Scalars['ID'];
  input?: Maybe<UpdateOfflineBankTransferWithdrawalMethodInput>;
};

export type MutationCreateAlipayWithdrawalMethodArgs = {
  input?: Maybe<CreateAlipayWithdrawalMethodInput>;
};

export type MutationUpdateAlipayWithdrawalMethodArgs = {
  id: Scalars['ID'];
  input?: Maybe<UpdateAlipayWithdrawalMethodInput>;
};

export type MutationCreateWechatWithdrawalMethodArgs = {
  input?: Maybe<CreateWechatWithdrawalMethodInput>;
};

export type MutationUpdateWechatWithdrawalMethodArgs = {
  id: Scalars['ID'];
  input?: Maybe<UpdateWechatWithdrawalMethodInput>;
};

export type MutationCreateOnlineBankTransferWithdrawalMethodArgs = {
  input?: Maybe<CreateOnlineWithdrawalMethodInput>;
};

export type MutationUpdateOnlineBankTransferWithdrawalMethodArgs = {
  id: Scalars['ID'];
  input?: Maybe<UpdateOnlineWithdrawalMethodInput>;
};

export type MutationCreateOnlineAlipayWithdrawalMethodArgs = {
  input?: Maybe<CreateOnlineWithdrawalMethodInput>;
};

export type MutationUpdateOnlineAlipayWithdrawalMethodArgs = {
  id: Scalars['ID'];
  input?: Maybe<UpdateOnlineWithdrawalMethodInput>;
};

export type MutationCreateOnlineWechatWithdrawalMethodArgs = {
  input?: Maybe<CreateOnlineWithdrawalMethodInput>;
};

export type MutationUpdateOnlineWechatWithdrawalMethodArgs = {
  id: Scalars['ID'];
  input?: Maybe<UpdateOnlineWithdrawalMethodInput>;
};

export type MutationCreateHexoPayWithdrawalMethodArgs = {
  input?: Maybe<CreateHexoPayWithdrawalMethodInput>;
};

export type MutationCreateCryptoCurrencyWithdrawalMethodArgs = {
  input?: Maybe<CreateCryptoCurrencyWithdrawalMethodInput>;
};

export type MutationUpdateHexoPayWithdrawalMethodArgs = {
  id: Scalars['ID'];
  input?: Maybe<UpdateHexoPayWithdrawalMethodInput>;
};

export type MutationDeleteWithdrawalMethodArgs = {
  id: Scalars['ID'];
};

export type MutationCreateAstroPayWalletWithdrawalMethodArgs = {
  input?: Maybe<CreateAstroPayWalletWithdrawalMethodInput>;
};

export type MutationUpdateAstroPayWalletWithdrawalMethodArgs = {
  id: Scalars['ID'];
  input?: Maybe<UpdateAstroPayWalletWithdrawalMethodInput>;
};

export type MutationCreateWithdrawalProviderArgs = {
  input: CreateWithdrawalProviderInput;
};

export type MutationUpdateWithdrawalProviderArgs = {
  id: Scalars['ID'];
  input: UpdateWithdrawalProviderInput;
};

export type MutationDeleteWithdrawalProviderArgs = {
  id: Scalars['ID'];
};

export type MutationStartProcessWithdrawalArgs = {
  id: Scalars['ID'];
};

export type MutationStopProcessWithdrawalArgs = {
  id: Scalars['ID'];
};

export type MutationApproveWithdrawalArgs = {
  id: Scalars['ID'];
  input: ApproveWithdrawalInput;
};

export type MutationApproveWithdrawalNextArgs = {
  id: Scalars['ID'];
  input?: Maybe<ApproveWithdrawalNextInput>;
};

export type MutationOnHoldWithdrawalArgs = {
  id: Scalars['ID'];
  reason: Scalars['String'];
};

export type MutationRejectWithdrawalArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateWithdrawalArgs = {
  id: Scalars['ID'];
  input: UpdateWithdrawalInput;
};

export type NetellerDeposit = Deposit & {
  __typename?: 'NetellerDeposit';
  id: Scalars['ID'];
  amount: Scalars['RoundedFloat'];
  account: Member;
  processingTime?: Maybe<Scalars['Int']>;
  paymentMethod?: Maybe<PaymentMethod>;
  status: DepositStatus;
  processor?: Maybe<Account>;
  serialCode: Scalars['String'];
  firstDeposit?: Maybe<Scalars['Boolean']>;
  remarks?: Maybe<Scalars['String']>;
  transactionReference?: Maybe<Scalars['String']>;
  manualAdjustment: Scalars['Boolean'];
  transactionDetails: DepositEventDetails;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeProcessed?: Maybe<Scalars['DateTime']>;
  baseCurrency?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  amountInBaseCurrency?: Maybe<Scalars['RoundedFloat']>;
  isCrypto?: Maybe<Scalars['Boolean']>;
};

export type NetellerPaymentMethod = PaymentMethod & {
  __typename?: 'NetellerPaymentMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  currentAccumulation: Scalars['RoundedFloat'];
  memberLoyaltyLevel?: Maybe<Array<MemberLoyaltyLevel>>;
  excludedMemberLevel?: Maybe<Array<MemberLevel>>;
  minimumDepositAmount: Scalars['RoundedFloat'];
  maximumDepositAmount: Scalars['RoundedFloat'];
  instructionText?: Maybe<Scalars['String']>;
  maximumDepositAmountPerDay: Scalars['RoundedFloat'];
  depositExpiry: Scalars['RoundedFloat'];
  turnoverRequirementMultiplier: Scalars['RoundedFloat'];
  suggestedAmounts: Array<Maybe<Scalars['RoundedFloat']>>;
  transactionFee: Scalars['RoundedFloat'];
  transactionFeeType?: Maybe<TransactionFeeType>;
  allowCustomAmount: Scalars['Boolean'];
  updateable: Scalars['Boolean'];
  provider?: Maybe<PaymentMethodProvider>;
};

export type NetellerWithdrawalMethod = WithdrawalMethod & {
  __typename?: 'NetellerWithdrawalMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  updateable: Scalars['Boolean'];
  transactionFee: Scalars['RoundedFloat'];
  transactionFeeType: TransactionFeeType;
  provider?: Maybe<WithdrawalMethodProvider>;
};

export type NewlyRegisteredMembersReport = {
  __typename?: 'NewlyRegisteredMembersReport';
  id: Scalars['ID'];
  creator: Account;
  report?: Maybe<File>;
  status: NewlyRegisteredMembersReportStatus;
  dateTimeCreated: Scalars['DateTime'];
};

export type NewlyRegisteredMembersReportFilterInput = {
  admin?: Maybe<IdQueryOperatorInput>;
};

export type NewlyRegisteredMembersReportsConnection = {
  __typename?: 'NewlyRegisteredMembersReportsConnection';
  totalCount: Scalars['Int'];
  edges: Array<NewlyRegisteredMembersReportsConnectionEdge>;
  pageInfo: PageInfo;
};

export type NewlyRegisteredMembersReportsConnectionEdge = {
  __typename?: 'NewlyRegisteredMembersReportsConnectionEdge';
  cursor: Scalars['Binary'];
  node: NewlyRegisteredMembersReport;
};

export enum NewlyRegisteredMembersReportStatus {
  Generating = 'GENERATING',
  Generated = 'GENERATED',
}

export type OfflineBankTransferDeposit = Deposit & {
  __typename?: 'OfflineBankTransferDeposit';
  id: Scalars['ID'];
  amount: Scalars['RoundedFloat'];
  account: Member;
  processingTime?: Maybe<Scalars['Int']>;
  paymentMethod?: Maybe<PaymentMethod>;
  status: DepositStatus;
  processor?: Maybe<Account>;
  serialCode: Scalars['String'];
  firstDeposit?: Maybe<Scalars['Boolean']>;
  remarks?: Maybe<Scalars['String']>;
  manualAdjustment: Scalars['Boolean'];
  dateTimeCreated: Scalars['DateTime'];
  dateTimeProcessed?: Maybe<Scalars['DateTime']>;
  depositorBank?: Maybe<Bank>;
  depositorName?: Maybe<Scalars['String']>;
  depositSource?: Maybe<DepositSource>;
  transactionDetails: DepositEventDetails;
  sourceBank?: Maybe<Bank>;
  transactionReference?: Maybe<Scalars['String']>;
  baseCurrency?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  amountInBaseCurrency?: Maybe<Scalars['RoundedFloat']>;
  isCrypto?: Maybe<Scalars['Boolean']>;
};

export type OfflineBankTransferPaymentMethod = PaymentMethod & {
  __typename?: 'OfflineBankTransferPaymentMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  currentAccumulation: Scalars['RoundedFloat'];
  memberLoyaltyLevel?: Maybe<Array<MemberLoyaltyLevel>>;
  excludedMemberLevel?: Maybe<Array<MemberLevel>>;
  minimumDepositAmount: Scalars['RoundedFloat'];
  maximumDepositAmount: Scalars['RoundedFloat'];
  instructionText?: Maybe<Scalars['String']>;
  maximumDepositAmountPerDay: Scalars['RoundedFloat'];
  depositExpiry: Scalars['RoundedFloat'];
  turnoverRequirementMultiplier: Scalars['RoundedFloat'];
  suggestedAmounts: Array<Scalars['RoundedFloat']>;
  transactionFee: Scalars['RoundedFloat'];
  transactionFeeType?: Maybe<TransactionFeeType>;
  allowCustomAmount: Scalars['Boolean'];
  updateable: Scalars['Boolean'];
  bank: Bank;
  accountNumber: Scalars['String'];
  accountName: Scalars['String'];
  branch?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  provider?: Maybe<PaymentMethodProvider>;
};

export type OfflineBankTransferWithdrawalMethod = WithdrawalMethod & {
  __typename?: 'OfflineBankTransferWithdrawalMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  updateable: Scalars['Boolean'];
  transactionFee: Scalars['RoundedFloat'];
  transactionFeeType: TransactionFeeType;
  provider?: Maybe<WithdrawalMethodProvider>;
  bank: Bank;
  accountNumber: Scalars['String'];
  accountName: Scalars['String'];
  branch?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
};

export type OneTimeMaintenanceSchedule = MaintenanceSchedule & {
  __typename?: 'OneTimeMaintenanceSchedule';
  id: Scalars['ID'];
  vendor: Vendor;
  creator: Account;
  gameType: GameType;
  mode: MaintenanceScheduleMode;
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
  dateTimeCreated: Scalars['DateTime'];
};

export type OnlineAlipayDeposit = Deposit & {
  __typename?: 'OnlineAlipayDeposit';
  id: Scalars['ID'];
  amount: Scalars['RoundedFloat'];
  account: Member;
  processingTime?: Maybe<Scalars['Int']>;
  paymentMethod?: Maybe<PaymentMethod>;
  status: DepositStatus;
  processor?: Maybe<Account>;
  serialCode: Scalars['String'];
  firstDeposit?: Maybe<Scalars['Boolean']>;
  remarks?: Maybe<Scalars['String']>;
  transactionReference?: Maybe<Scalars['String']>;
  manualAdjustment: Scalars['Boolean'];
  dateTimeCreated: Scalars['DateTime'];
  dateTimeProcessed?: Maybe<Scalars['DateTime']>;
  transactionDetails: DepositEventDetails;
  depositDetails: DepositDetails;
  baseCurrency?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  amountInBaseCurrency?: Maybe<Scalars['RoundedFloat']>;
  isCrypto?: Maybe<Scalars['Boolean']>;
};

export type OnlineAlipayPaymentMethod = PaymentMethod & {
  __typename?: 'OnlineAlipayPaymentMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  currentAccumulation: Scalars['RoundedFloat'];
  memberLoyaltyLevel?: Maybe<Array<MemberLoyaltyLevel>>;
  excludedMemberLevel?: Maybe<Array<MemberLevel>>;
  minimumDepositAmount: Scalars['RoundedFloat'];
  maximumDepositAmount: Scalars['RoundedFloat'];
  instructionText?: Maybe<Scalars['String']>;
  maximumDepositAmountPerDay: Scalars['RoundedFloat'];
  depositExpiry: Scalars['RoundedFloat'];
  turnoverRequirementMultiplier: Scalars['RoundedFloat'];
  suggestedAmounts: Array<Scalars['RoundedFloat']>;
  transactionFee: Scalars['RoundedFloat'];
  transactionFeeType?: Maybe<TransactionFeeType>;
  allowCustomAmount: Scalars['Boolean'];
  updateable: Scalars['Boolean'];
  depositProvider: DepositProvider;
  paymentChannel: Scalars['String'];
  provider?: Maybe<PaymentMethodProvider>;
};

export type OnlineAlipayWithdrawalMethod = WithdrawalMethod & {
  __typename?: 'OnlineAlipayWithdrawalMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  updateable: Scalars['Boolean'];
  transactionFee: Scalars['RoundedFloat'];
  transactionFeeType: TransactionFeeType;
  provider?: Maybe<WithdrawalMethodProvider>;
  withdrawalProvider: WithdrawalProvider;
  paymentChannel?: Maybe<Scalars['String']>;
};

export type OnlineBankTransferDeposit = Deposit & {
  __typename?: 'OnlineBankTransferDeposit';
  id: Scalars['ID'];
  amount: Scalars['RoundedFloat'];
  account: Member;
  processingTime?: Maybe<Scalars['Int']>;
  paymentMethod?: Maybe<PaymentMethod>;
  status: DepositStatus;
  processor?: Maybe<Account>;
  serialCode: Scalars['String'];
  firstDeposit?: Maybe<Scalars['Boolean']>;
  remarks?: Maybe<Scalars['String']>;
  transactionReference?: Maybe<Scalars['String']>;
  manualAdjustment: Scalars['Boolean'];
  dateTimeCreated: Scalars['DateTime'];
  dateTimeProcessed?: Maybe<Scalars['DateTime']>;
  transactionDetails: DepositEventDetails;
  depositDetails: DepositDetails;
  baseCurrency?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  amountInBaseCurrency?: Maybe<Scalars['RoundedFloat']>;
  isCrypto?: Maybe<Scalars['Boolean']>;
};

export type OnlineBankTransferPaymentMethod = PaymentMethod & {
  __typename?: 'OnlineBankTransferPaymentMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  currentAccumulation: Scalars['RoundedFloat'];
  memberLoyaltyLevel?: Maybe<Array<MemberLoyaltyLevel>>;
  excludedMemberLevel?: Maybe<Array<MemberLevel>>;
  minimumDepositAmount: Scalars['RoundedFloat'];
  maximumDepositAmount: Scalars['RoundedFloat'];
  instructionText?: Maybe<Scalars['String']>;
  maximumDepositAmountPerDay: Scalars['RoundedFloat'];
  depositExpiry: Scalars['RoundedFloat'];
  turnoverRequirementMultiplier: Scalars['RoundedFloat'];
  suggestedAmounts: Array<Scalars['RoundedFloat']>;
  transactionFee: Scalars['RoundedFloat'];
  transactionFeeType?: Maybe<TransactionFeeType>;
  allowCustomAmount: Scalars['Boolean'];
  updateable: Scalars['Boolean'];
  depositProvider: DepositProvider;
  provider?: Maybe<PaymentMethodProvider>;
};

export type OnlineBankTransferWithdrawalMethod = WithdrawalMethod & {
  __typename?: 'OnlineBankTransferWithdrawalMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  updateable: Scalars['Boolean'];
  transactionFee: Scalars['RoundedFloat'];
  transactionFeeType: TransactionFeeType;
  provider?: Maybe<WithdrawalMethodProvider>;
  withdrawalProvider: WithdrawalProvider;
  paymentChannel?: Maybe<Scalars['String']>;
};

export type OnlineWechatDeposit = Deposit & {
  __typename?: 'OnlineWechatDeposit';
  id: Scalars['ID'];
  amount: Scalars['RoundedFloat'];
  account: Member;
  processingTime?: Maybe<Scalars['Int']>;
  paymentMethod?: Maybe<PaymentMethod>;
  status: DepositStatus;
  processor?: Maybe<Account>;
  serialCode: Scalars['String'];
  firstDeposit?: Maybe<Scalars['Boolean']>;
  remarks?: Maybe<Scalars['String']>;
  transactionReference?: Maybe<Scalars['String']>;
  manualAdjustment: Scalars['Boolean'];
  dateTimeCreated: Scalars['DateTime'];
  dateTimeProcessed?: Maybe<Scalars['DateTime']>;
  transactionDetails: DepositEventDetails;
  depositDetails: DepositDetails;
  baseCurrency?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  amountInBaseCurrency?: Maybe<Scalars['RoundedFloat']>;
  isCrypto?: Maybe<Scalars['Boolean']>;
};

export type OnlineWechatPaymentMethod = PaymentMethod & {
  __typename?: 'OnlineWechatPaymentMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  currentAccumulation: Scalars['RoundedFloat'];
  memberLoyaltyLevel?: Maybe<Array<MemberLoyaltyLevel>>;
  excludedMemberLevel?: Maybe<Array<MemberLevel>>;
  minimumDepositAmount: Scalars['RoundedFloat'];
  maximumDepositAmount: Scalars['RoundedFloat'];
  instructionText?: Maybe<Scalars['String']>;
  maximumDepositAmountPerDay: Scalars['RoundedFloat'];
  depositExpiry: Scalars['RoundedFloat'];
  turnoverRequirementMultiplier: Scalars['RoundedFloat'];
  suggestedAmounts: Array<Scalars['RoundedFloat']>;
  transactionFee: Scalars['RoundedFloat'];
  transactionFeeType?: Maybe<TransactionFeeType>;
  allowCustomAmount: Scalars['Boolean'];
  updateable: Scalars['Boolean'];
  depositProvider: DepositProvider;
  paymentChannel: Scalars['String'];
  provider?: Maybe<PaymentMethodProvider>;
};

export type OnlineWechatWithdrawalMethod = WithdrawalMethod & {
  __typename?: 'OnlineWechatWithdrawalMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  updateable: Scalars['Boolean'];
  transactionFee: Scalars['RoundedFloat'];
  transactionFeeType: TransactionFeeType;
  provider?: Maybe<WithdrawalMethodProvider>;
  withdrawalProvider: WithdrawalProvider;
  paymentChannel?: Maybe<Scalars['String']>;
};

export type Operator = Account & {
  __typename?: 'Operator';
  id: Scalars['ID'];
  role: AccountRole;
  username: Scalars['String'];
  currency: Scalars['Currency'];
  language: Scalars['Language'];
  timezone: Scalars['Timezone'];
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  realName?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  address?: Maybe<Address>;
  countryCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  wechatId?: Maybe<Scalars['String']>;
  qqId?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  mobileNumberVerified?: Maybe<Scalars['Boolean']>;
  wechatIdVerified?: Maybe<Scalars['Boolean']>;
  qqIdVerified?: Maybe<Scalars['Boolean']>;
  lastLoginDateTime: Scalars['DateTime'];
  registrationDateTime: Scalars['DateTime'];
  dateTimeCreated: Scalars['DateTime'];
  dateTimeUpdated: Scalars['DateTime'];
  notes?: Maybe<Scalars['String']>;
  permissionGroups: Array<PermissionGroup>;
  permissions: Array<Scalars['String']>;
  status: OperatorStatus;
  otpValidationEnabled: Scalars['Boolean'];
  linkedAdmins?: Maybe<Array<Maybe<LinkedAdmin>>>;
  company?: Maybe<Scalars['String']>;
  admin?: Maybe<Scalars['ID']>;
  forceChangePassword?: Maybe<Scalars['Boolean']>;
  multiCurrencyEnabled?: Maybe<Scalars['Boolean']>;
};

export type OperatorConnection = {
  __typename?: 'OperatorConnection';
  totalCount: Scalars['Int'];
  edges: Array<OperatorsConnectionEdge>;
  pageInfo: PageInfo;
};

export type OperatorsConnectionEdge = {
  __typename?: 'OperatorsConnectionEdge';
  cursor: Scalars['Binary'];
  node: Operator;
};

export type OperatorsFilterInput = {
  id?: Maybe<IdQueryOperatorInput>;
  username?: Maybe<StringQueryOperatorInput>;
  registrationDateTime?: Maybe<DateTimeQueryOperatorInput>;
  lastLoginDateTime?: Maybe<DateTimeQueryOperatorInput>;
  permissionGroups?: Maybe<IdArrayQueryOperatorInput>;
  status?: Maybe<OperatorStatusQueryOperatorInput>;
};

export enum OperatorStatus {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
}

export type OperatorStatusQueryOperatorInput = {
  eq?: Maybe<OperatorStatus>;
  ne?: Maybe<OperatorStatus>;
  in?: Maybe<Array<OperatorStatus>>;
  nin?: Maybe<Array<OperatorStatus>>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['Binary']>;
  hasNextPage: Scalars['Boolean'];
};

export type PasswordResetInput = {
  verificationCode: Scalars['String'];
  newPassword: Scalars['String'];
};

export type PayByAstroPayDeposit = Deposit & {
  __typename?: 'PayByAstroPayDeposit';
  id: Scalars['ID'];
  amount: Scalars['RoundedFloat'];
  account: Member;
  processingTime?: Maybe<Scalars['Int']>;
  paymentMethod?: Maybe<PaymentMethod>;
  status: DepositStatus;
  processor?: Maybe<Account>;
  serialCode: Scalars['String'];
  firstDeposit?: Maybe<Scalars['Boolean']>;
  remarks?: Maybe<Scalars['String']>;
  transactionReference?: Maybe<Scalars['String']>;
  manualAdjustment: Scalars['Boolean'];
  transactionDetails: DepositEventDetails;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeProcessed?: Maybe<Scalars['DateTime']>;
  baseCurrency?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  amountInBaseCurrency?: Maybe<Scalars['RoundedFloat']>;
  isCrypto?: Maybe<Scalars['Boolean']>;
};

export type PayByAstroPayPaymentMethod = PaymentMethod & {
  __typename?: 'PayByAstroPayPaymentMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
  minimumDepositAmount: Scalars['RoundedFloat'];
  maximumDepositAmount: Scalars['RoundedFloat'];
  instructionText?: Maybe<Scalars['String']>;
  turnoverRequirementMultiplier: Scalars['RoundedFloat'];
  suggestedAmounts: Array<Maybe<Scalars['RoundedFloat']>>;
  provider?: Maybe<PaymentMethodProvider>;
  transactionFee: Scalars['RoundedFloat'];
  transactionFeeType?: Maybe<TransactionFeeType>;
  updateable: Scalars['Boolean'];
  depositExpiry: Scalars['RoundedFloat'];
  allowCustomAmount: Scalars['Boolean'];
  excludedMemberLevel?: Maybe<Array<MemberLevel>>;
  memberLoyaltyLevel?: Maybe<Array<MemberLoyaltyLevel>>;
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  currentAccumulation: Scalars['RoundedFloat'];
  maximumDepositAmountPerDay: Scalars['RoundedFloat'];
};

export type PaymentGateway = {
  id: Scalars['ID'];
  name: Scalars['String'];
  paymentChannels: Array<Scalars['String']>;
};

export enum PaymentGatewayCredentialField {
  MerchantId = 'MERCHANT_ID',
  SafeCode = 'SAFE_CODE',
  PrivateKey = 'PRIVATE_KEY',
  PublicKey = 'PUBLIC_KEY',
  MerchantAppId = 'MERCHANT_APP_ID',
  MerchantName = 'MERCHANT_NAME',
}

export enum PaymentGatewayIntegrationType {
  Diorpay2 = 'DIORPAY2',
  Midpay = 'MIDPAY',
  MidpayDirect = 'MIDPAY_DIRECT',
}

export enum PaymentGatewayMethodType {
  Deposit = 'DEPOSIT',
  Withdraw = 'WITHDRAW',
}

export type PaymentGatewayResult =
  | DiorPay2PaymentGateway
  | MidPayDirectPaymentGateway;

export type PaymentGatewaysInput = {
  gateway?: Maybe<Scalars['String']>;
  integrationType: PaymentGatewayIntegrationType;
  methodType: PaymentGatewayMethodType;
  apiKey?: Maybe<Scalars['String']>;
};

export type PaymentMethod = {
  id: Scalars['ID'];
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  currentAccumulation: Scalars['RoundedFloat'];
  memberLoyaltyLevel?: Maybe<Array<MemberLoyaltyLevel>>;
  excludedMemberLevel?: Maybe<Array<MemberLevel>>;
  minimumDepositAmount: Scalars['RoundedFloat'];
  maximumDepositAmount: Scalars['RoundedFloat'];
  instructionText?: Maybe<Scalars['String']>;
  maximumDepositAmountPerDay: Scalars['RoundedFloat'];
  depositExpiry: Scalars['RoundedFloat'];
  turnoverRequirementMultiplier: Scalars['RoundedFloat'];
  suggestedAmounts: Array<Maybe<Scalars['RoundedFloat']>>;
  transactionFee: Scalars['RoundedFloat'];
  transactionFeeType?: Maybe<TransactionFeeType>;
  allowCustomAmount: Scalars['Boolean'];
  provider?: Maybe<PaymentMethodProvider>;
  updateable: Scalars['Boolean'];
};

export type PaymentMethodConfiguration = {
  __typename?: 'PaymentMethodConfiguration';
  enable: Scalars['Boolean'];
  active: Scalars['Int'];
  rank: Scalars['Int'];
  type: PaymentMethodType;
};

export type PaymentMethodConfigurationInput = {
  enable: Scalars['Boolean'];
  active: Scalars['Int'];
  rank: Scalars['Int'];
  type: PaymentMethodType;
};

export enum PaymentMethodProvider {
  Alphapo = 'ALPHAPO',
  TripleA = 'TRIPLE_A',
}

export type PaymentMethodProviderQueryOperatorInput = {
  eq?: Maybe<PaymentMethodProvider>;
  ne?: Maybe<PaymentMethodProvider>;
  in?: Maybe<Array<PaymentMethodProvider>>;
  nin?: Maybe<Array<PaymentMethodProvider>>;
};

export type PaymentMethodRotation = {
  __typename?: 'PaymentMethodRotation';
  successRateWeight: Scalars['Int'];
  elapseMinuteWeight: Scalars['Int'];
};

export type PaymentMethodRotationInput = {
  successRateWeight: Scalars['Int'];
  elapseMinuteWeight: Scalars['Int'];
};

export type PaymentMethodsConnection = {
  __typename?: 'PaymentMethodsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<PaymentMethodsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type PaymentMethodsConnectionEdge = {
  __typename?: 'PaymentMethodsConnectionEdge';
  cursor: Scalars['Binary'];
  node: PaymentMethod;
};

export type PaymentMethodsFilterInput = {
  name?: Maybe<StringQueryOperatorInput>;
  minimumDepositAmount?: Maybe<FloatQueryOperatorInput>;
  maximumDepositAmount?: Maybe<FloatQueryOperatorInput>;
  account?: Maybe<IdQueryOperatorInput>;
  currentAccumulation?: Maybe<FloatQueryOperatorInput>;
  bank?: Maybe<BankQueryOperatorInput>;
  nickname?: Maybe<StringQueryOperatorInput>;
  excludedMemberLevel?: Maybe<IdQueryOperatorInput>;
  paymentMethodType?: Maybe<PaymentMethodTypeQueryOperatorInput>;
  enabled?: Maybe<BooleanQueryOperatorInput>;
  depositProvider?: Maybe<IdQueryOperatorInput>;
};

export enum PaymentMethodType {
  OfflineBankTransfer = 'OFFLINE_BANK_TRANSFER',
  Alipay = 'ALIPAY',
  Wechat = 'WECHAT',
  OnlineBankTransfer = 'ONLINE_BANK_TRANSFER',
  OnlineAlipay = 'ONLINE_ALIPAY',
  OnlineWechat = 'ONLINE_WECHAT',
  Unionpay = 'UNIONPAY',
  ManualAdjustment = 'MANUAL_ADJUSTMENT',
  Cryptocurrency = 'CRYPTOCURRENCY',
  Alphapo = 'ALPHAPO',
  Hexopay = 'HEXOPAY',
  Neteller = 'NETELLER',
  Coinify = 'COINIFY',
  Skrill = 'SKRILL',
  Wonkapay = 'WONKAPAY',
  Astropaywallet = 'ASTROPAYWALLET',
  Paybyastropay = 'PAYBYASTROPAY',
}

export type PaymentMethodTypeQueryOperatorInput = {
  eq?: Maybe<PaymentMethodType>;
  ne?: Maybe<PaymentMethodType>;
  in?: Maybe<Array<PaymentMethodType>>;
  nin?: Maybe<Array<PaymentMethodType>>;
};

export type PayoutManualAdjustment = ManualAdjustment & {
  __typename?: 'PayoutManualAdjustment';
  id: Scalars['ID'];
  type: ManualAdjustmentType;
  account: Account;
  amount: Scalars['RoundedFloat'];
  remarks: Scalars['String'];
  dateTimeCreated: Scalars['DateTime'];
};

export enum Period {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
}

export type PeriodicRebateGroup = RebateGroup & {
  __typename?: 'PeriodicRebateGroup';
  id: Scalars['ID'];
  serialCode: Scalars['String'];
  creator: Account;
  status: RebateGroupStatus;
  enabled: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  members?: Maybe<Array<Member>>;
  assignedMembers?: Maybe<Array<Member>>;
  qualifyingMemberLoyaltyLevels?: Maybe<Array<Maybe<MemberLoyaltyLevel>>>;
  excludedMemberLevels?: Maybe<Array<Maybe<MemberLevel>>>;
  validityDateTimeRange?: Maybe<DateTimeRange>;
  automaticPayout?: Maybe<Scalars['Boolean']>;
  turnoverRequirementMultiplier?: Maybe<Scalars['RoundedFloat']>;
  claimOffsetDuration?: Maybe<Scalars['Duration']>;
  claimExpiryDuration?: Maybe<Scalars['Duration']>;
  payoutExpiryDuration?: Maybe<Scalars['Duration']>;
  minimumPayout?: Maybe<Scalars['RoundedFloat']>;
  maximumPayout?: Maybe<Scalars['RoundedFloat']>;
  type?: Maybe<RebateGroupType>;
  payoutSettlementPeriod?: Maybe<RebatePayoutSettlementPeriod>;
  payoutSettlementDayOfWeek?: Maybe<Scalars['DayOfWeek']>;
  payoutSettlementDayOfMonth?: Maybe<Scalars['DayOfMonth']>;
  payoutSettlementTime?: Maybe<Scalars['Time']>;
  payoutSettlementMonth?: Maybe<Scalars['Int']>;
  levels?: Maybe<Array<RebateLevel>>;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeSubmitted?: Maybe<Scalars['DateTime']>;
  dateTimeUpdated: Scalars['DateTime'];
  qualifiedMembersCount?: Maybe<Scalars['Int']>;
  participatingMembers: MembersConnection;
  qualifyingMembersCount: Scalars['Int'];
  totalPayout: Scalars['RoundedFloat'];
  totalPendingPayout: Scalars['RoundedFloat'];
  payoutClaimantsCount: Scalars['Int'];
  totalClaimedPayout: Scalars['RoundedFloat'];
  totalExpiredPayout: Scalars['RoundedFloat'];
  totalClawback: Scalars['RoundedFloat'];
};

export type PeriodicRebateGroupParticipatingMembersArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<MembersFilterInput>;
};

export type PermissionGroup = {
  __typename?: 'PermissionGroup';
  id: Scalars['ID'];
  serialCode: Scalars['String'];
  name: Scalars['String'];
  permissions?: Maybe<Array<Scalars['String']>>;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeUpdated: Scalars['DateTime'];
};

export type PermissionGroupsConnection = {
  __typename?: 'PermissionGroupsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<PermissionGroupsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type PermissionGroupsConnectionEdge = {
  __typename?: 'PermissionGroupsConnectionEdge';
  cursor: Scalars['Binary'];
  node: PermissionGroup;
};

export type PermissionGroupsFilterInput = {
  id?: Maybe<IdQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  serialCode?: Maybe<StringQueryOperatorInput>;
  permissions?: Maybe<StringQueryOperatorInput>;
  dateTimeUpdated?: Maybe<DateTimeQueryOperatorInput>;
};

export type Place = {
  __typename?: 'Place';
  id: Scalars['ID'];
  displayName: Scalars['String'];
  type: PlaceType;
  lat?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['String']>;
};

export type PlaceDisplayNameArgs = {
  language?: Maybe<Scalars['Language']>;
};

export type PlacesConnection = {
  __typename?: 'PlacesConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<PlacesConnectionEdge>>;
  pageInfo: PageInfo;
};

export type PlacesConnectionEdge = {
  __typename?: 'PlacesConnectionEdge';
  node: Place;
  cursor: Scalars['Binary'];
};

export type PlacesFilterInput = {
  id?: Maybe<IdQueryOperatorInput>;
  displayName?: Maybe<StringQueryOperatorInput>;
  type?: Maybe<PlaceTypeQueryOperatorInput>;
};

export enum PlaceType {
  City = 'CITY',
  Country = 'COUNTRY',
  State = 'STATE',
}

export type PlaceTypeQueryOperatorInput = {
  eq?: Maybe<PlaceType>;
  ne?: Maybe<PlaceType>;
  in?: Maybe<Array<PlaceType>>;
  nin?: Maybe<Array<PlaceType>>;
};

export enum Platform {
  Mobile = 'MOBILE',
  Desktop = 'DESKTOP',
  Horizontal = 'HORIZONTAL',
}

export type PlatformQueryOperatorInput = {
  eq?: Maybe<Platform>;
  ne?: Maybe<Platform>;
  in?: Maybe<Array<Platform>>;
  nin?: Maybe<Array<Platform>>;
};

export type PlayerStatusReport = {
  __typename?: 'PlayerStatusReport';
  id: Scalars['ID'];
  creator: Account;
  report?: Maybe<File>;
  status: PlayerStatusReportStatus;
  dateTimeCreated: Scalars['DateTime'];
};

export type PlayerStatusReportFilterInput = {
  admin?: Maybe<IdQueryOperatorInput>;
};

export type PlayerStatusReportsConnection = {
  __typename?: 'PlayerStatusReportsConnection';
  totalCount: Scalars['Int'];
  edges: Array<PlayerStatusReportsConnectionEdge>;
  pageInfo: PageInfo;
};

export type PlayerStatusReportsConnectionEdge = {
  __typename?: 'PlayerStatusReportsConnectionEdge';
  cursor: Scalars['Binary'];
  node: PlayerStatusReport;
};

export enum PlayerStatusReportStatus {
  Generating = 'GENERATING',
  Generated = 'GENERATED',
}

export type ProfileFieldSettings = {
  __typename?: 'ProfileFieldSettings';
  fieldLabel: Scalars['String'];
  type: ProfileFieldType;
  verificationEnabled: Scalars['Boolean'];
};

export type ProfileFieldSettingsInput = {
  fieldLabel: Scalars['String'];
  type: ProfileFieldType;
  verificationEnabled: Scalars['Boolean'];
};

export enum ProfileFieldType {
  Optional = 'OPTIONAL',
  Mandatory = 'MANDATORY',
}

export type Promo = {
  id: Scalars['ID'];
  creator: Account;
  status: PromoStatus;
  name: Scalars['String'];
  type: PromoType;
  event: PromoEvent;
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
  dateTimeCreated: Scalars['DateTime'];
  platform: Platform;
  includedMemberTags: Array<Maybe<MemberTag>>;
  excludedMemberTags: Array<Maybe<MemberTag>>;
  claimType: PromoClaimType;
  awardPreRequisite: PromoAwardPreRequisiteType;
  turnoverRequirementMultiplier: Scalars['RoundedFloat'];
  wagerContributions: Array<Maybe<PromoWagerContribution>>;
  autoCancel: Scalars['Boolean'];
  maintainingBalance?: Maybe<Scalars['RoundedFloat']>;
  totalAmountAwarded?: Maybe<Scalars['RoundedFloat']>;
};

export enum PromoAwardPreRequisiteType {
  IncludingNonVerifiedMembers = 'INCLUDING_NON_VERIFIED_MEMBERS',
  VerifiedMembersOnly = 'VERIFIED_MEMBERS_ONLY',
}

export enum PromoAwardType {
  Flat = 'FLAT',
  Percentage = 'PERCENTAGE',
}

export enum PromoClaimType {
  Manual = 'MANUAL',
  Auto = 'AUTO',
}

export enum PromoEvent {
  SignUp = 'SIGN_UP',
  LogIn = 'LOG_IN',
  FirstDeposit = 'FIRST_DEPOSIT',
  RecurringDeposit = 'RECURRING_DEPOSIT',
}

export type PromoPayout = {
  __typename?: 'PromoPayout';
  id: Scalars['ID'];
  promo: Promo;
  member: Member;
  admin: Admin;
  deposits?: Maybe<Array<Maybe<Deposit>>>;
  status: PromoPayoutStatus;
  name: Scalars['String'];
  type: PromoType;
  event: PromoEvent;
  dateTimeCreated: Scalars['DateTime'];
  platform: Platform;
  claimType: PromoClaimType;
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
  turnoverRequirementMultiplier: Scalars['RoundedFloat'];
  wagerContributions: Array<Maybe<PromoWagerContribution>>;
  autoCancel: Scalars['Boolean'];
  maintainingBalance?: Maybe<Scalars['RoundedFloat']>;
  currency?: Maybe<Scalars['Currency']>;
  amount: Scalars['RoundedFloat'];
  depositAmount?: Maybe<Scalars['RoundedFloat']>;
  amountClaimed?: Maybe<Scalars['RoundedFloat']>;
  maxMemberCount?: Maybe<Scalars['Int']>;
};

export type PromoPayoutManualAdjustment = ManualAdjustment & {
  __typename?: 'PromoPayoutManualAdjustment';
  id: Scalars['ID'];
  type: ManualAdjustmentType;
  account: Account;
  amount: Scalars['RoundedFloat'];
  remarks: Scalars['String'];
  dateTimeCreated: Scalars['DateTime'];
  promo: Promo;
  playerRemarks: Scalars['String'];
  automaticPayoutClaiming: Scalars['Boolean'];
  turnoverRequirementMultiplier: Scalars['RoundedFloat'];
  claimOffsetDuration?: Maybe<Scalars['Duration']>;
  claimExpiryDuration?: Maybe<Scalars['Duration']>;
};

export type PromoPayoutReport = {
  __typename?: 'PromoPayoutReport';
  creator: Account;
  report?: Maybe<File>;
  status: ReportStatus;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeGenerated?: Maybe<Scalars['DateTime']>;
  totalRows?: Maybe<Scalars['Int']>;
  processedRows?: Maybe<Scalars['Int']>;
};

export type PromoPayoutReportFilterInput = {
  admin?: Maybe<IdQueryOperatorInput>;
};

export type PromoPayoutReportsConnection = {
  __typename?: 'PromoPayoutReportsConnection';
  totalCount: Scalars['Int'];
  edges: Array<PromoPayoutReportsConnectionEdge>;
  pageInfo: PageInfo;
};

export type PromoPayoutReportsConnectionEdge = {
  __typename?: 'PromoPayoutReportsConnectionEdge';
  cursor: Scalars['Binary'];
  node: PromoPayoutReport;
};

export enum PromoPayoutReportStatus {
  Generating = 'GENERATING',
  Generated = 'GENERATED',
  Failed = 'FAILED',
}

export type PromoPayoutsConnection = {
  __typename?: 'PromoPayoutsConnection';
  totalCount: Scalars['Int'];
  edges: Array<PromoPayoutsConnectionEdge>;
  pageInfo: PageInfo;
};

export type PromoPayoutsConnectionEdge = {
  __typename?: 'PromoPayoutsConnectionEdge';
  cursor: Scalars['Binary'];
  node: PromoPayout;
};

export type PromoPayoutsFilterInput = {
  name?: Maybe<StringQueryOperatorInput>;
  member?: Maybe<IdQueryOperatorInput>;
  type?: Maybe<PromoTypeQueryOperatorInput>;
  startDateTime?: Maybe<DateTimeQueryOperatorInput>;
  endDateTime?: Maybe<DateTimeQueryOperatorInput>;
};

export enum PromoPayoutStatus {
  Active = 'ACTIVE',
  Claimed = 'CLAIMED',
  Canceled = 'CANCELED',
}

export type PromosConnection = {
  __typename?: 'PromosConnection';
  totalCount: Scalars['Int'];
  edges: Array<PromosConnectionEdge>;
  pageInfo: PageInfo;
};

export type PromosConnectionEdge = {
  __typename?: 'PromosConnectionEdge';
  cursor: Scalars['Binary'];
  node: Promo;
};

export type PromosFilterInput = {
  name?: Maybe<StringQueryOperatorInput>;
  type?: Maybe<PromoTypeQueryOperatorInput>;
  startDateTime?: Maybe<DateTimeQueryOperatorInput>;
  endDateTime?: Maybe<DateTimeQueryOperatorInput>;
};

export enum PromoStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum PromoType {
  SignUp = 'SIGN_UP',
  Deposit = 'DEPOSIT',
}

export type PromoTypeQueryOperatorInput = {
  eq?: Maybe<PromoType>;
  ne?: Maybe<PromoType>;
  in?: Maybe<Array<PromoType>>;
  nin?: Maybe<Array<PromoType>>;
};

export type PromoWagerContribution = {
  __typename?: 'PromoWagerContribution';
  vendor: Vendor;
  gameType: GameType;
  contribution: Scalars['RoundedFloat'];
  rank: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  accountUsageReports: AccountUsageReportsConnection;
  accountUsageReport?: Maybe<AccountUsageReport>;
  me?: Maybe<Account>;
  /** @deprecated use countryCode */
  getCountryCode?: Maybe<Scalars['String']>;
  loqateAddresses: Array<Maybe<LoqateAddress>>;
  address?: Maybe<Address>;
  adminMetrics?: Maybe<AdminMetrics>;
  admin?: Maybe<Admin>;
  admins: AdminsConnection;
  adminGroup?: Maybe<AdminGroup>;
  adminGroups: AdminsConnection;
  adminOperator?: Maybe<AdminOperator>;
  adminOperators: AdminOperatorsConnection;
  affiliateRequest?: Maybe<AffiliateRequest>;
  affiliateRequests: AffiliateRequestsConnection;
  affiliateProgramme?: Maybe<AffiliateProgramme>;
  affiliateProgrammes: AffiliateProgrammesConnection;
  balanceTransactionRecordReport?: Maybe<Report>;
  balanceTransactionRecord?: Maybe<BalanceTransactionRecord>;
  balanceTransactionRecords: BalanceTransactionRecordsConnection;
  bankAccounts: BankAccountsConnection;
  betHistoryReports: BetHistoryReportsConnection;
  betHistoryReport?: Maybe<BetHistoryReport>;
  validPassword: Scalars['Boolean'];
  ping: Scalars['Boolean'];
  customFilter?: Maybe<CustomFilter>;
  customFilters: CustomFiltersConnection;
  dailyCasinoPerformances: DailyCasinoPerformancesConnection;
  dailyDepositReports: DailyDepositReportsConnection;
  dailyOperationReport: DailyOperationReportItemsConnection;
  dailyPlayerActivities: DailyPlayerActivitiesConnection;
  dailySelfExcludedReports: DailySelfExcludedReportsConnection;
  dailySportsPerformances: DailySportsPerformancesConnection;
  dailyTimeOutReports: DailyTimeOutReportsConnection;
  dailyTransactionReports: DailyTransactionReportsConnection;
  totalEffectiveBetChart: ChartData;
  totalEffectiveBet: Scalars['RoundedFloat'];
  totalDepositAmountChart: ChartData;
  totalDepositAmount: Scalars['RoundedFloat'];
  totalWithdrawalAmountChart: ChartData;
  totalWithdrawalAmount: Scalars['RoundedFloat'];
  totalWinlossChart: ChartData;
  totalWinloss: Scalars['RoundedFloat'];
  totalNetProfitChart: ChartData;
  totalNetProfit: Scalars['RoundedFloat'];
  totalDepositsCount: Scalars['Int'];
  totalDepositorsCount: Scalars['Int'];
  totalWithdrawalsCount: Scalars['Int'];
  totalWithdrawersCount: Scalars['Int'];
  totalBetsCount: Scalars['Int'];
  totalBetsCountChart: ChartData;
  averageEffectiveBetChart: ChartData;
  averageEffectiveBet: Scalars['RoundedFloat'];
  totalMemberRegistrationsCountChart: ChartData;
  totalMemberRegistrationsCount: Scalars['Int'];
  totalFirstDepositorsCountChart: ChartData;
  totalFirstDepositorsCount: Scalars['Int'];
  totalOnlineMembersCountChart: ChartData;
  totalOnlineMembersCount: Scalars['Int'];
  depositLimitReports: DepositLimitReportsConnection;
  depositProviders: DepositProvidersConnection;
  depositProvider?: Maybe<DepositProvider>;
  depositSources: DepositSourceConnection;
  deposit: Deposit;
  deposits: DepositsConnection;
  emailAuditTrail: EmailAuditTrailConnection;
  file?: Maybe<File>;
  fullCustomerDumps: FullCustomerDumpsConnection;
  gameSessionDurations: GameSessionDurationsConnection;
  gameSessions: GameSessionsConnection;
  game?: Maybe<Game>;
  games: GamesConnection;
  gamstopAuditTrail: GamstopAuditTrailConnection;
  gbgAuditTrail: GbgAuditTrailConnection;
  geoFenceConfig?: Maybe<GeoFenceConfig>;
  places: PlacesConnection;
  place?: Maybe<Place>;
  hexoPayCreditCards: HexoPayCreditCardsConnection;
  job?: Maybe<Job>;
  jobs: JobsConnection;
  loqateAuditTrail: LoqateAuditTrailConnection;
  /**
   * ### Warning
   *
   * > **NOTE**: this query is not available yet
   */
  maintenanceSchedules: MaintenanceSchedulesConnection;
  memberAccessHistoryReport: MemberAccessHistoryReportItemsConnection;
  memberIpAddressReport: MemberIpAddressReportItemsConnection;
  ipAddressSummaryReport: IpAddressSummaryReportItemsConnection;
  memberAccessSummaryReport: MemberAccessSummaryReportItemsConnection;
  memberBalanceLogs: MemberBalanceLogsConnection;
  memberBalanceUnitRecords: MemberBalanceUnitRecordsConnection;
  memberBetRecordReport?: Maybe<MemberBetRecordReport>;
  memberBetRecord?: Maybe<MemberBetRecord>;
  memberBetRecordSummary?: Maybe<MemberBetRecordSummary>;
  memberBetRecords: MemberBetRecordsConnection;
  bulkUpdateGameRoundRecord?: Maybe<BulkUpdateGameRoundRecord>;
  memberInteractionLogs: MemberInteractionLogConnection;
  memberLevel?: Maybe<MemberLevel>;
  memberLevels: MemberLevelsConnection;
  memberLoyaltyProgramme?: Maybe<MemberLoyaltyProgramme>;
  memberLoyaltyProgrammes: MemberLoyaltyProgrammesConnection;
  memberLoyaltyLevel?: Maybe<MemberLoyaltyLevel>;
  memberLoyaltyLevels: MemberLoyaltyLevelsConnection;
  /** @deprecated No longer used, and soon to be removed */
  memberLoyaltyPayouts: MemberLoyaltyPayoutsConnection;
  memberSummaryReport?: Maybe<MemberSummaryReport>;
  memberSummaryReports: MemberSummaryReportsConnection;
  member?: Maybe<Member>;
  members: MembersConnection;
  memberTags: MemberTagsConnection;
  memberTag?: Maybe<MemberTag>;
  memberDocument?: Maybe<MemberDocument>;
  memberDocuments: MemberDocumentsConnection;
  usernameExists: Scalars['Boolean'];
  memberBulkStatusUpdateRecord?: Maybe<MemberBulkStatusUpdateRecord>;
  memberBulkTagsUpdateRecord?: Maybe<MemberBulkUpdateJobRecord>;
  memberBulkNoteCreateRecord?: Maybe<MemberBulkNoteCreateRecord>;
  memberExportRecord?: Maybe<MemberExportRecord>;
  message?: Maybe<Message>;
  messages: MessagesConnection;
  automatedMessage?: Maybe<AutomatedMessage>;
  automatedMessages: AutomatedMessagesConnection;
  mission: Mission;
  missions: MissionsConnection;
  newlyRegisteredMembersReports: NewlyRegisteredMembersReportsConnection;
  operator?: Maybe<Operator>;
  operators: OperatorConnection;
  otpValidationRequired: Scalars['Boolean'];
  paymentGateways: Array<PaymentGatewayResult>;
  paymentMethods: PaymentMethodsConnection;
  paymentMethod?: Maybe<PaymentMethod>;
  availablePaymentMethods: PaymentMethodsConnection;
  permissions?: Maybe<Array<Scalars['String']>>;
  permissionGroup?: Maybe<PermissionGroup>;
  permissionGroups: PermissionGroupsConnection;
  playerStatusReports: PlayerStatusReportsConnection;
  preferences?: Maybe<Scalars['JSON']>;
  preference?: Maybe<Scalars['JSON']>;
  promoPayout?: Maybe<PromoPayout>;
  promoPayouts: PromoPayoutsConnection;
  promoPayoutReport?: Maybe<PromoPayoutReport>;
  promo?: Maybe<Promo>;
  promos: PromosConnection;
  /** @deprecated No longer used, and soon to be removed */
  agentLevel?: Maybe<AgentLevel>;
  /** @deprecated No longer used, and soon to be removed */
  agentLevels: Array<AgentLevel>;
  /** @deprecated No longer used, and soon to be removed */
  isAgentLevelNameAvailable: Scalars['Boolean'];
  memberVendorBalance?: Maybe<Scalars['RoundedFloat']>;
  revenueShares: RevenueSharesConnection;
  /** @deprecated No longer used, and soon to be removed */
  memberAffiliateModel?: Maybe<MemberAffiliateModel>;
  /** @deprecated No longer used, and soon to be removed */
  memberAffiliateModels: Array<MemberAffiliateModel>;
  /** @deprecated No longer used, and soon to be removed */
  memberAffiliateSettlements: MemberAffiliateSettlementsConnection;
  /** @deprecated No longer used, and soon to be removed */
  memberAffiliateCommissions: MemberAffiliateCommissionsConnection;
  /** @deprecated No longer used, and soon to be removed */
  memberAffiliates: MemberAffiliatesConnection;
  /** @deprecated Not available */
  statisticalReport: StatisticalReportItemsConnection;
  /** @deprecated No longer used, and soon to be removed */
  universalVendorSettings: UniversalVendorSettings;
  /** @deprecated No longer used, and soon to be removed */
  universalVendorSettingsItems: UniversalVendorSettingsItemsConnection;
  /** @deprecated No longer used, and soon to be removed */
  vendorSettings: VendorSettings;
  vendorSettingsItems: VendorSettingsItemsConnection;
  universalGameSettings: UniversalGameSettings;
  universalGameSettingsItems: UniversalGameSettingsItemsConnection;
  gameSettings: GameSettings;
  gameSettingsItems: GameSettingsItemsConnection;
  realityCheckAuditTrail: RealityCheckAuditTrailConnection;
  /** @deprecated To be removed. */
  rebatePayoutRecord?: Maybe<RebatePayoutRecord>;
  rebatePayoutRecords: RebatePayoutRecordsConnection;
  /** @deprecated To be removed. */
  memberRebatePayoutRecords: MemberRebatePayoutRecordsConnection;
  rebateGroup?: Maybe<RebateGroup>;
  rebateGroups: RebateGroupsConnection;
  /** @deprecated No longer used, and soon to be removed */
  rebatePayout?: Maybe<RebatePayout>;
  /** @deprecated No longer used, and soon to be removed */
  rebatePayouts: RebatePayoutsConnection;
  registrationAuditTrail: RegistrationAuditTrailConnection;
  banner?: Maybe<Banner>;
  banners: BannersConnection;
  marqueeMessage?: Maybe<MarqueeMessage>;
  marqueeMessages: MarqueeMessagesConnection;
  config: Config;
  gameCategory?: Maybe<GameCategory>;
  gameCategories: Array<GameCategory>;
  domain?: Maybe<Domain>;
  domains: DomainsConnection;
  profileSettings?: Maybe<Array<ProfileFieldSettings>>;
  systemNotifications: SystemNotifacationConnection;
  systemNotification?: Maybe<SystemNotification>;
  withdrawalComplianceCheckConfig: WithdrawalComplianceCheckConfig;
  countryCode: CountryCode;
  countryCodes: Array<CountryCode>;
  countries: CountryConnection;
  currencies: CurrencyMetadataConnection;
  dynamicPages: DynamicPageConnection;
  dynamicPage?: Maybe<DynamicPage>;
  availableCurrencies?: Maybe<Array<AvailableCurrency>>;
  vendorGameReport: VendorGameReportItemsConnection;
  vendorGameChart: VendorGameChart;
  vendorGameMetrics: Scalars['RoundedFloat'];
  vendorGroup: VendorGroup;
  vendorGroups: VendorGroupsConnection;
  vendorUsageReports: VendorUsageReportsConnection;
  vendorUsageReport?: Maybe<VendorUsageReport>;
  vendor?: Maybe<Vendor>;
  vendors: VendorsConnection;
  withdrawalMethods: WithdrawalMethodsConnection;
  withdrawalMethod?: Maybe<WithdrawalMethod>;
  withdrawalProviders: WithdrawalProvidersConnection;
  withdrawalProvider?: Maybe<WithdrawalProvider>;
  withdrawal?: Maybe<Withdrawal>;
  withdrawals: WithdrawalsConnection;
};

export type QueryAccountUsageReportsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<AccountUsageReportFilterInput>;
  sort?: Maybe<SortInput>;
};

export type QueryAccountUsageReportArgs = {
  id: Scalars['ID'];
};

export type QueryGetCountryCodeArgs = {
  country?: Maybe<Scalars['String']>;
};

export type QueryLoqateAddressesArgs = {
  filter: LoqateAddressesFilterInput;
};

export type QueryAddressArgs = {
  id: Scalars['ID'];
};

export type QueryAdminArgs = {
  id: Scalars['ID'];
};

export type QueryAdminsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<AdminsFilterInput>;
};

export type QueryAdminGroupArgs = {
  id: Scalars['ID'];
};

export type QueryAdminGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
};

export type QueryAdminOperatorArgs = {
  id: Scalars['ID'];
};

export type QueryAdminOperatorsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<AdminOperatorsFilterInput>;
};

export type QueryAffiliateRequestArgs = {
  id: Scalars['ID'];
};

export type QueryAffiliateRequestsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<AffiliateRequestsFilterInput>;
};

export type QueryAffiliateProgrammeArgs = {
  id: Scalars['ID'];
};

export type QueryAffiliateProgrammesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<AffiliateProgrammesFilterInput>;
};

export type QueryBalanceTransactionRecordArgs = {
  id: Scalars['ID'];
};

export type QueryBalanceTransactionRecordsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<BalanceTransactionRecordsFilterInput>;
  sort?: Maybe<SortInput>;
};

export type QueryBankAccountsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<BankAccountsFilterInput>;
};

export type QueryBetHistoryReportsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<BetHistoryReportFilterInput>;
  sort?: Maybe<SortInput>;
};

export type QueryBetHistoryReportArgs = {
  id: Scalars['ID'];
};

export type QueryValidPasswordArgs = {
  password: Scalars['String'];
};

export type QueryCustomFilterArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type QueryCustomFiltersArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<CustomFiltersFilterInput>;
};

export type QueryDailyCasinoPerformancesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<DailyCasinoPerformanceFilterInput>;
  sort?: Maybe<SortInput>;
};

export type QueryDailyDepositReportsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<DailyDepositReportFilterInput>;
  sort?: Maybe<SortInput>;
};

export type QueryDailyOperationReportArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<DailyOperationReportFilterInput>;
  sort?: Maybe<SortInput>;
};

export type QueryDailyPlayerActivitiesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<DailyPlayerActivityFilterInput>;
  sort?: Maybe<SortInput>;
};

export type QueryDailySelfExcludedReportsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<DailySelfExcludedReportFilterInput>;
  sort?: Maybe<SortInput>;
};

export type QueryDailySportsPerformancesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<DailySportsPerformanceFilterInput>;
  sort?: Maybe<SortInput>;
};

export type QueryDailyTimeOutReportsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<DailyTimeOutReportFilterInput>;
  sort?: Maybe<SortInput>;
};

export type QueryDailyTransactionReportsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<DailyTransactionReportFilterInput>;
  sort?: Maybe<SortInput>;
};

export type QueryTotalEffectiveBetChartArgs = {
  input: TotalEffectiveBetChartInput;
};

export type QueryTotalEffectiveBetArgs = {
  input: TotalEffectiveBetInput;
};

export type QueryTotalDepositAmountChartArgs = {
  input: TotalDepositAmountChartInput;
};

export type QueryTotalDepositAmountArgs = {
  input: TotalDepositAmountInput;
};

export type QueryTotalWithdrawalAmountChartArgs = {
  input: TotalWithdrawalAmountChartInput;
};

export type QueryTotalWithdrawalAmountArgs = {
  input: TotalWithdrawalAmountInput;
};

export type QueryTotalWinlossChartArgs = {
  input: TotalWinlossChartInput;
};

export type QueryTotalWinlossArgs = {
  input: TotalWinlossInput;
};

export type QueryTotalNetProfitChartArgs = {
  input: TotalNetProfitChartInput;
};

export type QueryTotalNetProfitArgs = {
  input: TotalNetProfitInput;
};

export type QueryTotalDepositsCountArgs = {
  input: TotalDepositsCount;
};

export type QueryTotalDepositorsCountArgs = {
  input: TotalDepositorsCount;
};

export type QueryTotalWithdrawalsCountArgs = {
  input: TotalWithdrawalsCount;
};

export type QueryTotalWithdrawersCountArgs = {
  input: TotalWithdrawersCount;
};

export type QueryTotalBetsCountArgs = {
  input: TotalBetsCountInput;
};

export type QueryTotalBetsCountChartArgs = {
  input: TotalBetsCountChartInput;
};

export type QueryAverageEffectiveBetChartArgs = {
  input: AverageEffectiveBetChartInput;
};

export type QueryAverageEffectiveBetArgs = {
  input: AverageEffectiveBetInput;
};

export type QueryTotalMemberRegistrationsCountChartArgs = {
  input: TotalMemberRegistrationsCountChartInput;
};

export type QueryTotalMemberRegistrationsCountArgs = {
  input: TotalMemberRegistrationsCountInput;
};

export type QueryTotalFirstDepositorsCountChartArgs = {
  input: TotalFirstDepositorsCountChartInput;
};

export type QueryTotalFirstDepositorsCountArgs = {
  input: TotalFirstDepositorsCountInput;
};

export type QueryTotalOnlineMembersCountChartArgs = {
  input: TotalOnlineMembersCountChartInput;
};

export type QueryTotalOnlineMembersCountArgs = {
  input: TotalOnlineMembersCountnput;
};

export type QueryDepositLimitReportsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<DepositLimitReportFilterInput>;
  sort?: Maybe<SortInput>;
};

export type QueryDepositProvidersArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<DepositProvidersFilterInput>;
};

export type QueryDepositProviderArgs = {
  id: Scalars['ID'];
};

export type QueryDepositSourcesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
};

export type QueryDepositArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type QueryDepositsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<DepositsFilterInput>;
};

export type QueryEmailAuditTrailArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<EmailAuditTrailFilterInput>;
  sort?: Maybe<SortInput>;
};

export type QueryFileArgs = {
  id: Scalars['ID'];
};

export type QueryFullCustomerDumpsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<FullCustomerDumpFilterInput>;
  sort?: Maybe<SortInput>;
};

export type QueryGameSessionDurationsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<GameSessionDurationFilterInput>;
  sort?: Maybe<SortInput>;
};

export type QueryGameSessionsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<GameSessionFilterInput>;
  sort?: Maybe<SortInput>;
};

export type QueryGameArgs = {
  id: Scalars['ID'];
};

export type QueryGamesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<GamesFilterInput>;
};

export type QueryGamstopAuditTrailArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
};

export type QueryGbgAuditTrailArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
};

export type QueryGeoFenceConfigArgs = {
  type: GeoFenceConfigType;
};

export type QueryPlacesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter: PlacesFilterInput;
};

export type QueryPlaceArgs = {
  id: Scalars['ID'];
};

export type QueryHexoPayCreditCardsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<HexoPayCreditCardsFilterInput>;
};

export type QueryJobArgs = {
  id: Scalars['ID'];
};

export type QueryJobsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<JobsFilterInput>;
};

export type QueryLoqateAuditTrailArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  sort?: Maybe<SortInput>;
};

export type QueryMaintenanceSchedulesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};

export type QueryMemberAccessHistoryReportArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<MemberAccessHistoryReportItemsFilterInput>;
};

export type QueryMemberIpAddressReportArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<MemberIpAddressReportItemsFilterInput>;
};

export type QueryIpAddressSummaryReportArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<IpAddressSummaryReportItemsFilterInput>;
};

export type QueryMemberAccessSummaryReportArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<MemberAccessSummaryReportItemsFilterInput>;
};

export type QueryMemberBalanceLogsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  filter?: Maybe<MemberBalanceLogsFilterInput>;
};

export type QueryMemberBalanceUnitRecordsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter: MemberBalanceUnitRecordFilterInput;
  sort?: Maybe<SortInput>;
};

export type QueryMemberBetRecordArgs = {
  id: Scalars['ID'];
};

export type QueryMemberBetRecordSummaryArgs = {
  filter?: Maybe<MemberBetRecordsFilterInput>;
};

export type QueryMemberBetRecordsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<MemberBetRecordsFilterInput>;
  sort?: Maybe<SortInput>;
};

export type QueryMemberInteractionLogsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<MemberInteractionLogFilterInput>;
};

export type QueryMemberLevelArgs = {
  id: Scalars['ID'];
};

export type QueryMemberLevelsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<MemberLevelFilterInput>;
};

export type QueryMemberLoyaltyProgrammeArgs = {
  id: Scalars['ID'];
};

export type QueryMemberLoyaltyProgrammesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<MemberLoyaltyProgrammesFilterInput>;
};

export type QueryMemberLoyaltyLevelArgs = {
  id: Scalars['ID'];
};

export type QueryMemberLoyaltyLevelsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<MemberLoyaltyLevelsFilterInput>;
};

export type QueryMemberLoyaltyPayoutsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<MemberLoyaltyPayoutsFilterInput>;
};

export type QueryMemberSummaryReportArgs = {
  id: Scalars['ID'];
};

export type QueryMemberSummaryReportsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  sort?: Maybe<SortInput>;
};

export type QueryMemberArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type QueryMembersArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<MembersFilterInput>;
};

export type QueryMemberTagsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<MemberTagsFilterInput>;
};

export type QueryMemberTagArgs = {
  id: Scalars['ID'];
};

export type QueryMemberDocumentArgs = {
  id: Scalars['ID'];
};

export type QueryMemberDocumentsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<MemberDocumentsFilterInput>;
};

export type QueryUsernameExistsArgs = {
  username: Scalars['String'];
};

export type QueryMessageArgs = {
  id: Scalars['ID'];
};

export type QueryMessagesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<MessagesFilterInput>;
};

export type QueryAutomatedMessageArgs = {
  id: Scalars['ID'];
};

export type QueryAutomatedMessagesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<AutomatedMessageFilterInput>;
};

export type QueryMissionArgs = {
  id: Scalars['ID'];
};

export type QueryMissionsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<MissionsFilterInput>;
};

export type QueryNewlyRegisteredMembersReportsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<NewlyRegisteredMembersReportFilterInput>;
  sort?: Maybe<SortInput>;
};

export type QueryOperatorArgs = {
  id: Scalars['ID'];
};

export type QueryOperatorsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<OperatorsFilterInput>;
};

export type QueryPaymentGatewaysArgs = {
  input: PaymentGatewaysInput;
};

export type QueryPaymentMethodsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<PaymentMethodsFilterInput>;
};

export type QueryPaymentMethodArgs = {
  id: Scalars['ID'];
};

export type QueryAvailablePaymentMethodsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter: AvailablePaymentMethodsFilterInput;
};

export type QueryPermissionGroupArgs = {
  id: Scalars['ID'];
};

export type QueryPermissionGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<PermissionGroupsFilterInput>;
};

export type QueryPlayerStatusReportsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<PlayerStatusReportFilterInput>;
  sort?: Maybe<SortInput>;
};

export type QueryPreferenceArgs = {
  key: Scalars['String'];
};

export type QueryPromoPayoutArgs = {
  id: Scalars['ID'];
};

export type QueryPromoPayoutsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<PromoPayoutsFilterInput>;
};

export type QueryPromoArgs = {
  id: Scalars['ID'];
};

export type QueryPromosArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<PromosFilterInput>;
};

export type QueryAgentLevelArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type QueryIsAgentLevelNameAvailableArgs = {
  name: Scalars['String'];
};

export type QueryMemberVendorBalanceArgs = {
  account: Scalars['ID'];
  vendor: TransferVendorCode;
};

export type QueryRevenueSharesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  filter: RevenueSharesFilterInput;
};

export type QueryMemberAffiliateModelArgs = {
  id: Scalars['ID'];
};

export type QueryMemberAffiliateSettlementsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  filter?: Maybe<MemberAffiliateSettlementsFilterInput>;
};

export type QueryMemberAffiliateCommissionsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  filter?: Maybe<MemberAffiliateCommissionsFilterInput>;
};

export type QueryMemberAffiliatesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  filter?: Maybe<MemberAffiliatesFilterInput>;
};

export type QueryStatisticalReportArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<StatisticalReportFilterInput>;
};

export type QueryUniversalVendorSettingsArgs = {
  vendor: VendorCode;
};

export type QueryUniversalVendorSettingsItemsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<UniversalVendorSettingsItemsFilterInput>;
};

export type QueryVendorSettingsArgs = {
  vendor: VendorCode;
  admin?: Maybe<Scalars['ID']>;
  context: VendorSettingsContext;
};

export type QueryVendorSettingsItemsArgs = {
  admin: Scalars['ID'];
  context: VendorSettingsContext;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<VendorSettingsItemsFilterInput>;
};

export type QueryUniversalGameSettingsArgs = {
  game: Scalars['ID'];
};

export type QueryUniversalGameSettingsItemsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<UniversalGameSettingsItemsFilterInput>;
};

export type QueryGameSettingsArgs = {
  game: Scalars['ID'];
  admin: Scalars['ID'];
  context: VendorSettingsContext;
};

export type QueryGameSettingsItemsArgs = {
  admin: Scalars['ID'];
  context: GameSettingsContext;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<GameSettingsItemsFilterInput>;
};

export type QueryRealityCheckAuditTrailArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  sort?: Maybe<SortInput>;
};

export type QueryRebatePayoutRecordArgs = {
  id: Scalars['ID'];
};

export type QueryRebatePayoutRecordsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<RebatePayoutRecordsFilterInput>;
  sort?: Maybe<SortInput>;
};

export type QueryMemberRebatePayoutRecordsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<MemberRebatePayoutRecordsFilterInput>;
  sort?: Maybe<SortInput>;
};

export type QueryRebateGroupArgs = {
  id: Scalars['ID'];
};

export type QueryRebateGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<RebateGroupsFilterInput>;
  sort?: Maybe<SortInput>;
};

export type QueryRebatePayoutArgs = {
  id: Scalars['ID'];
};

export type QueryRebatePayoutsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<RebatePayoutFilterInput>;
};

export type QueryRegistrationAuditTrailArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
};

export type QueryBannerArgs = {
  id: Scalars['ID'];
};

export type QueryBannersArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<BannersFilterInput>;
};

export type QueryMarqueeMessageArgs = {
  id: Scalars['ID'];
};

export type QueryMarqueeMessagesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<MarqueeMessagesFilterInput>;
};

export type QueryGameCategoryArgs = {
  id: Scalars['ID'];
};

export type QueryGameCategoriesArgs = {
  filter?: Maybe<GameCategoriesFilterInput>;
};

export type QueryDomainArgs = {
  id: Scalars['ID'];
};

export type QueryDomainsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<DomainFilterInput>;
};

export type QuerySystemNotificationsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<SystemNotificationFilterInput>;
};

export type QuerySystemNotificationArgs = {
  id: Scalars['ID'];
};

export type QueryCountryCodeArgs = {
  country: Scalars['String'];
};

export type QueryCountriesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<CountryFilterInput>;
};

export type QueryCurrenciesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<CurrencyMetadataFilterInput>;
};

export type QueryDynamicPagesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<DynamicPageFilterInput>;
};

export type QueryDynamicPageArgs = {
  id: Scalars['ID'];
};

export type QueryVendorGameReportArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<VendorGameReportFilterInput>;
  sort?: Maybe<SortInput>;
};

export type QueryVendorGameChartArgs = {
  input: VendorGameChartInput;
};

export type QueryVendorGameMetricsArgs = {
  input: VendorGameMetricsInput;
};

export type QueryVendorGroupArgs = {
  id: Scalars['ID'];
};

export type QueryVendorGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<VendorGroupsFilterInput>;
};

export type QueryVendorUsageReportsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<VendorUsageReportFilterInput>;
  sort?: Maybe<SortInput>;
};

export type QueryVendorUsageReportArgs = {
  id: Scalars['ID'];
};

export type QueryVendorArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type QueryVendorsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<VendorsFilterInput>;
};

export type QueryWithdrawalMethodsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<WithdrawalMethodFilterInput>;
};

export type QueryWithdrawalMethodArgs = {
  id: Scalars['ID'];
};

export type QueryWithdrawalProvidersArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<WithdrawalProviderFilterInput>;
};

export type QueryWithdrawalProviderArgs = {
  id: Scalars['ID'];
};

export type QueryWithdrawalArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type QueryWithdrawalsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<WithdrawalsFilterInput>;
};

export type RealityCheckAuditTrail = {
  __typename?: 'RealityCheckAuditTrail';
  account: Account;
  actionType: RealityCheckAuditTrailActionType;
  dateTimeCreated: Scalars['DateTime'];
};

export enum RealityCheckAuditTrailActionType {
  Off = 'OFF',
  Mins_15 = 'MINS_15',
  Mins_30 = 'MINS_30',
  Mins_45 = 'MINS_45',
  Mins_60 = 'MINS_60',
}

export type RealityCheckAuditTrailConnection = {
  __typename?: 'RealityCheckAuditTrailConnection';
  totalCount: Scalars['Int'];
  edges: Array<RealityCheckAuditTrailConnectionEdge>;
  pageInfo: PageInfo;
};

export type RealityCheckAuditTrailConnectionEdge = {
  __typename?: 'RealityCheckAuditTrailConnectionEdge';
  cursor: Scalars['Binary'];
  node: RealityCheckAuditTrail;
};

export type RebateGamePercentage = {
  __typename?: 'RebateGamePercentage';
  game: Game;
  percentage: Scalars['RoundedFloat'];
};

export type RebateGamePercentageInput = {
  game: Scalars['ID'];
  percentage: Scalars['Float'];
};

export type RebateGroup = {
  id: Scalars['ID'];
  serialCode: Scalars['String'];
  creator: Account;
  status: RebateGroupStatus;
  enabled: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  members?: Maybe<Array<Member>>;
  assignedMembers?: Maybe<Array<Member>>;
  qualifyingMemberLoyaltyLevels?: Maybe<Array<Maybe<MemberLoyaltyLevel>>>;
  excludedMemberLevels?: Maybe<Array<Maybe<MemberLevel>>>;
  validityDateTimeRange?: Maybe<DateTimeRange>;
  automaticPayout?: Maybe<Scalars['Boolean']>;
  turnoverRequirementMultiplier?: Maybe<Scalars['RoundedFloat']>;
  claimOffsetDuration?: Maybe<Scalars['Duration']>;
  claimExpiryDuration?: Maybe<Scalars['Duration']>;
  payoutExpiryDuration?: Maybe<Scalars['Duration']>;
  minimumPayout?: Maybe<Scalars['RoundedFloat']>;
  maximumPayout?: Maybe<Scalars['RoundedFloat']>;
  type?: Maybe<RebateGroupType>;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeSubmitted?: Maybe<Scalars['DateTime']>;
  dateTimeUpdated: Scalars['DateTime'];
  qualifiedMembersCount?: Maybe<Scalars['Int']>;
  participatingMembers: MembersConnection;
  qualifyingMembersCount: Scalars['Int'];
  totalPayout: Scalars['RoundedFloat'];
  totalPendingPayout: Scalars['RoundedFloat'];
  payoutClaimantsCount: Scalars['Int'];
  totalClaimedPayout: Scalars['RoundedFloat'];
  totalExpiredPayout: Scalars['RoundedFloat'];
  totalClawback: Scalars['RoundedFloat'];
};

export type RebateGroupParticipatingMembersArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Binary']>;
  filter?: Maybe<MembersFilterInput>;
};

export type RebateGroupsConnection = {
  __typename?: 'RebateGroupsConnection';
  totalCount: Scalars['Int'];
  edges: Array<RebateGroupsConnectionEdge>;
  pageInfo: PageInfo;
};

export type RebateGroupsConnectionEdge = {
  __typename?: 'RebateGroupsConnectionEdge';
  cursor: Scalars['Binary'];
  node: RebateGroup;
};

export type RebateGroupsFilterInput = {
  id?: Maybe<IdQueryOperatorInput>;
  serialCode?: Maybe<StringQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  type?: Maybe<RebateGroupTypeQueryOperatorInput>;
  status?: Maybe<RebateGroupStatusQueryOperatorInput>;
  qualifyingMemberLoyaltyLevels?: Maybe<IdArrayQueryOperatorInput>;
  excludedMemberLevels?: Maybe<IdArrayQueryOperatorInput>;
  validityDateTimeRange?: Maybe<DateTimeRangeInput>;
  dateTimeCreated?: Maybe<DateTimeQueryOperatorInput>;
  dateTimeSubmitted?: Maybe<DateTimeQueryOperatorInput>;
  brand?: Maybe<StringQueryOperatorInput>;
  brandId?: Maybe<StringQueryOperatorInput>;
  platformId?: Maybe<StringQueryOperatorInput>;
};

export enum RebateGroupStatus {
  Draft = 'DRAFT',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type RebateGroupStatusQueryOperatorInput = {
  eq?: Maybe<RebateGroupStatus>;
  ne?: Maybe<RebateGroupStatus>;
  in?: Maybe<Array<RebateGroupStatus>>;
  nin?: Maybe<Array<RebateGroupStatus>>;
};

export enum RebateGroupType {
  Live = 'LIVE',
  Periodic = 'PERIODIC',
}

export type RebateGroupTypeQueryOperatorInput = {
  eq?: Maybe<RebateGroupType>;
  ne?: Maybe<RebateGroupType>;
  in?: Maybe<Array<RebateGroupType>>;
  nin?: Maybe<Array<RebateGroupType>>;
};

export type RebateLevel = {
  __typename?: 'RebateLevel';
  minimum: Scalars['RoundedFloat'];
  percentages: Array<RebatePercentage>;
};

export type RebateLevelInput = {
  minimum: Scalars['Float'];
  percentages: Array<RebatePercentageInput>;
};

export type RebatePayout = {
  __typename?: 'RebatePayout';
  id: Scalars['ID'];
  creator: Account;
  rebateGroup: RebateGroup;
  member: Member;
  amount: Scalars['RoundedFloat'];
  payoutExpiryDuration?: Maybe<Scalars['Duration']>;
  turnoverRequirement: Scalars['RoundedFloat'];
  status: RebatePayoutStatus;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeClaimed: Scalars['DateTime'];
};

export type RebatePayoutFilterInput = {
  id?: Maybe<IdQueryOperatorInput>;
  member?: Maybe<IdQueryOperatorInput>;
  dateTimeCreated?: Maybe<DateTimeQueryOperatorInput>;
};

export type RebatePayoutRecord = {
  __typename?: 'RebatePayoutRecord';
  id: Scalars['ID'];
  serialCode: Scalars['ID'];
  name: Scalars['String'];
  type: RebateGroupType;
  validityDateTimeRange?: Maybe<DateTimeRange>;
  qualifyingMemberLoyaltyLevels?: Maybe<Array<Maybe<MemberLoyaltyLevel>>>;
  automaticPayout?: Maybe<Scalars['Boolean']>;
  payoutSettlementPeriod?: Maybe<RebatePayoutSettlementPeriod>;
  payoutSettlementDayOfWeek?: Maybe<Scalars['DayOfWeek']>;
  payoutSettlementDayOfMonth?: Maybe<Scalars['DayOfMonth']>;
  payoutSettlementMonth?: Maybe<Scalars['Month']>;
  payoutSettlementTime?: Maybe<Scalars['Time']>;
  totalParticipatingMembersCount: Scalars['Int'];
  totalQualifyingMembersCount: Scalars['Int'];
  totalAmountPayout: Scalars['RoundedFloat'];
  totalAmountToBePayout: Scalars['RoundedFloat'];
  totalUserClaimedCount: Scalars['Int'];
  totalAmountClaimed: Scalars['RoundedFloat'];
  totalAmountUnClaimed: Scalars['RoundedFloat'];
  totalAmountExpired: Scalars['RoundedFloat'];
  totalClawback: Scalars['RoundedFloat'];
  dateTimeCreated: Scalars['DateTime'];
};

export type RebatePayoutRecordsConnection = {
  __typename?: 'RebatePayoutRecordsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<RebatePayoutRecordsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type RebatePayoutRecordsConnectionEdge = {
  __typename?: 'RebatePayoutRecordsConnectionEdge';
  cursor: Scalars['Binary'];
  node: RebatePayoutRecord;
};

export type RebatePayoutRecordsFilterInput = {
  id?: Maybe<IdQueryOperatorInput>;
  serialCode?: Maybe<StringQueryOperatorInput>;
  type?: Maybe<RebateGroupTypeQueryOperatorInput>;
  payoutSettlementPeriod?: Maybe<RebatePayoutSettlementPeriodOperatorInput>;
  qualifyingMemberLoyaltylevels?: Maybe<IdQueryOperatorInput>;
  totalParticipatingMembersCount?: Maybe<IntQueryOperatorInput>;
  totalQualifyingMembersCount?: Maybe<IntQueryOperatorInput>;
  totalAmountPayout?: Maybe<FloatQueryOperatorInput>;
  totalAmountToBePayout?: Maybe<FloatQueryOperatorInput>;
  totalUserClaimedCount?: Maybe<IntQueryOperatorInput>;
  totalAmountClaimed?: Maybe<FloatQueryOperatorInput>;
  totalAmountUnClaimed?: Maybe<FloatQueryOperatorInput>;
  totalAmountExpired?: Maybe<FloatQueryOperatorInput>;
  dateTimeCreated?: Maybe<DateTimeQueryOperatorInput>;
};

export type RebatePayoutsConnection = {
  __typename?: 'RebatePayoutsConnection';
  totalCount: Scalars['Int'];
  edges: Array<RebatePayoutsConnectionEdge>;
  pageInfo: PageInfo;
};

export type RebatePayoutsConnectionEdge = {
  __typename?: 'RebatePayoutsConnectionEdge';
  cursor: Scalars['Binary'];
  node: RebatePayout;
};

export enum RebatePayoutSettlementPeriod {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY',
}

export type RebatePayoutSettlementPeriodOperatorInput = {
  eq?: Maybe<RebatePayoutSettlementPeriod>;
  ne?: Maybe<RebatePayoutSettlementPeriod>;
  in?: Maybe<Array<RebatePayoutSettlementPeriod>>;
  nin?: Maybe<Array<RebatePayoutSettlementPeriod>>;
};

export enum RebatePayoutStatus {
  Pending = 'PENDING',
  Claimed = 'CLAIMED',
}

export type RebatePercentage = {
  __typename?: 'RebatePercentage';
  vendor: Vendor;
  gameType: GameType;
  games?: Maybe<Array<RebateGamePercentage>>;
  percentage: Scalars['RoundedFloat'];
};

export type RebatePercentageInput = {
  vendor: Scalars['ID'];
  gameType: GameType;
  games?: Maybe<Array<RebateGamePercentageInput>>;
  percentage: Scalars['Float'];
};

export type RecurringMaintenanceSchedule = MaintenanceSchedule & {
  __typename?: 'RecurringMaintenanceSchedule';
  id: Scalars['ID'];
  vendor: Vendor;
  creator: Account;
  gameType: GameType;
  mode: MaintenanceScheduleMode;
  period?: Maybe<Period>;
  startTime: Scalars['Time'];
  endTime: Scalars['Time'];
  startDay?: Maybe<Day>;
  endDay?: Maybe<Day>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  dateTimeCreated: Scalars['DateTime'];
};

export enum RegistrationAuditLogActionType {
  Off = 'OFF',
  On = 'ON',
}

export type RegistrationAuditTrail = {
  __typename?: 'RegistrationAuditTrail';
  account: Account;
  actionType: RegistrationAuditLogActionType;
  feature: Scalars['String'];
  dateTimeCreated: Scalars['DateTime'];
};

export type RegistrationAuditTrailConnection = {
  __typename?: 'RegistrationAuditTrailConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<RegistrationAuditTrailConnectionEdge>>;
  pageInfo: PageInfo;
};

export type RegistrationAuditTrailConnectionEdge = {
  __typename?: 'RegistrationAuditTrailConnectionEdge';
  cursor: Scalars['Binary'];
  node: RegistrationAuditTrail;
};

export type Report = {
  __typename?: 'Report';
  id: Scalars['ID'];
  creator: Account;
  report?: Maybe<File>;
  status: ReportStatus;
  totalRows?: Maybe<Scalars['Int']>;
  processedRows?: Maybe<Scalars['Int']>;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeGenerated?: Maybe<Scalars['DateTime']>;
};

export enum ReportStatus {
  Generating = 'GENERATING',
  Generated = 'GENERATED',
  Failed = 'FAILED',
}

export enum ResourceType {
  PaymentMethod = 'PAYMENT_METHOD',
  DepositProvider = 'DEPOSIT_PROVIDER',
  WithdrawalMethod = 'WITHDRAWAL_METHOD',
  WithdrawalProvider = 'WITHDRAWAL_PROVIDER',
}

export type RevenueShare = {
  __typename?: 'RevenueShare';
  id: Scalars['ID'];
  vendor: Vendor;
  gameType: GameType;
  percentage: Scalars['RoundedFloat'];
  winloss: Scalars['RoundedFloat'];
  revenueShare: Scalars['RoundedFloat'];
};

export type RevenueSharesConnection = {
  __typename?: 'RevenueSharesConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<RevenueSharesEdge>>;
  pageInfo: PageInfo;
};

export type RevenueSharesEdge = {
  __typename?: 'RevenueSharesEdge';
  cursor: Scalars['String'];
  node: RevenueShare;
};

export type RevenueSharesFilterInput = {
  admin?: Maybe<Scalars['ID']>;
  vendor?: Maybe<VendorCode>;
  gameType?: Maybe<GameType>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
};

export enum SeamlessVendorCode {
  Sagaming = 'SAGAMING',
  Cq9 = 'CQ9',
  Jdb = 'JDB',
  Mgdashur = 'MGDASHUR',
  Spadegaming = 'SPADEGAMING',
  Ebet = 'EBET',
  Pgsoft = 'PGSOFT',
}

export type SelfExcludeMemberInput = {
  period: Scalars['BigInt'];
};

export type Session = {
  __typename?: 'Session';
  id: Scalars['ID'];
  ipAddress: Scalars['IpAddress'];
  ipAddressLocation: IpAddressLocation;
  domain?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  deviceOs?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
  deviceFingerprint?: Maybe<Scalars['String']>;
  browser?: Maybe<Scalars['String']>;
  channel?: Maybe<Channel>;
  status: SessionStatus;
  expirationDateTime: Scalars['DateTime'];
  dateTimeDestroyed?: Maybe<Scalars['DateTime']>;
  dateTimeCreated: Scalars['DateTime'];
  turnover?: Maybe<Scalars['Float']>;
  ggr?: Maybe<Scalars['Float']>;
};

export enum SessionStatus {
  Active = 'ACTIVE',
  Stopped = 'STOPPED',
  Expired = 'EXPIRED',
  Destroyed = 'DESTROYED',
}

export type SignUpPromo = Promo & {
  __typename?: 'SignUpPromo';
  id: Scalars['ID'];
  creator: Account;
  status: PromoStatus;
  name: Scalars['String'];
  type: PromoType;
  event: PromoEvent;
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
  dateTimeCreated: Scalars['DateTime'];
  platform: Platform;
  includedMemberTags: Array<Maybe<MemberTag>>;
  excludedMemberTags: Array<Maybe<MemberTag>>;
  claimType: PromoClaimType;
  awardPreRequisite: PromoAwardPreRequisiteType;
  turnoverRequirementMultiplier: Scalars['RoundedFloat'];
  wagerContributions: Array<Maybe<PromoWagerContribution>>;
  autoCancel: Scalars['Boolean'];
  maintainingBalance?: Maybe<Scalars['RoundedFloat']>;
  currency?: Maybe<Scalars['Currency']>;
  amount?: Maybe<Scalars['RoundedFloat']>;
  maxMemberCount: Scalars['Int'];
  totalAmountAwarded?: Maybe<Scalars['RoundedFloat']>;
};

export type SkrillDeposit = Deposit & {
  __typename?: 'SkrillDeposit';
  id: Scalars['ID'];
  amount: Scalars['RoundedFloat'];
  account: Member;
  processingTime?: Maybe<Scalars['Int']>;
  paymentMethod?: Maybe<PaymentMethod>;
  status: DepositStatus;
  processor?: Maybe<Account>;
  serialCode: Scalars['String'];
  firstDeposit?: Maybe<Scalars['Boolean']>;
  remarks?: Maybe<Scalars['String']>;
  transactionReference?: Maybe<Scalars['String']>;
  manualAdjustment: Scalars['Boolean'];
  transactionDetails: DepositEventDetails;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeProcessed?: Maybe<Scalars['DateTime']>;
  baseCurrency?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  amountInBaseCurrency?: Maybe<Scalars['RoundedFloat']>;
  isCrypto?: Maybe<Scalars['Boolean']>;
};

export type SkrillPaymentMethod = PaymentMethod & {
  __typename?: 'SkrillPaymentMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  currentAccumulation: Scalars['RoundedFloat'];
  memberLoyaltyLevel?: Maybe<Array<MemberLoyaltyLevel>>;
  excludedMemberLevel?: Maybe<Array<MemberLevel>>;
  minimumDepositAmount: Scalars['RoundedFloat'];
  maximumDepositAmount: Scalars['RoundedFloat'];
  instructionText?: Maybe<Scalars['String']>;
  maximumDepositAmountPerDay: Scalars['RoundedFloat'];
  depositExpiry: Scalars['RoundedFloat'];
  turnoverRequirementMultiplier: Scalars['RoundedFloat'];
  suggestedAmounts: Array<Maybe<Scalars['RoundedFloat']>>;
  transactionFee: Scalars['RoundedFloat'];
  transactionFeeType?: Maybe<TransactionFeeType>;
  allowCustomAmount: Scalars['Boolean'];
  updateable: Scalars['Boolean'];
  provider?: Maybe<PaymentMethodProvider>;
};

export type SkrillWithdrawalMethod = WithdrawalMethod & {
  __typename?: 'SkrillWithdrawalMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  updateable: Scalars['Boolean'];
  transactionFee: Scalars['RoundedFloat'];
  transactionFeeType: TransactionFeeType;
  provider?: Maybe<WithdrawalMethodProvider>;
};

export type SortInput = {
  key?: Maybe<Scalars['String']>;
  direction?: Maybe<SortOption>;
};

export enum SortOption {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type StatisticalReportFilterInput = {
  memberAffiliate?: Maybe<Scalars['ID']>;
};

export type StatisticalReportItem = {
  __typename?: 'StatisticalReportItem';
  id: Scalars['ID'];
  memberAffiliate: Member;
  activeMembersCount: Scalars['Int'];
  betsCount: Scalars['Int'];
  turnover: Scalars['RoundedFloat'];
  effectiveBet: Scalars['RoundedFloat'];
  winloss: Scalars['RoundedFloat'];
  profitRatio: Scalars['RoundedFloat'];
};

export type StatisticalReportItemActiveMembersCountArgs = {
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
};

export type StatisticalReportItemBetsCountArgs = {
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
};

export type StatisticalReportItemTurnoverArgs = {
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
};

export type StatisticalReportItemEffectiveBetArgs = {
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
};

export type StatisticalReportItemWinlossArgs = {
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
};

export type StatisticalReportItemProfitRatioArgs = {
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
};

export type StatisticalReportItemsConnection = {
  __typename?: 'StatisticalReportItemsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<StatisticalReportItemsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type StatisticalReportItemsConnectionEdge = {
  __typename?: 'StatisticalReportItemsConnectionEdge';
  cursor: Scalars['Binary'];
  node: StatisticalReportItem;
};

export type StringArrayQueryOperatorInput = {
  includesAny?: Maybe<Array<Scalars['String']>>;
  excludesAll?: Maybe<Array<Scalars['String']>>;
};

export type StringLanguageInput = {
  EN?: Maybe<Scalars['String']>;
  ZH?: Maybe<Scalars['String']>;
  ZH_HANT?: Maybe<Scalars['String']>;
  ZH_HANS?: Maybe<Scalars['String']>;
};

export type StringLanguageQueryOperatorInput = {
  eq?: Maybe<StringLanguageInput>;
  ne?: Maybe<StringLanguageInput>;
  in?: Maybe<Array<StringLanguageInput>>;
  nin?: Maybe<Array<StringLanguageInput>>;
  startsWith?: Maybe<StringLanguageInput>;
  contains?: Maybe<StringLanguageInput>;
};

export type StringQueryOperator = {
  __typename?: 'StringQueryOperator';
  eq?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  nin?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
};

export type StringQueryOperatorInput = {
  eq?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  nin?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
};

export type SuperAdmin = Account & {
  __typename?: 'SuperAdmin';
  id: Scalars['ID'];
  role: AccountRole;
  username: Scalars['String'];
  currency: Scalars['Currency'];
  language: Scalars['Language'];
  timezone: Scalars['Timezone'];
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  realName?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  address?: Maybe<Address>;
  countryCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  wechatId?: Maybe<Scalars['String']>;
  qqId?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  mobileNumberVerified?: Maybe<Scalars['Boolean']>;
  wechatIdVerified?: Maybe<Scalars['Boolean']>;
  qqIdVerified?: Maybe<Scalars['Boolean']>;
  lastLoginDateTime: Scalars['DateTime'];
  registrationDateTime: Scalars['DateTime'];
  dateTimeCreated: Scalars['DateTime'];
  dateTimeUpdated: Scalars['DateTime'];
  otpValidationEnabled: Scalars['Boolean'];
  forceChangePassword?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Scalars['String']>;
};

export type SystemCreditManualAdjustment = ManualAdjustment & {
  __typename?: 'SystemCreditManualAdjustment';
  id: Scalars['ID'];
  type: ManualAdjustmentType;
  account: Account;
  amount: Scalars['RoundedFloat'];
  remarks: Scalars['String'];
  dateTimeCreated: Scalars['DateTime'];
};

export type SystemDebitManualAdjustment = ManualAdjustment & {
  __typename?: 'SystemDebitManualAdjustment';
  id: Scalars['ID'];
  type: ManualAdjustmentType;
  account: Account;
  amount: Scalars['RoundedFloat'];
  remarks: Scalars['String'];
  dateTimeCreated: Scalars['DateTime'];
};

export type SystemManualAdjustment = ManualAdjustment & {
  __typename?: 'SystemManualAdjustment';
  id: Scalars['ID'];
  type: ManualAdjustmentType;
  account: Account;
  amount: Scalars['RoundedFloat'];
  remarks: Scalars['String'];
  dateTimeCreated: Scalars['DateTime'];
};

export type SystemNotifacationConnection = {
  __typename?: 'SystemNotifacationConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<SystemNotificationsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type SystemNotification = {
  __typename?: 'SystemNotification';
  id: Scalars['ID'];
  title: Scalars['String'];
  message: Scalars['String'];
  priority: SystemNotificationPriorityType;
  recipient: Array<Scalars['ID']>;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeUpdated: Scalars['DateTime'];
};

export type SystemNotificationFilterInput = {
  title?: Maybe<StringQueryOperatorInput>;
  message?: Maybe<StringQueryOperatorInput>;
  priority?: Maybe<SystemNotificationPriorityTypeQueryOperatorInput>;
  dateTime?: Maybe<DateTimeQueryOperatorInput>;
};

export type SystemNotificationInput = {
  title: Scalars['String'];
  message: Scalars['String'];
  priority: SystemNotificationPriorityType;
  recipient: Array<Scalars['ID']>;
};

export enum SystemNotificationPriorityType {
  High = 'HIGH',
  Medium = 'MEDIUM',
  Low = 'LOW',
}

export type SystemNotificationPriorityTypeQueryOperatorInput = {
  eq?: Maybe<SystemNotificationPriorityType>;
  ne?: Maybe<SystemNotificationPriorityType>;
  in?: Maybe<Array<SystemNotificationPriorityType>>;
  nin?: Maybe<Array<SystemNotificationPriorityType>>;
};

export type SystemNotificationsConnectionEdge = {
  __typename?: 'SystemNotificationsConnectionEdge';
  cursor: Scalars['Binary'];
  node: SystemNotification;
};

export type TagsQueryOperatorInput = {
  overlaps?: Maybe<Array<Scalars['ID']>>;
  includesAny?: Maybe<Array<Scalars['ID']>>;
  excludesAll?: Maybe<Array<Scalars['ID']>>;
  startsWith?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ThirdPartyPaymentChannels = {
  __typename?: 'ThirdPartyPaymentChannels';
  bank?: Maybe<Array<Scalars['String']>>;
  alipay?: Maybe<Array<Scalars['String']>>;
  wechat?: Maybe<Array<Scalars['String']>>;
  unionpay?: Maybe<Array<Scalars['String']>>;
};

export type TimeOutMemberInput = {
  period: Scalars['BigInt'];
};

export type TogglePaymentMethodClosedLoopInput = {
  paymentMethod: Scalars['ID'];
  account: Scalars['ID'];
  disableClosedLoop: Scalars['Boolean'];
  disableCard?: Maybe<Scalars['Boolean']>;
  hexoPayCardId?: Maybe<Scalars['ID']>;
};

export type TotalBalanceRange = {
  __typename?: 'TotalBalanceRange';
  minimum?: Maybe<Scalars['RoundedFloat']>;
  maximum?: Maybe<Scalars['RoundedFloat']>;
};

export type TotalBalanceRangeInput = {
  minimum?: Maybe<Scalars['RoundedFloat']>;
  maximum?: Maybe<Scalars['RoundedFloat']>;
};

export type TotalBetsCountChartInput = {
  vendor?: Maybe<Scalars['ID']>;
  gameType?: Maybe<GameType>;
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
};

export type TotalBetsCountInput = {
  vendor?: Maybe<Scalars['ID']>;
  gameType?: Maybe<GameType>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
};

export type TotalDepositAmountChartInput = {
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
};

export type TotalDepositAmountInput = {
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
};

export type TotalDepositorsCount = {
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
};

export type TotalDepositsCount = {
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
};

export type TotalEffectiveBetChartInput = {
  vendor?: Maybe<Scalars['ID']>;
  gameType?: Maybe<GameType>;
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
};

export type TotalEffectiveBetInput = {
  vendor?: Maybe<Scalars['ID']>;
  gameType?: Maybe<GameType>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
};

export type TotalFirstDepositorsCountChartInput = {
  channel?: Maybe<Channel>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
};

export type TotalFirstDepositorsCountInput = {
  channel?: Maybe<Channel>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
};

export type TotalMemberRegistrationsCountChartInput = {
  channel?: Maybe<Channel>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
};

export type TotalMemberRegistrationsCountInput = {
  channel?: Maybe<Channel>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
};

export type TotalNetProfitChartInput = {
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
};

export type TotalNetProfitInput = {
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
};

export type TotalOnlineMembersCountChartInput = {
  channel?: Maybe<Channel>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
};

export type TotalOnlineMembersCountnput = {
  channel?: Maybe<Channel>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
};

export type TotalWinlossChartInput = {
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
};

export type TotalWinlossInput = {
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
};

export type TotalWithdrawalAmountChartInput = {
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
};

export type TotalWithdrawalAmountInput = {
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
};

export type TotalWithdrawalsCount = {
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
};

export type TotalWithdrawersCount = {
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
};

export enum TransactionFeeType {
  Absolute = 'ABSOLUTE',
  Relative = 'RELATIVE',
}

export enum TransferVendorCode {
  Kagaming = 'KAGAMING',
  Megawin = 'MEGAWIN',
  PpTransfer = 'PP_TRANSFER',
  SagamingTransfer = 'SAGAMING_TRANSFER',
  Kenogame = 'KENOGAME',
  Hc = 'HC',
  Goldenf = 'GOLDENF',
  Allbet = 'ALLBET',
  Pt = 'PT',
  Ky = 'KY',
  Bbin = 'BBIN',
  Sgwin = 'SGWIN',
  GpkCq9 = 'GPK_CQ9',
  GpkJdb = 'GPK_JDB',
  GpkSg = 'GPK_SG',
  GpkBb = 'GPK_BB',
  GpkAe = 'GPK_AE',
  GpkTh = 'GPK_TH',
  GpkAg = 'GPK_AG',
  GpkKg = 'GPK_KG',
  GpkNw = 'GPK_NW',
  GpkRgtp = 'GPK_RGTP',
  GpkAt = 'GPK_AT',
  GpkRgTycoon = 'GPK_RG_TYCOON',
  GpkMgplus = 'GPK_MGPLUS',
  GpkRgKing = 'GPK_RG_KING',
  GpkRgComing = 'GPK_RG_COMING',
  GpkRgMill = 'GPK_RG_MILL',
  GpkRgCandy = 'GPK_RG_CANDY',
  GpkRgJewels = 'GPK_RG_JEWELS',
  Mgplus = 'MGPLUS',
  Newworld = 'NEWWORLD',
  V365Cards = 'V365CARDS',
  SpadegamingTransfer = 'SPADEGAMING_TRANSFER',
  JdbTransfer = 'JDB_TRANSFER',
  Longcheng = 'LONGCHENG',
  Bg = 'BG',
  PgsoftTransfer = 'PGSOFT_TRANSFER',
  Cmd = 'CMD',
  FgTransfer = 'FG_TRANSFER',
  Legpoker = 'LEGPOKER',
  Onebook = 'ONEBOOK',
  EbetTransfer = 'EBET_TRANSFER',
  NetentTransfer = 'NETENT_TRANSFER',
  Imesportsbull = 'IMESPORTSBULL',
  HabaneroTransfer = 'HABANERO_TRANSFER',
  EvoliveTransfer = 'EVOLIVE_TRANSFER',
  Iglivecasino = 'IGLIVECASINO',
  Ag = 'AG',
  Sbo = 'SBO',
  BooongoTransfer = 'BOOONGO_TRANSFER',
}

export enum TriggerEventName {
  WelcomeEmail = 'WELCOME_EMAIL',
  SelfExclusion = 'SELF_EXCLUSION',
  PasswordChange = 'PASSWORD_CHANGE',
  PasswordReset = 'PASSWORD_RESET',
  AccountClosure = 'ACCOUNT_CLOSURE',
  TimeOutFacility = 'TIME_OUT_FACILITY',
  DepositLimitUpdates = 'DEPOSIT_LIMIT_UPDATES',
  PossibleLinkage = 'POSSIBLE_LINKAGE',
  WithdrawalApproved = 'WITHDRAWAL_APPROVED',
  DepositApproved = 'DEPOSIT_APPROVED',
  MemberDocumentCreated = 'MEMBER_DOCUMENT_CREATED',
  MemberDocumentRejected = 'MEMBER_DOCUMENT_REJECTED',
  MemberDocumentApproved = 'MEMBER_DOCUMENT_APPROVED',
  MemberVerified = 'MEMBER_VERIFIED',
  EmailOtp = 'EMAIL_OTP',
  Triggers = 'TRIGGERS',
  AccountClosedLegacy = 'ACCOUNT_CLOSED_LEGACY',
  Fraud = 'FRAUD',
  OtpVerification = 'OTP_VERIFICATION',
  AlertWeightingsAndCompoundRules = 'ALERT_WEIGHTINGS_AND_COMPOUND_RULES',
  SelfAssessment = 'SELF_ASSESSMENT',
  SelfAssessmentFeedback = 'SELF_ASSESSMENT_FEEDBACK',
  GenerateTemporaryPassword = 'GENERATE_TEMPORARY_PASSWORD',
  IncreasingCasinoStakes = 'INCREASING_CASINO_STAKES',
}

export enum TriggerSource {
  SystemGenerated = 'SYSTEM_GENERATED',
  UserRequested = 'USER_REQUESTED',
}

export type UnionPayDeposit = Deposit & {
  __typename?: 'UnionPayDeposit';
  id: Scalars['ID'];
  amount: Scalars['RoundedFloat'];
  account: Member;
  processingTime?: Maybe<Scalars['Int']>;
  paymentMethod?: Maybe<PaymentMethod>;
  status: DepositStatus;
  processor?: Maybe<Account>;
  serialCode: Scalars['String'];
  firstDeposit?: Maybe<Scalars['Boolean']>;
  remarks?: Maybe<Scalars['String']>;
  transactionReference?: Maybe<Scalars['String']>;
  transactionDetails: DepositEventDetails;
  manualAdjustment: Scalars['Boolean'];
  dateTimeCreated: Scalars['DateTime'];
  dateTimeProcessed?: Maybe<Scalars['DateTime']>;
  baseCurrency?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  amountInBaseCurrency?: Maybe<Scalars['RoundedFloat']>;
  isCrypto?: Maybe<Scalars['Boolean']>;
};

export type UnionPayPaymentMethod = PaymentMethod & {
  __typename?: 'UnionPayPaymentMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  currentAccumulation: Scalars['RoundedFloat'];
  memberLoyaltyLevel?: Maybe<Array<MemberLoyaltyLevel>>;
  excludedMemberLevel?: Maybe<Array<MemberLevel>>;
  minimumDepositAmount: Scalars['RoundedFloat'];
  maximumDepositAmount: Scalars['RoundedFloat'];
  instructionText?: Maybe<Scalars['String']>;
  maximumDepositAmountPerDay: Scalars['RoundedFloat'];
  depositExpiry: Scalars['RoundedFloat'];
  turnoverRequirementMultiplier: Scalars['RoundedFloat'];
  suggestedAmounts: Array<Scalars['RoundedFloat']>;
  transactionFee: Scalars['RoundedFloat'];
  transactionFeeType?: Maybe<TransactionFeeType>;
  allowCustomAmount: Scalars['Boolean'];
  updateable: Scalars['Boolean'];
  depositProvider: DepositProvider;
  paymentChannel: Scalars['String'];
  provider?: Maybe<PaymentMethodProvider>;
};

export type UnitScopeInput = {
  game?: Maybe<Array<Scalars['String']>>;
  gameType?: Maybe<Array<Scalars['String']>>;
  vendor?: Maybe<Array<Scalars['String']>>;
  channel?: Maybe<Array<Scalars['String']>>;
};

export type UniversalGameSettings = {
  __typename?: 'UniversalGameSettings';
  game: Game;
  vendor: VendorCode;
  disabled: Scalars['Boolean'];
  hidden: Scalars['Boolean'];
  assets: GameAssets;
};

export type UniversalGameSettingsAssetsArgs = {
  language: Scalars['Language'];
};

export type UniversalGameSettingsItemsConnection = {
  __typename?: 'UniversalGameSettingsItemsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<UniversalGameSettingsItemsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type UniversalGameSettingsItemsConnectionEdge = {
  __typename?: 'UniversalGameSettingsItemsConnectionEdge';
  cursor: Scalars['Binary'];
  node: UniversalGameSettings;
};

export type UniversalGameSettingsItemsFilterInput = {
  vendor?: Maybe<VendorCodeQueryOperatorInput>;
  game?: Maybe<IdQueryOperatorInput>;
};

export type UniversalVendorSettings = {
  __typename?: 'UniversalVendorSettings';
  vendor: VendorCode;
  disabled: Scalars['Boolean'];
  hidden: Scalars['Boolean'];
  gameTypeSettings: VendorSettingsGameTypeSettings;
  details: Scalars['JSON'];
};

export type UniversalVendorSettingsItemsConnection = {
  __typename?: 'UniversalVendorSettingsItemsConnection';
  totalCount: Scalars['Int'];
  edges: Array<UniversalVendorSettingsItemsConnectionEdge>;
  pageInfo: PageInfo;
};

export type UniversalVendorSettingsItemsConnectionEdge = {
  __typename?: 'UniversalVendorSettingsItemsConnectionEdge';
  cursor: Scalars['Binary'];
  node: UniversalVendorSettings;
};

export type UniversalVendorSettingsItemsFilterInput = {
  vendor?: Maybe<VendorCodeQueryOperatorInput>;
};

export type UpdateAccountUsageReportInput = {
  scheduledDateTime?: Maybe<Scalars['DateTime']>;
};

export type UpdateAdminGroupInput = {
  name?: Maybe<Scalars['String']>;
  admins?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type UpdateAdminInput = {
  password?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['Language']>;
  timezone?: Maybe<Scalars['Timezone']>;
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  realName?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  email?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  wechatId?: Maybe<Scalars['String']>;
  qqId?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  skypeId?: Maybe<Scalars['String']>;
  trial?: Maybe<Scalars['Boolean']>;
  contractStartDateTime?: Maybe<Scalars['DateTime']>;
  contractEndDateTime?: Maybe<Scalars['DateTime']>;
  twoFactorAuthenticationEnabled?: Maybe<Scalars['Boolean']>;
  ipWhitelist?: Maybe<Array<Scalars['IpAddress']>>;
  vendorGroup?: Maybe<Scalars['ID']>;
  attachments?: Maybe<Array<Scalars['ID']>>;
  status?: Maybe<AdminStatus>;
  subdomain?: Maybe<Scalars['String']>;
  personInCharge?: Maybe<Scalars['String']>;
  otpValidationEnabled?: Maybe<Scalars['Boolean']>;
  linkedAdmins?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateAdminOperatorInput = {
  password?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['Language']>;
  currency?: Maybe<Scalars['Currency']>;
  timezone?: Maybe<Scalars['Timezone']>;
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  realName?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  email?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  wechatId?: Maybe<Scalars['String']>;
  qqId?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  skypeId?: Maybe<Scalars['String']>;
};

export type UpdateAffiliateProgrammeInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  automaticPayoutClaiming?: Maybe<Scalars['Boolean']>;
  negativeCarryOverEnabled?: Maybe<Scalars['Boolean']>;
  positiveCarryOverEnabled?: Maybe<Scalars['Boolean']>;
  vendorPlatformFees?: Maybe<Array<VendorPlatformFeeInput>>;
  depositTransactionCost?: Maybe<Scalars['Float']>;
  thirdPartyDepositTransactionCost?: Maybe<Scalars['Float']>;
  withdrawalTransactionCost?: Maybe<Scalars['Float']>;
  thirdPartyWithdrawalTransactionCost?: Maybe<Scalars['Float']>;
  promoCost?: Maybe<Scalars['Float']>;
  rebateCost?: Maybe<Scalars['Float']>;
  interestAccountCost?: Maybe<Scalars['Float']>;
  minimumEffectiveBetRequirement?: Maybe<Scalars['Float']>;
  minimumDepositRequirement?: Maybe<Scalars['Float']>;
  minimumPayoutAccumulationEnabled?: Maybe<Scalars['Boolean']>;
  minimumPayoutAccumulationAmount?: Maybe<Scalars['Float']>;
  turnoverRequirementMultiplier?: Maybe<Scalars['Float']>;
  settlementPeriod?: Maybe<AffiliateProgrammeSettlementPeriod>;
  settlementTime?: Maybe<Scalars['Time']>;
  settlementDayOfWeek?: Maybe<Scalars['DayOfWeek']>;
  settlementDayOfMonth?: Maybe<Scalars['DayOfMonth']>;
  payoutClaimOffsetDuration?: Maybe<Scalars['Duration']>;
  payoutClaimExpiryDuration?: Maybe<Scalars['Duration']>;
  payoutExpiryDuration?: Maybe<Scalars['Duration']>;
  levels?: Maybe<Array<AffiliateProgrammeLevelInput>>;
  levelsRequirement?: Maybe<AffiliateProgrammeLevelsRequirement>;
  enabled?: Maybe<Scalars['Boolean']>;
};

export type UpdateAffiliateRequestInput = {
  remarks?: Maybe<Scalars['String']>;
  programme?: Maybe<Scalars['ID']>;
};

export type UpdateAgentLevelInput = {
  name?: Maybe<Scalars['String']>;
  commissionEnabled?: Maybe<Scalars['Boolean']>;
  minimumMemberTurnover?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
  withdrawalFeeExpense?: Maybe<AgentExpenseInput>;
  rebateExpense?: Maybe<AgentExpenseInput>;
  commissionLevels?: Maybe<Array<CommissionLevelInput>>;
  isDefault?: Maybe<Scalars['Boolean']>;
};

export type UpdateAlipayWithdrawalMethodInput = {
  name?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  transactionFee?: Maybe<Scalars['Float']>;
  transactionFeeType?: Maybe<TransactionFeeType>;
  provider?: Maybe<WithdrawalMethodProvider>;
  nickname?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
};

export type UpdateAstroPayWalletWithdrawalMethodInput = {
  name?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  transactionFee?: Maybe<Scalars['Float']>;
  transactionFeeType?: Maybe<TransactionFeeType>;
  provider?: Maybe<WithdrawalMethodProvider>;
};

export type UpdateAutomatedMessageInput = {
  title_en?: Maybe<Scalars['String']>;
  title_zh?: Maybe<Scalars['String']>;
  content_en?: Maybe<Scalars['String']>;
  content_zh?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
};

export type UpdateBankAccountInput = {
  bank?: Maybe<Bank>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
};

export type UpdateBannerInput = {
  name?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['URL']>;
  promo?: Maybe<Scalars['ID']>;
  imageDesktop?: Maybe<Scalars['ID']>;
  imageMobile?: Maybe<Scalars['ID']>;
  imageHorizontal?: Maybe<Scalars['ID']>;
  enabledDesktop?: Maybe<Scalars['Boolean']>;
  enabledMobile?: Maybe<Scalars['Boolean']>;
  enabledHorizontal?: Maybe<Scalars['Boolean']>;
  rank?: Maybe<Scalars['Int']>;
};

export type UpdateBetHistoryReportInput = {
  scheduledDateTime?: Maybe<Scalars['DateTime']>;
};

export type UpdateComplianceCheckConfigInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  turnoverRequirementEnabled?: Maybe<Scalars['Boolean']>;
  turnoverRequirementActivationThreshold?: Maybe<Scalars['Float']>;
  ipCollisionOffenderEnabled?: Maybe<Scalars['Boolean']>;
  contraBetOffenderEnabled?: Maybe<Scalars['Boolean']>;
  contraBetOffenderActivationThreshold?: Maybe<Scalars['Float']>;
  martingaleBetOffenderEnabled?: Maybe<Scalars['Boolean']>;
  martingaleBetOffenderActivationThreshold?: Maybe<Scalars['Float']>;
  bonusHunterEnabled?: Maybe<Scalars['Boolean']>;
  bonusHunterActivationThreshold?: Maybe<Scalars['Float']>;
};

export type UpdateConfigInput = {
  marqueeMessageSpeed?: Maybe<MarqueeMessageSpeed>;
  bannerAutomaticScrollingEnabled?: Maybe<Scalars['Boolean']>;
  bannerScrollingSpeed?: Maybe<BannerScrollingSpeed>;
  emailVerificationEnabled?: Maybe<Scalars['Boolean']>;
  isEmailReusable?: Maybe<Scalars['Boolean']>;
  emailReusabilityCount?: Maybe<Scalars['Int']>;
  mobileNumberVerificationEnabled?: Maybe<Scalars['Boolean']>;
  isMobileNumberReusable?: Maybe<Scalars['Boolean']>;
  mobileNumberReusabilityCount?: Maybe<Scalars['Int']>;
  domainName?: Maybe<Scalars['String']>;
  domainDescription?: Maybe<Scalars['String']>;
  crtFile?: Maybe<Scalars['ID']>;
  intermediaryCert?: Maybe<Scalars['ID']>;
  privateKey?: Maybe<Scalars['ID']>;
  sslEnabled?: Maybe<Scalars['Boolean']>;
  captchaEnabledFrontend?: Maybe<Scalars['Boolean']>;
  captchaEnabledBackend?: Maybe<Scalars['Boolean']>;
  transferVendorsEnabled?: Maybe<Scalars['Boolean']>;
  seamlessVendorsEnabled?: Maybe<Scalars['Boolean']>;
  googleAuthEnabledBackend?: Maybe<Scalars['Boolean']>;
  paymentMethodSuccessRateCalculationLastTransactionsCount?: Maybe<
    Scalars['Int']
  >;
  turnoverRequirementWaiverEnabled?: Maybe<Scalars['Boolean']>;
  turnoverRequirementWaiverThreshold?: Maybe<Scalars['Float']>;
  withdrawalHandlingFeeType?: Maybe<WithdrawalHandlingFeeType>;
  withdrawalHandlingFee?: Maybe<Scalars['Float']>;
  withdrawalTaxType?: Maybe<WithdrawalTaxType>;
  withdrawalTaxValue?: Maybe<Scalars['Float']>;
  depositSourceFieldStateInputType?: Maybe<FieldStateInputType>;
  paymentMethodConfiguration?: Maybe<Array<PaymentMethodConfigurationInput>>;
  paymentMethodRotation?: Maybe<PaymentMethodRotationInput>;
  timezone?: Maybe<Scalars['Timezone']>;
  affiliateRequestFormFields?: Maybe<Array<FormFieldInput>>;
  memberFormFields?: Maybe<Array<FormFieldInput>>;
  memberForceVerificationSettings?: Maybe<MemberForceVerificationSettingsInput>;
  defaultCurrency?: Maybe<Scalars['ID']>;
  defaultCountry?: Maybe<Scalars['ID']>;
  defaultCountryCode?: Maybe<Scalars['ID']>;
  depositLimitSettings?: Maybe<Array<DepositLimitSettingInput>>;
  geolocationSettings?: Maybe<Array<GeolocationSettingInput>>;
  realityCheckTime?: Maybe<Scalars['Int']>;
  selfExclusionCheckInterval?: Maybe<Scalars['Int']>;
  checkFraud?: Maybe<Scalars['Boolean']>;
  multipleSession?: Maybe<Scalars['Boolean']>;
  hexoPayShopId?: Maybe<Scalars['String']>;
  hexoPaySecretKey?: Maybe<Scalars['String']>;
  hexoPayPublicKey?: Maybe<Scalars['String']>;
  hexoPayTestMode?: Maybe<Scalars['Boolean']>;
  enableLoqateIntegration?: Maybe<Scalars['Boolean']>;
  enableGBG?: Maybe<Scalars['Boolean']>;
  enableGBGAffordabilityCheck?: Maybe<Scalars['Boolean']>;
  gbgNetDepositThreshold?: Maybe<Scalars['Float']>;
  gbgTrigger?: Maybe<GbgTrigger>;
  gbgThresholdAmount?: Maybe<Scalars['Int']>;
  websiteLink?: Maybe<Scalars['String']>;
  logoLink?: Maybe<Scalars['String']>;
  themeColor?: Maybe<Scalars['String']>;
  themeSubColor?: Maybe<Scalars['String']>;
  smtpHost?: Maybe<Scalars['String']>;
  smtpService?: Maybe<Scalars['String']>;
  smtpUser?: Maybe<Scalars['String']>;
  smtpPassword?: Maybe<Scalars['String']>;
  checkDepositLimit?: Maybe<Scalars['Boolean']>;
  interimSafeGamblingPage?: Maybe<Scalars['Boolean']>;
  autoDetectUserCountryCode?: Maybe<Scalars['Boolean']>;
  optInChecked?: Maybe<Scalars['Boolean']>;
  tncChecked?: Maybe<Scalars['Boolean']>;
  checkGamstop?: Maybe<Scalars['Boolean']>;
  netellerShopId?: Maybe<Scalars['String']>;
  netellerSecretKey?: Maybe<Scalars['String']>;
  netellerTestMode?: Maybe<Scalars['Boolean']>;
  skrillShopId?: Maybe<Scalars['String']>;
  skrillSecretKey?: Maybe<Scalars['String']>;
  skrillTestMode?: Maybe<Scalars['Boolean']>;
  skrillAccount?: Maybe<Scalars['String']>;
  coinifyShopId?: Maybe<Scalars['String']>;
  coinifySecretKey?: Maybe<Scalars['String']>;
  coinifyTestMode?: Maybe<Scalars['Boolean']>;
  coinifyAccount?: Maybe<Scalars['String']>;
  coinifyBTCAddress?: Maybe<Scalars['String']>;
  wonkaPayKey?: Maybe<Scalars['String']>;
  wonkaPaySecret?: Maybe<Scalars['String']>;
  xtpAppToken?: Maybe<Scalars['String']>;
  enableDepositLimit?: Maybe<Scalars['Boolean']>;
  enableWithdrawalCancelation?: Maybe<Scalars['Boolean']>;
  availableCurrencies?: Maybe<Array<Scalars['String']>>;
  multiCurrencyEnabled?: Maybe<Scalars['Boolean']>;
  astroPayKey?: Maybe<Scalars['String']>;
  astroPayMerchantCode?: Maybe<Scalars['String']>;
  astroPaySecretKey?: Maybe<Scalars['String']>;
  quickDepositEnabled?: Maybe<Scalars['Boolean']>;
  preDepositEnabled?: Maybe<Scalars['Boolean']>;
  preDepositValues?: Maybe<Array<Scalars['Float']>>;
};

export type UpdateCustomFilterInput = {
  name?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['JSON']>;
};

export type UpdateDepositProviderInput = {
  name?: Maybe<Scalars['String']>;
  apiKey?: Maybe<Scalars['String']>;
  encryptionKey?: Maybe<Scalars['String']>;
  merchantId?: Maybe<Scalars['String']>;
  merchantCode?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  partner?: Maybe<Scalars['String']>;
  midPayDirectPaymentGateway?: Maybe<Scalars['ID']>;
  credentialValues?: Maybe<Array<Scalars['String']>>;
};

export type UpdateDepositSourceInput = {
  logo?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  shortName?: Maybe<StringLanguageInput>;
  displayName?: Maybe<StringLanguageInput>;
  visible?: Maybe<Scalars['Boolean']>;
  loadBankSource?: Maybe<Scalars['Boolean']>;
};

export type UpdateDomainInput = {
  name?: Maybe<Scalars['URL']>;
  status?: Maybe<DomainStatus>;
  description?: Maybe<Scalars['String']>;
  sslEnabled?: Maybe<Scalars['Boolean']>;
  certificate?: Maybe<Scalars['String']>;
  privateKey?: Maybe<Scalars['String']>;
  intermediateCertificate?: Maybe<Scalars['String']>;
};

export type UpdateGameCategoryInput = {
  name?: Maybe<Scalars['String']>;
  gameTypes?: Maybe<Array<GameType>>;
  icon?: Maybe<Scalars['ID']>;
  desktopIcon?: Maybe<Scalars['ID']>;
  vendors?: Maybe<Array<Scalars['ID']>>;
  rank?: Maybe<Scalars['Int']>;
  heroBanner?: Maybe<Scalars['ID']>;
  banner?: Maybe<Scalars['ID']>;
  mascotHorizontal?: Maybe<Scalars['ID']>;
  backgroundHorizontal?: Maybe<Scalars['ID']>;
};

export type UpdateGameInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  visible?: Maybe<Scalars['Boolean']>;
};

export type UpdateGameInputNext = {
  name?: Maybe<GameNameInput>;
  image?: Maybe<GameImageInput>;
  hot?: Maybe<Scalars['Boolean']>;
  new?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  subCategory?: Maybe<Scalars['String']>;
  supportedChannels?: Maybe<Array<Maybe<Channel>>>;
  jackpotStakes?: Maybe<Scalars['Boolean']>;
  rtpStakes?: Maybe<Scalars['Int']>;
  houseEdgeStakes?: Maybe<Scalars['Int']>;
  volatilityStakes?: Maybe<Scalars['String']>;
};

export type UpdateGameSettingsInput = {
  disabled: Scalars['Boolean'];
  hidden: Scalars['Boolean'];
  assets: GameSettingsAssetsInput;
};

export type UpdateHexoPayWithdrawalMethodInput = {
  name?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  transactionFee?: Maybe<Scalars['Float']>;
  transactionFeeType?: Maybe<TransactionFeeType>;
  provider?: Maybe<WithdrawalMethodProvider>;
};

export type UpdateMaintenanceScheduleInput = {
  vendor: VendorCode;
  gameType: GameType;
  mode: MaintenanceScheduleMode;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  period?: Maybe<Period>;
  startTime?: Maybe<Scalars['Time']>;
  endTime?: Maybe<Scalars['Time']>;
  startDay?: Maybe<Day>;
  endDay?: Maybe<Day>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};

export type UpdateMarqueeMessageInput = {
  title?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  snippet?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  rank?: Maybe<Scalars['Int']>;
};

export type UpdateMeInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  realName?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  email?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  wechatId?: Maybe<Scalars['String']>;
  qqId?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['Language']>;
  password?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['Currency']>;
};

export type UpdateMemberAffiliateModelInput = {
  name?: Maybe<Scalars['String']>;
  levels?: Maybe<Array<MemberAffiliateLevelInput>>;
  maximumCommission?: Maybe<Scalars['Float']>;
  salesMetric?: Maybe<MemberAffiliateSalesMetricInput>;
  settlementPeriod?: Maybe<MemberAffiliateSettlementPeriodSettingsInput>;
  automaticSettlement?: Maybe<Scalars['Boolean']>;
  default?: Maybe<Scalars['Boolean']>;
  enabled?: Maybe<Scalars['Boolean']>;
};

export type UpdateMemberAttributesConfigurationInput = {
  fields: Array<MemberAttributesConfigurationFieldInput>;
};

export type UpdateMemberInput = {
  password?: Maybe<Scalars['String']>;
  withdrawalPassword?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  realName?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  address?: Maybe<AddressInput>;
  countryCode?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['Currency']>;
  email?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  wechatId?: Maybe<Scalars['String']>;
  qqId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  status?: Maybe<MemberStatus>;
  tags?: Maybe<Array<Maybe<Scalars['ID']>>>;
  tagsV2?: Maybe<MemberTagOperation>;
  starred?: Maybe<Scalars['Boolean']>;
  forceChangeWithdrawalPassword?: Maybe<Scalars['Boolean']>;
  forceChangePassword?: Maybe<Scalars['Boolean']>;
  forceVerification?: Maybe<Scalars['Boolean']>;
  remarks?: Maybe<Scalars['String']>;
  memberLevel?: Maybe<Scalars['ID']>;
  memberLoyaltyProgramme?: Maybe<Scalars['ID']>;
  memberLoyaltyLevel?: Maybe<Scalars['ID']>;
  memberLoyaltyLevels?: Maybe<Array<Maybe<Scalars['ID']>>>;
  otpValidationEnabled?: Maybe<Scalars['Boolean']>;
  communicationTypePreferences?: Maybe<Array<Maybe<CommunicationType>>>;
  phoneNumber?: Maybe<Scalars['String']>;
  title?: Maybe<MemberTitle>;
  verified?: Maybe<Scalars['Boolean']>;
  realityCheckDuration?: Maybe<Scalars['Int']>;
  depositLimitFrequency?: Maybe<MemberDepositLimitFrequency>;
  depositLimit?: Maybe<Scalars['Float']>;
  depositLimitLock?: Maybe<Scalars['Boolean']>;
  depositLimitLockValue?: Maybe<Scalars['Float']>;
  depositBlocked?: Maybe<Scalars['Boolean']>;
  withdrawalBlocked?: Maybe<Scalars['Boolean']>;
  wagerBlocked?: Maybe<Scalars['Boolean']>;
  brandLinkagesUpdated?: Maybe<Scalars['Boolean']>;
  promotionBlocked?: Maybe<Scalars['Boolean']>;
  platformId?: Maybe<Scalars['ID']>;
  playerFileLink?: Maybe<Scalars['String']>;
  btags?: Maybe<Array<Maybe<Scalars['String']>>>;
  btagsNext?: Maybe<BtagsNextInput>;
  skrillDisabled?: Maybe<Scalars['Boolean']>;
  coinifyDisabled?: Maybe<Scalars['Boolean']>;
  netellerDisabled?: Maybe<Scalars['Boolean']>;
  preferredLanguage?: Maybe<Scalars['Language']>;
};

export type UpdateMemberLevelInput = {
  name?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  status?: Maybe<MemberLevelStatus>;
  brandLinkage?: Maybe<Scalars['Boolean']>;
};

export type UpdateMemberLoyaltyProgrammeInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  validityStartDate?: Maybe<Scalars['Date']>;
  validityEndDate?: Maybe<Scalars['Date']>;
  evaluationPeriod?: Maybe<MemberLoyaltyProgrammeEvaluationPeriod>;
  automaticMemberLoyaltyLevelUpgrade?: Maybe<Scalars['Boolean']>;
  automaticMemberLoyaltyLevelDowngrade?: Maybe<Scalars['Boolean']>;
  badgeSet?: Maybe<BadgeSet>;
  levels?: Maybe<Array<MemberLoyaltyLevelInput>>;
};

export type UpdateMemberNoteInput = {
  header?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  pinned?: Maybe<Scalars['Boolean']>;
};

export type UpdateMemberTagInput = {
  name?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  brandLinkage?: Maybe<Scalars['Boolean']>;
};

export type UpdateMessageInput = {
  title: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  targetMembers?: Maybe<Array<Scalars['ID']>>;
  targetMemberLevels?: Maybe<Array<Scalars['ID']>>;
  targetMemberLoyaltyLevels?: Maybe<Array<Scalars['ID']>>;
  excludedMembers?: Maybe<Array<Scalars['ID']>>;
  excludedMemberLevels?: Maybe<Array<Scalars['ID']>>;
  excludedMemberLoyaltyLevels?: Maybe<Array<Scalars['ID']>>;
  schedule?: Maybe<Scalars['DateTime']>;
};

export type UpdateMissionInput = {
  name?: Maybe<Scalars['String']>;
  automaticEnrollment?: Maybe<Scalars['Boolean']>;
  enrollmentStartDate?: Maybe<Scalars['Date']>;
  enrollmentEndDate?: Maybe<Scalars['Date']>;
  requireBankAccount?: Maybe<Scalars['Boolean']>;
  maximumEnrolleesCount?: Maybe<Scalars['Int']>;
  maximumEnrolleesCountPerIpAddress?: Maybe<Scalars['Int']>;
  maximumEnrolleesCountPerName?: Maybe<Scalars['Int']>;
  automaticPayout?: Maybe<Scalars['Boolean']>;
  generateRebate?: Maybe<Scalars['Boolean']>;
  generateMemberLoyaltyProgress?: Maybe<Scalars['Boolean']>;
  cyclesCount?: Maybe<Scalars['Int']>;
  dayRewardEnabled?: Maybe<Scalars['Boolean']>;
  dayRewardAmount?: Maybe<Scalars['RoundedFloat']>;
  dayRewardMultiplier?: Maybe<Scalars['Boolean']>;
  dayRewardPointsRequirement?: Maybe<Scalars['Int']>;
  dayRewardTurnoverRequirementMultiplier?: Maybe<Scalars['RoundedFloat']>;
  dayRewardType?: Maybe<MissionRewardType>;
  days?: Maybe<Array<MissionDayInput>>;
  memberLoyaltyGroups?: Maybe<Array<MissionMemberLoyaltyGroupInput>>;
  milestones?: Maybe<Array<MissionMilestoneInput>>;
  milestonesEnabled?: Maybe<Scalars['Boolean']>;
};

export type UpdateOfflineBankTransferWithdrawalMethodInput = {
  name?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  transactionFee?: Maybe<Scalars['Float']>;
  transactionFeeType?: Maybe<TransactionFeeType>;
  provider?: Maybe<WithdrawalMethodProvider>;
  bank?: Maybe<Bank>;
  accountNumber?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
};

export type UpdateOnlineWithdrawalMethodInput = {
  name?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  transactionFee?: Maybe<Scalars['Float']>;
  transactionFeeType?: Maybe<TransactionFeeType>;
  provider?: Maybe<WithdrawalMethodProvider>;
  withdrawalProvider?: Maybe<Scalars['ID']>;
  paymentChannel?: Maybe<Scalars['String']>;
};

export type UpdateOperatorInput = {
  password?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  realName?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  email?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  wechatId?: Maybe<Scalars['String']>;
  qqId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  permissionGroups?: Maybe<Array<Scalars['ID']>>;
  status?: Maybe<OperatorStatus>;
  otpValidationEnabled?: Maybe<Scalars['Boolean']>;
};

export type UpdatePasswordInput = {
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type UpdatePaymentMethodInput = {
  name?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  excludedMemberLevel?: Maybe<Array<Scalars['String']>>;
  memberLoyaltyLevel?: Maybe<Array<Scalars['String']>>;
  minimumDepositAmount?: Maybe<Scalars['Float']>;
  maximumDepositAmount?: Maybe<Scalars['Float']>;
  instructionText?: Maybe<Scalars['String']>;
  maximumDepositAmountPerDay?: Maybe<Scalars['Float']>;
  depositExpiry?: Maybe<Scalars['Float']>;
  turnoverRequirementMultiplier?: Maybe<Scalars['Float']>;
  suggestedAmounts?: Maybe<Array<Maybe<Scalars['Float']>>>;
  transactionFee?: Maybe<Scalars['Float']>;
  transactionFeeType?: Maybe<TransactionFeeType>;
  allowCustomAmount?: Maybe<Scalars['Boolean']>;
  bank?: Maybe<Bank>;
  accountNumber?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['ID']>;
  depositProvider?: Maybe<Scalars['String']>;
  paymentChannel?: Maybe<Scalars['String']>;
  cryptocurrency?: Maybe<Cryptocurrency>;
  blockchain?: Maybe<Blockchain>;
  blockchainAddress?: Maybe<Scalars['String']>;
  minimumConfirmationsCount?: Maybe<Scalars['Int']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  exchangeRateSource?: Maybe<ExchangeRateSource>;
  exchangeRateUpdateFrequency?: Maybe<Scalars['String']>;
  exchangeRateSpread?: Maybe<Scalars['Float']>;
  provider?: Maybe<PaymentMethodProvider>;
};

export type UpdatePermissionGroupInput = {
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Scalars['String']>>;
};

export type UpdatePromoInput = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<PromoType>;
  status?: Maybe<PromoStatus>;
  event?: Maybe<PromoEvent>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  platform?: Maybe<Platform>;
  includedMemberTags?: Maybe<Array<Maybe<Scalars['ID']>>>;
  excludedMemberTags?: Maybe<Array<Maybe<Scalars['ID']>>>;
  claimType?: Maybe<PromoClaimType>;
  awardPreRequisite?: Maybe<PromoAwardPreRequisiteType>;
  turnoverRequirementMultiplier?: Maybe<Scalars['Float']>;
  wagerContributions?: Maybe<Array<Maybe<CreatePromoWagerContributionInput>>>;
  autoCancel?: Maybe<Scalars['Boolean']>;
  maintainingBalance?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['Currency']>;
  amount?: Maybe<Scalars['Float']>;
  amountPercentage?: Maybe<Scalars['Float']>;
  maxMemberCount?: Maybe<Scalars['Int']>;
  awardType?: Maybe<PromoAwardType>;
  awardCap?: Maybe<Scalars['Float']>;
  minAmount?: Maybe<Scalars['Float']>;
  maxAmount?: Maybe<Scalars['Float']>;
  claimCountLimit?: Maybe<Scalars['Int']>;
  paymentMethods?: Maybe<Array<Maybe<Scalars['ID']>>>;
  maxPayoutAmount?: Maybe<Scalars['Float']>;
};

export type UpdateRebateGroupInput = {
  name?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  assignedMembers?: Maybe<Array<Scalars['ID']>>;
  qualifyingMemberLoyaltyLevels?: Maybe<Array<Scalars['ID']>>;
  excludedMemberLevels?: Maybe<Array<Scalars['ID']>>;
  validityDateTimeRange?: Maybe<DateTimeRangeInput>;
  automaticPayout?: Maybe<Scalars['Boolean']>;
  turnoverRequirementMultiplier?: Maybe<Scalars['Float']>;
  claimOffsetDuration?: Maybe<Scalars['Duration']>;
  claimExpiryDuration?: Maybe<Scalars['Duration']>;
  payoutExpiryDuration?: Maybe<Scalars['Duration']>;
  minimumPayout?: Maybe<Scalars['Float']>;
  maximumPayout?: Maybe<Scalars['Float']>;
  type?: Maybe<RebateGroupType>;
  percentages?: Maybe<Array<RebatePercentageInput>>;
  payoutSettlementPeriod?: Maybe<RebatePayoutSettlementPeriod>;
  payoutSettlementDayOfWeek?: Maybe<Scalars['DayOfWeek']>;
  payoutSettlementDayOfMonth?: Maybe<Scalars['DayOfMonth']>;
  payoutSettlementTime?: Maybe<Scalars['Time']>;
  payoutSettlementMonth?: Maybe<Scalars['Int']>;
  levels?: Maybe<Array<RebateLevelInput>>;
};

export type UpdateRebateGroupInput_Next = {
  name?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  assignedMembers?: Maybe<Array<Scalars['ID']>>;
  qualifyingMemberLoyaltyLevels?: Maybe<Array<Scalars['ID']>>;
  excludedMemberLevels?: Maybe<Array<Scalars['ID']>>;
  validityDateTimeRange?: Maybe<DateTimeRangeInput>;
  automaticPayout?: Maybe<Scalars['Boolean']>;
  turnoverRequirementMultiplier?: Maybe<Scalars['Float']>;
  claimOffsetDuration?: Maybe<Scalars['Duration']>;
  claimExpiryDuration?: Maybe<Scalars['Duration']>;
  payoutExpiryDuration?: Maybe<Scalars['Duration']>;
  minimumPayout?: Maybe<Scalars['Float']>;
  maximumPayout?: Maybe<Scalars['Float']>;
  type?: Maybe<RebateGroupType>;
  percentages?: Maybe<Array<RebatePercentageInput>>;
  payoutSettlementPeriod?: Maybe<RebatePayoutSettlementPeriod>;
  payoutSettlementDayOfWeek?: Maybe<Scalars['DayOfWeek']>;
  payoutSettlementDayOfMonth?: Maybe<Scalars['DayOfMonth']>;
  payoutSettlementTime?: Maybe<Scalars['Time']>;
  payoutSettlementMonth?: Maybe<Scalars['Int']>;
  levels?: Maybe<Array<RebateLevelInput>>;
};

export type UpdateUniversalVendorSettingsInput = {
  disabled: Scalars['Boolean'];
  hidden: Scalars['Boolean'];
  details: Scalars['JSON'];
  gameTypeSettings: VendorSettingsGameTypeSettingsInput;
};

export type UpdateVendorGroupInput = {
  name?: Maybe<Scalars['String']>;
  GGRCharges?: Maybe<Array<VendorGroupGgrChargeInput>>;
  enabled?: Maybe<Scalars['Boolean']>;
};

export type UpdateVendorInput = {
  image?: Maybe<Scalars['JSON']>;
  desktopImage?: Maybe<Scalars['JSON']>;
  horizontalImage?: Maybe<Scalars['JSON']>;
  enabled?: Maybe<Scalars['Boolean']>;
  visible?: Maybe<Scalars['Boolean']>;
  externalLobby?: Maybe<Scalars['JSON']>;
  status?: Maybe<VendorStatus>;
};

export type UpdateVendorSettingsInput = {
  disabled: Scalars['Boolean'];
  hidden: Scalars['Boolean'];
  details?: Maybe<Scalars['JSON']>;
  gameTypeSettings: VendorSettingsGameTypeSettingsInput;
};

export type UpdateVendorUsageReportInput = {
  scheduledDateTime?: Maybe<Scalars['DateTime']>;
};

export type UpdateWechatWithdrawalMethodInput = {
  name?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  transactionFee?: Maybe<Scalars['Float']>;
  transactionFeeType?: Maybe<TransactionFeeType>;
  provider?: Maybe<WithdrawalMethodProvider>;
  nickname?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['ID']>;
};

export type UpdateWithdrawalInput = {
  remarks?: Maybe<Scalars['String']>;
  handlingFeeApplied?: Maybe<Scalars['Float']>;
  withdrawalTaxFeeApplied?: Maybe<Scalars['Float']>;
  promoDeductionFeeApplied?: Maybe<Scalars['Float']>;
  withdrawalLimitFeeApplied?: Maybe<Scalars['Float']>;
};

export type UpdateWithdrawalProviderInput = {
  name?: Maybe<Scalars['String']>;
  apiKey?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  merchantCode?: Maybe<Scalars['String']>;
  midPayDirectPaymentGateway?: Maybe<Scalars['ID']>;
  credentialValues?: Maybe<Array<Scalars['String']>>;
};

export type Vendor = {
  __typename?: 'Vendor';
  id: Scalars['ID'];
  type: VendorType;
  gameTypes: Array<GameType>;
  defaultImage: Scalars['URL'];
  image?: Maybe<File>;
  desktopImage?: Maybe<File>;
  horizontalImage?: Maybe<File>;
  name?: Maybe<Scalars['String']>;
  shortcode: Scalars['String'];
  externalLobby?: Maybe<Scalars['Boolean']>;
  status: VendorStatus;
  gameCategories: Array<Maybe<Scalars['String']>>;
  gameSubCategories: Array<Maybe<Scalars['String']>>;
};

export type VendorImageArgs = {
  gameCategory?: Maybe<Scalars['ID']>;
};

export type VendorDesktopImageArgs = {
  gameCategory?: Maybe<Scalars['ID']>;
};

export type VendorHorizontalImageArgs = {
  gameCategory?: Maybe<Scalars['ID']>;
};

export type VendorNameArgs = {
  language?: Maybe<Scalars['Language']>;
};

export type VendorExternalLobbyArgs = {
  gameCategory?: Maybe<Scalars['ID']>;
};

export type VendorAverageEffectiveBetInput = {
  gameType?: Maybe<GameType>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
};

export enum VendorCode {
  Kagaming = 'KAGAMING',
  Megawin = 'MEGAWIN',
  PpTransfer = 'PP_TRANSFER',
  SagamingTransfer = 'SAGAMING_TRANSFER',
  Kenogame = 'KENOGAME',
  Hc = 'HC',
  Goldenf = 'GOLDENF',
  Allbet = 'ALLBET',
  Pt = 'PT',
  Sagaming = 'SAGAMING',
  Cq9 = 'CQ9',
  Jdb = 'JDB',
  Mgdashur = 'MGDASHUR',
  Ky = 'KY',
  Spadegaming = 'SPADEGAMING',
  Bbin = 'BBIN',
  Sgwin = 'SGWIN',
  GpkCq9 = 'GPK_CQ9',
  GpkJdb = 'GPK_JDB',
  GpkSg = 'GPK_SG',
  GpkBb = 'GPK_BB',
  GpkAe = 'GPK_AE',
  GpkTh = 'GPK_TH',
  GpkAg = 'GPK_AG',
  GpkKg = 'GPK_KG',
  GpkNw = 'GPK_NW',
  GpkRgtp = 'GPK_RGTP',
  GpkAt = 'GPK_AT',
  GpkRgTycoon = 'GPK_RG_TYCOON',
  GpkMgplus = 'GPK_MGPLUS',
  GpkRgKing = 'GPK_RG_KING',
  GpkRgComing = 'GPK_RG_COMING',
  GpkRgMill = 'GPK_RG_MILL',
  GpkRgCandy = 'GPK_RG_CANDY',
  GpkRgJewels = 'GPK_RG_JEWELS',
  GpkFg = 'GPK_FG',
  GpkBg = 'GPK_BG',
  GpkAp = 'GPK_AP',
  GpkTgp = 'GPK_TGP',
  GpkSw = 'GPK_SW',
  Mgplus = 'MGPLUS',
  Newworld = 'NEWWORLD',
  V365Cards = 'V365CARDS',
  Ebet = 'EBET',
  SpadegamingTransfer = 'SPADEGAMING_TRANSFER',
  JdbTransfer = 'JDB_TRANSFER',
  Longcheng = 'LONGCHENG',
  Bg = 'BG',
  Pgsoft = 'PGSOFT',
  PgsoftTransfer = 'PGSOFT_TRANSFER',
  Cmd = 'CMD',
  FgTransfer = 'FG_TRANSFER',
  Legpoker = 'LEGPOKER',
  Onebook = 'ONEBOOK',
  EbetTransfer = 'EBET_TRANSFER',
  NetentTransfer = 'NETENT_TRANSFER',
  Imesportsbull = 'IMESPORTSBULL',
  HabaneroTransfer = 'HABANERO_TRANSFER',
  EvoliveTransfer = 'EVOLIVE_TRANSFER',
  Iglivecasino = 'IGLIVECASINO',
  Ag = 'AG',
  Sbo = 'SBO',
  BooongoTransfer = 'BOOONGO_TRANSFER',
}

export type VendorCodeQueryOperatorInput = {
  eq?: Maybe<VendorCode>;
  ne?: Maybe<VendorCode>;
  in?: Maybe<Array<Maybe<VendorCode>>>;
  nin?: Maybe<Array<Maybe<VendorCode>>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type VendorGameChart = {
  __typename?: 'VendorGameChart';
  dataPoints: Array<Maybe<DataPoint>>;
};

export type VendorGameChartInput = {
  metricsType: VendorGameMetricsType;
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
  gameType: GameType;
};

export type VendorGameMetricsInput = {
  type: VendorGameMetricsType;
  gameType: GameType;
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
};

export enum VendorGameMetricsType {
  TotalTurnover = 'TOTAL_TURNOVER',
  TotalEffectiveBet = 'TOTAL_EFFECTIVE_BET',
  TotalWinloss = 'TOTAL_WINLOSS',
  TotalBetsCount = 'TOTAL_BETS_COUNT',
  ProfitRatio = 'PROFIT_RATIO',
}

export type VendorGameReportFilterInput = {
  vendor?: Maybe<IdQueryOperatorInput>;
  gameType?: Maybe<GameTypeQueryOperatorInput>;
};

export type VendorGameReportItem = {
  __typename?: 'VendorGameReportItem';
  id: Scalars['ID'];
  vendor: Vendor;
  gameType: GameType;
  betsCount: Scalars['Int'];
  turnover: Scalars['RoundedFloat'];
  effectiveBet: Scalars['RoundedFloat'];
  winloss: Scalars['RoundedFloat'];
  profitRatio: Scalars['RoundedFloat'];
};

export type VendorGameReportItemBetsCountArgs = {
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
};

export type VendorGameReportItemTurnoverArgs = {
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
};

export type VendorGameReportItemEffectiveBetArgs = {
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
};

export type VendorGameReportItemWinlossArgs = {
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
};

export type VendorGameReportItemProfitRatioArgs = {
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
};

export type VendorGameReportItemsConnection = {
  __typename?: 'VendorGameReportItemsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<VendorGameReportItemsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type VendorGameReportItemsConnectionEdge = {
  __typename?: 'VendorGameReportItemsConnectionEdge';
  cursor: Scalars['Binary'];
  node: VendorGameReportItem;
};

export type VendorGroup = {
  __typename?: 'VendorGroup';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  GGRCharges: Array<VendorGroupGgrCharge>;
  status: VendorGroupStatus;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeSubmitted?: Maybe<Scalars['DateTime']>;
  dateTimeUpdated: Scalars['DateTime'];
  vendors: Array<Vendor>;
  admins: Array<Admin>;
};

export type VendorGroupGgrCharge = {
  __typename?: 'VendorGroupGGRCharge';
  vendor: Vendor;
  minimum?: Maybe<Scalars['RoundedFloat']>;
  maximum?: Maybe<Scalars['RoundedFloat']>;
  levels?: Maybe<Array<VendorGroupGgrChargeLevel>>;
};

export type VendorGroupGgrChargeInput = {
  vendor: Scalars['ID'];
  minimum?: Maybe<Scalars['Float']>;
  maximum?: Maybe<Scalars['Float']>;
  levels?: Maybe<Array<VendorGroupGgrChargeLevelInput>>;
};

export type VendorGroupGgrChargeLevel = {
  __typename?: 'VendorGroupGGRChargeLevel';
  minimum: Scalars['RoundedFloat'];
  percentage: Scalars['RoundedFloat'];
};

export type VendorGroupGgrChargeLevelInput = {
  minimum: Scalars['Float'];
  percentage: Scalars['Float'];
};

export type VendorGroupsConnection = {
  __typename?: 'VendorGroupsConnection';
  totalCount: Scalars['Int'];
  edges: Array<VendorGroupsConnectionEdge>;
  pageInfo: PageInfo;
};

export type VendorGroupsConnectionEdge = {
  __typename?: 'VendorGroupsConnectionEdge';
  cursor: Scalars['Binary'];
  node: VendorGroup;
};

export type VendorGroupsFilterInput = {
  id?: Maybe<IdQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<VendorGroupStatusQueryOperatorInput>;
  admins?: Maybe<IdArrayQueryOperatorInput>;
  vendors?: Maybe<IdArrayQueryOperatorInput>;
};

export enum VendorGroupStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Draft = 'DRAFT',
}

export type VendorGroupStatusQueryOperatorInput = {
  eq?: Maybe<VendorGroupStatus>;
  ne?: Maybe<VendorGroupStatus>;
  in?: Maybe<Array<VendorGroupStatus>>;
  nin?: Maybe<Array<VendorGroupStatus>>;
};

export type VendorPlatformFee = {
  __typename?: 'VendorPlatformFee';
  gameType: GameType;
  vendor: Vendor;
  minimumCharge?: Maybe<Scalars['RoundedFloat']>;
  maximumCharge?: Maybe<Scalars['RoundedFloat']>;
  chargeLevels: Array<VendorPlatformFeeLevel>;
};

export type VendorPlatformFeeInput = {
  vendor: Scalars['ID'];
  gameType: GameType;
  minimumCharge?: Maybe<Scalars['Float']>;
  maximumCharge?: Maybe<Scalars['Float']>;
  chargeLevels: Array<VendorPlatformFeeLevelInput>;
};

export type VendorPlatformFeeLevel = {
  __typename?: 'VendorPlatformFeeLevel';
  minimumTotalWinloss: Scalars['Int'];
  percentage: Scalars['RoundedFloat'];
};

export type VendorPlatformFeeLevelInput = {
  minimumTotalWinloss: Scalars['Int'];
  percentage: Scalars['Float'];
};

export type VendorsConnection = {
  __typename?: 'VendorsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<VendorsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type VendorsConnectionEdge = {
  __typename?: 'VendorsConnectionEdge';
  cursor: Scalars['Binary'];
  node: Vendor;
};

export type VendorSettings = {
  __typename?: 'VendorSettings';
  vendor: VendorCode;
  admin: Scalars['ID'];
  context: VendorSettingsContext;
  disabled: Scalars['Boolean'];
  hidden: Scalars['Boolean'];
  gameTypeSettings: VendorSettingsGameTypeSettings;
  details: Scalars['JSON'];
};

export enum VendorSettingsContext {
  Admin = 'ADMIN',
  SuperAdmin = 'SUPER_ADMIN',
}

export type VendorSettingsContextQueryOperatorInput = {
  eq?: Maybe<VendorSettingsContext>;
  ne?: Maybe<VendorSettingsContext>;
  in?: Maybe<Array<Maybe<VendorSettingsContext>>>;
  nin?: Maybe<Array<Maybe<VendorSettingsContext>>>;
};

export type VendorSettingsGameTypeSettings = {
  __typename?: 'VendorSettingsGameTypeSettings';
  SLOT?: Maybe<GameTypeSettings>;
  LIVE?: Maybe<GameTypeSettings>;
  POKER?: Maybe<GameTypeSettings>;
  FISH?: Maybe<GameTypeSettings>;
  SPORTSBOOK?: Maybe<GameTypeSettings>;
  LOTTERY?: Maybe<GameTypeSettings>;
  TABLE?: Maybe<GameTypeSettings>;
  SCRATCH?: Maybe<GameTypeSettings>;
  VIRTUAL?: Maybe<GameTypeSettings>;
  CASUAL?: Maybe<GameTypeSettings>;
  VIDEO_SLOTS?: Maybe<GameTypeSettings>;
  VIDEO_POKER?: Maybe<GameTypeSettings>;
  BLACKJACK?: Maybe<GameTypeSettings>;
  ROULETTE?: Maybe<GameTypeSettings>;
  GAME_SHOWS?: Maybe<GameTypeSettings>;
  OTHER_TABLE_GAMES?: Maybe<GameTypeSettings>;
  BACCARAT?: Maybe<GameTypeSettings>;
  JACKPOT_SLOTS?: Maybe<GameTypeSettings>;
  OTHER_LIVE_GAMES?: Maybe<GameTypeSettings>;
  SCRATCH_CARDS?: Maybe<GameTypeSettings>;
  VIRTUAL_SPORTS?: Maybe<GameTypeSettings>;
  DICE?: Maybe<GameTypeSettings>;
  BINGO?: Maybe<GameTypeSettings>;
  ARCADE_GAMES?: Maybe<GameTypeSettings>;
  DRAGON_TIGER?: Maybe<GameTypeSettings>;
  HILO?: Maybe<GameTypeSettings>;
  CRASH_GAMES?: Maybe<GameTypeSettings>;
  CRASH?: Maybe<GameTypeSettings>;
  LIVE_DEALER?: Maybe<GameTypeSettings>;
  MINI_GAMES?: Maybe<GameTypeSettings>;
  RNG?: Maybe<GameTypeSettings>;
};

export type VendorSettingsGameTypeSettingsInput = {
  SLOT?: Maybe<GameTypeSettingsInput>;
  LIVE?: Maybe<GameTypeSettingsInput>;
  POKER?: Maybe<GameTypeSettingsInput>;
  FISH?: Maybe<GameTypeSettingsInput>;
  SPORTSBOOK?: Maybe<GameTypeSettingsInput>;
  LOTTERY?: Maybe<GameTypeSettingsInput>;
  TABLE?: Maybe<GameTypeSettingsInput>;
  SCRATCH?: Maybe<GameTypeSettingsInput>;
};

export type VendorSettingsItemsConnection = {
  __typename?: 'VendorSettingsItemsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<VendorSettingsItemsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type VendorSettingsItemsConnectionEdge = {
  __typename?: 'VendorSettingsItemsConnectionEdge';
  cursor: Scalars['Binary'];
  node: VendorSettings;
};

export type VendorSettingsItemsFilterInput = {
  admin?: Maybe<IdQueryOperatorInput>;
  vendor?: Maybe<VendorCodeQueryOperatorInput>;
  context?: Maybe<VendorSettingsContextQueryOperatorInput>;
};

export type VendorsFilterInput = {
  id?: Maybe<IdQueryOperatorInput>;
  type?: Maybe<VendorTypeQueryOperatorInput>;
  gameTypes?: Maybe<GameTypeArrayQueryOperatorInput>;
  status?: Maybe<VendorStatusQueryOperatorInput>;
  shortcode?: Maybe<StringQueryOperatorInput>;
};

export enum VendorStatus {
  Active = 'ACTIVE',
  InMaintenance = 'IN_MAINTENANCE',
}

export type VendorStatusQueryOperatorInput = {
  eq?: Maybe<VendorStatus>;
  ne?: Maybe<VendorStatus>;
  in?: Maybe<Array<VendorStatus>>;
  nin?: Maybe<Array<VendorStatus>>;
};

export type VendorTotalBetsCountInput = {
  gameType?: Maybe<GameType>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
};

export type VendorTotalEffectiveBetInput = {
  gameType?: Maybe<GameType>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
};

export enum VendorType {
  Transfer = 'TRANSFER',
  Seamless = 'SEAMLESS',
}

export type VendorTypeQueryOperatorInput = {
  eq?: Maybe<VendorType>;
  ne?: Maybe<VendorType>;
  in?: Maybe<Array<VendorType>>;
  nin?: Maybe<Array<VendorType>>;
};

export type VendorUsageReport = {
  __typename?: 'VendorUsageReport';
  id: Scalars['ID'];
  timezone?: Maybe<Scalars['Timezone']>;
  creator: Account;
  dateTimeCreated: Scalars['DateTime'];
  filter: VendorUsageReportFilter;
  report?: Maybe<File>;
  scheduledDateTime?: Maybe<Scalars['DateTime']>;
  status: VendorUsageReportStatus;
};

export type VendorUsageReportFilter = {
  __typename?: 'VendorUsageReportFilter';
  dateTimeProcessed?: Maybe<DateTimeQueryOperator>;
  vendor?: Maybe<IdQueryOperator>;
};

export type VendorUsageReportFilterInput = {
  status?: Maybe<StringQueryOperatorInput>;
};

export type VendorUsageReportsConnection = {
  __typename?: 'VendorUsageReportsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<VendorUsageReportsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type VendorUsageReportsConnectionEdge = {
  __typename?: 'VendorUsageReportsConnectionEdge';
  cursor: Scalars['Binary'];
  node: VendorUsageReport;
};

export enum VendorUsageReportStatus {
  Generating = 'GENERATING',
  Generated = 'GENERATED',
  Scheduled = 'SCHEDULED',
}

export type VerifyPasswordResetInput = {
  verificationCode: Scalars['String'];
};

export type WechatDeposit = Deposit & {
  __typename?: 'WechatDeposit';
  id: Scalars['ID'];
  amount: Scalars['RoundedFloat'];
  account: Member;
  processingTime?: Maybe<Scalars['Int']>;
  paymentMethod?: Maybe<PaymentMethod>;
  status: DepositStatus;
  processor?: Maybe<Account>;
  serialCode: Scalars['String'];
  firstDeposit?: Maybe<Scalars['Boolean']>;
  remarks?: Maybe<Scalars['String']>;
  manualAdjustment: Scalars['Boolean'];
  transactionReference?: Maybe<Scalars['String']>;
  depositorName?: Maybe<Scalars['String']>;
  transactionDetails: DepositEventDetails;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeProcessed?: Maybe<Scalars['DateTime']>;
  baseCurrency?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  amountInBaseCurrency?: Maybe<Scalars['RoundedFloat']>;
  isCrypto?: Maybe<Scalars['Boolean']>;
};

export type WechatPaymentMethod = PaymentMethod & {
  __typename?: 'WechatPaymentMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  currentAccumulation: Scalars['RoundedFloat'];
  memberLoyaltyLevel?: Maybe<Array<MemberLoyaltyLevel>>;
  excludedMemberLevel?: Maybe<Array<MemberLevel>>;
  minimumDepositAmount: Scalars['RoundedFloat'];
  maximumDepositAmount: Scalars['RoundedFloat'];
  instructionText?: Maybe<Scalars['String']>;
  maximumDepositAmountPerDay: Scalars['RoundedFloat'];
  depositExpiry: Scalars['RoundedFloat'];
  turnoverRequirementMultiplier: Scalars['RoundedFloat'];
  suggestedAmounts: Array<Scalars['RoundedFloat']>;
  transactionFee: Scalars['RoundedFloat'];
  transactionFeeType?: Maybe<TransactionFeeType>;
  allowCustomAmount: Scalars['Boolean'];
  updateable: Scalars['Boolean'];
  nickname: Scalars['String'];
  image?: Maybe<File>;
  provider?: Maybe<PaymentMethodProvider>;
};

export type WechatWithdrawalMethod = WithdrawalMethod & {
  __typename?: 'WechatWithdrawalMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  updateable: Scalars['Boolean'];
  transactionFee: Scalars['RoundedFloat'];
  transactionFeeType: TransactionFeeType;
  provider?: Maybe<WithdrawalMethodProvider>;
  nickname: Scalars['String'];
  accountNumber?: Maybe<Scalars['String']>;
};

export type Withdrawal = {
  __typename?: 'Withdrawal';
  id: Scalars['ID'];
  serialCode: Scalars['String'];
  bankAccount?: Maybe<BankAccount>;
  amount: Scalars['RoundedFloat'];
  account: Member;
  status: WithdrawalStatus;
  externalStatus?: Maybe<WithdrawalExternalStatus>;
  withdrawalMethod?: Maybe<WithdrawalMethod>;
  processor?: Maybe<Account>;
  externalRemarks?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  firstWithdrawal: Scalars['Boolean'];
  handlingFee?: Maybe<Scalars['RoundedFloat']>;
  withdrawalTaxFee?: Maybe<Scalars['RoundedFloat']>;
  promoDeductionFee?: Maybe<Scalars['RoundedFloat']>;
  withdrawalLimitFee?: Maybe<Scalars['RoundedFloat']>;
  hexoPayCreditCard?: Maybe<HexoPayCreditCardDetails>;
  hexoPayUid?: Maybe<Scalars['String']>;
  manualBankTransfer: Scalars['Boolean'];
  handlingFeeApplied?: Maybe<Scalars['RoundedFloat']>;
  withdrawalTaxFeeApplied?: Maybe<Scalars['RoundedFloat']>;
  promoDeductionFeeApplied?: Maybe<Scalars['RoundedFloat']>;
  withdrawalLimitFeeApplied?: Maybe<Scalars['RoundedFloat']>;
  manualAdjustment?: Maybe<Scalars['ID']>;
  oneTimeTurnoverDetectionCheck: ComplainceCheckStatus;
  promoHunterCheck: ComplainceCheckStatus;
  contraBetCheck: ComplainceCheckStatus;
  ipCollisionOffenderCheck: ComplainceCheckStatus;
  martingaleBetOffenderCheck: ComplainceCheckStatus;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeProcessed?: Maybe<Scalars['DateTime']>;
  processingTime: Scalars['Int'];
  transactionReference?: Maybe<Scalars['String']>;
  transactionDetails?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  amountInBaseCurrency?: Maybe<Scalars['RoundedFloat']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  isCrypto?: Maybe<Scalars['Boolean']>;
};

export type WithdrawalComplianceCheckConfig = {
  __typename?: 'WithdrawalComplianceCheckConfig';
  enabled: Scalars['Boolean'];
  turnoverRequirementEnabled: Scalars['Boolean'];
  turnoverRequirementActivationThreshold: Scalars['Float'];
  ipCollisionOffenderEnabled: Scalars['Boolean'];
  contraBetOffenderEnabled: Scalars['Boolean'];
  contraBetOffenderActivationThreshold: Scalars['Float'];
  martingaleBetOffenderEnabled: Scalars['Boolean'];
  martingaleBetOffenderActivationThreshold: Scalars['Float'];
  bonusHunterEnabled: Scalars['Boolean'];
  bonusHunterActivationThreshold: Scalars['Float'];
};

export type WithdrawalComplianceCheckSetting = {
  __typename?: 'WithdrawalComplianceCheckSetting';
  enabled: Scalars['Boolean'];
  complianceCheckSettings: Array<ComplianceCheckSetting>;
};

export enum WithdrawalExternalStatus {
  Approved = 'APPROVED',
  Failed = 'FAILED',
  Processing = 'PROCESSING',
  Rejected = 'REJECTED',
}

export enum WithdrawalHandlingFeeType {
  Always = 'ALWAYS',
  Unmet = 'UNMET',
}

export type WithdrawalManualAdjustment = ManualAdjustment & {
  __typename?: 'WithdrawalManualAdjustment';
  id: Scalars['ID'];
  type: ManualAdjustmentType;
  account: Account;
  amount: Scalars['RoundedFloat'];
  remarks: Scalars['String'];
  dateTimeCreated: Scalars['DateTime'];
  actual: Scalars['Boolean'];
  turnoverRequirementMultiplier: Scalars['RoundedFloat'];
};

export type WithdrawalMethod = {
  id: Scalars['ID'];
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  updateable: Scalars['Boolean'];
  transactionFee: Scalars['RoundedFloat'];
  transactionFeeType: TransactionFeeType;
  provider?: Maybe<WithdrawalMethodProvider>;
};

export type WithdrawalMethodFilterInput = {
  name?: Maybe<StringQueryOperatorInput>;
  nickname?: Maybe<StringQueryOperatorInput>;
  enabled?: Maybe<BooleanQueryOperatorInput>;
  type?: Maybe<WithdrawalMethodTypeQueryOperatorInput>;
  withdrawalProvider?: Maybe<IdQueryOperatorInput>;
  bank?: Maybe<BankQueryOperatorInput>;
  provider?: Maybe<StringQueryOperatorInput>;
};

export enum WithdrawalMethodProvider {
  Alphapo = 'ALPHAPO',
  TripleA = 'TRIPLE_A',
}

export type WithdrawalMethodsConnection = {
  __typename?: 'WithdrawalMethodsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<WithdrawalMethodsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type WithdrawalMethodsConnectionEdge = {
  __typename?: 'WithdrawalMethodsConnectionEdge';
  cursor: Scalars['Binary'];
  node: WithdrawalMethod;
};

export enum WithdrawalMethodType {
  OfflineBankTransferWithdrawalMethod = 'OFFLINE_BANK_TRANSFER_WITHDRAWAL_METHOD',
  AlipayWithdrawalMethod = 'ALIPAY_WITHDRAWAL_METHOD',
  WechatWithdrawalMethod = 'WECHAT_WITHDRAWAL_METHOD',
  OnlineBankTransferWithdrawalMethod = 'ONLINE_BANK_TRANSFER_WITHDRAWAL_METHOD',
  OnlineAlipayWithdrawalMethod = 'ONLINE_ALIPAY_WITHDRAWAL_METHOD',
  OnlineWechatWithdrawalMethod = 'ONLINE_WECHAT_WITHDRAWAL_METHOD',
  ManualAdjustmentWithdrawalMethod = 'MANUAL_ADJUSTMENT_WITHDRAWAL_METHOD',
  HexopayWithdrawalMethod = 'HEXOPAY_WITHDRAWAL_METHOD',
  NetellerWithdrawalMethod = 'NETELLER_WITHDRAWAL_METHOD',
  SkrillWithdrawalMethod = 'SKRILL_WITHDRAWAL_METHOD',
  CoinifyWithdrawalMethod = 'COINIFY_WITHDRAWAL_METHOD',
  WonkapayWithdrawalMethod = 'WONKAPAY_WITHDRAWAL_METHOD',
  CryptocurrencyWithdrawalMethod = 'CRYPTOCURRENCY_WITHDRAWAL_METHOD',
  AlphapoWithdrawalMethod = 'ALPHAPO_WITHDRAWAL_METHOD',
  AstropayWalletWithdrawalMethod = 'ASTROPAY_WALLET_WITHDRAWAL_METHOD',
}

export type WithdrawalMethodTypeQueryOperatorInput = {
  eq?: Maybe<WithdrawalMethodType>;
  ne?: Maybe<WithdrawalMethodType>;
  in?: Maybe<Array<WithdrawalMethodType>>;
  nin?: Maybe<Array<Maybe<WithdrawalMethodType>>>;
};

export type WithdrawalProvider = {
  id: Scalars['ID'];
  name: Scalars['String'];
  type: WithdrawalProviderType;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeUpdated: Scalars['DateTime'];
};

export type WithdrawalProviderFilterInput = {
  id?: Maybe<IdQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  type?: Maybe<WithdrawalProviderTypeQueryOperatorInput>;
};

export type WithdrawalProvidersConnection = {
  __typename?: 'WithdrawalProvidersConnection';
  totalCount: Scalars['Int'];
  edges: Array<Maybe<WithdrawalProvidersConnectionEdge>>;
  pageInfo: PageInfo;
};

export type WithdrawalProvidersConnectionEdge = {
  __typename?: 'WithdrawalProvidersConnectionEdge';
  cursor: Scalars['Binary'];
  node: WithdrawalProvider;
};

export enum WithdrawalProviderType {
  Midpay = 'MIDPAY',
  MidpayDirect = 'MIDPAY_DIRECT',
  Diorpay = 'DIORPAY',
  TripleA = 'TRIPLE_A',
  Alphapo = 'ALPHAPO',
}

export type WithdrawalProviderTypeQueryOperatorInput = {
  eq?: Maybe<WithdrawalProviderType>;
  ne?: Maybe<WithdrawalProviderType>;
  in?: Maybe<Array<WithdrawalProviderType>>;
  nin?: Maybe<Array<WithdrawalProviderType>>;
};

export type WithdrawalsConnection = {
  __typename?: 'WithdrawalsConnection';
  totalWithdrawalRequested: Scalars['RoundedFloat'];
  totalWithdrawalApproved: Scalars['RoundedFloat'];
  totalWithdrawalProcessed: Scalars['RoundedFloat'];
  totalCount: Scalars['Int'];
  edges: Array<Maybe<WithdrawalsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type WithdrawalsConnectionEdge = {
  __typename?: 'WithdrawalsConnectionEdge';
  cursor: Scalars['Binary'];
  node: Withdrawal;
};

export type WithdrawalsFilterInput = {
  serialCode?: Maybe<StringQueryOperatorInput>;
  account?: Maybe<IdQueryOperatorInput>;
  memberLevel?: Maybe<IdQueryOperatorInput>;
  memberLoyaltyLevel?: Maybe<IdQueryOperatorInput>;
  amount?: Maybe<FloatQueryOperatorInput>;
  status?: Maybe<WithdrawalStatusQueryOperatorInput>;
  processor?: Maybe<IdQueryOperatorInput>;
  dateTimeCreated?: Maybe<DateTimeQueryOperatorInput>;
  processingTime?: Maybe<IntQueryOperatorInput>;
  handlingFeeApplied?: Maybe<FloatQueryOperatorInput>;
  withdrawalTaxFeeApplied?: Maybe<FloatQueryOperatorInput>;
  promoDeductionFeeApplied?: Maybe<FloatQueryOperatorInput>;
  withdrawalLimitFeeApplied?: Maybe<FloatQueryOperatorInput>;
  oneTimeTurnoverDetectionCheck?: Maybe<
    ComplainceCheckStatusQueryOperatorInput
  >;
  promoHunterCheck?: Maybe<ComplainceCheckStatusQueryOperatorInput>;
  contraBetCheck?: Maybe<ComplainceCheckStatusQueryOperatorInput>;
  ipCollisionOffenderCheck?: Maybe<ComplainceCheckStatusQueryOperatorInput>;
  martingaleBetOffenderCheck?: Maybe<ComplainceCheckStatusQueryOperatorInput>;
  withdrawalMethodType?: Maybe<WithdrawalMethodTypeQueryOperatorInput>;
  withdrawalMethod?: Maybe<IdQueryOperatorInput>;
  brandId?: Maybe<StringQueryOperatorInput>;
  platformId?: Maybe<StringQueryOperatorInput>;
  currency?: Maybe<CurrencyQueryOperatorInput>;
};

export enum WithdrawalStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Processing = 'PROCESSING',
  Failed = 'FAILED',
  OnHold = 'ON_HOLD',
  Canceled = 'CANCELED',
  Draft = 'DRAFT',
}

export type WithdrawalStatusQueryOperatorInput = {
  eq?: Maybe<WithdrawalStatus>;
  ne?: Maybe<WithdrawalStatus>;
  in?: Maybe<Array<WithdrawalStatus>>;
  nin?: Maybe<Array<Maybe<WithdrawalStatus>>>;
};

export enum WithdrawalTaxType {
  Relative = 'RELATIVE',
  Absolute = 'ABSOLUTE',
}

export type WonkaPayDeposit = Deposit & {
  __typename?: 'WonkaPayDeposit';
  id: Scalars['ID'];
  amount: Scalars['RoundedFloat'];
  account: Member;
  processingTime?: Maybe<Scalars['Int']>;
  paymentMethod?: Maybe<PaymentMethod>;
  status: DepositStatus;
  processor?: Maybe<Account>;
  serialCode: Scalars['String'];
  firstDeposit?: Maybe<Scalars['Boolean']>;
  remarks?: Maybe<Scalars['String']>;
  transactionReference?: Maybe<Scalars['String']>;
  manualAdjustment: Scalars['Boolean'];
  transactionDetails: DepositEventDetails;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeProcessed?: Maybe<Scalars['DateTime']>;
  baseCurrency?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  amountInBaseCurrency?: Maybe<Scalars['RoundedFloat']>;
  isCrypto?: Maybe<Scalars['Boolean']>;
};

export type WonkaPayPaymentMethod = PaymentMethod & {
  __typename?: 'WonkaPayPaymentMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  currentAccumulation: Scalars['RoundedFloat'];
  memberLoyaltyLevel?: Maybe<Array<MemberLoyaltyLevel>>;
  excludedMemberLevel?: Maybe<Array<MemberLevel>>;
  minimumDepositAmount: Scalars['RoundedFloat'];
  maximumDepositAmount: Scalars['RoundedFloat'];
  instructionText?: Maybe<Scalars['String']>;
  maximumDepositAmountPerDay: Scalars['RoundedFloat'];
  depositExpiry: Scalars['RoundedFloat'];
  turnoverRequirementMultiplier: Scalars['RoundedFloat'];
  suggestedAmounts: Array<Maybe<Scalars['RoundedFloat']>>;
  transactionFee: Scalars['RoundedFloat'];
  transactionFeeType?: Maybe<TransactionFeeType>;
  allowCustomAmount: Scalars['Boolean'];
  updateable: Scalars['Boolean'];
  provider?: Maybe<PaymentMethodProvider>;
};

export type WonkaPayWithdrawalMethod = WithdrawalMethod & {
  __typename?: 'WonkaPayWithdrawalMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  updateable: Scalars['Boolean'];
  transactionFee: Scalars['RoundedFloat'];
  transactionFeeType: TransactionFeeType;
  provider?: Maybe<WithdrawalMethodProvider>;
};
